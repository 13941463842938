/* eslint-disable max-len */
/* eslint-disable no-console */
import { useState, useEffect, Fragment } from 'react';
import PropTypes from 'prop-types';
import { Box, Container, Divider, Typography } from '@material-ui/core';
import { loremIpsum } from 'lorem-ipsum';

import ErrorLoggerObserver from '@rmm/error-tracker';

import videojs from 'video.js';
import DemoControls from '../DemoControls';
import VideoPlayer from '../VideoPlayer';

import useStyles from './styles';

const recommendations = [
	{
		mcpid: '7625676',
		title:
			'Could you tell the difference between this plant-based egg and a traditional chicken egg?',
		image:
			'https://h104216-fcdn.mp.lura.live/1/938892/pvw_lin/AF5/79E/AF579E2107F8B55EDE0CA01776DB12BB_5.jpg?aktaexp=2082787200&aktasgn=ea6899e4fa149f028b32a9ac5fb87e36',
		token:
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2aWQiOiI3NjI1Njc2IiwiaXNzIjoiMkRWemw5UVJWdjhwWW51TEdEU2F1M01YZ09RT25xUEsiLCJleHAiOjE2OTU5MjA5NDN9.nfr4VZhadglFhKrwGdQrZH-X76DgEN9B-jfsS8gI_V8',
		ad_unit_path: '/5678/nx.thehill/changing_america',
	},
	{
		mcpid: '7625676',
		title:
			'Could you tell the difference between this plant-based egg and a traditional chicken egg?',
		image:
			'https://h104216-fcdn.mp.lura.live/1/938892/pvw_lin/AF5/79E/AF579E2107F8B55EDE0CA01776DB12BB_5.jpg?aktaexp=2082787200&aktasgn=ea6899e4fa149f028b32a9ac5fb87e36',
		token:
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2aWQiOiI3NjI1Njc2IiwiaXNzIjoiMkRWemw5UVJWdjhwWW51TEdEU2F1M01YZ09RT25xUEsiLCJleHAiOjE2OTU5MjA5NDN9.nfr4VZhadglFhKrwGdQrZH-X76DgEN9B-jfsS8gI_V8',
		ad_unit_path: '/5678/nx.thehill/changing_america2',
	},
	{
		mcpid: '7625676',
		title:
			'Could you tell the difference between this plant-based egg and a traditional chicken egg?',
		image:
			'https://h104216-fcdn.mp.lura.live/1/938892/pvw_lin/AF5/79E/AF579E2107F8B55EDE0CA01776DB12BB_5.jpg?aktaexp=2082787200&aktasgn=ea6899e4fa149f028b32a9ac5fb87e36',
		token:
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2aWQiOiI3NjI1Njc2IiwiaXNzIjoiMkRWemw5UVJWdjhwWW51TEdEU2F1M01YZ09RT25xUEsiLCJleHAiOjE2OTU5MjA5NDN9.nfr4VZhadglFhKrwGdQrZH-X76DgEN9B-jfsS8gI_V8',
		ad_unit_path: '/5678/nx.thehill/changing_america/video',
	},
	{
		mcpid: '7625676',
		title:
			'Could you tell the difference between this plant-based egg and a traditional chicken egg?',
		image:
			'https://h104216-fcdn.mp.lura.live/1/938892/pvw_lin/AF5/79E/AF579E2107F8B55EDE0CA01776DB12BB_5.jpg?aktaexp=2082787200&aktasgn=ea6899e4fa149f028b32a9ac5fb87e36',
		token:
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2aWQiOiI3NjI1Njc2IiwiaXNzIjoiMkRWemw5UVJWdjhwWW51TEdEU2F1M01YZ09RT25xUEsiLCJleHAiOjE2OTU5MjA5NDN9.nfr4VZhadglFhKrwGdQrZH-X76DgEN9B-jfsS8gI_V8',
		ad_unit_path: '/5678/nx.thehill/changing_america3',
	},
	{
		mcpid: '9036473',
		title: 'Top-Tested Robot Vacuums',
		image:
			'https://m104216-ucdn.mp.lura.live/iupl_lin/F0D/683/F0D6834FFAF8375330F2C1C4D2A213C4.jpg?Expires=2082758400&KeyName=mcpkey1&Signature=XuRq9hCxyRMaNuNdDId-PyKsO5s',
		token:
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2aWQiOiI5MDM2NDczIiwiaXNzIjoiMkRWemw5UVJWdjhwWW51TEdEU2F1M01YZ09RT25xUEsiLCJleHAiOjE2OTU5MjA5NDN9.V3FPzgA3fhKJEDCQ_zgBTLLbTqZOVn04ineCEYCPHng',
		ad_unit_path: '',
	},
	{
		mcpid: '9036455',
		title: 'Pet Pal: Gobi',
		image:
			'https://m104216-ucdn.mp.lura.live/iupl_lin/939/080/939080F9273203F48D450DF3BFD14057.jpg?Expires=2082758400&KeyName=mcpkey1&Signature=h3pMzHFHrZceNoafvqbe1DSW2Sg',
		token:
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2aWQiOiI5MDM2NDU1IiwiaXNzIjoiMkRWemw5UVJWdjhwWW51TEdEU2F1M01YZ09RT25xUEsiLCJleHAiOjE2OTU5MjA5NDN9.KKHEHbV0gwfRbPTJ8n0Me2QToBFYeNHETxXJA7EgG5I',
		ad_unit_path: '',
	},
	{
		mcpid: '9036449',
		title: 'Community Connection: Fore The Kids Golf Tournament',
		image:
			'https://m104216-ucdn.mp.lura.live/iupl_lin/1BA/CD2/1BACD2AFF1B6E237D67BCF1AA497C7BF.jpg?Expires=2082758400&KeyName=mcpkey1&Signature=sh9tojYV8QOsc2XRGVuf_eNq7kU',
		token:
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2aWQiOiI5MDM2NDQ5IiwiaXNzIjoiMkRWemw5UVJWdjhwWW51TEdEU2F1M01YZ09RT25xUEsiLCJleHAiOjE2OTU5MjA5NDN9.6gzxubAFe0hTXu7nIQwBBoxmqKvnDr7VnKOm1e5XvGc',
		ad_unit_path: '',
	},
	{
		mcpid: '9036447',
		title: 'Empowering Community Through Fashion',
		image:
			'https://m104216-ucdn.mp.lura.live/iupl_lin/763/997/763997E0E4549C3FBCCA7409D9D8BBFB.jpg?Expires=2082758400&KeyName=mcpkey1&Signature=ktHjflI_NYzT6VXExGfEph4s7m4',
		token:
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2aWQiOiI5MDM2NDQ3IiwiaXNzIjoiMkRWemw5UVJWdjhwWW51TEdEU2F1M01YZ09RT25xUEsiLCJleHAiOjE2OTU5MjA5NDN9.v8iPY7x8Ey8i-R3wWYLqHTT0CjA733hMgfxGtEjjiHA',
		ad_unit_path: '',
	},
	{
		mcpid: '9036381',
		title: 'Artists working to win the International Sandsculpting Championship in VB',
		image:
			'https://m104216-ucdn.mp.lura.live/iupl_lin/F8B/01D/F8B01DCEBEDE24154EA6B6DC01A7688F.jpg?Expires=2082758400&KeyName=mcpkey1&Signature=1kAG2OWsGT7Bh4ke2qNYxX1Ai7E',
		token:
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2aWQiOiI5MDM2MzgxIiwiaXNzIjoiMkRWemw5UVJWdjhwWW51TEdEU2F1M01YZ09RT25xUEsiLCJleHAiOjE2OTU5MjA5NDN9.yRCWVIeTu6dZbHFgd9LdJb7rlpveO8qLj-D54g8GZbo',
		ad_unit_path: '',
	},
	{
		mcpid: '9036102',
		title: 'Norfolk says staff shortages, other factors, resulted in increased water bills',
		image:
			'https://m104216-ucdn.mp.lura.live/iupl_lin/4F4/3B8/4F43B8EA5F7E882CDB63F4EEDC7B8261.jpg?Expires=2082758400&KeyName=mcpkey1&Signature=vOO0Bk_ifvN8ewNDGr__YRJ2Gg8',
		token:
			'eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ2aWQiOiI5MDM2MTAyIiwiaXNzIjoiMkRWemw5UVJWdjhwWW51TEdEU2F1M01YZ09RT25xUEsiLCJleHAiOjE2OTU5MjA5NDN9.-MWJfrk9P-wZt3GLf22mihk66q5yxdJuD8H3QXBNb8k',
		ad_unit_path: '',
	},
];

const AD_TAG_URLS = {
	gampad:
		'https://pubads.g.doubleclick.net/gampad/ads?sz=1x1000&iu=%2F5678%2Fdfp-test%2Fwm&impl=s&gdfp_req=1&env=vp&output=vmap&unviewed_position_start=1&ad_rule=1&url=%5BUDL_PAGE_URL%5D&video_url_to_fetch=%5BUDL_PAGE_URL%5D&cust_params=vid%3D6416622%26pid%3D%255BUDL_PAGE_ID%255D%26vidcat%3D%255BUDL_PAGE_CATEGORY%255D%26bob_ck%3D%255Bbob_ck_val%255D%26d_code%3D%255BUDL_DCODE%255D%26nlpcat1%3D%255BUDL_NLP_CAT_1%255D%26nlpcat2%3D%255BUDL_NLP_CAT_2%255D%26nlpcat3%3D%255BUDL_NLP_CAT_3%255D%26nlpsent%3D%255BUDL_NLP_SENT%255D%26playerwidth%3D%255BPLAYER_WIDTH%255D%26playerheight%3D%255BPLAYER_HEIGHT%255D',
	freewheel:
		'https://demo.v.fwmrm.net/ad/g/1?flag=+emcr+slcb&nw=96749&prof=global-as3_secure&csid=demo_request_ss&caid=demo_request_asset&vdur=3000&resp=vmap1+vast4;;slid=pre&tpos=0&ptgt=a&slau=preroll&maxd=30;slid=post&tpos=0&ptgt=a&slau=postroll&maxd=30',
};

const DEFAULT_VIDEO_SETTING = {
	sticky: false,
	min_player_enabled: false,
	autoplay: false,
	ads: {
		disable_ads: false,
		ad_tag_url: AD_TAG_URLS.freewheel,
		isFreewheel: true,
	},
	recommendations,
	show_title_overlay: false,
	anvack: 'VMJ9Y4YJDkoDKf6Yo6Un3ULpejzykbjw',
	nielsen: {
		apn: 'Videojs',
		apid: 'P47962C49-DB7D-4400-B548-50C219172CDD',
		sfcode: 'dcr',
		type: 'dcr',
		useDerivedMetadata: true,
		mapping: { adloadtype: 2, adModel: 2 },
		environment: 'cert',
	},
};

const initializeErrorLogger = (onlyLeadMedia, dispatchingEnabled) => {
	window.NXSTdata = {
		page: {
			fullUrl: window.location.href,
		},
	};
	window.errorLoggerInstance = ErrorLoggerObserver.getInstance({
		logLevel: 100,
		dispatchers: ['console'],
		sampleSize: 100,
		onlyLeadMedia,
		dispatchingEnabled,
		debug: true,
	});
};

/**
 * Demo page to view different video player functionality.
 * @param {videos} list of videos with which the page is initalized.
 */
const DemoPage = ({ videos = [], options = [] }) => {
	const classes = useStyles();

	initializeErrorLogger(true, false);

	const generate_text = (count) => {
		const max_count = 10;
		const min_count = 3;
		const default_value = Math.round(Math.random() * (max_count - min_count) + min_count);
		const result = [];

		for (let index = 0; index < (count || default_value); ++index) {
			result.push(
				<Typography align="justify" className={classes.text} component="p" key={index}>
					{loremIpsum({
						format: 'plain',
						count: 1,
						units: 'paragraph',
					})}
				</Typography>,
			);
		}
		return result;
	};

	const [forceReRender, setForceReRender] = useState();

	/* Generate video state variables. */
	const [video_setting_list, set_video_setting_list] = useState(
		videos.map((video, index) => {
			const video_options = options[index] || {};
			const new_options = {
				...DEFAULT_VIDEO_SETTING,
				ads: {
					...DEFAULT_VIDEO_SETTING.ads,
					ad_tag_url: !index ? AD_TAG_URLS.freewheel : AD_TAG_URLS.gampad,
					isFreewheel: !index,
				},
				anvack: !index ? '2DVzl9QRVv8pYnuLGDSau3MXgOQOnqPK' : 'VMJ9Y4YJDkoDKf6Yo6Un3ULpejzykbjw',
				recommendations: !index ? true : recommendations,
				...video_options,
			};
			return new_options;
		}),
	);
	/* Register hook to initialize text. */
	useEffect(() => {
		const new_setting_list = videos.map((video, index) => {
			const video_options = options[index] || {};
			const new_options = {
				...DEFAULT_VIDEO_SETTING,
				ads: {
					...DEFAULT_VIDEO_SETTING.ads,
					ad_tag_url: !index ? AD_TAG_URLS.freewheel : AD_TAG_URLS.gampad,
					isFreewheel: !index,
				},
				anvack: !index ? '2DVzl9QRVv8pYnuLGDSau3MXgOQOnqPK' : 'VMJ9Y4YJDkoDKf6Yo6Un3ULpejzykbjw',
				recommendations: !index ? true : recommendations,
				...video_options,
			};
			return new_options;
		});
		set_video_setting_list(new_setting_list);
		return () => {
			set_video_setting_list([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videos, options]);

	/* Generate text state variables. */
	const [text_list, set_text_list] = useState([]);
	/* Register hook to initialize text. */
	useEffect(() => {
		const new_text_list = videos.map(() => generate_text());
		set_text_list(new_text_list);
		return () => {
			set_text_list([]);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [videos]);

	/* Generate video ID state variables. */
	const [video_id_list, set_video_id_list] = useState(videos);
	/* Register hook to initialize text. */
	useEffect(() => {
		const new_ids = videos;
		set_video_id_list(new_ids);
		return () => {
			set_video_id_list([]);
		};
	}, [videos]);

	if (!video_setting_list.length) return null;

	const updateSetting = () => {
		set_video_setting_list(
			videos.map((video, index) => {
				const video_options =
					{
						...options[index],
						ads: {
							...(options[index].ads || {}),
							ad_tag_url:
								'https://pubads.g.doubleclick.net/gampad/ads?sz=1x1000&iu=/5678/lin.wavy/news/local_news&impl=s&gdfp_req=1&env=vp&output=vmap&unviewed_position_start=1&ad_rule=1&description_url=http://local.dev.psg.nexstardigital.net/wavy/news/local-news/test/&cust_params=cmsid%3D105%26pid%3D105%26pers_cid%3Dnxs-3-article-105%26vidcat%3D%2Fnews%2Flocal_news%26bob_ck%3D%5Bbob_ck_val%5D%26d_code%3Dna003%26pagetype%3Dstory',
						},
					} || {};
				const new_options = {
					...DEFAULT_VIDEO_SETTING,
					...video_options,
				};
				return new_options;
			}),
		);
	};

	return (
		<Box>
			<Container maxWidth="md" data-testid="article">
				<DemoControls
					id_list={video_id_list}
					id_list_setter={set_video_id_list}
					setting_list={video_setting_list}
					setting_list_setter={set_video_setting_list}
					videos={videos}
				/>

				<Divider />

				{window.errorLoggerInstance &&
					videos.map((video_id, index) => (
						<Fragment key={index}>
							<div className={classes.video}>
								<VideoPlayer
									video_id={video_id}
									player_id={`vjs_player_${index + 1}`}
									sticky={Boolean(video_setting_list[index]?.sticky)}
									min_player_enabled={Boolean(video_setting_list[index]?.min_player_enabled)}
									autoplay={Boolean(video_setting_list[index]?.autoplay)}
									ads={video_setting_list[index]?.ads}
									on_start={() => {
										const player = videojs.getPlayer(
											document.querySelector(`[data-internal-video-id="${video_id}"]`),
										);
										if (Array.isArray(videos)) {
											player?.on('playlistNewItem', () => updateSetting());
										}
									}}
									bobBaseHost="uat.bob.dmpxs.com"
									_udl={{}}
									recommendations={video_setting_list[index]?.recommendations}
									update_ads={(ads) => {
										const newVideoSettingList = video_setting_list;
										newVideoSettingList[index].ads = ads;
										set_video_setting_list(newVideoSettingList);
										setForceReRender(true);
									}}
									show_title_overlay={Boolean(video_setting_list[index]?.show_title_overlay)}
									anvack={video_setting_list[index]?.anvack}
									nielsen={video_setting_list[index]?.nielsen}
								/>
							</div>

							{text_list}
						</Fragment>
					))}
			</Container>
		</Box>
	);
};

DemoPage.propTypes = {
	videos: PropTypes.array.isRequired,
	options: PropTypes.array,
};

DemoPage.defaultProps = {
	videos: [],
	options: [],
};

export default DemoPage;
