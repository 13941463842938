import videojs from 'video.js';

const Component = videojs.getComponent('Component');

class PlaylistInterface extends Component {
	// eslint-disable-next-line class-methods-use-this
	createEl() {
		const el = videojs.dom.createEl('div', {
			className: 'vjs-playlist-interface',
		});

		return el;
	}

	// eslint-disable-next-line class-methods-use-this
	addNewElementToDOM(elementType, className, parentElement) {
		const newElement = document.createElement(elementType);
		newElement.classList.add(className);
		parentElement.appendChild(newElement);
		return newElement;
	}

	createPlaylistItems() {
		const playlistInterfaceEl = this.el_;
		const header = this.addNewElementToDOM('div', 'playlist-header', playlistInterfaceEl);
		header.innerHTML = 'Playlist';
		const closeButton = this.addNewElementToDOM('div', 'playlist-close', header);
		closeButton.innerHTML = '&#x2715';
		closeButton.addEventListener('click', () => {
			playlistInterfaceEl.classList.remove('visible');
		});
		const playlistWrapper = this.addNewElementToDOM('div', 'playlist-wrapper', playlistInterfaceEl);
		this.options_.playlist.forEach((element, elementIndex) => {
			const playlistItem = this.addNewElementToDOM('div', 'playlist-item', playlistWrapper);
			const imageWrapper = this.addNewElementToDOM(
				'div',
				'playlist-item-image-wrapper',
				playlistItem,
			);
			const image = this.addNewElementToDOM('img', 'playlist-item-image', imageWrapper);
			image.src = element.poster;
			const textElement = this.addNewElementToDOM('div', 'playlist-item-text', playlistItem);
			const title = this.addNewElementToDOM('div', 'playlist-item-title', textElement);
			title.innerHTML = element.title;
			playlistItem.addEventListener('click', () => {
				const playerComponent = this.player();
				if (this.player().el_.querySelector('.vjs-overlay-background'))
					this.player().el_.querySelector('.vjs-overlay-background').innerHTML = element.title;
				playerComponent.playlist().set_current_index(elementIndex);
			});
		});
	}
}

videojs.registerComponent('playlistInterface', PlaylistInterface);
