import PropTypes from 'prop-types';
import { Grid, Button, Typography, TextField, Checkbox, FormControlLabel } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';
import { isObject } from '../../util/helper';

const VIDEO_SETTING_NAMES = {
	sticky: 'Sticky',
	min_player_enabled: "Enable Minimized",
	autoplay: 'Autoplay',
	ads: 'Disable Ads',
	recommendations: 'Recommendations',
	show_title_overlay: 'Show Title Overlay',
};

const DEFAULT_VIDEO_SETTING = {
	sticky: false,
	min_player_enabled: false,
	autoplay: false,
	ads: {
		disable_ads: false,
		ad_tag_url:
			'https://pubads.g.doubleclick.net/gampad/ads?sz=1x1000&iu=%2F5678%2Fdfp-test%2Fwm&impl=s&gdfp_req=1&env=vp&output=vmap&unviewed_position_start=1&ad_rule=1&url=%5BUDL_PAGE_URL%5D&video_url_to_fetch=%5BUDL_PAGE_URL%5D&cust_params=vid%3D6416622%26pid%3D%255BUDL_PAGE_ID%255D%26vidcat%3D%255BUDL_PAGE_CATEGORY%255D%26bob_ck%3D%255Bbob_ck_val%255D%26d_code%3D%255BUDL_DCODE%255D%26nlpcat1%3D%255BUDL_NLP_CAT_1%255D%26nlpcat2%3D%255BUDL_NLP_CAT_2%255D%26nlpcat3%3D%255BUDL_NLP_CAT_3%255D%26nlpsent%3D%255BUDL_NLP_SENT%255D%26playerwidth%3D%255BPLAYER_WIDTH%255D%26playerheight%3D%255BPLAYER_HEIGHT%255D',
	},
	recommendations: true,
	show_title_overlay: false,
};

/**
 * Demo page to view different video player functionality.
 * Disabled for now since we dont have a deployed graphql instance.
 * @param {videos} list of videos with which the page is initalized.
 */
const DemoPage = ({ id_list, id_list_setter, setting_list, setting_list_setter, videos }) => {
	const history = useHistory();

	const id_form_handler = (event, index) => {
		const new_value = event.target.value.replaceAll(' ', '');
		const new_ids = Array.from(id_list);
		new_ids[index] = new_value;
		id_list_setter(new_ids);
	};

	const id_close_handler = (index) => {
		const new_ids = id_list.filter((id, index_id) => index_id !== index);
		if (new_ids.length) {
			id_list_setter(new_ids);
		}
		const new_setting_list = setting_list.filter(
			(setting, index_setting) => index_setting !== index,
		);
		if (new_setting_list.length) {
			setting_list_setter(new_setting_list);
		}
	};

	const id_button_handler = (add_type = 'video') => {
		const new_ids = Array.from(id_list);
		const new_setting_list = Array.from(setting_list);
		/* Create a new setting if not existing before. */
		if (new_ids.length >= videos.length) {
			new_setting_list.push({ ...DEFAULT_VIDEO_SETTING });
		}
		if (add_type === 'playlist') {
			new_ids.push(['']);
		} else {
			new_ids.push('');
		}
		setting_list_setter(new_setting_list);
		id_list_setter(new_ids);
	};

	const id_redirect_handler = () => {
		const is_valid = (item) => (Array.isArray(item) ? item.length : Boolean(item));
		const format = (item) => (Array.isArray(item) ? String(item.join(',')) : String(item));
		const query_video = `videos=${JSON.stringify(id_list.filter(is_valid).map(format))}`;
		const query_setting_list = `options=${window.btoa(JSON.stringify(setting_list))}`;
		const query_string = `${query_video}&${query_setting_list}`;
		history.push({
			search: query_string,
		});
		window.location.reload();
	};

	const options_check_handler = (index, name) => {
		const new_setting_list = setting_list.slice();
		const setting = { ...setting_list[index] };
		let adSettings = {};
		if (isObject(setting[name])) {
			adSettings = { ...setting[name] };
			adSettings.disable_ads = !adSettings.disable_ads;
		} else {
			setting[name] = !setting[name];
			adSettings = setting.ads;
		}
		new_setting_list[index] = { ...setting, ads: adSettings };
		setting_list_setter(new_setting_list);
	};

	return (
		<Grid direction="column" justifyContent="space-around" container>
			<Typography>Video IDs:</Typography>
			{id_list.map((ident, index) => (
				<Grid
					key={index}
					direction="row"
					justifyContent="space-between"
					alignItems="center"
					container>
					<>
						<TextField
							id="standard-basic"
							label={`Video ID #${index + 1}`}
							onChange={(event) => id_form_handler(event, index)}
							value={ident}
							style={{ paddingBottom: '1rem' }}
						/>
						{Object.entries(setting_list[index])
							.filter(([name]) => name !== 'anvack')
							.map(([name, value]) => (
								<FormControlLabel
									key={name}
									control={
										<Checkbox
											checked={isObject(value) ? value.disable_ads : value}
											onChange={() => options_check_handler(index, name)}
											name={String(index)}
										/>
									}
									label={VIDEO_SETTING_NAMES[name]}
								/>
							))}
						<CloseIcon
							onClick={() => {
								id_close_handler(index);
							}}
						/>
					</>
				</Grid>
			))}
			<Grid
				direction="row"
				justifyContent="space-between"
				alignItems="center"
				container
				style={{ margin: '2% 0' }}>
				<Button
					variant="contained"
					onClick={() => {
						id_button_handler();
					}}>
					Add a New ID
				</Button>
				<Button
					variant="contained"
					color="primary"
					onClick={() => {
						id_redirect_handler();
					}}>
					Apply ID Changes
				</Button>
			</Grid>
		</Grid>
	);
};

DemoPage.propTypes = {
	id_list: PropTypes.array.isRequired,
	id_list_setter: PropTypes.func.isRequired,
	setting_list: PropTypes.array.isRequired,
	setting_list_setter: PropTypes.func.isRequired,
	videos: PropTypes.array,
};

DemoPage.defaultProps = {
	id_list: [],
	id_list_setter: () => {},
	setting_list: [],
	setting_list_setter: () => {},
};

export default DemoPage;
