import { shapeOVPEvent } from './helper';

const dispatchLoadEvent = (eventName, payload, playerId, target = window) => {
	const event = new CustomEvent(eventName, { detail: payload });
	const shapedEvent = shapeOVPEvent(eventName, playerId, payload);
	target.dispatchEvent(event);
	window.dispatchEvent(shapedEvent);
};

const shapeEventObject = ({ error, videoId, playerId, analyticsPayload }) => ({
	videoId,
	playerId,
	livestream: analyticsPayload?.livestream,
	program: analyticsPayload?.program,
	airDate: analyticsPayload?.airDate,
	videoInitType: analyticsPayload?.videoInitType,
	videoTitle: analyticsPayload?.videoTitle,
	videoUrl: analyticsPayload?.videoUrl,
	errorMessage: analyticsPayload?.errorMessage || error?.message,
	loadTime: analyticsPayload?.loadTime,
	fullEpisode: analyticsPayload?.fullEpisode,
	fullScreen: analyticsPayload?.fullScreen,
	muteStatus: analyticsPayload?.muteStatus,
	playlistSize: analyticsPayload?.playlistSize,
	videoDuration: analyticsPayload?.videoDuration,
	videoDurationTime: analyticsPayload?.videoDurationTime,
});

export const errorHandler = (videoId, playerId, analyticsPayload, error) => {
	const payload = shapeEventObject({ error, videoId, playerId, analyticsPayload });
	dispatchLoadEvent('playerFatal', payload, playerId);
};

export const loadHandler = (videoId, playerId, analyticsPayload, playerElement) => {
	const payload = shapeEventObject({ error: null, videoId, playerId, analyticsPayload });
	dispatchLoadEvent('playerLoadSuccess', payload, playerElement);
};
