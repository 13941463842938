import videojs from 'video.js'

import { is_dev } from '../util/env.js'


const Plugin = videojs.getPlugin('plugin')


const copy = (obj) => JSON.parse(JSON.stringify(obj))


const equals = (obj1, obj2) => JSON.stringify(obj1) === JSON.stringify(obj2)


class SafeUpdate extends Plugin {

    constructor(player, initial_state) {
        super(player, initial_state)

        this.setState(copy(initial_state))

        if (is_dev()) {
            this.on('statechanged', (...args) => console.log("SAFEUPDATE", ...args))
        }
    }

    dispose() {
        super.dispose()
    }

    update(new_state) {
        const updated_state = {
            
            ...copy(this.state),
            ...copy(new_state),
        }
        const has_change = !equals(this.state, updated_state)
        if (has_change) {
            this.setState(updated_state)
            this.player.trigger('metadataUpdate')
        }
        return has_change
    }
}


if (!videojs.getPlugin('safeupdate')) {
    videojs.registerPlugin('safeupdate', SafeUpdate)
}
