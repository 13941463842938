!(function (t, n) {
	'object' == typeof exports && 'undefined' != typeof module
		? n(require('video.js'))
		: 'function' == typeof define && define.amd
		? define(['video.js'], n)
		: n((t = 'undefined' != typeof globalThis ? globalThis : t || self).videojs);
})(this, function (t) {
	'use strict';
	var n = function (t, e) {
		return (
			(n =
				Object.setPrototypeOf ||
				({ __proto__: [] } instanceof Array &&
					function (t, n) {
						t.__proto__ = n;
					}) ||
				function (t, n) {
					for (var e in n) Object.prototype.hasOwnProperty.call(n, e) && (t[e] = n[e]);
				}),
			n(t, e)
		);
	};
	function e(t, n, e) {
		if (e || 2 === arguments.length)
			for (var r, i = 0, o = n.length; i < o; i++)
				(!r && i in n) || (r || (r = Array.prototype.slice.call(n, 0, i)), (r[i] = n[i]));
		return t.concat(r || Array.prototype.slice.call(n));
	}
	var r =
			'undefined' != typeof globalThis
				? globalThis
				: 'undefined' != typeof window
				? window
				: 'undefined' != typeof global
				? global
				: 'undefined' != typeof self
				? self
				: {},
		i = {},
		o = {},
		s = {},
		a = {};
	!(function (t, n) {
		'undefined' != typeof self && self,
			(t.exports = (function (t) {
				var n = {};
				function e(r) {
					if (n[r]) return n[r].exports;
					var i = (n[r] = { t: r, i: !1, exports: {} });
					return t[r].call(i.exports, i, i.exports, e), (i.i = !0), i.exports;
				}
				return (
					(e.o = t),
					(e.c = n),
					(e.d = function (t, n, r) {
						e.u(t, n) || Object.defineProperty(t, n, { l: !0, get: r });
					}),
					(e.r = function (t) {
						'undefined' != typeof Symbol &&
							Symbol.toStringTag &&
							Object.defineProperty(t, Symbol.toStringTag, { value: 'Module' }),
							Object.defineProperty(t, 'v', { value: !0 });
					}),
					(e._ = function (t, n) {
						if ((1 & n && (t = e(t)), 8 & n)) return t;
						if (4 & n && 'object' == typeof t && t && t.v) return t;
						var r = Object.create(null);
						if (
							(e.r(r),
							Object.defineProperty(r, 'default', { l: !0, value: t }),
							2 & n && 'string' != typeof t)
						)
							for (var i in t)
								e.d(
									r,
									i,
									function (n) {
										return t[n];
									}.bind(null, i),
								);
						return r;
					}),
					(e.n = function (t) {
						var n =
							t && t.v
								? function () {
										return t.default;
								  }
								: function () {
										return t;
								  };
						return e.d(n, 'a', n), n;
					}),
					(e.u = function (t, n) {
						return Object.prototype.hasOwnProperty.call(t, n);
					}),
					(e.p = ''),
					e((e.s = 54))
				);
			})([
				function (t, n) {
					var e = e || {};
					(e.filter = function (t, n) {
						var e = {};
						for (var r in n) n.hasOwnProperty(r) && t(n[r]) && (e[r] = n[r]);
						return e;
					}),
						(e.extend = function (t) {
							var n,
								e = arguments.length;
							t = t || {};
							for (var r = 1; r < e; r++)
								if ((n = arguments[r])) for (var i in n) n.hasOwnProperty(i) && (t[i] = n[i]);
							return t;
						}),
						(e.h = function (t, n) {
							if (((n = n || 0), 'object' != typeof t)) return t;
							var r;
							if (t instanceof Array) {
								r = [];
								for (var i = 0, o = t.length; i < o; i++) r[i] = e.h(t[i], n - 1);
								return r;
							}
							for (var s in ((r = {}), t))
								if (t.hasOwnProperty(s)) {
									var a = t[s];
									'object' == typeof a && n > 0 && (a = e.h(a, n - 1)), (r[s] = a);
								}
							return r;
						}),
						(t.exports.filter = e.filter),
						(t.exports.extend = e.extend),
						(t.exports.h = e.h);
				},
				function (t, n) {
					var e,
						r =
							((e = [
								'play',
								'pause',
								'pause-on-buffering',
								'end',
								'buffer',
								'buffer-stop',
								'keep-alive',
								'hb',
								'custom',
								'load',
								'start',
								'skstart',
								'adskip',
								'cta',
								'error',
								'trans',
								'drmfa',
								'drmap',
								'drmde',
								'bitrt',
								'playrt',
								'volume',
								'window',
								'audio',
								'video',
								'subs',
								'cdn',
							]),
							{
								PLAY: 0,
								PAUSE: 1,
								PAUSE_ON_BUFFERING: 2,
								END: 3,
								BUFFER: 4,
								BUFFER_STOP: 5,
								KEEPALIVE: 6,
								HEARTBEAT: 7,
								CUSTOM: 8,
								LOAD: 9,
								ENGAGE: 10,
								SEEK_START: 11,
								AD_SKIP: 12,
								CTA: 13,
								ERROR: 14,
								TRANSFER: 15,
								DRM_FAILED: 16,
								DRM_APPROVED: 17,
								DRM_DENIED: 18,
								BIT_RATE: 19,
								PLAYBACK_RATE: 20,
								VOLUME: 21,
								WINDOW_STATE: 22,
								AUDIO: 23,
								VIDEO: 24,
								SUBS: 25,
								CDN: 26,
								toString: function (t) {
									return e[t];
								},
							}),
						i = (function () {
							var t = ['c', 's', 'r'];
							return {
								g: 0,
								m: 1,
								S: 2,
								toString: function (n) {
									return t[n];
								},
							};
						})();
					(t.exports.I = r),
						(t.exports.P = {
							IDLE: 0,
							PLAYBACK_NOT_STARTED: 1,
							PLAYING: 2,
							PAUSED: 3,
							BUFFERING_BEFORE_PLAYBACK: 4,
							BUFFERING_DURING_PLAYBACK: 5,
							BUFFERING_DURING_SEEKING: 6,
							BUFFERING_DURING_PAUSE: 7,
							SEEKING_BEFORE_PLAYBACK: 8,
							SEEKING_DURING_PLAYBACK: 9,
							SEEKING_DURING_BUFFERING: 10,
							SEEKING_DURING_PAUSE: 11,
							PAUSED_DURING_BUFFERING: 12,
						}),
						(t.exports.A = i);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(58),
						o = e(59),
						s = e(5),
						a = e(60),
						u = e(64),
						c = e(65),
						f = e(66),
						l = e(67),
						d = e(69),
						_ = e(72),
						p = e(73),
						h = e(74),
						v = e(75),
						g = e(76),
						E = e(37),
						m = e(79),
						A = {
							D: 'PLATFORM',
							C: 'defaultLiveEndpointUrl',
							L: 'defaultSecureLiveEndpointUrl',
							T: 'httpGet',
							N: 'httpPost',
							Storage: 'Storage',
							O: 'offlineCache',
							R: 'migratedStorage',
							k: 'migratedIO',
							M: 'defaultStorageWriteInterval',
							U: 'onDataFetch',
							W: 'getCrossPublisherUniqueDeviceId',
							F: 'getApplicationName',
							B: 'getApplicationVersion',
							V: 'getPublisherSpecificUniqueDeviceId',
							G: 'getPublisherSpecificUniqueDeviceIdSuffix',
							j: 'getDeviceModel',
							H: 'getPlatformVersion',
							K: 'getPlatformName',
							J: 'getRuntimeName',
							Y: 'getRuntimeVersion',
							X: 'getDisplayResolution',
							Z: 'getApplicationResolution',
							nn: 'getLanguage',
							tn: 'getPackageName',
							setPlatformAPI: 'setPlatformAPI',
							setTimeout: 'setTimeout',
							setInterval: 'setInterval',
							clearTimeout: 'clearTimeout',
							clearInterval: 'clearInterval',
							en: 'getDeviceArchitecture',
							in: 'getConnectionType',
							rn: 'getDeviceJailBrokenFlag',
							un: 'updateMeasurementLabels',
							sn: 'standardOutputLog',
							an: 'defaultSystemClockJumpDetectorNormalContextInterval',
							fn: 'defaultSystemClockJumpDetectorAlternativeContextInterval',
							cn: 'isNormalContext',
							ln: 'addContextChangeListener',
							dn: 'removeContextChangeListener',
							vn: 'addEnvironmentExitListener',
							_n: 'removeEnvironmentExitListener',
							pn: 'overrideCollectedC12Value',
							hn: 'defaultC12OverrideValue',
							gn: 'getPlatformEnvironmentProperties',
						};
					t.exports = new (function () {
						var t,
							n,
							e = this;
						function L(e) {
							if (!t)
								switch (((t = !0), e)) {
									case o.SmartTV:
										r.extend(n, a);
										break;
									case o.Netcast:
										r.extend(n, u);
										break;
									case o.Cordova:
										r.extend(n, f);
										break;
									case o.Trilithium:
										r.extend(n, l);
										break;
									case o.AppleTV:
										r.extend(n, d);
										break;
									case o.Chromecast:
										r.extend(n, p);
										break;
									case o.Xbox:
										r.extend(n, _);
										break;
									case o.webOS:
										r.extend(n, c);
										break;
									case o.tvOS:
										r.extend(n, h);
										break;
									case o.JSMAF:
										r.extend(n, v);
										break;
									case o.nodejs:
										r.extend(n, g);
										break;
									case o.html5:
										r.extend(n, E);
										break;
									case o.WebBrowser:
										r.extend(n, m);
								}
						}
						r.extend(e, {
							mn: null,
							PlatformApis: o,
							yn: null,
							Sn: function () {
								t || L(o.WebBrowser);
							},
							setPlatformApi: function (t, e) {
								var i, s;
								if ('number' == typeof t) (i = t), (s = e || {});
								else {
									if ('object' != typeof t) return;
									(i = o.Skeleton), (s = t);
								}
								L(i),
									r.extend(
										n,
										(function (t) {
											var n = {};
											for (var e in A) {
												var r = A[e];
												r in t && (n[e] = t[r]);
											}
											return n;
										})(s),
									);
							},
						}),
							(t = !1),
							(e.yn = n = r.h(s)),
							(e.mn = new i(e));
					})();
				},
				function (t, n) {
					t.exports = {
						wn: 'unknown',
						bn: '0x0',
						In: '-----BEGIN PUBLIC KEY-----\nMIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQD0+fCVxGq3Bk24jUKO1PzsiUs3\nvqww6zR4n2e3AweVLUAgsrDRbAWJ/EjZm1WBLBVNMiTLpSAkV6sjOIrUs03xdUEj\nQZJHwOGK+MfzFaZukoo0qAsEMPwQ5posv0JdkBdUGhKchPk6+NYmD6Hb44Lkp7/a\nQnVeWzvfAPQyTJR5wQIDAQAB\n-----END PUBLIC KEY-----',
					};
				},
				function (t, n) {
					var e,
						r = r || {};
					(r.En =
						((e = 1),
						function () {
							return +new Date() + '_' + e++;
						})),
						(r.Pn = function () {
							return 'xxxxxxxxxxxx4xxxyxxxxxxxxxxxxxxx'.replace(/[xy]/g, function (t) {
								var n = (16 * Math.random()) | 0;
								return ('x' == t ? n : (3 & n) | 8).toString(16);
							});
						}),
						(r.An = function (t) {
							return null == t || '' === t || (t instanceof Array && 0 === t.length);
						}),
						(r.Dn = function (t) {
							return !this.An(t);
						}),
						(r.Cn = function (t, n) {
							return (n = this.Ln(n) ? n : ''), this.Ln(t) ? t : n;
						}),
						(r.Tn = function (t) {
							return (
								void 0 !== t &&
								('string' == typeof t
									? 'true' === (t = t.toLowerCase()) || '1' === t || 'on' === t
									: !!t)
							);
						}),
						(r.Nn = function (t, n, e, r, i) {
							if (n < 0 || r < 0 || n + i > t.length || r + i > e.length) return !1;
							for (; --i >= 0; ) if (t.charAt(n++) != e.charAt(r++)) return !1;
							return !0;
						}),
						(r.Ln = function (t) {
							return void 0 !== t && null != t;
						}),
						(r.On = function (t) {
							return !!(t && t.constructor && t.call && t.apply);
						}),
						(t.exports.On = r.On),
						(t.exports.Ln = r.Ln),
						(t.exports.Nn = r.Nn),
						(t.exports.Tn = r.Tn),
						(t.exports.Cn = r.Cn),
						(t.exports.Dn = r.Dn),
						(t.exports.An = r.An),
						(t.exports.En = r.En),
						(t.exports.uuid = r.Pn);
				},
				function (t, n, e) {
					var r = e(18),
						i = e(13),
						o = e(8),
						s = e(3),
						a = e(9),
						u = s.wn,
						c = s.bn,
						f = {
							D: 'generic',
							C: 'http://b.scorecardresearch.com/p2',
							L: 'https://sb.scorecardresearch.com/p2',
							T: i,
							N: null,
							Storage: o,
							O: !1,
							R: null,
							k: null,
							M: 0,
							U: function (t) {
								t();
							},
							W: function () {
								return null;
							},
							F: function () {
								return u;
							},
							B: function () {
								return u;
							},
							V: function () {
								return +new Date() + ~~(1e3 * Math.random());
							},
							G: function () {
								return '72';
							},
							j: function () {
								return u;
							},
							H: function () {
								return u;
							},
							K: function () {
								return 'js';
							},
							J: function () {
								return u;
							},
							Y: function () {
								return u;
							},
							X: function () {
								return c;
							},
							Z: function () {
								return c;
							},
							nn: function () {
								return u;
							},
							tn: function () {
								return u;
							},
							setPlatformAPI: function () {},
							setTimeout: function (t, n) {
								return setTimeout(t, n);
							},
							setInterval: function (t, n) {
								return setInterval(t, n);
							},
							clearTimeout: function (t) {
								return clearTimeout(t);
							},
							clearInterval: function (t) {
								return clearInterval(t);
							},
							en: function () {
								return u;
							},
							in: function () {
								return a.UNKNOWN;
							},
							rn: function () {
								return u;
							},
							un: function (t) {},
							sn: function (t) {
								console.log(t);
							},
							an: 3e3,
							fn: 3e4,
							cn: function () {
								return !r.Rn() || !r.kn();
							},
							ln: function (t) {
								r.Rn() && r.Mn(t);
							},
							dn: function (t) {
								r.Rn() && r.Un(t);
							},
							vn: function (t) {},
							_n: function (t) {},
							pn: !1,
							hn: '_',
							gn: function () {
								return '0-0-2';
							},
						};
					t.exports = f;
				},
				function (t, n) {
					(n.xn = function (t, n) {
						if ('undefined' != typeof XMLHttpRequest) {
							var e = new XMLHttpRequest();
							e.open('GET', t, !0),
								(e.onreadystatechange = function () {
									4 === e.readyState && (n && n(e.status), (e = null));
								}),
								e.send();
						} else 'function' == typeof setTimeout ? n && setTimeout(n, 0) : n && n();
					}),
						(n.Wn = function (t, n, e) {
							if ('undefined' != typeof XMLHttpRequest) {
								var r = new XMLHttpRequest();
								r.open('POST', t, !0),
									(r.onreadystatechange = function () {
										4 === r.readyState && (e && e(r.status), (r = null));
									}),
									r.send(n);
							} else 'function' == typeof setTimeout ? e && setTimeout(e, 0) : e && e();
						});
				},
				function (t, n) {
					var e = {
						Fn: function (t, n, e) {
							return null != n && n + '' != '' && null != e && ((t[n + ''] = e + ''), !0);
						},
						Bn: function (t, n) {
							for (var r in n) n.hasOwnProperty(r) && e.Fn(t, r, n[r]);
						},
					};
					(t.exports.Fn = e.Fn), (t.exports.Bn = e.Bn);
				},
				function (t, n, e) {
					var r = e(0);
					t.exports = function (t) {
						var n;
						!(function () {
							try {
								n = 'undefined' != typeof localStorage ? localStorage : null;
							} catch (t) {
								n = null;
							}
						})(),
							r.extend(this, {
								storeProperties: function (t) {
									if (n)
										try {
											'function' == typeof n.setItem
												? n.setItem('cs_settings', t)
												: n && (n.Vn = t);
										} catch (t) {}
								},
								getProperties: function () {
									if (!n) return null;
									try {
										if ('function' == typeof n.getItem) return n.getItem('cs_settings');
										if (n) return n.Vn;
									} catch (t) {}
								},
								storeCache: function (t) {
									if (n)
										try {
											'function' == typeof n.setItem ? n.setItem('cs_cache', t) : n && (n.qn = t);
										} catch (t) {}
								},
								getCache: function () {
									if (!n) return null;
									try {
										if ('function' == typeof n.getItem) return n.getItem('cs_cache');
										if (n) return n.qn;
									} catch (t) {}
								},
							});
					};
				},
				function (t, n) {
					t.exports = {
						UNKNOWN: 0,
						UNAVAILABLE: 1,
						DISCONNECTED: 2,
						CONNECTED: 3,
						ETHERNET: 4,
						WIFI: 5,
						WWAN: 6,
						BLUETOOTH: 7,
						EMULATOR: 8,
					};
				},
				function (t, n, e) {
					var r = e(0);
					t.exports = function () {
						var t = 'undefined' != typeof localStorage ? localStorage : null;
						r.extend(this, {
							get: function (n) {
								try {
									return t && 'function' == typeof t.getItem
										? t.getItem('cs_' + n)
										: t
										? t['cs_' + n]
										: t;
								} catch (t) {}
							},
							remove: function (n) {
								try {
									t && 'function' == typeof t.removeItem
										? t.removeItem('cs_' + n)
										: t && delete t['cs_' + n];
								} catch (t) {}
							},
							clear: function () {
								try {
									for (var n = 0; t && n < t.length; ++n) {
										var e = t.key(n);
										'cs_' === e.substr(0, 'cs_'.length) &&
											('function' == typeof t.removeItem ? t.removeItem(e) : delete t[e]);
									}
								} catch (t) {}
							},
						});
					};
				},
				function (t, n) {
					t.exports = {
						Gn: 'ocdrm',
						jn: 'ltrts',
						Hn: 'pappv',
						Kn: 'fits',
						Jn: 'cits',
						Yn: 'cpidmd5',
						Xn: 'cpidrsa',
						zn: 'c12s',
						Qn: 'lappaccts',
						$n: 'lsaccts',
						Zn: 'lappsts',
						nt: 'ftrsc',
						tt: 'accft',
						et: 'accbt',
						it: 'accit',
						rt: 'tft',
						ot: 'tbt',
						ut: 'tit',
						st: 'accappst',
						ft: 'appsc',
						ct: 'g',
						lt: 'lrt',
						dt: 'dc',
						vt: 'dco',
						_t: 'csc',
						pt: 'sl',
					};
				},
				function (t, n, e) {
					var r = e(21),
						i = e(0);
					t.exports = function () {
						var t = this,
							n = 'undefined' != typeof encodeURIComponent ? encodeURIComponent : escape,
							e = 'undefined' != typeof decodeURIComponent ? decodeURIComponent : unescape,
							o = 'undefined' != typeof localStorage ? localStorage : null,
							s = {},
							a = o && n && e;
						i.extend(t, {
							dir: function (t) {
								if (!a) return null;
								var n = 'cs_dir_' + t,
									r = s[n];
								if (r) return r.slice();
								var i = o.getItem(n);
								if (i) {
									r = [];
									for (var u = 0, c = (i = i.split('|')).length; u < c; u++)
										i[u].length > 0 && r.push(e(i[u]));
									return (s[n] = r), r.slice();
								}
								return null;
							},
							append: function (n, e, r) {
								if (a) {
									var i = t.read(n, e);
									i ? (i += r) : (i = r), t.write(n, e, i);
								}
							},
							write: function (e, i, u) {
								if (a) {
									var c = t.dir(e);
									c ||
										((function (t) {
											var n = 'cs_dir_' + t;
											'function' == typeof o.setItem ? o.setItem(n, '') : (o[n] = ''), (s[n] = []);
										})(e),
										(c = [])),
										-1 == r.indexOf(i, c) &&
											(function (t, e) {
												var r = 'cs_dir_' + t;
												try {
													'function' == typeof o.setItem
														? o.setItem(r, o.getItem(r) + '|' + n(e))
														: (o[r] = o.getItem(r) + '|' + n(e));
												} catch (t) {}
												s[r].push(e);
											})(e, i),
										(function (t, n, e) {
											try {
												'function' == typeof o.setItem
													? o.setItem('cs_file_' + t + n, e)
													: (o['cs_file_' + t + n] = e);
											} catch (t) {}
										})(e, i, u);
								}
							},
							deleteFile: function (e, i) {
								if (!a) return !1;
								var u = t.dir(e);
								return (
									!!u &&
									-1 != r.indexOf(i, u) &&
									((function (t, e) {
										var i = 'cs_dir_' + t,
											a = s[i];
										a.splice(r.indexOf(e, a), 1);
										for (var u = [], c = 0, f = a.length; c < f; c++) u.push(n(a[c]));
										try {
											'function' == typeof o.setItem
												? o.setItem(i, u.join('|'))
												: (o[i] = u.join('|')),
												'function' == typeof o.removeItem
													? o.removeItem('cs_file_' + t + e)
													: delete o['cs_file_' + t + e];
										} catch (t) {}
									})(e, i),
									!0)
								);
							},
							read: function (n, e) {
								if (!a) return null;
								var i = t.dir(n);
								return i
									? -1 == r.indexOf(e, i)
										? null
										: (function (t, n) {
												try {
													return 'function' == typeof o.getItem
														? o.getItem('cs_file_' + t + n)
														: o['cs_file_' + t + n];
												} catch (t) {}
										  })(n, e)
									: null;
							},
						});
					};
				},
				function (t, n) {
					t.exports = function (t, n, e) {
						if ('undefined' != typeof Image) {
							var r = new Image();
							(r.onload = function () {
								n && n(200), (r = null);
							}),
								(r.onerror = function () {
									n && n(), (r = null);
								}),
								(r.src = t);
						} else 'function' == typeof setTimeout ? n && setTimeout(n, 0) : n && n();
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).P;
					(n.ht = function (t) {
						var n = {};
						for (var e in t) {
							var r = t[e];
							n[e] = null == r ? r : t[e] + '';
						}
						return n;
					}),
						(n.gt = function (t, n) {
							var e,
								r = [];
							for (e in t) (n && !n.test(e)) || !t.hasOwnProperty(e) || (r[r.length] = e);
							return r;
						}),
						(n.yt = function () {
							return 'undefined' != typeof window && 'undefined' != typeof document;
						}),
						(n.St = function (t, n, e, i) {
							var o = r.h(t, 1),
								s = { start: n, end: e };
							if (s.start >= s.end) return o;
							for (var a = 0; a < o.length; ++a) {
								var u = o[a];
								if (u.start <= s.start && s.end <= u.end) break;
								if (u.start <= s.start && s.start <= u.end && u.end <= s.end) break;
								if (s.start <= u.start && u.start <= s.end && s.end <= u.end) break;
								if (s.end <= u.start) break;
							}
							o.splice(a, 0, s);
							for (var c = 1; c < o.length; ) {
								var f = o[c],
									l = o[c - 1];
								l.start <= f.start && f.end <= l.end
									? o.splice(c, 1)
									: f.start <= l.start && l.end <= f.end
									? o.splice(c - 1, 1)
									: l.start <= f.start && f.start <= l.end + i && l.end <= f.end
									? o.splice(c - 1, 2, { start: l.start, end: f.end })
									: f.start <= l.start && l.start - i <= f.end && f.end <= l.end
									? o.splice(c - 1, 2, { start: f.start, end: l.end })
									: c++;
							}
							return o;
						}),
						(n.wt = function (t) {
							for (var n in i) if (i.hasOwnProperty(n) && i[n] == t) return n;
						}),
						(n.bt = function (t) {
							return (
								t == i.IDLE ||
								t == i.BUFFERING_BEFORE_PLAYBACK ||
								t == i.SEEKING_BEFORE_PLAYBACK ||
								t == i.PLAYBACK_NOT_STARTED
							);
						}),
						(n.It = function (t, n) {
							for (var e = 'hash:', r = 0; r < n.length; r++)
								t[n[r]] && (e += n[r] + ':' + t[n[r]] + ';');
							return e;
						});
				},
				function (t, n, e) {
					var r = {
						VERSION: '7.7.0+211006',
						Et: !0,
						Pt: 5e3,
						At: !0,
						Dt: e(25).STANDARD,
						Ct: [
							'c1',
							'c2',
							'ca2',
							'cb2',
							'cc2',
							'cd2',
							'ns_site',
							'ns_ap_an',
							'ns_ap_pn',
							'ns_ap_pv',
							'c12',
							'ca12',
							'cb12',
							'cc12',
							'cd12',
							'ns_ak',
							'ns_ar',
							'name',
							'ns_ap_ni',
							'ns_ap_ec',
							'ns_ap_ev',
							'ns_ap_device',
							'ns_ap_id',
							'ns_ap_csf',
							'ns_ap_bi',
							'ns_ap_pfm',
							'ns_ap_pfv',
							'ns_ap_ver',
							'ns_ap_sv',
							'ns_ap_bv',
							'ns_ap_cv',
							'ns_ap_smv',
							'ns_type',
							'cs_partner',
							'cs_xcid',
							'cs_impid',
							'cs_proid',
							'cs_dc_di',
							'cs_dc_ci',
							'cs_dc_ei',
							'ns_ap_ui',
							'ns_ap_gs',
							'ns_ap_ie',
							'ns_ts',
							'ns_ap_cfg',
							'ns_ap_env',
							'ns_ap_ais',
							'ns_ap_ut',
							'ns_ap_er',
							'ns_st_sv',
							'ns_st_pv',
							'ns_st_smv',
							'ns_st_it',
							'ns_st_id',
							'ns_st_ec',
							'ns_st_cn',
							'ns_st_ev',
							'ns_st_sp',
							'ns_st_sc',
							'ns_st_ppc',
							'ns_st_apc',
							'ns_st_spc',
							'ns_st_dppc',
							'ns_st_dapc',
							'ns_st_dspc',
							'ns_st_psq',
							'ns_st_asq',
							'ns_st_sq',
							'ns_st_po',
							'ns_st_lda',
							'ns_st_ldw',
							'ns_st_ldo',
							'ns_st_hc',
							'ns_st_hd',
							'ns_st_mp',
							'ns_st_mv',
							'ns_st_cl',
							'ns_st_sl',
							'ns_st_pn',
							'ns_st_tp',
							'ns_st_ct',
							'ns_st_ad',
							'ns_st_li',
							'ns_st_ty',
							'ns_st_ci',
							'ns_st_si',
							'ns_ap_et',
							'ns_ap_ar',
							'cs_c12u',
							'ca_cs_c12u',
							'cb_cs_c12u',
							'cc_cs_c12u',
							'cd_cs_c12u',
							'ns_ap_cs',
							'ns_ap_fg',
							'ns_ap_dft',
							'ns_ap_dbt',
							'ns_ap_dit',
							'ns_ap_as',
							'ns_ap_das',
							'ns_ap_usage',
							'ns_category',
							'category',
							'ns_radio',
							'ns_st_pt',
							'ns_st_ipt',
							'ns_st_iap',
							'ns_st_iet',
							'ns_st_iupc',
							'ns_st_iupa',
							'ns_st_ilpc',
							'ns_st_ilpa',
							'ns_st_ibc',
							'ns_st_ibt',
							'ns_st_itpc',
							'ns_st_icpc',
							'ns_st_ae',
							'ns_st_er',
							'ns_st_cfg',
							'ns_st_rcn',
							'ns_st_cpo',
							'ns_st_ui',
							'c3',
							'ca3',
							'cb3',
							'cc3',
							'cd3',
							'c4',
							'ca4',
							'cb4',
							'cc4',
							'cd4',
							'c6',
							'ca6',
							'cb6',
							'cc6',
							'cd6',
							'ns_st_bn',
							'ns_st_tb',
							'ns_st_an',
							'ns_st_ta',
							'ns_st_ce',
							'ns_st_ia',
							'ns_st_pu',
							'ns_st_st',
							'ns_st_stc',
							'ns_st_sta',
							'ns_st_pr',
							'ns_st_tpr',
							'ns_st_sn',
							'ns_st_en',
							'ns_st_ep',
							'ns_st_tep',
							'ns_st_dt',
							'ns_st_ddt',
							'ns_st_tdt',
							'ns_st_tm',
							'ns_st_dtm',
							'ns_st_ttm',
							'ns_st_ge',
							'ns_st_tge',
							'ns_st_cs',
							'ns_st_ft',
							'ns_st_amg',
							'ns_st_ami',
							'ns_st_amp',
							'ns_st_amw',
							'ns_st_amt',
							'ns_st_ams',
							'ns_st_cde',
							'ns_st_cds',
							'ns_st_cdc',
							'ns_st_cda',
							'ns_st_cdm',
							'ns_st_cmt',
							'ns_st_amd',
							'ns_st_amo',
							'ns_st_sr',
							'ns_st_pl',
							'ns_st_ca',
							'ns_st_cp',
							'ns_st_fee',
							'ns_st_dskc',
							'ns_st_dska',
							'ns_st_skd',
							'ns_st_dskt',
							'ns_st_dpc',
							'ns_ap_i1',
							'ns_ap_i2',
							'ns_ap_i3',
							'ns_ap_i4',
							'ns_ap_i5',
							'ns_ap_i6',
							'cs_ucfr',
							'gdpr',
							'gdpr_p1t',
							'gdpr_li',
							'gdpr_pcc',
							'gdpr_purps',
							'gdpr_consent',
							'c5',
							'c10',
							'c11',
							'c13',
							'c14',
							'c15',
							'c16',
							'ns_ap_install',
							'ns_ap_updated',
							'ns_ap_lastrun',
							'ns_ap_ft',
							'ns_ap_bt',
							'ns_ap_it',
							'ns_ap_res',
							'ns_ap_sd',
							'ns_ap_po',
							'ns_ap_ot',
							'ns_ap_lang',
							'ns_ap_miss',
							'ns_ap_jb',
							'ns_ap_oc',
							'ns_c',
							'ns_st_cev',
							'ns_st_lt',
							'ns_st_br',
							'ns_st_pbr',
							'ns_st_rt',
							'ns_st_prt',
							'ns_st_vo',
							'ns_st_pvo',
							'ns_st_ws',
							'ns_st_pws',
							'ns_st_rp',
							'ns_st_at',
							'ns_st_pat',
							'ns_st_vt',
							'ns_st_pvt',
							'ns_st_tt',
							'ns_st_ptt',
							'ns_st_cdn',
							'ns_st_pcdn',
							'ns_st_pb',
							'ns_st_dpt',
							'ns_st_ap',
							'ns_st_dap',
							'ns_st_et',
							'ns_st_det',
							'ns_st_upc',
							'ns_st_dupc',
							'ns_st_upa',
							'ns_st_dupa',
							'ns_st_lpc',
							'ns_st_dlpc',
							'ns_st_lpa',
							'ns_st_dlpa',
							'ns_st_bc',
							'ns_st_dbc',
							'ns_st_bt',
							'ns_st_dbt',
							'ns_st_bp',
							'ns_st_dtpc',
							'ns_st_dcpc',
							'ns_st_skc',
							'ns_st_ska',
							'ns_st_skt',
							'ns_st_pc',
							'ns_st_pp',
							'ns_st_pa',
							'c7',
							'c8',
							'c9',
							'ns_st_cu',
							'ns_st_amc',
						],
					};
					t.exports = r;
				},
				function (t, n) {
					t.exports = function (t) {
						var n = this;
						(n.Lt = t),
							(n.Tt = {}),
							(n.Nt = {}),
							(n.Ot = +new Date()),
							(n.setLabel = function (t, e) {
								n.Nt[t] = e;
							});
					};
				},
				function (t, n, e) {
					var r,
						i = e(0),
						o = e(32),
						s = e(84),
						a = e(28),
						u = e(30),
						c = e(19),
						f = e(2).yn,
						l = e(26),
						d = e(27),
						_ = e(87),
						p = e(88),
						h = e(41),
						v = e(33),
						g = e(89),
						E = e(43),
						m = e(90),
						A = e(11),
						L = e(91),
						y = e(15),
						I = e(38),
						b = e(29),
						P = e(92);
					(r = new (function () {
						var t,
							n,
							e,
							r,
							N,
							S,
							w,
							D,
							C,
							O,
							R,
							x,
							T,
							U,
							M,
							F,
							B,
							G,
							k,
							V = this;
						function K() {
							var t;
							M.Rt() || N.kt(), ((t = N.Ut()).Mt || t.xt) && M.Wt(t.Ft);
						}
						function Y(t) {
							k.push(function () {
								e.Bt(t, X(), M);
							});
						}
						function W(i) {
							switch (i) {
								case a.DISABLE:
									!(function () {
										C &&
											((C = !1),
											R && f.clearTimeout(G),
											U && (D.stop(), b.qt().Vt()),
											(O || R) && (n.Gt(this), n.jt(this), n.Ht()),
											w && w.Kt());
									})();
									break;
								case a.TIME_WINDOW_ELAPSED:
									(e = new s(n, t, w, r, S, N, B)),
										(D = new h(t)).Jt(V),
										H(),
										M.getUsagePropertiesAutoUpdateInterval() >= 0 &&
											n.Yt(M.getUsagePropertiesAutoUpdateInterval(), M.Xt() == d.FOREGROUND_ONLY),
										D.zt(M.isKeepAliveMeasurementEnabled()),
										b.qt().Qt(M),
										(U = !0),
										t.$t(),
										(function () {
											for (var t = 0; t < k.length; ++t) (0, k[t])();
											k = [];
										})();
									break;
								case a.PARTNER:
								case a.PUBLISHER:
									U && (H(), t.$t());
									break;
								case a.OFFLINE_CACHE_MODE:
									break;
								case a.KEEP_ALIVE:
									U && D.zt(M.isKeepAliveMeasurementEnabled());
									break;
								case a.Zt:
									B.zt(!1);
							}
						}
						function j(t, e, r, i) {
							var o;
							O && U
								? t()
								: T
								? k.push(t)
								: r
								? (k.push(t), Y(e), (x = !0))
								: (x ||
										((o = e),
										k.push(function () {
											n.notifyEnterForeground(o);
										}),
										(x = !0)),
								  i || Y(e),
								  k.push(t),
								  (T = !0));
						}
						function H() {
							B.ne() || B.te();
							for (
								var n = t.get(A.zn, {}),
									r = M.getPublisherConfigurations(),
									i = 'a'.charCodeAt(0) - 1,
									o = 0;
								o < r.length;
								++o
							) {
								var s = r[o],
									a = s.getPublisherId(),
									u = N.ee(s.getPublisherSecret()),
									c = n[a],
									f = '';
								i >= 'a'.charCodeAt(0) && (f = 'c' + String.fromCharCode(i) + '_'),
									i++,
									c && c != u && e.ie(f + 'cs_c12u', c),
									(n[a] = u),
									s.setPublisherUniqueDeviceId(u);
							}
							t.put(A.zn, n);
						}
						function X(t) {
							var n;
							if (t instanceof u) n = t;
							else {
								(n = new u()), 'object' == typeof t && n.addLabels(t);
								var e = M.getIncludedPublishers();
								if (e.length > 0) for (var r = 0; r < e.length; ++r) n.addIncludedPublisher(e[r]);
							}
							return (
								n.getIncludedPublishers().length > 0 &&
									M.getPartnerConfigurations().length > 0 &&
									n.addIncludedPublisher(o.re),
								n
							);
						}
						(r = new p(K)),
							(C = !0),
							(O = !1),
							(R = !1),
							(x = !1),
							(T = !1),
							(U = !1),
							(M = new o()),
							(B = new P()),
							(k = []),
							M.addListener(W),
							i.extend(V, {
								configuration: M,
								start: function () {
									if ((M.oe(), !O && !R)) {
										R = !0;
										var r,
											i,
											o = +new Date();
										(F = new I(M)),
											(function (t) {
												var n = new m();
												n.ue(t), n.se(t);
											})((t = new _(F))),
											(w = new v(t, F)),
											(r = new L(F, t, w)).ae(),
											r.fe(),
											B.ce(t),
											(N = new E(t, B)),
											C &&
												((S = new g(t, M)),
												(n = new l(t, o)),
												B.ne() && B.te(),
												n.le(V),
												n.de(V),
												T ||
													((i = o),
													C &&
														j(
															function () {
																0 == e.ve() && e.Bt(i, X(), M);
															},
															i,
															!1,
															!0,
														),
													(T = !0)),
												(O = !0),
												t.$t(),
												(G = f.setTimeout(function () {
													(G = null), M._e();
												}, y.Pt)));
									}
								},
								pe: function (t) {
									if (C) {
										var n = +new Date();
										j(function () {
											e.pe(n, X(t), M);
										}, n);
									}
								},
								notifyDistributedContentViewEvent: function (t, n) {
									if (M.getPartnerConfiguration(t)) {
										var e = new c();
										e.he(!1),
											e.addIncludedPublisher(o.re),
											e.setLabel('ns_ap_ev', 'distributed_view'),
											e.setLabel('cs_dc_di', t),
											e.setLabel('cs_dc_ci', n),
											V.ge(e);
									}
								},
								ge: function (t) {
									if (C) {
										var n = +new Date();
										j(function () {
											e.ge(n, X(t), M);
										}, n);
									}
								},
								notifyUxActive: function () {
									if (C) {
										var t = +new Date();
										j(function () {
											n.notifyUxActive(t);
										}, t);
									}
								},
								notifyUxInactive: function () {
									if (C) {
										var t = +new Date();
										j(function () {
											n.notifyUxInactive(t);
										}, t);
									}
								},
								notifyEnterForeground: function () {
									if (C) {
										var t = +new Date();
										j(
											function () {
												n.notifyEnterForeground(t);
											},
											t,
											!0,
										);
									}
								},
								notifyExitForeground: function () {
									if (C) {
										var t = +new Date();
										j(
											function () {
												n.notifyExitForeground(t);
											},
											t,
											!0,
										);
									}
								},
								flushOfflineCache: function () {
									C &&
										O &&
										j(function () {
											w.flush(M, !0), w.me(), t.$t();
										}, +new Date());
								},
								clearOfflineCache: function () {
									C &&
										O &&
										j(function () {
											w.Kt(), w.me(), t.$t();
										}, +new Date());
								},
								clearInternalData: function () {
									C &&
										O &&
										j(function () {
											t.clear(), t.$t();
										}, +new Date());
								},
								ye: function () {
									return B;
								},
								Se: function () {
									e.we(+new Date(), X(), M), t.$t();
								},
								be: function (t, n) {
									C &&
										t == l.Ee.Ie &&
										(U
											? D.start(h.Pe)
											: k.push(function () {
													D.start(h.Pe);
											  }));
								},
								Ae: function (t, n) {
									if (C) {
										var e = function () {
											t == l.Ee.Ie &&
												(U
													? D.stop()
													: k.push(function () {
															D.stop();
													  }));
										};
										U ? e() : k.push(e);
									}
								},
								De: function (t, n) {},
								Ce: function (t, n) {},
							});
					})()),
						(t.exports = r);
				},
				function (t, n) {
					var e = {
						Le: function () {
							try {
								return 'undefined' != typeof document;
							} catch (t) {
								return !1;
							}
						},
						Te: function () {
							try {
								return 'undefined' != typeof navigator;
							} catch (t) {
								return !1;
							}
						},
						Rn: function () {
							if (!e.Le()) return !1;
							var t = !1;
							return (
								(void 0 !== document.hidden ||
									void 0 !== document.mozHidden ||
									void 0 !== document.msHidden ||
									void 0 !== document.webkitHidden) &&
									(t = !0),
								t
							);
						},
					};
					(e.Ne = (function () {
						if (!e.Le()) return null;
						var t, n, r;
						void 0 !== document.hidden
							? ((t = 'hidden'), (n = 'visibilitychange'), (r = 'visibilityState'))
							: void 0 !== document.mozHidden
							? ((t = 'mozHidden'), (n = 'mozvisibilitychange'), (r = 'mozVisibilityState'))
							: void 0 !== document.msHidden
							? ((t = 'msHidden'), (n = 'msvisibilitychange'), (r = 'msVisibilityState'))
							: void 0 !== document.webkitHidden &&
							  ((t = 'webkitHidden'),
							  (n = 'webkitvisibilitychange'),
							  (r = 'webkitVisibilityState'));
						var i = { hidden: t, Oe: n, state: r };
						return function () {
							return i;
						};
					})()),
						(e.kn = function () {
							if (!e.Le()) return !1;
							if (!e.Rn()) return !1;
							var t = e.Ne();
							return document[t.hidden];
						}),
						(e.Mn = function (t) {
							if (e.Le() && e.Rn()) {
								var n = e.Ne();
								document.addEventListener(n.Oe, t, !1);
							}
						}),
						(e.Un = function (t) {
							if (e.Le() && e.Rn()) {
								var n = e.Ne();
								document.removeEventListener(n.Oe, t, !1);
							}
						}),
						(e.Re = function () {
							if (!e.Te()) return '';
							var t,
								n,
								r = navigator.userAgent || '',
								i = navigator.appName || '';
							return (
								-1 != (n = r.indexOf('Opera')) || -1 != (n = r.indexOf('OPR/'))
									? (i = 'Opera')
									: -1 != (n = r.indexOf('Android'))
									? (i = 'Android')
									: -1 != (n = r.indexOf('Chrome'))
									? (i = 'Chrome')
									: -1 != (n = r.indexOf('Safari'))
									? (i = 'Safari')
									: -1 != (n = r.indexOf('Firefox'))
									? (i = 'Firefox')
									: -1 != (n = r.indexOf('IEMobile'))
									? (i = 'Internet Explorer Mobile')
									: 'Microsoft Internet Explorer' == i || 'Netscape' == i
									? (i = 'Internet Explorer')
									: (t = r.lastIndexOf(' ') + 1) < (n = r.lastIndexOf('/'))
									? (i = r.substring(t, n)).toLowerCase() == i.toUpperCase() &&
									  (i = navigator.appName)
									: (i = 'unknown'),
								i
							);
						}),
						(e.ke = function () {
							if (!e.Te()) return '';
							var t,
								n,
								r,
								i = navigator.userAgent || '',
								o = navigator.appName || '',
								s = navigator.appVersion ? '' + parseFloat(navigator.appVersion) : '';
							return (
								-1 != (n = i.indexOf('Opera'))
									? ((s = i.substring(n + 6)),
									  -1 != (n = i.indexOf('Version')) && (s = i.substring(n + 8)))
									: -1 != (n = i.indexOf('OPR/'))
									? (s = i.substring(n + 4))
									: -1 != (n = i.indexOf('Android'))
									? (s = i.substring(n + 11))
									: -1 != (n = i.indexOf('Chrome'))
									? (s = i.substring(n + 7))
									: -1 != (n = i.indexOf('Safari'))
									? ((s = i.substring(n + 7)),
									  -1 != (n = i.indexOf('Version')) && (s = i.substring(n + 8)))
									: -1 != (n = i.indexOf('Firefox'))
									? (s = i.substring(n + 8))
									: 'Microsoft Internet Explorer' == o
									? null != new RegExp('MSIE ([0-9]{1,}[.0-9]{0,})').exec(i) &&
									  (s = parseFloat(RegExp.$1))
									: 'Netscape' == o
									? null != new RegExp('Trident/.*rv:([0-9]{1,}[.0-9]{0,})').exec(i) &&
									  (s = parseFloat(RegExp.$1))
									: (s =
											i.lastIndexOf(' ') + 1 < (n = i.lastIndexOf('/'))
												? i.substring(n + 1)
												: 'unknown'),
								-1 != (r = (s = s.toString()).indexOf(';')) && (s = s.substring(0, r)),
								-1 != (r = s.indexOf(' ')) && (s = s.substring(0, r)),
								-1 != (r = s.indexOf(')')) && (s = s.substring(0, r)),
								(t = parseInt('' + s, 10)),
								isNaN(t) && (s = '' + parseFloat(navigator.appVersion)),
								s
							);
						}),
						(e.Me = function () {
							return 'undefined' == typeof window || !0;
						}),
						(e.yt = function () {
							return 'undefined' != typeof window && 'undefined' != typeof document;
						}),
						(e.Ue = function () {
							return !!e.Le() && 's' === document.location.href.charAt(4);
						}),
						(t.exports.Rn = e.Rn),
						(t.exports.Ne = e.Ne),
						(t.exports.kn = e.kn),
						(t.exports.Re = e.Re),
						(t.exports.ke = e.ke),
						(t.exports.Me = e.Me),
						(t.exports.yt = e.yt),
						(t.exports.Ue = e.Ue),
						(t.exports.Mn = e.Mn),
						(t.exports.Un = e.Un);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(30);
					function o() {
						var t = new i(),
							n = [],
							e = !0;
						r.extend(this, t),
							r.extend(this, {
								xe: function (t) {
									n = t;
								},
								We: function () {
									return n;
								},
								he: function (t) {
									e = t;
								},
								Fe: function () {
									return e;
								},
							});
					}
					(o.prototype = Object.create(i.prototype)), (t.exports = o);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(56),
						o = {
							Be: function (t, n, e) {
								return -1 == t.indexOf('?') ? (t += '?') : (t += '&'), t + o.Ve(n, e);
							},
							qe: function (t, n) {
								return r.extend(t, o.ht(n || {}));
							},
							ht: function (t) {
								var n = {};
								for (var e in t)
									if (t.hasOwnProperty(e)) {
										var r = t[e];
										n[e] = null == r ? r : t[e] + '';
									}
								return n;
							},
							Ve: function (t, n) {
								var e = !1,
									i = '';
								t = r.h(t);
								for (
									var o = 'undefined' != typeof encodeURIComponent ? encodeURIComponent : escape,
										s = 0;
									s < n.length;
									++s
								) {
									var a = n[s];
									null != t[a] &&
										(e && (i += '&'), (e = !0), (i += o(a) + '=' + o(t[a])), delete t[a]);
								}
								for (a in t)
									null != t[a] && (e && (i += '&'), (e = !0), (i += o(a) + '=' + o(t[a])));
								return i;
							},
							Ge: function (t) {
								return 'unknown' == t ? 'unknown' : i.je(t) ? '1' : '0';
							},
						};
					t.exports = o;
				},
				function (t, n) {
					var e = {
						indexOf: function (t, n) {
							var r = -1;
							return (
								e.forEach(n, function (n, e) {
									n == t && (r = e);
								}),
								r
							);
						},
						forEach: function (t, n, e) {
							try {
								if ('function' == typeof n)
									if (
										((e = void 0 !== e ? e : null), 'number' != typeof t.length || void 0 === t[0])
									) {
										var r = void 0 !== t.__proto__;
										for (var i in t)
											t.hasOwnProperty(i) &&
												(!r || (r && void 0 === t.__proto__[i])) &&
												'function' != typeof t[i] &&
												n.call(e, t[i], i);
									} else for (var o = 0, s = t.length; o < s; o++) n.call(e, t[o], o);
							} catch (t) {}
						},
					};
					(n.indexOf = e.indexOf), (n.forEach = e.forEach);
				},
				function (t, n) {
					t.exports = o;
				},
				function (t, n) {
					var e = {};
					(e.He = (function () {
						function t(t, n) {
							var s = t[0],
								a = t[1],
								u = t[2],
								f = t[3];
							(s = e(s, a, u, f, n[0], 7, -680876936)),
								(f = e(f, s, a, u, n[1], 12, -389564586)),
								(u = e(u, f, s, a, n[2], 17, 606105819)),
								(a = e(a, u, f, s, n[3], 22, -1044525330)),
								(s = e(s, a, u, f, n[4], 7, -176418897)),
								(f = e(f, s, a, u, n[5], 12, 1200080426)),
								(u = e(u, f, s, a, n[6], 17, -1473231341)),
								(a = e(a, u, f, s, n[7], 22, -45705983)),
								(s = e(s, a, u, f, n[8], 7, 1770035416)),
								(f = e(f, s, a, u, n[9], 12, -1958414417)),
								(u = e(u, f, s, a, n[10], 17, -42063)),
								(a = e(a, u, f, s, n[11], 22, -1990404162)),
								(s = e(s, a, u, f, n[12], 7, 1804603682)),
								(f = e(f, s, a, u, n[13], 12, -40341101)),
								(u = e(u, f, s, a, n[14], 17, -1502002290)),
								(s = r(s, (a = e(a, u, f, s, n[15], 22, 1236535329)), u, f, n[1], 5, -165796510)),
								(f = r(f, s, a, u, n[6], 9, -1069501632)),
								(u = r(u, f, s, a, n[11], 14, 643717713)),
								(a = r(a, u, f, s, n[0], 20, -373897302)),
								(s = r(s, a, u, f, n[5], 5, -701558691)),
								(f = r(f, s, a, u, n[10], 9, 38016083)),
								(u = r(u, f, s, a, n[15], 14, -660478335)),
								(a = r(a, u, f, s, n[4], 20, -405537848)),
								(s = r(s, a, u, f, n[9], 5, 568446438)),
								(f = r(f, s, a, u, n[14], 9, -1019803690)),
								(u = r(u, f, s, a, n[3], 14, -187363961)),
								(a = r(a, u, f, s, n[8], 20, 1163531501)),
								(s = r(s, a, u, f, n[13], 5, -1444681467)),
								(f = r(f, s, a, u, n[2], 9, -51403784)),
								(u = r(u, f, s, a, n[7], 14, 1735328473)),
								(s = i(s, (a = r(a, u, f, s, n[12], 20, -1926607734)), u, f, n[5], 4, -378558)),
								(f = i(f, s, a, u, n[8], 11, -2022574463)),
								(u = i(u, f, s, a, n[11], 16, 1839030562)),
								(a = i(a, u, f, s, n[14], 23, -35309556)),
								(s = i(s, a, u, f, n[1], 4, -1530992060)),
								(f = i(f, s, a, u, n[4], 11, 1272893353)),
								(u = i(u, f, s, a, n[7], 16, -155497632)),
								(a = i(a, u, f, s, n[10], 23, -1094730640)),
								(s = i(s, a, u, f, n[13], 4, 681279174)),
								(f = i(f, s, a, u, n[0], 11, -358537222)),
								(u = i(u, f, s, a, n[3], 16, -722521979)),
								(a = i(a, u, f, s, n[6], 23, 76029189)),
								(s = i(s, a, u, f, n[9], 4, -640364487)),
								(f = i(f, s, a, u, n[12], 11, -421815835)),
								(u = i(u, f, s, a, n[15], 16, 530742520)),
								(s = o(s, (a = i(a, u, f, s, n[2], 23, -995338651)), u, f, n[0], 6, -198630844)),
								(f = o(f, s, a, u, n[7], 10, 1126891415)),
								(u = o(u, f, s, a, n[14], 15, -1416354905)),
								(a = o(a, u, f, s, n[5], 21, -57434055)),
								(s = o(s, a, u, f, n[12], 6, 1700485571)),
								(f = o(f, s, a, u, n[3], 10, -1894986606)),
								(u = o(u, f, s, a, n[10], 15, -1051523)),
								(a = o(a, u, f, s, n[1], 21, -2054922799)),
								(s = o(s, a, u, f, n[8], 6, 1873313359)),
								(f = o(f, s, a, u, n[15], 10, -30611744)),
								(u = o(u, f, s, a, n[6], 15, -1560198380)),
								(a = o(a, u, f, s, n[13], 21, 1309151649)),
								(s = o(s, a, u, f, n[4], 6, -145523070)),
								(f = o(f, s, a, u, n[11], 10, -1120210379)),
								(u = o(u, f, s, a, n[2], 15, 718787259)),
								(a = o(a, u, f, s, n[9], 21, -343485551)),
								(t[0] = c(s, t[0])),
								(t[1] = c(a, t[1])),
								(t[2] = c(u, t[2])),
								(t[3] = c(f, t[3]));
						}
						function n(t, n, e, r, i, o) {
							return (n = c(c(n, t), c(r, o))), c((n << i) | (n >>> (32 - i)), e);
						}
						function e(t, e, r, i, o, s, a) {
							return n((e & r) | (~e & i), t, e, o, s, a);
						}
						function r(t, e, r, i, o, s, a) {
							return n((e & i) | (r & ~i), t, e, o, s, a);
						}
						function i(t, e, r, i, o, s, a) {
							return n(e ^ r ^ i, t, e, o, s, a);
						}
						function o(t, e, r, i, o, s, a) {
							return n(r ^ (e | ~i), t, e, o, s, a);
						}
						function s(t) {
							var n,
								e = [];
							for (n = 0; n < 64; n += 4)
								e[n >> 2] =
									t.charCodeAt(n) +
									(t.charCodeAt(n + 1) << 8) +
									(t.charCodeAt(n + 2) << 16) +
									(t.charCodeAt(n + 3) << 24);
							return e;
						}
						var a = '0123456789abcdef'.split('');
						function u(t) {
							for (var n = '', e = 0; e < 4; e++)
								n += a[(t >> (8 * e + 4)) & 15] + a[(t >> (8 * e)) & 15];
							return n;
						}
						function c(t, n) {
							var e = (65535 & t) + (65535 & n);
							return (((t >> 16) + (n >> 16) + (e >> 16)) << 16) | (65535 & e);
						}
						return function (n) {
							return (function (t) {
								for (var n = 0; n < t.length; n++) t[n] = u(t[n]);
								return t.join('');
							})(
								(function (n) {
									var e,
										r = n.length,
										i = [1732584193, -271733879, -1732584194, 271733878];
									for (e = 64; e <= n.length; e += 64) t(i, s(n.substring(e - 64, e)));
									n = n.substring(e - 64);
									var o = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
									for (e = 0; e < n.length; e++) o[e >> 2] |= n.charCodeAt(e) << (e % 4 << 3);
									if (((o[e >> 2] |= 128 << (e % 4 << 3)), e > 55))
										for (t(i, o), e = 0; e < 16; e++) o[e] = 0;
									return (o[14] = 8 * r), t(i, o), i;
								})(n),
							);
						};
					})()),
						(t.exports = e.He);
				},
				function (t, n) {
					t.exports = { DISABLED: 4, LAN: 3, MANUAL_FLUSH: 2, ENABLED: 1 };
				},
				function (t, n) {
					t.exports = { STANDARD: 1, LAN: 2, CACHE: 3 };
				},
				function (t, n, e) {
					var r = e(0),
						i = e(2).yn,
						o = e(11),
						s = e(27),
						a = { Ke: 2, Je: 3, Ie: 4 },
						u = { Ye: 1, Xe: 2, Ie: 3 };
					function c(t, n) {
						var e,
							s,
							c,
							f,
							l,
							d,
							_,
							p,
							h,
							v,
							g,
							E,
							m,
							A,
							L,
							y,
							I,
							b,
							P,
							N,
							S,
							w = this,
							D = [],
							C = [];
						function O(t) {
							var n;
							(n = s ? u.Ye : c ? u.Xe : u.Ie) != f &&
								(function (t, n) {
									f != t &&
										((function (t, n) {
											switch (t) {
												case u.Ie:
													break;
												case u.Xe:
												case u.Ye:
													T();
											}
											for (var e = 0; e < C.length; ++e) C[e].be(t, n);
										})(f, n),
										(function (t, n) {
											switch (t) {
												case u.Ie:
													break;
												case u.Xe:
													l || x();
													break;
												case u.Ye:
													x(), d++;
											}
											for (var e = 0; e < C.length; ++e) C[e].Ae(t, n);
										})(t, n),
										R(n, !0),
										(f = t));
								})(n, t);
						}
						function R(n, e) {
							var r = n - _;
							switch (f) {
								case u.Ye:
									(p += r), (h += r);
									break;
								case u.Xe:
									(v += r), (g += r);
									break;
								case u.Ie:
									(E += r), (m += r);
							}
							(_ = n),
								e &&
									(t.put(o.Qn, _),
									t.put(o.nt, d),
									t.put(o.tt, p),
									t.put(o.et, v),
									t.put(o.it, E),
									t.put(o.rt, h),
									t.put(o.ot, g),
									t.put(o.ut, m));
						}
						function x() {
							T(),
								P > 0 &&
									(S = i.setTimeout(function () {
										w.ze(+new Date(), !0), (S = null), x(), t.$t();
									}, P));
						}
						function T() {
							S && (i.clearTimeout(S), (S = null));
						}
						function U(t) {
							var n;
							(n = c ? a.Ke : s ? a.Je : a.Ie) != e &&
								(function (t, n) {
									e != t &&
										((function (t, n) {
											switch (t) {
												case a.Ke:
												case a.Je:
													A = n;
													break;
												case a.Ie:
													F(n) || (L += n - y);
											}
											for (var e = 0; e < D.length; ++e) D[e].De(t, n);
										})(e, n),
										(function (t, n) {
											switch (t) {
												case a.Ke:
												case a.Je:
													F(n);
											}
											for (var e = 0; e < D.length; ++e) D[e].Ce(t, n);
										})(t, n),
										M(n, !0),
										(e = t));
								})(n, t);
						}
						function M(n, r) {
							var i = n - y;
							switch (e) {
								case a.Ke:
								case a.Je:
									(L += i), (A = n);
							}
							(y = n),
								r &&
									(t.put(o.$n, y), t.put(o.Zn, A), t.put(o.st, L), t.put(o.ct, I), t.put(o.ft, N));
						}
						function F(n) {
							var e = !1;
							return n - A > 18e5 && ((I = n), t.put(o.ct, I), N++, (e = !0)), (A = n), e;
						}
						(d = 0),
							(l = !1),
							(f = u.Ie),
							(e = a.Ie),
							(s = !1),
							(c = !1),
							(h = 0),
							(g = 0),
							(m = 0),
							(v = 0),
							(p = 0),
							(E = 0),
							(L = 0),
							(I = -1),
							(b = -1),
							(_ = -1),
							(y = -1),
							(A = -1),
							(P = 0),
							(N = 0),
							(_ = t.get(o.Qn, -1)),
							(y = t.get(o.$n, -1)),
							(A = t.get(o.Zn, -1)),
							(d = t.get(o.nt, 0)),
							(p = t.get(o.tt, 0)),
							(v = t.get(o.et, 0)),
							(E = t.get(o.it, 0)),
							(h = t.get(o.rt, 0)),
							(g = t.get(o.ot, 0)),
							(m = t.get(o.ut, 0)),
							(L = t.get(o.st, 0)),
							(N = t.get(o.ft, 0)),
							(function (n) {
								(b = t.get(o.lt, 0)),
									(I = t.get(o.ct, -1)) < 0
										? ((I = n), t.put(o.ct, I), (b = 0), t.put(o.lt, b), (A = I), N++)
										: (F(n) || ((L += n - y), t.put(o.st, L)), (A = I));
							})(n),
							(function (n) {
								if (_ > 0) {
									var e = n - _;
									(E += e), t.put(o.it, E), (m += e), t.put(o.ut, m);
								}
								(y = _ = n), t.put(o.$n, y), t.put(o.Qn, _), t.put(o.Zn, A);
							})(n),
							r.extend(w, {
								Qe: function () {
									return f;
								},
								notifyUxActive: function (n) {
									c || ((c = !0), O(n), U(n), t.$t());
								},
								notifyUxInactive: function (n) {
									c && ((c = !1), O(n), U(n), t.$t());
								},
								notifyEnterForeground: function (n) {
									s || ((s = !0), O(n), U(n), t.$t());
								},
								notifyExitForeground: function (n) {
									s && ((s = !1), O(n), U(n), t.$t());
								},
								ze: function (t, n) {
									R(t, n), M(t, n);
								},
								Yt: function (t, n) {
									(P = 1e3 * t), (l = n), t > 0 && (f == u.Ye || (f == u.Xe && !n)) ? x() : T();
								},
								$e: function (n) {
									void 0 === n && (n = !0);
									var e = N;
									return n && ((N = 0), t.put(o.ft, N)), e;
								},
								Ze: function (n) {
									void 0 === n && (n = !0);
									var e = L;
									return n && ((L = 0), t.put(o.st, L)), e;
								},
								ni: function (n) {
									void 0 === n && (n = !0);
									var e = E;
									return n && ((E = 0), t.put(o.it, E)), e;
								},
								ti: function (n) {
									void 0 === n && (n = !0);
									var e = m;
									return n && (m = 0), t.put(o.ut, m), e;
								},
								ei: function (n) {
									void 0 === n && (n = !0);
									var e = v;
									return n && ((v = 0), t.put(o.et, v)), e;
								},
								ii: function (n) {
									void 0 === n && (n = !0);
									var e = p;
									return n && ((p = 0), t.put(o.tt, p)), e;
								},
								ri: function (n) {
									void 0 === n && (n = !0);
									var e = h;
									return n && (h = 0), t.put(o.rt, h), e;
								},
								oi: function (n) {
									void 0 === n && (n = !0);
									var e = d;
									return n && ((d = 0), t.put(o.nt, d)), e;
								},
								ui: function (n) {
									void 0 === n && (n = !0);
									var e = g;
									return n && (g = 0), t.put(o.ot, g), e;
								},
								si: function () {
									return I;
								},
								ai: function () {
									return b;
								},
								le: function (t) {
									C.push(t);
								},
								Gt: function (t) {
									var n = C.indexOf(t);
									-1 != n && C.splice(n, 1);
								},
								de: function (t) {
									D.push(t);
								},
								jt: function (t) {
									var n = D.indexOf(t);
									-1 != n && D.splice(n, 1);
								},
								Ht: function () {
									T();
								},
							});
					}
					(c.fi = s.FOREGROUND_ONLY), (c.ci = 60), (c.li = a), (c.Ee = u), (t.exports = c);
				},
				function (t, n) {
					t.exports = { DISABLED: 2, FOREGROUND_AND_BACKGROUND: 1, FOREGROUND_ONLY: 0 };
				},
				function (t, n) {
					t.exports = {
						PERSISTENT_LABELS: 0,
						PARTNER: 1,
						PUBLISHER: 2,
						KEEP_ALIVE: 3,
						LIVE_TRANSMISSION_MODE: 4,
						OFFLINE_CACHE_MODE: 5,
						DISABLE: 6,
						TIME_WINDOW_ELAPSED: 7,
						START_LABELS: 8,
						LABEL_ORDER: 9,
						Zt: 10,
					};
				},
				function (t, n, e) {
					var r,
						i = e(0),
						o = e(2).yn,
						s = { di: 1, vi: 2, _i: 3 },
						a = +new Date();
					function u() {
						var t, n, e, r, u, c, f, l, d, _;
						function p() {
							(f = o.cn()), t && (g(), h());
						}
						function h() {
							var n = f ? l : d;
							(r = +new Date()),
								(u = r + n),
								(t = o.setInterval(function () {
									var t = (r = +new Date()) - u;
									(u = r + n), Math.abs(t) > _ && v(t > 0 ? s._i : r < e ? s.vi : s.di);
								}, n));
						}
						function v(t) {
							for (var n = 0; n < c.length; ++n) c[n](t);
						}
						function g() {
							t && (o.clearInterval(t), (t = null));
						}
						i.extend(this, {
							Qt: function (e) {
								e.pi() &&
									((l = e.hi()),
									(d = e.gi()),
									(_ = e.mi()),
									(f = o.cn()),
									t || h(),
									n || ((n = !0), o.ln(p)));
							},
							Vt: function () {
								g(), n && (o.dn(p), (n = !1));
							},
							yi: function (t) {
								c.push(t);
							},
							Si: function (t) {
								var n = c.indexOf(t);
								-1 != n && c.splice(n, 1);
							},
							wi: function (n) {
								var i;
								t
									? u < n && n - u > _
										? (i = s._i)
										: e > n
										? (i = s.vi)
										: r > n && (i = s.di)
									: e > n && (i = s.vi),
									i && (v(i), t && (g(), h())),
									(e = n);
							},
						}),
							(t = null),
							(n = !1),
							(c = []),
							(e = a),
							(f = !0),
							(l = -1),
							(d = -1),
							(_ = 1e3);
					}
					(u.bi = 1e3),
						(u.ENABLED = !0),
						(u.Ii = s),
						(u.qt = function () {
							return r || (r = new u()), r;
						}),
						(t.exports = u);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(7);
					t.exports = function () {
						var t = {},
							n = [],
							e = {};
						r.extend(this, {
							addLabels: function (n) {
								i.Bn(t, n);
							},
							getLabels: function () {
								return t;
							},
							setLabel: function (n, e) {
								i.Fn(t, n, e);
							},
							getIncludedPublishers: function () {
								return n;
							},
							addIncludedPublisher: function (t) {
								n && -1 == n.indexOf(t) && n.push(t);
							},
							addPublisherLabels: function (t, n) {
								t && ((e[t] = e[t] || {}), i.Bn(e[t], n));
							},
							setPublisherLabel: function (t, n, r) {
								t && ((e[t] = e[t] || {}), i.Fn(e[t], n, r));
							},
							getPublisherLabels: function (t) {
								return e[t] || {};
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(7),
						o = e(52);
					function s() {
						var t,
							n,
							e,
							s,
							u,
							c = this;
						function f(t, n) {
							null != t && l('ns_st_ct', (n ? 'ac' : 'vc') + t);
						}
						function l(t, e) {
							i.Fn(n, t, e);
						}
						function d(t) {
							delete n[t];
						}
						(n = {}),
							(u = !1),
							(e = {}),
							(t = new o()),
							l('ns_st_li', '0'),
							l('ns_st_ty', 'video'),
							r.extend(c, t),
							r.extend(c, {
								setMediaType: function (t) {
									(s = t) == a.LIVE || s == a.USER_GENERATED_LIVE
										? l('ns_st_li', '1')
										: l('ns_st_li', '0'),
										f(s, u);
								},
								classifyAsAudioStream: function (t) {
									null == t && (t = !0), l('ns_st_ty', (u = t) ? 'audio' : 'video'), f(s, u);
								},
								classifyAsCompleteEpisode: function (t) {
									null == t && (t = !0), t ? l('ns_st_ce', '1') : d('ns_st_ce');
								},
								carryTvAdvertisementLoad: function (t) {
									null == t && (t = !0), t ? l('ns_st_ia', '1') : d('ns_st_ia');
								},
								setLength: function (t) {
									l('ns_st_cl', t);
								},
								setTotalSegments: function (t) {
									l('ns_st_tp', t);
								},
								setClipUrl: function (t) {
									l('ns_st_cu', t);
								},
								setFeedType: function (t) {
									l('ns_st_ft', t);
								},
								setVideoDimensions: function (t, n) {
									l('ns_st_cs', (t = t || 0) + 'x' + (n = n || 0));
								},
								setStack: function (t, n) {
									e[t] = n;
								},
								getStandardLabels: function () {
									return r.extend({}, t.getStandardLabels(), n);
								},
								getMetadataLabels: function () {
									return r.extend({}, c.getStandardLabels(), c.getCustomLabels());
								},
								getStacks: function () {
									return e;
								},
							});
					}
					r.extend(s, o);
					var a = {
						LONG_FORM_ON_DEMAND: '12',
						SHORT_FORM_ON_DEMAND: '11',
						LIVE: '13',
						USER_GENERATED_LONG_FORM_ON_DEMAND: '22',
						USER_GENERATED_SHORT_FORM_ON_DEMAND: '21',
						USER_GENERATED_LIVE: '23',
						BUMPER: '99',
						OTHER: '00',
					};
					(s.ContentType = a),
						(s.ContentFeedType = {
							EAST_HD: 'EASTHD',
							WEST_HD: 'WESTHD',
							EAST_SD: 'EASTSD',
							WEST_SD: 'WESTSD',
							OTHER: 'OTHER',
						}),
						(t.exports = s);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(7),
						o = e(4),
						s = e(33),
						a = e(24),
						u = e(15),
						c = e(3),
						f = e(26),
						l = e(38),
						d = e(28),
						_ = e(82),
						p = e(42),
						h = e(39),
						v = e(2),
						g = v.yn,
						E = e(29),
						m = c.wn;
					function A() {
						var t,
							n,
							e,
							c,
							L,
							y,
							I,
							b,
							P,
							N,
							S,
							w,
							D,
							C,
							O,
							R,
							x,
							T,
							U,
							M,
							F,
							B,
							G,
							k,
							V,
							K,
							Y,
							W,
							j,
							H,
							X,
							q,
							J,
							$,
							z,
							Z,
							Q,
							tt,
							nt,
							et = this;
						function rt() {
							if (
								(function () {
									if (G.length > 0) {
										for (var t = !1, n = 0; n < C.length; ++n) {
											var e = C[n];
											if (-1 != G.indexOf(e.getPublisherId())) {
												t = !0;
												break;
											}
										}
										return !t && O.length > 0 && -1 != G.indexOf('21193409') && (t = !0), t;
									}
									return !0;
								})()
							) {
								if ((ot(), S))
									!(function () {
										var t = S.split('?');
										if (((S = t[0]), t[1]))
											for (var n = t[1].split('&'), e = 0; e < n.length; e++) {
												var r = n[e].split('=');
												I[r[0]] = r[1] ? r[1] : '';
											}
									})();
								else {
									var n = g.L,
										e = g.C;
									S = t ? (o.On(n) ? n() : n) : o.On(e) ? e() : e;
								}
								(D && D != s.Ei) || (D = t ? s.Pi : s.Ei),
									O.length > 0 && 0 == C.length && it(),
									(c = !0),
									st(d.TIME_WINDOW_ELAPSED);
							}
						}
						function it() {
							if (!y) {
								y = !0;
								var t = new p({
									publisherId: H.getPublisherId(),
									publisherSecret: H.getPublisherSecret(),
								});
								C.push(t), st(d.PUBLISHER);
							}
						}
						function ot() {
							!(function () {
								if (0 != C.length)
									for (var e = 0; e < C.length; ++e) {
										var r = C[e];
										r.getPublisherId() != H.getPublisherId() &&
											((t = r.isSecureTransmissionEnabled()),
											(P = r.isKeepAliveMeasurementEnabled()),
											(n = r.isHttpRedirectCachingEnabled()));
									}
							})(),
								(function () {
									if (0 != O.length)
										for (
											var e = 1 == C.length && C[0].getPublisherId() == H.getPublisherId(), r = 0;
											r < O.length;
											++r
										) {
											var i = O[r];
											e && i.isSecureTransmissionEnabled() && (t = !0),
												(P = i.isKeepAliveMeasurementEnabled()),
												(n = i.isHttpRedirectCachingEnabled());
										}
								})();
						}
						function st(t) {
							for (var n = M.slice(), e = 0; e < n.length; ++e) n[e](t);
						}
						r.extend(et, {
							oe: function () {
								v.Sn(),
									(J = -1 == J ? g.an : J),
									($ = -1 == $ ? g.fn : $),
									(x = x || g.F()),
									(T = T || g.B());
							},
							addListener: function (t) {
								o.On(t) && M.push(t);
							},
							_e: function () {
								L || ((L = !0), (O.length > 0 || C.length > 0) && rt());
							},
							addClient: function (t) {
								t instanceof h &&
									(function (t) {
										if (t.getPartnerId()) {
											for (var n = 0; n < O.length; ++n)
												if (O[n].getPartnerId() == t.getPartnerId()) return;
											O.push(t), it(), L && (c ? ot() : rt()), st(d.PARTNER);
										}
									})(t),
									t instanceof p &&
										(function (t) {
											if (
												t.getPublisherId() &&
												t.getPublisherSecret() &&
												t.getPublisherId() != A.re
											) {
												for (var n = 0; n < C.length; ++n)
													if (C[n].getPublisherId() == t.getPublisherId()) return;
												C.length > 0 && C[C.length - 1].getPublisherId() == H.getPublisherId()
													? C.splice(C.length - 1, 0, t)
													: C.push(t),
													L && (c ? ot() : rt()),
													st(d.PUBLISHER);
											}
										})(t);
							},
							Ai: function () {
								var r = '';
								return (
									(r += t ? '1' : '0'),
									(r += P ? '1' : '0'),
									(r += n ? '1' : '0'),
									(r += e ? '1' : '0'),
									(r += q ? '1' : '0'),
									(r += Z ? '1' : '0'),
									(r += R ? '1' : '0'),
									(r += '-'),
									(r += N + ''),
									(r += w + ''),
									(r += W + ''),
									(r += '-'),
									(r += j.toString(16).toUpperCase()),
									(r += '-'),
									(r += k.toString(16).toUpperCase()),
									(r += '-'),
									(r += V.toString(16).toUpperCase()),
									(r += '-'),
									(r += Y.toString(16).toUpperCase()),
									(r += '-'),
									(r += K.toString(16).toUpperCase()),
									(r += '-'),
									(r += (J / 100).toString(16).toUpperCase()),
									(r += '-'),
									(r += ($ / 100).toString(16).toUpperCase()),
									(r += '-') + (z / 100).toString(16).toUpperCase()
								);
							},
							Di: function (t) {
								for (var n = 0; n < C.length; ++n) if (C[n].getPublisherId() == t) return !0;
								return !1;
							},
							Ci: function (t) {
								for (var n = 0; n < O.length; ++n) if (O[n].getPartnerId() == t) return !0;
								return !1;
							},
							getPartnerConfiguration: function (t) {
								for (var n = 0; n < O.length; ++n) {
									var e = O[n];
									if (e.getPartnerId() == t) return e;
								}
								return null;
							},
							getPublisherConfiguration: function (t) {
								for (var n = 0; n < C.length; ++n) {
									var e = C[n];
									if (e.getPublisherId() == t) return e;
								}
								return null;
							},
							Li: function () {
								for (var t = [], n = 0; n < C.length; ++n) {
									var e = C[n];
									t.push(e.getPublisherId());
								}
								return t;
							},
							getPublisherConfigurations: function () {
								return C;
							},
							getPartnerConfigurations: function () {
								return O;
							},
							getLabelOrder: function () {
								return U;
							},
							setLabelOrder: function (t) {
								t instanceof Array && ((U = r.h(t)), st(d.LABEL_ORDER));
							},
							Ti: function () {
								return S;
							},
							setLiveEndpointUrl: function (t) {
								c || (S = t + '');
							},
							Ni: function () {
								return D;
							},
							setOfflineFlushEndpointUrl: function (t) {
								c || (D = t);
							},
							F: function () {
								return x;
							},
							setApplicationName: function (t) {
								c || (x = t);
							},
							setApplicationVersion: function (t) {
								c || (T = t);
							},
							B: function () {
								return T;
							},
							Oi: function () {
								return g.tn();
							},
							setPersistentLabel: function (t, n) {
								i.Fn(I, t, n) && st(d.PERSISTENT_LABELS);
							},
							removeAllPersistentLabels: function () {
								(I = {}), st(d.PERSISTENT_LABELS);
							},
							removePersistentLabel: function (t) {
								delete I[t], st(d.PERSISTENT_LABELS);
							},
							getPersistentLabels: function () {
								return I;
							},
							getPersistentLabel: function (t) {
								return I[t];
							},
							containsPersistentLabel: function (t) {
								return null != I[t];
							},
							addPersistentLabels: function (t) {
								i.Bn(I, t), st(d.PERSISTENT_LABELS);
							},
							setStartLabel: function (t, n) {
								i.Fn(b, t, n) && st(d.START_LABELS);
							},
							removeAllStartLabels: function () {
								(b = {}), st(d.START_LABELS);
							},
							removeStartLabel: function (t) {
								delete b[t], st(d.START_LABELS);
							},
							addStartLabels: function (t) {
								i.Bn(b, t), st(d.START_LABELS);
							},
							getStartLabels: function () {
								return b;
							},
							Ri: function (t) {
								return b[t];
							},
							containsStartLabel: function (t) {
								return null != b[t];
							},
							isKeepAliveMeasurementEnabled: function () {
								return P;
							},
							isSecureTransmissionEnabled: function () {
								return t;
							},
							isHttpRedirectCachingEnabled: function () {
								return n;
							},
							setLiveTransmissionMode: function (t) {
								t && ((N = t), st(d.LIVE_TRANSMISSION_MODE));
							},
							ki: function () {
								return N;
							},
							enableImplementationValidationMode: function () {
								c || (e = !0);
							},
							Mi: function () {
								return e;
							},
							setOfflineCacheMode: function (t) {
								t && ((w = t), st(d.OFFLINE_CACHE_MODE));
							},
							Ui: function () {
								return w;
							},
							setUsagePropertiesAutoUpdateMode: function (t) {
								t && (c || (W = t));
							},
							Xt: function () {
								return W;
							},
							setUsagePropertiesAutoUpdateInterval: function (t) {
								c || (j = t);
							},
							getUsagePropertiesAutoUpdateInterval: function () {
								return j;
							},
							setCacheMaxMeasurements: function (t) {
								c || (k = t);
							},
							xi: function () {
								return k;
							},
							setCacheMaxFlushesInARow: function (t) {
								c || (V = t);
							},
							Wi: function () {
								return V;
							},
							setCacheMinutesToRetry: function (t) {
								c || (K = t);
							},
							Fi: function () {
								return K;
							},
							setCacheMeasurementExpiry: function (t) {
								c || (Y = t);
							},
							Bi: function () {
								return Y;
							},
							isEnabled: function () {
								return B;
							},
							Vi: function () {
								return c;
							},
							disable: function () {
								B && ((B = !1), st(d.DISABLE));
							},
							disableTcfIntegration: function () {
								R && ((R = !1), st(d.Zt));
							},
							removeListener: function (t) {
								if (o.On(t)) {
									var n = M.indexOf(t);
									-1 != n && M.splice(n, 1);
								}
							},
							qi: function () {
								return et.getPublisherConfiguration('21193409');
							},
							setDebugEnabled: function (t) {
								c || (X = t);
							},
							Gi: function () {
								return X;
							},
							setSystemClockJumpDetectionEnabled: function (t) {
								c || (q = t);
							},
							pi: function () {
								return q;
							},
							setSystemClockJumpDetectionInterval: function (t) {
								c || ((t = 100 * Math.floor(t / 100)), (J = t));
							},
							setSystemClockJumpDetectionAlternativeContextInterval: function (t) {
								c || ((t = 100 * Math.floor(t / 100)), ($ = t));
							},
							hi: function () {
								return J;
							},
							gi: function () {
								return $;
							},
							setSystemClockJumpDetectionPrecision: function (t) {
								c || ((t = 100 * Math.floor(t / 100)), (z = t));
							},
							mi: function () {
								return z;
							},
							setStorageWriteInterval: function (t) {
								(0 != Q && (t < l.ji || t > l.Hi)) || ((tt = !0), (Q = t));
							},
							Ki: function () {
								return tt ? Q : g.M;
							},
							addIncludedPublisher: function (t) {
								-1 == G.indexOf(t) && G.push(t);
							},
							getIncludedPublishers: function () {
								return G;
							},
							addCrossPublisherUniqueDeviceIdChangeListener: function (t) {
								o.On(t) && -1 === F.indexOf(t) && F.push(t);
							},
							removeCrossPublisherUniqueDeviceIdChangeListener: function (t) {
								var n = F.indexOf(t);
								-1 !== n && F.splice(n, 1);
							},
							Wt: function (t) {
								if ('string' == typeof t) for (var n = 0; n < F.length; n++) F[n](t);
							},
							enableChildDirectedApplicationMode: function () {
								c || (Z = !0);
							},
							Rt: function () {
								return Z;
							},
							Ji: null,
						}),
							(B = !0),
							(y = !1),
							(c = !1),
							(L = !1),
							(K = s.Yi),
							(k = s.Xi),
							(V = s.zi),
							(Y = s.Qi),
							(W = f.fi),
							(j = f.ci),
							(I = {}),
							(b = {}),
							(U = u.Ct),
							(G = []),
							(H = new p({ publisherId: '21193409' })),
							(N = u.Dt),
							(w = a.ENABLED),
							(P = H.isKeepAliveMeasurementEnabled()),
							(t = !1),
							(n = !0),
							(e = !1),
							(M = []),
							(F = []),
							(O = []),
							(C = []),
							(R = !0),
							(X = !1),
							(q = E.ENABLED),
							(J = -1),
							($ = -1),
							(z = E.bi),
							(Z = !1),
							(Q = l.$i),
							(tt = !1),
							(x = m),
							(T = m),
							(nt = new _(et)),
							(et.Ji = nt);
					}
					(A.re = '21193409'), (t.exports = A);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(20),
						o = e(57),
						s = e(24),
						a = e(80),
						u = e(23),
						c = e(2).yn,
						f = e(9),
						l = e(11);
					function d(t, n) {
						var e,
							d,
							_,
							p,
							h,
							v = this;
						function g(n, r) {
							var i = n.Ui(),
								o = c.in();
							return !(
								i == s.DISABLED ||
								(i == s.MANUAL_FLUSH && !r) ||
								(i == s.LAN && (o == f.WWAN || o == f.BLUETOOTH || o == f.DISCONNECTED)) ||
								(function (n) {
									var e = 60 * n.Fi() * 1e3,
										r = n.Wi();
									return +new Date() - t.get(l.jn, -1) > e && (h = 0), h > r;
								})(n) ||
								0 == e.length ||
								d
							);
						}
						function E(t) {
							d = !0;
							var n = t.Ni(),
								r = {};
							r.c2 = t.getPublisherConfigurations()[0].getPublisherId();
							var s =
								'JetportGotAMaskOfThe' +
								t.getPublisherConfigurations()[0].getPublisherSecret() +
								'S.D_K-';
							(s = u(s)), (r.s = s);
							var c = i.Be(n, r, t.getLabelOrder());
							!(function (t) {
								for (var n = +new Date() - 24 * t.Bi() * 60 * 60 * 1e3, r = 0; r < e.length; ) {
									var i = e[r];
									parseInt(i.ns_ts) < n ? (e.splice(r, 1), p++) : r++;
								}
							})(t),
								(_ = e),
								(e = []),
								h++;
							var f = o.Zi(t, _, p);
							new a(c, f, v, t).nr();
						}
						(d = !1),
							(e = []),
							(_ = null),
							(p = t.get(l.Gn, 0)),
							(h = 0),
							(function () {
								var t = n.getCache();
								if (null != t)
									try {
										var r = JSON.parse(t);
										if (!(r instanceof Array)) return;
										e = r;
									} catch (t) {}
							})(),
							r.extend(v, {
								me: function () {
									c.O && t.put(l.Gn, p);
								},
								tr: function () {
									if (c.O) {
										var t = JSON.stringify(e);
										n.storeCache(t);
									}
								},
								onSuccess: function () {
									(d = !1), (_ = null), (p = 0), v.tr();
								},
								onFailure: function () {
									(d = !1), (e = _.concat(e)), (_ = null), v.tr();
								},
								er: function (t, n) {
									c.O &&
										n.Ui() != s.DISABLED &&
										((function (t) {
											return e.length <= t.xi();
										})(n)
											? e.push(t.getLabels())
											: p++);
								},
								ir: function (t) {
									if (c.O) {
										for (var n = [], r = 0; r < t.length; ++r) n.push(t[r].getLabels());
										e = n.concat(e);
									}
								},
								flush: function (t, n) {
									c.O && g(t, n) && E(t);
								},
								rr: function () {
									return e.length;
								},
								Kt: function () {
									e = [];
								},
							});
					}
					(d.Qi = 31),
						(d.Xi = 2e3),
						(d.Ei = 'http://udm.scorecardresearch.com/offline'),
						(d.Pi = 'https://udm.scorecardresearch.com/offline'),
						(d.zi = 10),
						(d.Yi = 30),
						(t.exports = d);
				},
				function (t, n) {
					(n.or = function (t, n) {
						if ('undefined' != typeof atv && 'undefined' != typeof XMLHttpRequest) {
							var e = new XMLHttpRequest();
							e.open('GET', t, !0),
								(e.onreadystatechange = function () {
									4 == e.readyState && (n && n(e.status), (e = null));
								}),
								e.send();
						} else
							'undefined' != typeof atv && 'function' == typeof atv.setTimeout
								? n && atv.setTimeout(n, 0)
								: n && n();
					}),
						(n.ur = function (t, n, e) {
							if ('undefined' != typeof atv && 'undefined' != typeof XMLHttpRequest) {
								var r = new XMLHttpRequest();
								r.open('POST', t, !0),
									(r.onreadystatechange = function () {
										4 === r.readyState && (e && e(r.status), (r = null));
									}),
									r.send(n);
							} else
								'undefined' != typeof atv && 'function' == typeof atv.setTimeout
									? e && atv.setTimeout(e, 0)
									: e && e();
						});
				},
				function (t, n) {
					(n.sr = function (t, n) {
						'undefined' != typeof WinJS && void 0 !== WinJS.xhr
							? WinJS.xhr({ url: t }).then(
									function (t) {
										n && n(t.status);
									},
									function () {
										n && n();
									},
							  )
							: 'function' == typeof setTimeout
							? n && setTimeout(n, 0)
							: n && n();
					}),
						(n.ar = function (t, n, e) {
							'undefined' != typeof WinJS && void 0 !== WinJS.xhr
								? WinJS.xhr({
										type: 'post',
										url: t,
										data: n,
										headers: { 'Content-type': 'application/xml' },
								  }).then(
										function (t) {
											e && e(t.status);
										},
										function () {
											e && e();
										},
								  )
								: 'function' == typeof setTimeout
								? e && setTimeout(e, 0)
								: e && e();
						});
				},
				function (t, n, e) {
					var r = e(18),
						i = [],
						o = !1;
					function s() {
						for (var t = 0; t < i.length; ++t) i[t]();
					}
					t.exports = {
						cr: function (t) {
							i.push(t),
								o ||
									(r.yt() &&
										(window.addEventListener
											? (window.addEventListener('unload', s, !1), (o = !0))
											: window.attachEvent && (window.attachEvent('onunload', s), (o = !0))));
						},
						lr: function (t) {
							for (var n = 0; n < i.length; ++n)
								if (i[n] == t) {
									i.splice(n, 1);
									break;
								}
							0 == i.length &&
								r.yt() &&
								(window.removeEventListener
									? (window.removeEventListener('unload', s, !1), (o = !1))
									: window.detachEvent && (window.detachEvent('onunload', s), (o = !1)));
						},
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(5),
						o = e(4),
						s = e(18),
						a = e(3),
						u = e(13),
						c = e(6).xn,
						f = e(6).Wn,
						l = e(8),
						d = e(12),
						_ = e(10),
						p = e(36),
						h = ('undefined' != typeof document && document) || void 0,
						v = a.wn,
						g = a.bn,
						E = r.extend({}, i, {
							D: 'html5',
							T: function () {
								return 'undefined' != typeof Image
									? u.apply(this, arguments)
									: c.apply(this, arguments);
							},
							N: f,
							Storage: l,
							R: _,
							k: d,
							O: !0,
							M: 6e4,
							F: function () {
								return (h && o.Ln(h.title) && h.title) || v;
							},
							V: function () {
								return this.j() + +new Date() + ~~(1e3 * Math.random());
							},
							G: function () {
								return '72';
							},
							j: function () {
								return (
									('undefined' != typeof window &&
										o.Ln(window.navigator) &&
										o.Cn(window.navigator.platform, '')) ||
									''
								);
							},
							H: function () {
								return o.Cn(s.Re() + ' ' + s.ke(), '');
							},
							J: function () {
								return 'html';
							},
							Y: function () {
								return '5';
							},
							X: function () {
								var t, n;
								'undefined' != typeof window &&
									window.screen &&
									window.screen.width &&
									(t = window.screen.width),
									'undefined' != typeof window &&
										window.screen &&
										window.screen.width &&
										(n = window.screen.height);
								var e = 1;
								return (
									'undefined' != typeof window &&
										window.devicePixelRatio &&
										(e = window.devicePixelRatio),
									t > 0 && n > 0 ? (t *= e) + 'x' + (n *= e) : g
								);
							},
							Z: function () {
								var t, n;
								'undefined' != typeof window && window.innerWidth && (t = window.innerWidth),
									'undefined' != typeof window && window.innerHeight && (n = window.innerHeight);
								var e = 1;
								return (
									'undefined' != typeof window &&
										window.devicePixelRatio &&
										(e = window.devicePixelRatio),
									t > 0 && n > 0 ? (t *= e) + 'x' + (n *= e) : g
								);
							},
							nn: function () {
								return (
									('undefined' != typeof window &&
										o.Ln(window.navigator) &&
										o.Cn(window.navigator.language, '')) ||
									v
								);
							},
							vn: function (t) {
								p.cr(t);
							},
							_n: function (t) {
								p.lr(t);
							},
						});
					t.exports = E;
				},
				function (t, n, e) {
					var r = e(0),
						i = e(2).yn,
						o = e(81);
					function s(t) {
						var n, e, s, a, u, c;
						function f() {
							s && ((s = !1), n.storeProperties(e)), u && ((u = !1), n.storeCache(a));
						}
						function l() {
							if (-1 == c) {
								var n = t.Ki();
								0 == n
									? f()
									: (c = i.setTimeout(function () {
											(c = -1), f();
									  }, n));
							}
						}
						r.extend(this, {
							storeProperties: function (t) {
								(e = t), (s = !0), l();
							},
							getProperties: function () {
								return s ? e : n.getProperties();
							},
							storeCache: function (t) {
								(a = t), (u = !0), l(), n.storeCache(t);
							},
							getCache: function () {
								return u ? a : n.getCache();
							},
						}),
							(n = 'function' == typeof i.Storage ? new i.Storage(t) : new o()),
							(e = ''),
							(a = ''),
							(s = !1),
							(u = !1),
							(c = -1);
					}
					(s.ji = 6e4), (s.Hi = 3e5), (s.$i = 0), (t.exports = s);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(40);
					t.exports = function (t) {
						var n, e, o;
						function s(n, e) {
							return t && null != t[n] ? t[n] : e;
						}
						(n = new i((t = t || {}))),
							r.extend(this, n),
							r.extend(this, {
								getPartnerId: function () {
									return e;
								},
								getExternalClientId: function () {
									return o;
								},
							}),
							(e = s('partnerId', '')),
							(o = s('externalClientId', ''));
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(7),
						o = e(15),
						s = e(41);
					t.exports = function (t) {
						var n, e, a, u, c;
						function f(n, e) {
							return t && null != t[n] ? t[n] : e;
						}
						r.extend(this, {
							addPersistentLabels: function (t) {
								i.Bn(e, t);
							},
							setPersistentLabel: function (t, n) {
								i.Fn(e, t, n);
							},
							removeAllPersistentLabels: function () {
								e = {};
							},
							removePersistentLabel: function (t) {
								delete e[t];
							},
							getStartLabels: function () {
								return n;
							},
							getPersistentLabels: function () {
								return e;
							},
							containsPersistentLabel: function (t) {
								return null != e[t];
							},
							containsStartLabel: function (t) {
								return null != n[t];
							},
							getPersistentLabel: function (t) {
								return e[t];
							},
							isKeepAliveMeasurementEnabled: function () {
								return a;
							},
							isSecureTransmissionEnabled: function () {
								return u;
							},
							isHttpRedirectCachingEnabled: function () {
								return c;
							},
						}),
							(a = s.ENABLED),
							(u = o.At),
							(c = o.Et),
							(e = {}),
							(n = f('startLabels', (n = {}))),
							(e = f('persistentLabels', e)),
							(a = f('keepAliveMeasurement', a)),
							(u = f('secureTransmission', u)),
							(c = f('httpRedirectCaching', c));
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(2).yn,
						o = e(11);
					function s(t) {
						var n,
							e,
							s,
							a,
							u = this;
						(n = !0),
							(e = !1),
							r.extend(u, {
								start: function (r) {
									u.stop(),
										n &&
											((e = !0),
											(s = i.setTimeout(function () {
												if (
													((function () {
														if (n) {
															var e = t.get(o.jn, 0),
																r = +new Date() - e;
															e > 0 && r > 86399e3 && a && a.Se();
														}
													})(),
													e)
												) {
													var r = t.get(o.jn, 0);
													if (0 == r) u.start(3e3);
													else {
														var i = 864e5 - (+new Date() - r);
														u.start(i);
													}
												}
											}, r)));
								},
								stop: function () {
									e && ((e = !1), i.clearTimeout(s), (s = null));
								},
								zt: function (t) {
									n = t;
								},
								Jt: function (t) {
									a = t;
								},
							});
					}
					(s.ENABLED = !0), (s.Pe = 3e3), (t.exports = s);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(40),
						o = e(43);
					t.exports = function t(n) {
						var e, s, a, u, c;
						function f(t, e) {
							return n && null != n[t] ? n[t] : e;
						}
						(n = r.h(n || {})),
							(s = f('publisherId', s)),
							(c = f('publisherUniqueDeviceIdListener')),
							(a = o.dr(s)),
							(u = null),
							(e = new i(n)),
							r.extend(this, e),
							r.extend(this, {
								getPublisherId: function () {
									return s;
								},
								getPublisherSecret: function () {
									return a;
								},
								getPublisherUniqueDeviceId: function () {
									return u;
								},
								setPublisherUniqueDeviceId: function (t) {
									(u = t), 'function' == typeof c && c(t);
								},
								copy: function (e) {
									return new t((e = r.extend(n, e)));
								},
							});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(2).yn,
						o = e(11),
						s = e(23),
						a = e(83);
					function u(t, n) {
						var e = null,
							u = null,
							c = !0,
							f = !1,
							l = {};
						r.extend(this, {
							kt: function () {
								var r = '';
								n.ne() ? (r = (i.W() || 'null').split(' ')) : n.te();
								var d = r[0] && 'null' != r[0] ? r[0] : null;
								if (null == d)
									return (e = null), (u = null), void (l = { Ft: null, Mt: !1, xt: c });
								u || ((e = t.get(o.Yn, null)), (u = t.get(o.Xn, null))),
									u
										? ('none' == d && 'none' == u) ||
										  (!c && 'none' == u) ||
										  ('none' != d && s(d) == e) ||
										  ((f = !0),
										  c && 'none' != d ? ((e = s(d)), (u = a(d))) : ((e = null), (u = 'none')),
										  t.put(o.Xn, u),
										  null == e ? t.remove(o.Yn) : t.put(o.Yn, e))
										: ('none' == d ? ((e = null), (u = 'none')) : ((e = s(d)), (u = a(d))),
										  t.put(o.Xn, u),
										  null == e ? t.remove(o.Yn) : t.put(o.Yn, e)),
									(l = { Ft: u, Mt: f, xt: c }),
									(c = !1);
							},
							Ut: function () {
								return l;
							},
							me: function () {},
							ee: function (t) {
								var e, r;
								return (
									n.ne()
										? ((e = i.V()), (r = i.G()))
										: (n.te(), (e = +new Date() + ~~(1e3 * Math.random())), (r = 72)),
									s(e + t) + '-cs' + r
								);
							},
						});
					}
					(u.dr = function (t) {
						return s('zutphen2011comScore@' + t);
					}),
						(t.exports = u);
				},
				function (t, n, e) {
					var r = e(0);
					t.exports = function () {
						var t,
							n = {};
						r.extend(this, {
							vr: function (t) {
								return null != n[t];
							},
							_r: function (t) {
								return n[t];
							},
							addLabels: function (t) {
								r.extend(n, t);
							},
							setLabel: function (t, e) {
								n[t] = e + '';
							},
							pr: function () {
								return 'start' == n.ns_ap_ev;
							},
							getLabels: function () {
								return n;
							},
							setLiveEndpointUrl: function (n) {
								t = n;
							},
							Ti: function () {
								return t;
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(1).I,
						i = e(0),
						o = e(16);
					function s(t) {
						var n,
							e,
							s,
							a = this;
						function u() {
							var n = new o(r.KEEPALIVE);
							t.gr().hr(n), a.start();
						}
						function c() {
							null != s && (t.gr().mr().clearTimeout(s), (s = null));
						}
						i.extend(a, {
							start: function () {
								n && (c(), (s = t.gr().mr().setTimeout(u, e)));
							},
							stop: function () {
								n && c();
							},
						}),
							(n = t.getConfiguration().yr),
							(e = t.getConfiguration().Sr);
					}
					(s.wr = 12e5), (s.br = 6e4), (s.ENABLED = !0), (t.exports = s);
				},
				function (t, n, e) {
					var r = e(0);
					function i(t) {
						var n,
							e,
							i,
							o,
							s,
							a,
							u = this;
						function c() {
							s++, (o = 0), u.resume(), t.gr().Ir();
						}
						function f() {
							null != i && (t.gr().mr().clearTimeout(i), (i = null));
						}
						r.extend(this, {
							Er: function () {
								return s;
							},
							Pr: function (t) {
								var n = 0;
								if (null != e)
									for (var r = 0; r < e.length; r++) {
										var i = e[r],
											o = i.Ar;
										if (!o || t < o) {
											n = i.interval;
											break;
										}
									}
								return n;
							},
							resume: function () {
								if (n) {
									f();
									var e = u.Pr(t.Lr().Cr().Dr() + (+new Date() - t.Lr().Cr().Tr()));
									if (e > 0) {
										var r = o > 0 ? o : e;
										(a = +new Date() + r), (i = t.gr().mr().setTimeout(c, r));
									}
									o = 0;
								}
							},
							pause: function () {
								if (n) {
									f();
									var e = u.Pr(t.Lr().Cr().Dr() + (+new Date() - t.Lr().Cr().Tr()));
									o = e - ((t.Lr().Cr().Dr() + (+new Date() - t.Lr().Cr().Tr())) % e);
								}
							},
							reset: function () {
								n && (f(), (o = 0), (s = 0));
							},
							Nr: function (t) {
								return null == i ? -1 : a - t;
							},
						}),
							(o = 0),
							(s = 0),
							(n = t.getConfiguration().Or),
							(e = r.h(t.getConfiguration().Rr));
					}
					(i.ENABLED = !0),
						(i.kr = [
							{ Ar: 6e4, interval: 1e4 },
							{ Ar: null, interval: 6e4 },
						]),
						(i.Mr = function (t, n) {
							if (t.length != n.length) return !1;
							for (var e = 0; e < t.length; ++e) {
								var r = t[e],
									i = n[e];
								if (r.Ar != i.Ar) return !1;
								if (r.interval != i.interval) return !1;
							}
							return !0;
						}),
						(t.exports = i);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(4),
						o = e(14),
						s = e(48),
						a = e(49).Ur,
						u = e(1).P,
						c = e(1).I,
						f = e(3),
						l = e(50),
						d = l.AdvertisementType,
						_ = e(31),
						p = e(53),
						h = e(113),
						v = e(16);
					function g(t) {
						var n,
							e,
							g,
							E,
							m,
							A,
							L,
							y,
							I,
							b,
							P,
							N,
							S,
							w,
							D,
							C,
							O,
							R,
							x,
							T,
							U,
							M,
							F,
							B,
							G,
							k,
							V,
							K,
							Y,
							W,
							j,
							H,
							X = this;
						r.extend(this, {
							xr: function () {
								var n = e,
									r = new _(),
									i = p.It(r);
								(e = new p(t, r, i)), p.xr(n, e);
							},
							addLabels: function (t) {
								null != t && r.extend(y, t);
							},
							getLabels: function () {
								return y;
							},
							setLabel: function (t, n) {
								var e = {};
								(e[t] = n), X.addLabels(e);
							},
							_r: function (t) {
								return y[t];
							},
							Cr: function () {
								return e;
							},
							Bn: function (t, n) {
								var i = t;
								(i.ns_st_pa = m + (isNaN(A) ? 0 : n - A) + ''),
									(i.ns_st_pp = O + ''),
									(i.ns_st_sp = R + ''),
									(i.ns_st_bp = x + ''),
									S || (i.ns_st_pb = '1'),
									e.Wr() &&
										((i.ns_st_ppc = w + ''), (i.ns_st_dppc = w - D + ''), (i.ns_st_psq = C + '')),
									(i.ns_st_sc = U + ''),
									r.extend(i, L);
							},
							Fr: function () {
								R++;
							},
							Br: function () {
								O++;
							},
							Vr: function (t) {
								if (!isNaN(A)) {
									var n = X.Dr();
									(n += t - A), X.qr(n), (A = NaN);
								}
							},
							Gr: function (t) {
								if (!isNaN(E)) {
									var n = X.jr();
									(n += t - E), X.Hr(n), (E = NaN);
								}
							},
							jr: function () {
								return x;
							},
							Hr: function (t) {
								x = t;
							},
							Dr: function () {
								return m;
							},
							Kr: function (t) {
								E = t;
							},
							Jr: function () {
								return E;
							},
							qr: function (t) {
								m = t;
							},
							Yr: function (t) {
								A = t;
							},
							Tr: function () {
								return A;
							},
							Xr: function () {
								return O;
							},
							zr: function (t) {
								O = t;
							},
							Qr: function () {
								return P;
							},
							$r: function (t, r, i) {
								var o;
								if (
									(e.Zr(e.no()),
									e.to(e.eo()),
									!K ||
										(t != u.IDLE &&
											t != u.PLAYBACK_NOT_STARTED &&
											t != u.BUFFERING_BEFORE_PLAYBACK &&
											t != u.SEEKING_BEFORE_PLAYBACK &&
											r != u.PLAYING))
								)
									if (t == u.IDLE) {
										var a = n.io();
										o =
											s.ro(e._r('ns_st_ad')) || (Y && 1 == G)
												? 0
												: b[e.oo()] && a && a.oo() == e.oo()
												? e.uo()
												: 0;
									} else o = t == u.PLAYING ? e.so(i) : e.uo();
								else (o = k), (K = !1);
								e.ao(o), e.fo(i);
							},
							co: function (t, n, r) {
								var i,
									o = e.lo();
								e.Zr(e.no()),
									e.to(e.eo()),
									e.do()
										? t == u.IDLE ||
										  t == u.BUFFERING_BEFORE_PLAYBACK ||
										  t == u.SEEKING_BEFORE_PLAYBACK ||
										  t == u.PLAYBACK_NOT_STARTED ||
										  n == u.PLAYING
											? (W ? ((W = !1), (o = V)) : (o = e.vo(r)), (i = e._o(r, o)))
											: t == u.PLAYING
											? ((o = e.po(r)), (i = e.ho(r)))
											: ((o = e.vo(r)), (i = e._o(r, o)))
										: (W && ((W = !1), (o = V)), (i = e.mo() - o)),
									e.ao(i),
									e.fo(r),
									e.yo(o),
									e.So(r);
							},
							wo: function () {
								var t,
									r = n.io();
								(t = s.ro(e._r('ns_st_ad'))
									? 1
									: Y
									? G
									: b[e.oo()] && b[e.oo()] && r && r.oo() == e.oo()
									? e.bo()
										? e.Io()
										: e.Io() + 1
									: 1),
									e.Eo(t),
									(Y = !1);
							},
							Po: function () {
								n.Ao(e), g && n.Ao(g);
								var t = e.Do();
								if ((N < t && (N = t), s.ro(e._r('ns_st_ad')))) {
									var r = o.It(e.getLabels(), a),
										i = parseInt(e._r('ns_st_an'));
									if (
										((null == F[r] || F[r] < i) && (F[r] = i), (B[e.oo()] = H), e.vr('ns_st_rcn'))
									) {
										var u = parseInt(e._r('ns_st_rcn'));
										N < u && (N = u);
									}
								}
								I && (X.Fr(), X.Co(0), (I = !1)), (P = !0);
							},
							Lo: function () {
								(b[e.oo()] = !0),
									s.ro(e._r('ns_st_ad')) ||
										(1 == e.Io()
											? (j[e.oo()] = 1)
											: (null == j[e.oo()] && (j[e.oo()] = 0), j[e.oo()]++));
								for (var t = 0; t < M.length; ++t) M[t]();
							},
							To: function (t) {
								M.push(t);
							},
							No: function () {
								return w;
							},
							Oo: function () {
								w++;
							},
							Ro: function (t) {
								S = t;
							},
							Co: function (t) {
								C = t;
							},
							ko: function () {
								C++;
							},
							Mo: function () {
								return L.ns_st_id;
							},
							Uo: function (t) {
								t < 0 || (L.ns_st_ca = t + '');
							},
							xo: function (t) {
								t < 1 || (L.ns_st_cp = t + '');
							},
							setMediaPlayerName: function (t) {
								t && (L.ns_st_mp = t + '');
							},
							setMediaPlayerVersion: function (t) {
								t && (L.ns_st_mv = t + '');
							},
							setImplementationId: function (t) {
								t && (L.cs_impid = t + '');
							},
							loopPlaybackSession: function () {
								for (var t in b)
									if (b.hasOwnProperty(t)) {
										var r = n.Cr(t);
										r && (r.Zr(0), r.Wo(0), r.Fo(!1), r.setDvrWindowLength(0), r.yo(0)),
											(b[t] = !1);
									}
								X.startFromSegment(1), e.Bo(!0), (I = !0);
							},
							startFromSegment: function (t) {
								(G = t), (Y = !0);
							},
							startFromPosition: function (t) {
								(k = t), (K = !0);
							},
							startFromDvrWindowOffset: function (t) {
								(V = t), (W = !0);
							},
							Vo: function (t) {
								e.Wr() && (D = parseInt(t.ns_st_ppc)), (T = !1);
							},
							qo: function (r) {
								if ((t.jo().Go('setMetadata', r), r && (r instanceof _ || r instanceof l))) {
									var i = r.getMetadataLabels();
									if ((t.jo().Ho('Passed labels:', i), s.ro(i.ns_st_ad))) {
										if (null == i.ns_st_bn) {
											var f = i.ns_st_ct;
											if (
												(f = null != f ? f.slice(2) : null) == d.ON_DEMAND_PRE_ROLL ||
												f == d.BRANDED_ON_DEMAND_PRE_ROLL
											)
												i.ns_st_bn = '1';
											else if (f == d.ON_DEMAND_POST_ROLL || f == d.BRANDED_ON_DEMAND_POST_ROLL)
												i.ns_st_bn = '1';
											else if (f == d.ON_DEMAND_MID_ROLL || f == d.BRANDED_ON_DEMAND_MID_ROLL) {
												var h = '';
												r instanceof l &&
													r.getRelatedContentMetadata() &&
													(h = p.It(r.getRelatedContentMetadata().getMetadataLabels())),
													h && null != j[h] ? (i.ns_st_bn = j[h]) : (i.ns_st_bn = '1');
											}
										}
										if (null == i.ns_st_an) {
											var E = o.It(i, a),
												m = 1;
											null != F[E] && (m = F[E] + 1), (i.ns_st_an = m + '');
										}
										!(function () {
											if (null == i.ns_st_rcn) {
												var t,
													e = '';
												r instanceof l &&
													r.getRelatedContentMetadata() &&
													(e = p.It(r.getRelatedContentMetadata().getMetadataLabels())),
													(t = e
														? n.Ko(e)
															? n.Cr(e).Do()
															: null == i.ns_st_cn
															? N + 2
															: parseInt(i.ns_st_cn) + 1
														: 0),
													(i.ns_st_rcn = t + '');
											}
										})();
									}
									var A = p.It(i),
										L = '';
									r instanceof l &&
										r.getRelatedContentMetadata() &&
										(L = p.It(r.getRelatedContentMetadata().getMetadataLabels()));
									var y = e;
									if (A == y.oo() && !(Y || K || I || null != i.ns_st_pn))
										return (
											t.jo().Ho('Updating existing asset labels with the newly provided ones:', i),
											y.Jo(),
											y.Yo(),
											y.addLabels(i),
											void X.Xo(r, y)
										);
									var b,
										P = t.Qo().zo();
									if (P != u.IDLE) {
										t.jo().Ho('Ending the current Clip. It was in state:', o.wt(P));
										var S = new v(c.END);
										(S.Nt.ns_st_ae = '1'), t.gr().$o(S);
									}
									if (
										(n.Ko(A)
											? ((b = n.Cr(A)).Zo(), b.Jo(), b.Yo(), b.addLabels(i))
											: ((b = new p(t, i, A)),
											  null == i.ns_st_cn ? b.nu(N + 1) : b.nu(parseInt(i.ns_st_cn))),
										(function () {
											if (r instanceof l && r.getRelatedContentMetadata()) {
												var e = r.getRelatedContentMetadata().getMetadataLabels(),
													i = p.It(e);
												n.Ko(i) ? (g = n.Cr(i)) : (g = new p(t, e, i)).nu(b.Do() + 1);
											} else g = null;
										})(),
										s.ro(b._r('ns_st_ad')) &&
											(function () {
												var t;
												if (L)
													if (n.Ko(L)) {
														var e = b._r('ns_st_ct');
														t =
															(e = null != e ? e.slice(2) : null) == d.ON_DEMAND_PRE_ROLL ||
															e == d.BRANDED_ON_DEMAND_PRE_ROLL
																? 0
																: n.Cr(L).no();
													} else t = 0;
												else t = 0;
												b.tu('ns_st_cpo', t + '');
											})(),
										X.Xo(r, b),
										I && b.Bo(!0),
										(e = b),
										(H = L),
										T || U++,
										(T = !0),
										t.getConfiguration().eu)
									)
										if (P == u.PLAYING) {
											t.jo().Ho('Resuming the new Asset.');
											var w = new v(c.PLAY);
											(w.Nt.ns_st_ae = '1'), t.gr().$o(w);
										} else
											(P != u.BUFFERING_DURING_PLAYBACK && P != u.PAUSED_DURING_BUFFERING) ||
												(t.jo().Ho('Starting buffering the new Asset.'),
												t.gr().$o(new v(c.BUFFER)),
												t.gr().iu());
								} else
									t.jo().Ho(
										'Ignoring API call. An AssetMetadata object was expected and received instead:',
										r,
									);
							},
							Xo: function (t, n) {
								if (t instanceof l) {
									var e = t.getStacks(),
										r = t.getRelatedContentMetadata()
											? t.getRelatedContentMetadata().getStacks()
											: null,
										i = [];
									for (var o in e)
										if (e.hasOwnProperty(o)) {
											var s = e[o],
												a = (r && r[o]) || null;
											i.push(o);
											var u = s.getMetadataLabels(a);
											n.ru(o, u);
										}
									if (r)
										for (var c in r)
											if (r.hasOwnProperty(c) && -1 == i.indexOf(c)) {
												var f = r[c];
												n.ru(c, f.getMetadataLabels());
											}
								} else {
									var d = t.getStacks();
									for (var _ in d)
										if (d.hasOwnProperty(_)) {
											var p = d[_];
											n.ru(_, p.getMetadataLabels());
										}
								}
							},
						}),
							(function () {
								n = new h();
								var r = new _().getMetadataLabels(),
									o = p.It(r);
								(e = new p(t, r, o)),
									(g = null),
									((L = {}).ns_st_id = i.uuid()),
									(L.ns_st_mp = f.wn),
									(L.ns_st_mv = f.wn),
									(y = {}),
									(I = !1),
									(b = {}),
									(E = NaN),
									(m = 0),
									(A = NaN),
									(N = 0),
									(P = !1),
									(S = !1),
									(w = 0),
									(D = 0),
									(O = 0),
									(C = 0),
									(R = 1),
									(x = 0),
									(T = !0),
									(U = 1),
									(M = []),
									(F = {}),
									(B = {}),
									(G = -1),
									(k = 0),
									(V = 0),
									(K = !1),
									(Y = !1),
									(W = !1),
									(j = {}),
									(H = null);
							})();
					}
					(g.ou = function (t, n, e) {
						for (var r = n.Cr(), i = n.getLabels(), o = {}, s = 0; e && s < e.length; s++)
							i.hasOwnProperty(e[s]) && (o[e[s]] = i[e[s]]);
						t.Lr().addLabels(o), p.xr(r, t.Lr().Cr(), e);
					}),
						(g.uu = !0),
						(t.exports = g);
				},
				function (t, n) {
					var e = e || {};
					(e.ro = function (t, n) {
						return (n = n || !1), t ? '0' != t : n;
					}),
						(e.su = function (t, n) {
							return null == t || isNaN(t) ? n || 0 : parseInt(t);
						}),
						(e.au = function (t, n) {
							var e = Number(t);
							return null == t || isNaN(e) ? n || 0 : e;
						}),
						(e.toString = function (t) {
							if (void 0 === t) return 'undefined';
							if ('string' == typeof t) return t;
							if (t instanceof Array) return t.join(',');
							var n = '';
							for (var e in t) t.hasOwnProperty(e) && (n += e + ':' + t[e] + ';');
							return n || t.toString();
						}),
						(t.exports.ro = e.ro),
						(t.exports.su = e.su),
						(t.exports.au = e.au),
						(t.exports.toString = e.toString);
				},
				function (t, n) {
					t.exports = {
						fu: [
							'ns_st_ci',
							'ns_st_pu',
							'ns_st_pr',
							'ns_st_sn',
							'ns_st_en',
							'ns_st_ep',
							'ns_st_st',
							'ns_st_ty',
							'ns_st_ct',
							'ns_st_li',
							'ns_st_ad',
							'ns_st_bn',
							'ns_st_tb',
							'ns_st_an',
							'ns_st_ta',
							'c3',
							'c4',
							'c6',
							'ns_st_tpr',
							'ns_st_tep',
							'ns_st_stc',
							'ns_st_sta',
							'ns_st_amg',
							'ns_st_ami',
							'ns_st_amt',
							'ns_st_dt',
							'ns_st_tm',
							'ns_st_ddt',
							'ns_st_dtm',
							'ns_st_tdt',
							'ns_st_ttm',
							'cs_fpid',
							'cs_fpit',
							'cs_fpdm',
							'cs_fpdt',
						],
						Ur: [
							'ns_st_ci',
							'ns_st_pu',
							'ns_st_pr',
							'ns_st_sn',
							'ns_st_en',
							'ns_st_ep',
							'ns_st_st',
							'ns_st_ty',
							'ns_st_ct',
							'ns_st_li',
							'ns_st_ad',
							'ns_st_bn',
							'ns_st_tb',
							'ns_st_ta',
							'c3',
							'c4',
							'c6',
							'ns_st_tpr',
							'ns_st_tep',
							'ns_st_stc',
							'ns_st_sta',
							'ns_st_dt',
							'ns_st_tm',
							'ns_st_ddt',
							'ns_st_dtm',
							'ns_st_tdt',
							'ns_st_ttm',
						],
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(7),
						o = e(51),
						s = e(31);
					function a() {
						var t,
							n,
							e,
							a,
							c,
							f = this,
							l = new o(),
							d = [
								'ns_st_ct',
								'ns_st_li',
								'ns_st_ty',
								'ns_st_cl',
								'ns_st_fee',
								'ns_st_cs',
								'ns_st_cu',
							];
						function _(t, n) {
							i.Fn(c, t, n);
						}
						(t = null),
							(n = {}),
							(a = !1),
							(c = {}),
							_('ns_st_li', '0'),
							_('ns_st_ty', 'video'),
							_('ns_st_ad', '1'),
							_('ns_st_tp', '1'),
							r.extend(f, l),
							r.extend(f, {
								setRelatedContentMetadata: function (n) {
									n instanceof s || (n = null), (t = n);
								},
								getRelatedContentMetadata: function () {
									return t;
								},
								setMediaType: function (t) {
									if (null != t) {
										_('ns_st_ct', (a ? 'aa' : 'va') + (e = t));
										var n = '1';
										e == u.ON_DEMAND_PRE_ROLL || e == u.BRANDED_ON_DEMAND_PRE_ROLL
											? (n = 'pre-roll')
											: e == u.ON_DEMAND_MID_ROLL || e == u.BRANDED_ON_DEMAND_MID_ROLL
											? (n = 'mid-roll')
											: (e != u.ON_DEMAND_POST_ROLL && e != u.BRANDED_ON_DEMAND_POST_ROLL) ||
											  (n = 'post-roll'),
											_('ns_st_ad', n),
											e == u.LIVE || e == u.BRANDED_DURING_LIVE
												? _('ns_st_li', '1')
												: _('ns_st_li', '0');
									}
								},
								classifyAsAudioStream: function (t) {
									null == t && (t = !0),
										(a = t),
										null == e ? delete c.ns_st_ct : _('ns_st_ct', (a ? 'aa' : 'va') + e),
										_('ns_st_ty', a ? 'audio' : 'video');
								},
								setVideoDimensions: function (t, n) {
									_('ns_st_cs', (t = t || 0) + 'x' + (n = n || 0));
								},
								setLength: function (t) {
									_('ns_st_cl', t);
								},
								setBreakNumber: function (t) {
									_('ns_st_bn', t);
								},
								setTotalBreaks: function (t) {
									_('ns_st_tb', t);
								},
								setNumberInBreak: function (t) {
									_('ns_st_an', t);
								},
								setTotalInBreak: function (t) {
									_('ns_st_ta', t);
								},
								setClipUrl: function (t) {
									_('ns_st_cu', t);
								},
								setServer: function (t) {
									_('ns_st_ams', t);
								},
								setCallToActionUrl: function (t) {
									_('ns_st_amc', t);
								},
								setDeliveryType: function (t) {
									_('ns_st_amd', t);
								},
								setOwner: function (t) {
									_('ns_st_amo', t);
								},
								setStack: function (t, e) {
									n[t] = e;
								},
								getStacks: function () {
									return n;
								},
								getStandardLabels: function () {
									return r.extend({}, l.getStandardLabels(), c);
								},
								getMetadataLabels: function () {
									var n = {};
									if (t) {
										var e = t.getStandardLabels();
										for (var i in e) e.hasOwnProperty(i) && -1 == d.indexOf(i) && (n[i] = e[i]);
									}
									return r.extend(
										n,
										f.getStandardLabels(),
										t ? t.getCustomLabels() : null,
										f.getCustomLabels(),
									);
								},
							});
					}
					var u = {
						ON_DEMAND_PRE_ROLL: '11',
						ON_DEMAND_MID_ROLL: '12',
						ON_DEMAND_POST_ROLL: '13',
						LIVE: '21',
						BRANDED_ON_DEMAND_PRE_ROLL: '31',
						BRANDED_ON_DEMAND_MID_ROLL: '32',
						BRANDED_ON_DEMAND_POST_ROLL: '33',
						BRANDED_AS_CONTENT: '34',
						BRANDED_DURING_LIVE: '35',
						OTHER: '00',
					};
					(a.AdvertisementType = u),
						(a.AdvertisementDeliveryType = {
							NATIONAL: 'national',
							LOCAL: 'local',
							SYNDICATION: 'syndication',
						}),
						(a.AdvertisementOwner = {
							DISTRIBUTOR: 'distributor',
							ORIGINATOR: 'originator',
							MULTIPLE: 'mp',
							NONE: 'none',
						}),
						(t.exports = a);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(7),
						o = e(14);
					t.exports = function () {
						var t, n;
						function e(n, e) {
							i.Fn(t, n, e);
						}
						(t = {}),
							(n = {}),
							r.extend(this, {
								setFee: function (t) {
									e('ns_st_fee', t);
								},
								setUniqueId: function (t) {
									e('ns_st_ami', t);
								},
								setTitle: function (t) {
									e('ns_st_amt', t);
								},
								setServerCampaignId: function (t) {
									e('ns_st_amg', t);
								},
								setPlacementId: function (t) {
									e('ns_st_amp', t);
								},
								setSiteId: function (t) {
									e('ns_st_amw', t);
								},
								addCustomLabels: function (t) {
									'object' != typeof t && (t = {}), (n = o.ht(t));
								},
								getCustomLabels: function () {
									return n;
								},
								getStandardLabels: function () {
									return t;
								},
								getMetadataLabels: function () {
									return r.extend({}, t, n);
								},
							});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(7),
						o = e(14),
						s = e(112);
					function a() {
						var t, n;
						function e(n, e) {
							i.Fn(t, n, e);
						}
						(t = {}),
							(n = {}),
							r.extend(this, {
								setUniqueId: function (t) {
									e('ns_st_ci', t);
								},
								setPublisherName: function (t) {
									e('ns_st_pu', t);
								},
								setProgramTitle: function (t) {
									e('ns_st_pr', t);
								},
								setEpisodeTitle: function (t) {
									e('ns_st_ep', t);
								},
								setEpisodeSeasonNumber: function (t) {
									e('ns_st_sn', t);
								},
								setEpisodeNumber: function (t) {
									e('ns_st_en', t);
								},
								setGenreName: function (t) {
									e('ns_st_ge', t);
								},
								setGenreId: function (t) {
									e('ns_st_tge', t);
								},
								setDateOfProduction: function (t, n, r) {
									e('ns_st_dt', u(t, n, r));
								},
								setTimeOfProduction: function (t, n) {
									e('ns_st_tm', c(t, n));
								},
								setDateOfDigitalAiring: function (t, n, r) {
									e('ns_st_ddt', u(t, n, r));
								},
								setTimeOfDigitalAiring: function (t, n) {
									e('ns_st_dtm', c(t, n));
								},
								setDateOfTvAiring: function (t, n, r) {
									e('ns_st_tdt', u(t, n, r));
								},
								setTimeOfTvAiring: function (t, n) {
									e('ns_st_ttm', c(t, n));
								},
								setStationTitle: function (t) {
									e('ns_st_st', t);
								},
								setStationCode: function (t) {
									e('ns_st_stc', t);
								},
								setProgramId: function (t) {
									e('ns_st_tpr', t);
								},
								setEpisodeId: function (t) {
									e('ns_st_tep', t);
								},
								setFee: function (t) {
									e('ns_st_fee', t);
								},
								setPlaylistTitle: function (t) {
									e('ns_st_pl', t);
								},
								setNetworkAffiliate: function (t) {
									e('ns_st_sta', t);
								},
								setDeliveryMode: function (t) {
									e('ns_st_cde', t);
								},
								setDeliverySubscriptionType: function (t) {
									e('ns_st_cds', t);
								},
								setDeliveryComposition: function (t) {
									e('ns_st_cdc', t);
								},
								setDeliveryAdvertisementCapability: function (t) {
									e('ns_st_cda', t);
								},
								setDistributionModel: function (t) {
									e('ns_st_cdm', t);
								},
								setMediaFormat: function (t) {
									e('ns_st_cmt', t);
								},
								setDictionaryClassificationC3: function (t) {
									e('c3', t);
								},
								setDictionaryClassificationC4: function (t) {
									e('c4', t);
								},
								setDictionaryClassificationC6: function (t) {
									e('c6', t);
								},
								addCustomLabels: function (t) {
									'object' != typeof t && (t = {}), (n = o.ht(t));
								},
								getStandardLabels: function () {
									return t;
								},
								getCustomLabels: function () {
									return n;
								},
								getMetadataLabels: function () {
									return r.extend({}, t, n);
								},
							});
					}
					function u(t, n, e) {
						return s.cu(4, t) + '-' + s.cu(2, n) + '-' + s.cu(2, e);
					}
					function c(t, n) {
						return s.cu(2, t) + ':' + s.cu(2, n);
					}
					(a.ContentDeliveryMode = { LINEAR: 'linear', ON_DEMAND: 'ondemand' }),
						(a.ContentDeliverySubscriptionType = {
							TRADITIONAL_MVPD: 'mvpd_auth',
							VIRTUAL_MVPD: 'virtualmvpd',
							SUBSCRIPTION: 'svod',
							ADVERTISING: 'avod',
							TRANSACTIONAL: 'tvod',
							PREMIUM: 'pvod',
						}),
						(a.ContentDeliveryComposition = { CLEAN: 'clean', EMBED: 'embedded' }),
						(a.ContentDeliveryAdvertisementCapability = {
							NONE: 'none',
							DYNAMIC_LOAD: 'dai',
							DYNAMIC_REPLACEMENT: 'dar',
							LINEAR_1DAY: 'lai-c1',
							LINEAR_2DAY: 'lai-c2',
							LINEAR_3DAY: 'lai-c3',
							LINEAR_4DAY: 'lai-c4',
							LINEAR_5DAY: 'lai-c5',
							LINEAR_6DAY: 'lai-c6',
							LINEAR_7DAY: 'lai-c7',
						}),
						(a.ContentDistributionModel = { TV_AND_ONLINE: 'to', EXCLUSIVELY_ONLINE: 'eo' }),
						(a.ContentMediaFormat = {
							FULL_CONTENT_GENERIC: 'fc',
							FULL_CONTENT_EPISODE: 'fc01',
							FULL_CONTENT_MOVIE: 'fc02',
							FULL_CONTENT_PODCAST: 'fc03',
							PARTIAL_CONTENT_GENERIC: 'pc',
							PARTIAL_CONTENT_EPISODE: 'pc01',
							PARTIAL_CONTENT_MOVIE: 'pc02',
							PARTIAL_CONTENT_PODCAST: 'pc03',
							PREVIEW_GENERIC: 'pv',
							PREVIEW_EPISODE: 'pv01',
							PREVIEW_MOVIE: 'pv02',
							EXTRA_GENERIC: 'ec',
							EXTRA_EPISODE: 'ec01',
							EXTRA_MOVIE: 'ec02',
						}),
						(t.exports = a);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(14),
						o = e(49).fu;
					function s(t, n, e) {
						(n = n || {}), (e = e || '');
						var o,
							s,
							a,
							u,
							c,
							f,
							l,
							d,
							_,
							p,
							h,
							v,
							g,
							E,
							m,
							A,
							L,
							y,
							I,
							b,
							P,
							N,
							S,
							w,
							D,
							C,
							O,
							R,
							x,
							T,
							U,
							M,
							F,
							B,
							G,
							k,
							V,
							K,
							Y,
							W,
							j,
							H,
							X,
							q,
							J,
							$,
							z,
							Z,
							Q,
							tt,
							nt,
							et,
							rt,
							it,
							ot,
							st,
							at,
							ut,
							ct,
							ft,
							lt,
							dt,
							_t,
							pt,
							ht,
							vt,
							gt,
							Et,
							mt,
							At,
							Lt,
							yt,
							It,
							bt,
							Pt,
							Nt,
							St,
							wt,
							Dt,
							Ct = this;
						function Ot(t) {
							var n = {},
								e = [];
							for (var r in t)
								if (t.hasOwnProperty(r)) {
									var i = t[r];
									if (
										r.length >= 2 &&
										'c' == r[0] &&
										r[1].charCodeAt(0) >= 'a'.charCodeAt(0) &&
										r[1].charCodeAt(0) <= 'z'.charCodeAt(0) &&
										'p' != r[1] &&
										's' != r[1]
									) {
										var o = r[1];
										r.length > 3 && '_' == r[2]
											? (((n[o] = n[o] || {})[r.substring(3, r.length)] = i), e.push(r))
											: 3 == r.length &&
											  r[2] >= '0' &&
											  r[2] <= '9' &&
											  (((n[o] = n[o] || {})['c' + r[2]] = i), e.push(r));
									}
								}
							for (var s = 0; s < e.length; ++s) delete t[e[s]];
							for (var a in n) {
								var u = n[a],
									c = u.c2;
								c && (delete u.c2, Ct.ru(c, u));
							}
						}
						r.extend(Ct, {
							ru: function (t, n) {
								(c[t] = c[t] || {}), r.extend(c[t], n);
							},
							Yo: function () {
								c = {};
							},
							getStacks: function () {
								return c;
							},
							oo: function () {
								return s;
							},
							lu: function (t) {
								O = t;
							},
							du: function () {
								return O;
							},
							tu: function (t, n) {
								a[t] = n;
							},
							vu: function (t) {
								return a[t];
							},
							_u: function (t) {
								return null != a[t];
							},
							addLabels: function (t) {
								Ot(t), r.extend(u, t);
							},
							getLabels: function () {
								return u;
							},
							setLabel: function (t, n) {
								u[t] = n;
							},
							Jo: function () {
								u = {};
							},
							_r: function (t) {
								return u[t];
							},
							vr: function (t) {
								return null != u[t];
							},
							Do: function () {
								return parseInt(Ct.vu('ns_st_cn'));
							},
							nu: function (t) {
								Ct.tu('ns_st_cn', t + '');
							},
							Eo: function (t) {
								(St = t), (wt = !0);
							},
							Io: function () {
								return St;
							},
							bo: function () {
								return wt;
							},
							Bn: function (t, n, e) {
								var s,
									u = t,
									c = parseInt(u.ns_st_po);
								(s =
									e && Nt < 0 && v - c > 0 ? P + v - c : e && Nt > 0 && c - v > 0 ? P + c - v : P),
									(u.ns_st_ap = s + ''),
									(u.ns_st_dap = s - N + ''),
									(u.ns_st_iap = s - S + ''),
									(u.ns_st_pt = d + (isNaN(_) ? 0 : n - _) + ''),
									(u.ns_st_dpt = d + (isNaN(_) ? 0 : n - _) - p + ''),
									(u.ns_st_ipt = d + (isNaN(_) ? 0 : n - _) - h + ''),
									(u.ns_st_et = E + (isNaN(m) ? 0 : n - m) + ''),
									(u.ns_st_det = E + (isNaN(m) ? 0 : n - m) - A + ''),
									(u.ns_st_iet = E + (isNaN(m) ? 0 : n - m) - w + ''),
									(u.ns_st_bt = Y + ''),
									(u.ns_st_dbt = Y + (isNaN(W) ? 0 : n - W) - j + ''),
									(u.ns_st_ibt = Y + (isNaN(W) ? 0 : n - W) - J + '');
								var l = R,
									g = x;
								e &&
									((l = i.St(R, Nt < 0 ? c : T, Nt < 0 ? T : c, O)),
									(g = i.St(x, Nt < 0 ? c : T, Nt < 0 ? T : c, O)));
								for (var L, b = 0, $ = 0, z = 0; z < l.length; z++)
									(b += L = Math.abs(l[z].end - l[z].start)), L > $ && ($ = L);
								var tt = 0,
									nt = 0;
								for (z = 0; z < g.length; z++)
									(tt += L = Math.abs(g[z].end - g[z].start)), L > nt && (nt = L);
								(u.ns_st_upc = b + ''),
									(u.ns_st_dupc = b - U + ''),
									(u.ns_st_iupc = b - M + ''),
									(u.ns_st_lpc = $ + ''),
									(u.ns_st_dlpc = $ - F + ''),
									(u.ns_st_ilpc = $ - C + ''),
									(u.ns_st_upa = tt + ''),
									(u.ns_st_dupa = tt - B + ''),
									(u.ns_st_iupa = tt - G + ''),
									(u.ns_st_lpa = nt + ''),
									(u.ns_st_dlpa = nt - k + ''),
									(u.ns_st_ilpa = nt - D + ''),
									(u.ns_st_pc = ot + ''),
									(u.ns_st_dpc = ot - st + ''),
									(u.ns_st_skc = at + ''),
									(u.ns_st_dskc = at - ut + ''),
									(u.ns_st_bc = H + ''),
									(u.ns_st_dbc = H - X + ''),
									(u.ns_st_ibc = H - q + ''),
									(u.ns_st_skt = Z + ''),
									(u.ns_st_dskt = Z - Q + ''),
									(u.ns_st_ska = et + ''),
									(u.ns_st_dska = et - rt + ''),
									o &&
										((u.ns_st_spc = ct + ''),
										(u.ns_st_dspc = ct - V + ''),
										(u.ns_st_apc = ft + ''),
										(u.ns_st_dapc = ft - K + ''),
										(u.ns_st_sq = lt + ''),
										(u.ns_st_asq = dt + '')),
									(u.ns_st_dtpc = At - Lt + ''),
									(u.ns_st_itpc = At - yt + ''),
									(u.ns_st_dcpc = ht - vt + ''),
									(u.ns_st_icpc = ht - gt + ''),
									(u.ns_st_rt = Nt + ''),
									(u.ns_st_ldw = y),
									(u.ns_st_ldo = I),
									(u.ns_st_pn = St + ''),
									(u.ns_st_lda = f ? '1' : '0'),
									r.extend(u, a);
							},
							Vo: function (t) {
								(p = parseInt(t.ns_st_pt)),
									(N = parseInt(t.ns_st_ap)),
									(A = parseInt(t.ns_st_et)),
									(j = parseInt(t.ns_st_bt)),
									(U = parseInt(t.ns_st_upc)),
									(F = parseInt(t.ns_st_lpc)),
									(B = parseInt(t.ns_st_upa)),
									(k = parseInt(t.ns_st_lpa)),
									(st = parseInt(t.ns_st_pc)),
									(ut = parseInt(t.ns_st_skc)),
									(X = parseInt(t.ns_st_bc)),
									(Q = parseInt(t.ns_st_skt)),
									(rt = parseInt(t.ns_st_ska)),
									o && ((V = parseInt(t.ns_st_spc)), (K = parseInt(t.ns_st_apc))),
									(Lt = At),
									(vt = ht),
									Ct.pu(0);
							},
							hu: function (t) {
								(h = parseInt(t.ns_st_pt)),
									(M = parseInt(t.ns_st_upc)),
									(G = parseInt(t.ns_st_upa)),
									(S = parseInt(t.ns_st_ap)),
									(w = parseInt(t.ns_st_et)),
									(D = parseInt(t.ns_st_lpa)),
									(C = parseInt(t.ns_st_lpc)),
									(J = parseInt(t.ns_st_bt)),
									(q = parseInt(t.ns_st_bc)),
									(yt = At),
									(gt = ht);
							},
							gu: function () {
								return Ct.vu('ns_st_vt');
							},
							mu: function (t) {
								Ct.tu('ns_st_vt', t + '');
							},
							yu: function () {
								return Ct.vu('ns_st_at');
							},
							Su: function (t) {
								Ct.tu('ns_st_at', t + '');
							},
							wu: function () {
								return Ct.vu('ns_st_tt');
							},
							bu: function (t) {
								Ct.tu('ns_st_tt', t + '');
							},
							Iu: function () {
								return Ct.vu('ns_st_cdn');
							},
							Eu: function (t) {
								Ct.tu('ns_st_cdn', t + '');
							},
							Br: function () {
								ot++;
							},
							Pu: function () {
								at++;
							},
							Fr: function () {
								lt++;
							},
							Au: function () {
								return lt;
							},
							ao: function (t) {
								_t = t;
							},
							fo: function (t) {
								pt = t;
							},
							eo: function () {
								return pt;
							},
							no: function () {
								return _t;
							},
							jr: function () {
								return Y;
							},
							Hr: function (t) {
								Y = t;
							},
							Gr: function (t) {
								if (!isNaN(W)) {
									var n = Ct.jr();
									(n += t - W), Ct.Hr(n), (W = NaN);
								}
							},
							Du: function (t) {
								T = parseInt(t);
							},
							Cu: function () {
								return T;
							},
							Lu: function (t) {
								isNaN(T) ||
									isNaN(t) ||
									((R = i.St(R, Nt < 0 ? t : T, Nt < 0 ? T : t, O)),
									(x = i.St(x, Nt < 0 ? t : T, Nt < 0 ? T : t, O)),
									(T = NaN));
							},
							Tu: function (t) {
								var n = x;
								null != t && (n = i.St(x, Nt < 0 ? _t : T, Nt < 0 ? T : _t, O)),
									It ||
										bt ||
										(n.length > 1 || 0 == n.length || n[0].start - O > 0
											? (It = !0)
											: n[0].end - n[0].start >= 3e3 && ((bt = !0), At++)),
									Et ||
										mt ||
										(n.length > 1 || 0 == n.length || n[0].start - O > 0
											? (Et = !0)
											: n[0].end - n[0].start >= Dt && ((mt = !0), ht++));
							},
							Nu: function () {
								return E;
							},
							Ou: function (t) {
								E = t;
							},
							Ru: function (t) {
								if (!isNaN(m)) {
									var n = Ct.Nu();
									(n += t - m), Ct.Ou(n), (m = NaN);
								}
							},
							ku: function () {
								return m;
							},
							Mu: function (t) {
								m = t;
							},
							Vr: function (t) {
								isNaN(_) || ((d += t - _), (_ = NaN));
							},
							Dr: function () {
								return d;
							},
							so: function (t) {
								return v + Math.floor(((t - g) * Nt) / 100);
							},
							ho: function (t) {
								return v + Math.floor(((t - b) * Nt) / 100);
							},
							_o: function (t, n) {
								return v + I - n + t - b;
							},
							po: function (t) {
								return I + Math.floor((t - b) * (1 - Nt / 100));
							},
							vo: function (t) {
								return I + t - b;
							},
							Zr: function (t) {
								v = t;
							},
							to: function (t) {
								g = t;
							},
							Uu: function () {
								return g;
							},
							uo: function () {
								return v;
							},
							Tr: function () {
								return _;
							},
							Yr: function (t) {
								_ = t;
							},
							xu: function (t) {
								p = t;
							},
							Jr: function () {
								return W;
							},
							Kr: function (t) {
								W = t;
							},
							Xr: function () {
								return ot;
							},
							zr: function (t) {
								ot = t;
							},
							Wu: function () {
								return at;
							},
							Fu: function (t) {
								at = t;
							},
							Bu: function (t) {
								$ = t;
							},
							Vu: function () {
								return $;
							},
							qu: function (t) {
								nt = t;
							},
							Gu: function () {
								return nt;
							},
							do: function () {
								return l;
							},
							Fo: function (t) {
								l = t;
							},
							ju: function (t) {
								o = t;
							},
							Wr: function () {
								return o;
							},
							Hu: function (t) {
								z = t;
							},
							Ku: function () {
								return z;
							},
							Ju: function (t) {
								if (!isNaN(z)) {
									var n = Ct.Yu();
									(n += t - z), Ct.Xu(n), (z = NaN);
								}
							},
							Yu: function () {
								return Z;
							},
							Xu: function (t) {
								Z = t;
							},
							zu: function (t) {
								it = t;
							},
							Qu: function () {
								return it;
							},
							$u: function (t) {
								tt = t;
							},
							Zu: function () {
								return tt;
							},
							ns: function (t) {
								et = t;
							},
							ts: function () {
								return et;
							},
							es: function (t) {
								var n,
									e = Ct.ts();
								(e += Math.abs(t - tt)),
									Ct.ns(e),
									tt == t ? (n = 0) : tt > t ? (n = -1) : tt < t && (n = 1),
									Ct.pu(n),
									(tt = 0);
							},
							rs: function () {
								return parseInt(Ct.vu('ns_st_skd'));
							},
							pu: function (t) {
								Ct.tu('ns_st_skd', t + '');
							},
							os: function () {
								(d = 0),
									(p = 0),
									(h = 0),
									(Y = 0),
									(j = 0),
									(J = 0),
									(H = 0),
									(X = 0),
									(q = 0),
									(ot = 0),
									(st = 0),
									(lt = 0),
									(w = 0),
									(E = 0),
									(A = 0),
									(Z = 0),
									(Q = 0),
									(et = 0),
									(rt = 0),
									(at = 0),
									(ut = 0),
									(wt = !1);
							},
							us: function () {
								ct++;
							},
							ss: function () {
								ft++;
							},
							as: function () {
								(P = 0),
									(N = 0),
									(S = 0),
									(x = []),
									(B = 0),
									(G = 0),
									(k = 0),
									(D = 0),
									(It = !1),
									(bt = !1),
									(Et = !1),
									(mt = !1);
							},
							fs: function (t) {
								ct = t;
							},
							Wo: function (t) {
								L = t;
							},
							cs: function () {
								return ct;
							},
							ls: function () {
								return L;
							},
							ds: function () {
								return H;
							},
							vs: function () {
								H++;
							},
							_s: function () {
								return j;
							},
							Co: function (t) {
								dt = t;
							},
							ko: function () {
								dt++;
							},
							ps: function () {
								return dt;
							},
							hs: function () {
								return Pt;
							},
							Bo: function (t) {
								Pt = t;
							},
							gs: function () {
								return Nt;
							},
							ms: function (t) {
								Nt = t;
							},
							ys: function (t) {
								Nt < 0 && v - t > 0 ? (P += v - t) : Nt > 0 && t - v > 0 && (P += t - v);
							},
							setDvrWindowLength: function (t) {
								y = t;
							},
							mo: function () {
								return y;
							},
							yo: function (t) {
								I = t;
							},
							So: function (t) {
								b = t;
							},
							Ss: function () {
								return b;
							},
							lo: function () {
								return I;
							},
							ws: function (t) {
								f = t;
							},
							bs: function () {
								return f;
							},
							Zo: function () {
								Pt = !1;
							},
						}),
							((a = {}).ns_st_cl = '0'),
							(a.ns_st_tp = '0'),
							(a.ns_st_cn = '1'),
							(a.ns_st_skd = '0'),
							(a.ns_st_ci = '0'),
							(a.ns_st_cn = '1'),
							(a.c3 = '*null'),
							(a.c4 = '*null'),
							(a.c6 = '*null'),
							(a.ns_st_st = '*null'),
							(a.ns_st_pu = '*null'),
							(a.ns_st_pr = '*null'),
							(a.ns_st_ep = '*null'),
							(a.ns_st_sn = '*null'),
							(a.ns_st_en = '*null'),
							(a.ns_st_ct = '*null'),
							(c = {}),
							Ot(n),
							(u = n),
							(o = !1),
							(f = !1),
							(l = !1),
							(d = 0),
							(_ = NaN),
							(v = 0),
							(p = 0),
							(E = 0),
							(_t = 0),
							(pt = NaN),
							(m = NaN),
							(L = 0),
							(A = 0),
							(h = 0),
							(y = 0),
							(I = 0),
							(b = NaN),
							(P = 0),
							(N = 0),
							(S = 0),
							(w = 0),
							(D = 0),
							(C = 0),
							(T = NaN),
							(R = []),
							(x = []),
							(U = 0),
							(M = 0),
							(F = 0),
							(B = 0),
							(G = 0),
							(k = 0),
							(V = 0),
							(K = 0),
							(Y = 0),
							(W = NaN),
							(j = 0),
							(H = 0),
							(X = 0),
							(q = 0),
							(J = 0),
							($ = !1),
							(z = NaN),
							(nt = !1),
							(tt = 0),
							(it = 0),
							(Z = 0),
							(Q = 0),
							(et = 0),
							(rt = 0),
							(ot = 0),
							(st = 0),
							(at = 0),
							(ut = 0),
							(ct = 0),
							(ft = 0),
							(lt = 0),
							(dt = 0),
							(ht = 0),
							(vt = 0),
							(gt = 0),
							(Et = !1),
							(mt = !1),
							(At = 0),
							(Lt = 0),
							(yt = 0),
							(It = !1),
							(bt = !1),
							(Pt = !1),
							(Nt = 100),
							(St = 1),
							(wt = !1),
							(s = e),
							(O = t.getConfiguration().Is),
							(Dt = t.getConfiguration().Es);
					}
					(s.It = function (t) {
						return null == t.ns_st_cn ? i.It(t, o) : t.ns_st_cn + '';
					}),
						(s.xr = function (t, n, e) {
							for (var r = t.getLabels(), i = {}, o = 0; e && o < e.length; ++o)
								r.hasOwnProperty(e[o]) && (i[e[o]] = r[e[o]]);
							n.addLabels(i), n.lu(t.du());
						}),
						(s.Ps = 500),
						(s.As = 500),
						(s.Ds = 3e3),
						(t.exports = s);
				},
				function (t, n, e) {
					var r = e(55);
					t.exports = r;
				},
				function (t, n, e) {
					var r,
						i = e(0),
						o = e(17),
						s = e(30),
						a = e(9),
						u = e(15),
						c = e(2),
						f = e(94);
					(r = new (function () {
						i.extend(this, {
							StreamingAnalytics: f,
							PlatformAPIs: c.mn.PlatformApis,
							PlatformApi: c.mn,
							ConnectivityType: a,
							EventInfo: s,
							configuration: o.configuration.Ji,
							version: u.VERSION,
							start: function () {
								o.start();
							},
							notifyHiddenEvent: function (t) {
								o.pe(t);
							},
							notifyViewEvent: function (t) {
								o.ge(t);
							},
							notifyDistributedContentViewEvent: function (t, n) {
								o.notifyDistributedContentViewEvent(t, n);
							},
							notifyUxActive: function () {
								o.notifyUxActive();
							},
							notifyUxInactive: function () {
								o.notifyUxInactive();
							},
							notifyEnterForeground: function () {
								o.notifyEnterForeground();
							},
							notifyExitForeground: function () {
								o.notifyExitForeground();
							},
							flushOfflineCache: function () {
								o.flushOfflineCache();
							},
							clearOfflineCache: function () {
								o.clearOfflineCache();
							},
							clearInternalData: function () {
								o.clearInternalData();
							},
						});
					})()),
						(t.exports = r);
				},
				function (t, n) {
					t.exports = {
						je: function (t) {
							return !(null == t || '' == t || '0' == t);
						},
					};
				},
				function (t, n, e) {
					var r = e(2).yn,
						i = e(23);
					function o(t, n, e) {
						var r = t.ns_ts,
							i = 'undefined' != typeof encodeURIComponent ? encodeURIComponent : escape,
							o = '',
							s = !1;
						for (var a in t)
							null == n[a] &&
								'ns_ts' != a &&
								(s && (o += '&'), (s = !0), (o += i(a) + '=' + i(t[a])));
						return e.push(o), '<event t="' + r + '">' + o + '</event>';
					}
					var s = {
						Zi: function (t, n, e) {
							var s = (function (t) {
									var n = {};
									return (
										(n.c12 = t.getPublisherConfigurations()[0].getPublisherUniqueDeviceId()),
										(n.c1 = '19'),
										(n.ns_ap_pn = r.K()),
										(n.ns_ap_an = t.F()),
										(n.ns_ap_device = r.j()),
										n
									);
								})(t),
								a = '';
							for (var u in ((a += '<?xml version="1.0" encoding="UTF-8" ?>\n<events '),
							(a += 't="' + +new Date() + '" '),
							s))
								a += u + '="' + s[u] + '" ';
							a += 'dropped="' + e + '" ';
							for (var c = [], f = '', l = 0; l < n.length; ++l) f += '    ' + o(n[l], s, c) + '\n';
							return (a += 'md5="' + i(c.join('')) + '" '), (a += '>\n'), (a += f) + '</events>';
						},
					};
					t.exports = s;
				},
				function (t, n, e) {
					var r = e(0);
					t.exports = function (t) {
						r.extend(this, {
							PlatformApis: t.PlatformApis,
							setPlatformAPI: t.setPlatformApi,
							setPlatformApi: t.setPlatformApi,
						});
					};
				},
				function (t, n) {
					t.exports = {
						SmartTV: 0,
						Netcast: 1,
						Cordova: 2,
						Trilithium: 3,
						AppleTV: 4,
						Chromecast: 5,
						Xbox: 6,
						webOS: 7,
						tvOS: 8,
						nodejs: 9,
						html5: 10,
						JSMAF: 11,
						Skeleton: 12,
						WebBrowser: 13,
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(5),
						o = e(4),
						s = e(3),
						a = e(6).xn,
						u = e(61),
						c = e(62),
						f = e(63),
						l = e(9),
						d = s.wn,
						_ = s.bn,
						p = null,
						h = null,
						v = null;
					function g() {
						if (null == p) {
							var t = (function () {
								if (
									'object' != typeof sf ||
									'object' != typeof sf.core ||
									'function' != typeof sf.core.sefplugin
								)
									return null;
								var t = sf.core.sefplugin('NNAVI');
								if ('function' != typeof t.Open || 'function' != typeof t.Execute) return null;
								t.Open('Network', '1.001', 'Network');
								var n = t.Execute('GetMAC', '0');
								if (!o.An(n)) return n;
								if (((n = t.Execute('GetMAC', '1')), !o.An(n))) return n;
								var e = t.Execute('GetDeviceID');
								return o.An(e) ? null : e;
							})();
							null != t
								? ((p = t), (h = '31'), (v = t))
								: ((p = +new Date() + ~~(1e3 * Math.random())), (h = '72'), (v = null));
						}
					}
					var E = r.extend({}, i, {
						D: 'smarttv',
						T: a,
						N: null,
						Storage: u,
						O: !0,
						R: c,
						k: f,
						M: 6e4,
						W: function () {
							return g(), v;
						},
						B: function () {
							return (
								('undefined' != typeof sf &&
									o.Ln(sf.env) &&
									o.Ln(sf.env.getAppVersion) &&
									sf.env.getAppVersion()) ||
								d
							);
						},
						V: function () {
							return g(), p;
						},
						G: function () {
							return h;
						},
						j: function () {
							return (
								('undefined' != typeof sf &&
									o.Ln(sf.core) &&
									o.Ln(sf.core.getEnvValue) &&
									sf.core.getEnvValue('modelid')) ||
								d
							);
						},
						H: function () {
							return '2.0.0';
						},
						J: function () {
							if (
								'undefined' != typeof sf &&
								o.Ln(sf.env) &&
								o.Ln(sf.env.getProductType) &&
								o.Ln(sf.env.PRODUCTTYPE_TV) &&
								o.Ln(sf.env.PRODUCTTYPE_MONITOR) &&
								o.Ln(sf.env.PRODUCTTYPE_BD)
							) {
								var t = [];
								return (
									(t[sf.env.PRODUCTTYPE_TV] = 'samsung-smarttv-tv'),
									(t[sf.env.PRODUCTTYPE_MONITOR] = 'samsung-smarttv-monitor'),
									(t[sf.env.PRODUCTTYPE_BD] = 'samsung-smarttv-bd'),
									t[sf.env.getProductType()]
								);
							}
						},
						Y: function () {
							return (
								'undefined' != typeof sf &&
								o.Ln(sf.env) &&
								o.Ln(sf.env.getFirmwareVer) &&
								sf.env.getFirmwareVer().version
							);
						},
						X: function () {
							if ('undefined' == typeof sf || !o.Ln(sf.env) || !o.Ln(sf.env.getScreenSize))
								return _;
							var t = sf.env.getScreenSize();
							return t.width + 'x' + t.height;
						},
						nn: function () {
							if ('undefined' != typeof sf && o.Ln(sf.env) && o.Ln(sf.env.getLanguageCode))
								return sf.env.getLanguageCode();
						},
						in: function () {
							if (
								'object' != typeof sf ||
								'object' != typeof sf.core ||
								'function' != typeof sf.core.sefplugin
							)
								return l.UNKNOWN;
							var t = sf.core.sefplugin('NETWORK');
							if ('function' != typeof t.Open || 'function' != typeof t.Execute) return l.UNKNOWN;
							t.Open('Network', '1.001', 'Network');
							var n = t.Execute('GetActiveType');
							return 0 === n ? l.ETHERNET : 1 === n ? l.WIFI : l.UNKNOWN;
						},
					});
					t.exports = E;
				},
				function (t, n, e) {
					var r,
						i,
						o,
						s,
						a = e(0);
					t.exports = function (t) {
						(o = 'undefined' != typeof FileSystem ? new FileSystem() : null),
							(s = 'undefined' != typeof curWidget ? curWidget.id : null),
							'undefined' != typeof sf
								? (r = i =
										function (t, n) {
											sf.core.localData(t, n);
										})
								: ((r = function (t, n) {
										$.sf.setData(t, void 0 === n ? null : n);
								  }),
								  (i = function (t) {
										return $.sf.getData(t);
								  })),
							null == o ||
								void 0 === o.isValidCommonPath ||
								o.isValidCommonPath(s) ||
								o.createCommonDir(s),
							a.extend(this, {
								storeProperties: function (t) {
									try {
										r('cs_settings', t);
									} catch (t) {}
								},
								getProperties: function () {
									try {
										return i('cs_settings');
									} catch (t) {}
								},
								storeCache: function (t) {
									try {
										!(function (t, n, e) {
											var r = s + '/cs_cache_dir';
											o.isValidCommonPath(r) || o.createCommonDir(r);
											var i = o.openCommonFile(r + '/' + n, 'w');
											i.writeLine(e), o.closeCommonFile(i);
										})(0, 'cs_cache_dir_single', t);
									} catch (t) {}
								},
								getCache: function () {
									try {
										return (function (t, n) {
											var e = s + '/cs_cache_dir';
											if (!o.isValidCommonPath(e)) return null;
											var r = o.openCommonFile(e + '/' + n, 'r');
											if (r) {
												var i = r.Cs();
												return o.closeCommonFile(r), i;
											}
											return null;
										})(0, 'cs_cache_dir_single');
									} catch (t) {}
								},
							});
					};
				},
				function (t, n, e) {
					var r,
						i,
						o = e(0);
					'undefined' != typeof sf
						? (i = r =
								function (t, n) {
									sf.core.localData(t, n);
								})
						: ((i = function (t, n) {
								$.sf.setData(t, void 0 === n ? null : n);
						  }),
						  (r = function (t) {
								return $.sf.getData(t);
						  })),
						(t.exports = function () {
							o.extend(this, {
								get: function (t) {
									return r('cs_' + t);
								},
								set: function (t, n) {
									i('cs_' + t, n);
								},
								has: function (t) {
									return void 0 !== r('cs_' + t);
								},
								remove: function (t) {
									i('cs_' + t, null);
								},
								clear: function () {},
							});
						});
				},
				function (t, n, e) {
					var r,
						i,
						o = e(21),
						s = e(0),
						a = null,
						u = null,
						c = {},
						f = !1;
					function l() {
						(a = 'undefined' != typeof FileSystem ? new FileSystem() : null),
							(u = 'undefined' != typeof curWidget ? curWidget.id : null),
							'undefined' != typeof sf &&
								(i = r =
									function (t, n) {
										sf.core.localData(t, n);
									}),
							null == a ||
								void 0 === a.isValidCommonPath ||
								a.isValidCommonPath(u) ||
								a.createCommonDir(u),
							(f = !0);
					}
					function d(t) {
						return t.replace(/^\s+|\s+$/g, '');
					}
					function _(t, n) {
						var e = c[t];
						e ? e.push(n) : (e = c[t] = [n]), i('cs_dir_' + t, e.join('|'));
					}
					function p(t, n) {
						var e = c[t],
							r = o.indexOf(n, e);
						r >= 0 && (e.splice(r, 1), i('cs_dir_' + t, 0 === e.length ? null : e.join('|')));
					}
					t.exports = function () {
						s.extend(this, {
							dir: function (t) {
								if ((f || l(), a.isValidCommonPath(u + '/' + t))) {
									var n = c[t];
									if (!n) {
										if (!(n = r('cs_dir_' + t))) return null;
										n = c[t] = n.split('|');
									}
									for (var e = n.slice(), i = 0, o = e.length; i < o; i++) {
										var s = n[i];
										a.isValidCommonPath(u + '/' + t + '/' + s) || p(t, s);
									}
									return e;
								}
								return null;
							},
							append: function (t, n, e) {
								f || l();
								var r = u + '/' + t;
								a.isValidCommonPath(r) || a.createCommonDir(r),
									a.isValidCommonPath(r + '/' + n) || _(t, n);
								var i = a.openCommonFile(r + '/' + n, 'a');
								i.writeLine(d(e)), a.closeCommonFile(i);
							},
							write: function (t, n, e) {
								f || l();
								var r = u + '/' + t;
								a.isValidCommonPath(r) || a.createCommonDir(r),
									a.isValidCommonPath(r + '/' + n) || _(t, n);
								var i = a.openCommonFile(r + '/' + n, 'w');
								i.writeLine(d(e)), a.closeCommonFile(i);
							},
							deleteFile: function (t, n) {
								f || l();
								var e = u + '/' + t;
								return !!a.isValidCommonPath(e) && (p(t, n), a.deleteCommonFile(e + '/' + n));
							},
							read: function (t, n) {
								f || l();
								var e = u + '/' + t;
								if (!a.isValidCommonPath(e)) return null;
								var r = a.openCommonFile(e + '/' + n, 'r');
								if (r) {
									for (var i, o = []; (i = r.readLine()); ) o.push(i);
									return a.closeCommonFile(r), o.join('\n');
								}
								return p(t, n), '';
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(5),
						o = e(4),
						s = e(3),
						a = e(13),
						u = e(6).xn,
						c = e(8),
						f = e(10),
						l = e(9),
						d = s.wn,
						_ = s.bn,
						p = null,
						h = null,
						v = null;
					function g() {
						if (null == p) {
							var t = (function () {
								if ('undefined' != typeof ns_ && o.Ln(ns_.netcastDevice)) {
									if (
										o.Ln(ns_.netcastDevice.net_macAddress) &&
										o.Dn(ns_.netcastDevice.net_macAddress)
									)
										return ns_.netcastDevice.net_macAddress;
									if (o.Ln(ns_.netcastDevice.serialNumber) && o.Dn(ns_.netcastDevice.serialNumber))
										return ns_.netcastDevice.serialNumber;
								}
								return null;
							})();
							null != t
								? ((p = t), (h = '31'), (v = t))
								: ((p = +new Date() + ~~(1e3 * Math.random())), (h = '72'), (v = null));
						}
					}
					var E = r.extend({}, i, {
						D: 'netcast',
						T: function () {
							return 'undefined' != typeof Image
								? a.apply(this, arguments)
								: u.apply(this, arguments);
						},
						N: null,
						Storage: c,
						R: f,
						O: !1,
						M: 6e4,
						W: function () {
							return g(), v;
						},
						V: function () {
							return g(), p;
						},
						G: function () {
							return h;
						},
						j: function () {
							return (
								('undefined' != typeof ns_ &&
									o.Ln(ns_.netcastDevice) &&
									o.Ln(ns_.netcastDevice.modelName) &&
									ns_.netcastDevice.modelName) ||
								d
							);
						},
						H: function () {
							return (
								('undefined' != typeof ns_ &&
									o.Ln(ns_.netcastDevice) &&
									o.Cn(ns_.netcastDevice.version, d)) ||
								d
							);
						},
						J: function () {
							return 'undefined' != typeof ns_ && o.Ln(ns_.netcastDevice)
								? 'lg-ott' + o.Cn(ns_.netcastDevice.platform, d)
								: d;
						},
						Y: function () {
							if ('undefined' != typeof ns_ && o.Ln(ns_.netcastDevice)) {
								if (o.Ln(ns_.netcastDevice.version)) return ns_.netcastDevice.version;
								if (o.Ln(ns_.netcastDevice.hwVersion)) return ns_.netcastDevice.hwVersion;
								if (o.Ln(ns_.netcastDevice.swVersion)) return ns_.netcastDevice.swVersion;
							}
							return d;
						},
						X: function () {
							if (
								'undefined' != typeof ns_ &&
								o.Ln(ns_.netcastDevice) &&
								o.Ln(ns_.netcastDevice.osdResolution)
							)
								switch (ns_.netcastDevice.osdResolution) {
									case 0:
										return '640x480';
									case 1:
										return '720x576';
									case 2:
										return '1280x720';
									case 3:
										return '1920x1080';
									case 4:
										return '1366x768';
								}
							return _;
						},
						nn: function () {
							return (
								('undefined' != typeof ns_ &&
									o.Ln(ns_.netcastDevice) &&
									o.Cn(ns_.netcastDevice.tvLanguage2, o.Cn(window.navigator.language))) ||
								d
							);
						},
						in: function () {
							if (void 0 !== ns_.netcastDevice) {
								if (0 === ns_.netcastDevice.networkType) return l.ETHERNET;
								if (1 === ns_.netcastDevice.networkType) return l.WIFI;
							}
							return d;
						},
					});
					t.exports = E;
				},
				function (t, n, e) {
					var r,
						i,
						o,
						s = e(0),
						a = e(5),
						u = e(3),
						c = e(13),
						f = e(6).Wn,
						l = e(8),
						d = e(12),
						_ = e(10),
						p = e(9),
						h = u.wn,
						v = u.bn,
						g = s.extend({}, a, {
							D: 'webos',
							T: c,
							N: f,
							Storage: l,
							R: _,
							k: d,
							O: !0,
							M: 6e4,
							U: function (t, n) {
								var e = 3,
									s = function () {
										0 == --e && t();
									};
								!(function (t, n) {
									webOS.service.request('luna://com.webos.service.tv.systemproperty', {
										method: 'getSystemInfo',
										parameters: { keys: ['modelName', 'firmwareVersion', 'UHD', 'sdkVersion'] },
										onSuccess: function (n) {
											n.returnValue && (r = n), t();
										},
										onFailure: function () {
											t();
										},
									});
								})(s),
									(function (t, n) {
										webOS.service.request('luna://com.webos.settingsservice', {
											method: 'getSystemSettings',
											parameters: { category: 'option' },
											onSuccess: function (n) {
												n.returnValue && (i = n), t();
											},
											onFailure: function () {
												t();
											},
										});
									})(s),
									(function (t, n) {
										webOS.service.request('luna://com.webos.service.connectionmanager', {
											method: 'getStatus',
											onSuccess: function (n) {
												n.returnValue && (o = n), t();
											},
											onFailure: function () {
												t();
											},
										});
									})(s);
							},
							W: function () {
								return 'undefined' != typeof webOS &&
									webOS.device &&
									webOS.device.serialNumber &&
									'Unknown' != webOS.device.serialNumber
									? webOS.device.serialNumber
									: null;
							},
							V: function () {
								return +new Date() + ~~(1e3 * Math.random());
							},
							G: function () {
								return '72';
							},
							j: function () {
								return r && r.modelName ? r.modelName : h;
							},
							H: function () {
								return r && r.sdkVersion ? r.sdkVersion : h;
							},
							J: function () {
								return 'webOS';
							},
							Y: function () {
								return 'undefined' != typeof webOS && webOS.device
									? webOS.device.platformVersion
									: h;
							},
							X: function () {
								var t = 0;
								'undefined' != typeof webOS && webOS.device
									? (t = webOS.device.screenWidth)
									: 'undefined' != typeof window && window.screen && (t = window.screen.availWidth);
								var n = 0;
								return (
									'undefined' != typeof webOS && void 0 !== webOS.device
										? (n = webOS.device.screenHeight)
										: 'undefined' != typeof window &&
										  window.screen &&
										  (n = window.screen.availHeight),
									t > 0 && n > 0 ? t + 'x' + n : v
								);
							},
							nn: function () {
								return (i && i.locales && (i.locales.UI || i.locales.TV)) || h;
							},
							in: function () {
								if (o && (o.wired || o.wifi)) {
									if ('connected' === o.wired.state && 'yes' === o.wired.onInternet)
										return p.ETHERNET;
									if ('connected' === o.wifi.state && 'yes' === o.wifi.onInternet) return p.WIFI;
								}
								return p.UNKNOWN;
							},
						});
					t.exports = g;
				},
				function (t, n, e) {
					var r = e(0),
						i = e(5),
						o = e(4),
						s = e(3),
						a = e(13),
						u = e(6).xn,
						c = e(6).Wn,
						f = e(8),
						l = e(12),
						d = e(10),
						_ = s.wn,
						p = s.bn,
						h = null,
						v = null,
						g = null;
					function E() {
						if (null == h) {
							var t =
								'undefined' != typeof device && o.Ln(device.uuid) && device.uuid.length > 0
									? device.uuid
									: null;
							null != t
								? ((h = t), (v = '31'), (g = t))
								: ((h = +new Date() + ~~(1e3 * Math.random())), (v = '72'), (g = null));
						}
					}
					var m = r.extend({}, i, {
						D: 'cordova',
						T: function () {
							return 'undefined' != typeof Image
								? a.apply(this, arguments)
								: u.apply(this, arguments);
						},
						N: c,
						Storage: f,
						O: !0,
						R: d,
						k: l,
						M: 6e4,
						W: function () {
							return E(), g;
						},
						V: function () {
							return E(), h;
						},
						G: function () {
							return v;
						},
						j: function () {
							return ('undefined' != typeof device && o.Ln(device.model)) || _;
						},
						H: function () {
							return ('undefined' != typeof device && o.Ln(device.cordova) && device.cordova) || _;
						},
						J: function () {
							return (
								('undefined' != typeof device &&
									o.Ln(device.platform) &&
									'cordova' + device.platform) ||
								'cordova'
							);
						},
						Y: function () {
							return ('undefined' != typeof device && o.Ln(device.version)) || _;
						},
						X: function () {
							var t =
									('undefined' != typeof window &&
										o.Ln(window.screen) &&
										o.Ln(window.screen.availWidth) &&
										window.screen.availWidth) ||
									0,
								n =
									('undefined' != typeof window &&
										o.Ln(window.screen) &&
										o.Ln(window.screen.availHeight) &&
										window.screen.availHeight) ||
									0;
							return t > 0 && n > 0 ? t + 'x' + n : p;
						},
						nn: function () {
							return (
								('undefined' != typeof window &&
									o.Ln(window.navigator) &&
									o.Ln(window.navigator.language) &&
									window.navigator.language) ||
								_
							);
						},
					});
					t.exports = m;
				},
				function (t, n, e) {
					var r = e(0),
						i = e(5),
						o = e(4),
						s = e(3),
						a = e(68),
						u = e(8),
						c = e(10),
						f = s.wn,
						l = s.bn,
						d = null,
						_ = null,
						p = null;
					function h() {
						if (null == d) {
							var t = o.Dn(engine.stats.device.id)
								? engine.stats.device.id
								: o.Dn(engine.stats.network.mac)
								? engine.stats.network.mac
								: null;
							null != t
								? ((d = t), (_ = '31'), (p = t))
								: ((d = +new Date() + ~~(1e3 * Math.random())), (_ = '72'), (p = null));
						}
					}
					var v = r.extend({}, i, {
						D: 'trilithium',
						T: a,
						N: null,
						Storage: u,
						R: c,
						O: !1,
						M: 6e4,
						W: function () {
							return h(), p;
						},
						F: function () {
							return o.Dn(engine.stats.application.name) ? engine.stats.application.name : f;
						},
						B: function () {
							return o.Dn(engine.stats.application.version) ? engine.stats.application.version : f;
						},
						V: function () {
							return h(), d;
						},
						G: function () {
							return _;
						},
						j: function () {
							return o.Cn(engine.stats.device.platform, f);
						},
						J: function () {
							return 'trilithium';
						},
						Y: function () {
							return o.Cn(engine.stats.device.version, f);
						},
						X: function () {
							return 'undefined' != typeof screen &&
								void 0 !== screen.height &&
								void 0 !== screen.width
								? screen.height + 'x' + screen.width
								: l;
						},
					});
					t.exports = v;
				},
				function (t, n) {
					t.exports = function (t, n) {
						'undefined' != typeof engine
							? (engine.createHttpClient().createRequest('GET', t, null).start(),
							  n && setTimeout(n, 0))
							: 'function' == typeof setTimeout
							? n && setTimeout(n, 0)
							: n && n();
					};
				},
				function (t, n, e) {
					var r = e(4),
						i = e(3),
						o = e(34).or,
						s = e(34).ur,
						a = e(70),
						u = e(71),
						c = i.wn,
						f = i.bn,
						l = null,
						d = null,
						_ = null;
					function p() {
						null == l &&
							('undefined' != typeof atv && void 0 !== atv.device && atv.device.idForVendor
								? ((l = r.Cn(atv.device.idForVendor, '')), (d = '62'))
								: ((l = +new Date() + ~~(1e3 * Math.random())), (d = '72')),
							(_ = null));
					}
					var h = {
						D: 'atv',
						T: o,
						N: s,
						Storage: a,
						R: u,
						O: !1,
						M: 6e4,
						W: function () {
							return p(), _;
						},
						F: function () {
							return c;
						},
						B: function () {
							return c;
						},
						V: function () {
							return p(), l;
						},
						G: function () {
							return d;
						},
						j: function () {
							return 'Apple TV';
						},
						H: function () {
							return (
								'undefined' != typeof atv &&
								void 0 !== atv.device &&
								r.Cn(atv.device.softwareVersion, c)
							);
						},
						K: function () {
							return 'js';
						},
						J: function () {
							return 'atv';
						},
						Y: function () {
							return (
								'undefined' != typeof atv &&
								void 0 !== atv.device &&
								r.Cn(atv.device.softwareVersion, c)
							);
						},
						X: function () {
							return void 0 !== atv.device &&
								void 0 !== atv.device.screenFrame &&
								void 0 !== atv.device.screenFrame.height &&
								void 0 !== atv.device.screenFrame.width
								? atv.device.screenFrame.height + 'x' + atv.device.screenFrame.width
								: f;
						},
						nn: function () {
							return (
								'undefined' != typeof atv && void 0 !== atv.device && r.Cn(atv.device.language, '')
							);
						},
						setTimeout: function (t, n) {
							return 'undefined' != typeof atv && void 0 !== atv.setTimeout && atv.setTimeout(t, n);
						},
						setInterval: function (t, n) {
							return (
								'undefined' != typeof atv && void 0 !== atv.setInterval && atv.setInterval(t, n)
							);
						},
						clearTimeout: function (t) {
							return (
								'undefined' != typeof atv && void 0 !== atv.clearTimeout && atv.clearTimeout(t)
							);
						},
						clearInterval: function (t) {
							return (
								'undefined' != typeof atv && void 0 !== atv.clearInterval && atv.clearInterval(t)
							);
						},
					};
					t.exports = h;
				},
				function (t, n, e) {
					var r = e(0);
					t.exports = function (t) {
						var n =
							('undefined' != typeof atv && void 0 !== atv.localStorage && atv.localStorage) ||
							null;
						r.extend(this, {
							storeProperties: function (t) {
								if (n)
									try {
										'function' == typeof n.setItem ? n.setItem('cs_settings', t) : n && (n.Vn = t);
									} catch (t) {}
							},
							getProperties: function () {
								if (!n) return null;
								try {
									if ('function' == typeof n.getItem) return n.getItem('cs_settings');
									if (n) return n.Vn;
								} catch (t) {}
							},
							storeCache: function (t) {
								if (n)
									try {
										'function' == typeof n.setItem ? n.setItem('cs_cache', t) : n && (n.qn = t);
									} catch (t) {}
							},
							getCache: function () {
								if (!n) return null;
								try {
									if ('function' == typeof n.getItem) return n.getItem('cs_cache');
									if (n) return n.qn;
								} catch (t) {}
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0);
					t.exports = function () {
						var t =
							('undefined' != typeof atv && void 0 !== atv.localStorage && atv.localStorage) ||
							null;
						r.extend(this, {
							get: function (n) {
								return (t && n && t.getItem('cs_' + n)) || null;
							},
							set: function (n, e) {
								t && n && (t['cs_' + n] = e);
							},
							remove: function (n) {
								t && n && t.removeItem('cs_' + n);
							},
							clear: function () {},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(5),
						o = e(3),
						s = e(35).sr,
						a = e(35).ar,
						u = e(8),
						c = e(12),
						f = e(10),
						l = e(9),
						d = o.wn,
						_ = o.bn,
						p = null,
						h = null,
						v = null,
						g = !1,
						E = !1,
						m = !1;
					function A() {
						var t = null;
						if (
							'undefined' != typeof Windows &&
							Windows &&
							Windows.Xbox &&
							Windows.Xbox.ApplicationModel &&
							Windows.Xbox.ApplicationModel.Core &&
							Windows.Xbox.ApplicationModel.Core.CoreApplicationContext &&
							Windows.Xbox.ApplicationModel.Core.CoreApplicationContext.currentUser
						) {
							var n = Windows.Xbox.ApplicationModel.Core.CoreApplicationContext.currentUser;
							null != n &&
								!n.Ls &&
								n.Ts &&
								(t =
									Windows.Xbox.ApplicationModel.Core.CoreApplicationContext.currentUser.xboxUserId);
						}
						v = t;
					}
					var L = r.extend({}, i, {
						D: 'xbox',
						T: s,
						N: a,
						Storage: u,
						R: f,
						k: c,
						O: !0,
						M: 6e4,
						W: function () {
							return (
								m ||
									('undefined' != typeof Windows &&
										Windows &&
										Windows.Xbox &&
										Windows.Xbox.ApplicationModel &&
										Windows.Xbox.ApplicationModel.Core &&
										Windows.Xbox.ApplicationModel.Core.CoreApplicationContext &&
										Windows.Xbox.ApplicationModel.Core.CoreApplicationContext.addEventListener(
											'currentuserchanged',
											function () {
												E = !0;
											},
										),
									A(),
									(m = !0)),
								E && A(),
								v
							);
						},
						F: function () {
							var t = d;
							return (
								'undefined' != typeof Windows &&
									Windows &&
									Windows.ApplicationModel &&
									Windows.ApplicationModel.Package &&
									Windows.ApplicationModel.Package.current &&
									Windows.ApplicationModel.Package.current.id &&
									Windows.ApplicationModel.Package.current.id.name &&
									(t = Windows.ApplicationModel.Package.current.id.name),
								t
							);
						},
						B: function () {
							var t = d;
							if (
								'undefined' != typeof Windows &&
								Windows &&
								Windows.ApplicationModel &&
								Windows.ApplicationModel.Package &&
								Windows.ApplicationModel.Package.current &&
								Windows.ApplicationModel.Package.current.id &&
								Windows.ApplicationModel.Package.current.id.version
							) {
								var n = Windows.ApplicationModel.Package.current.id.version;
								t = n.major + '.' + n.minor + '.' + n.build + '.' + n.revision;
							}
							return t;
						},
						V: function () {
							return (
								g ||
									(function () {
										'undefined' != typeof Windows &&
										Windows &&
										Windows.Xbox &&
										Windows.Xbox.System &&
										Windows.Xbox.System.Console &&
										Windows.Xbox.System.Console.applicationSpecificDeviceId
											? ((p = Windows.Xbox.System.Console.applicationSpecificDeviceId), (h = '72'))
											: ((p = this.j() + +new Date() + ~~(1e3 * Math.random())), (h = '72')),
											(g = !0);
									})(),
								p
							);
						},
						G: function () {
							return h;
						},
						j: function () {
							return 'xbox one';
						},
						H: function () {
							var t = d;
							return (
								'undefined' != typeof navigator &&
									navigator &&
									navigator.userAgent &&
									navigator.userAgent
										.split(';')
										.filter(function (t) {
											return -1 != t.indexOf('Windows NT');
										})
										.forEach(function (n) {
											t = n.substr(n.indexOf('Windows NT') + 11, n.length - 1);
										}),
								t
							);
						},
						K: function () {
							return 'xbox';
						},
						J: function () {
							return 'winjs';
						},
						Y: function () {
							var t = d;
							return (
								'undefined' != typeof Windows &&
									Windows &&
									Windows.ApplicationModel &&
									Windows.ApplicationModel.Package &&
									Windows.ApplicationModel.Package.current &&
									Windows.ApplicationModel.Package.current.dependencies &&
									Windows.ApplicationModel.Package.current.dependencies
										.filter(function (t) {
											return (
												t && t.id && t.id.name && -1 != t.id.name.indexOf('WinJS') && t.id.version
											);
										})
										.forEach(function (n) {
											t =
												n.id.version.major +
												'.' +
												n.id.version.minor +
												'.' +
												n.id.version.build +
												'.' +
												n.id.version.revision;
										}),
								t
							);
						},
						X: function () {
							var t = _;
							if (
								'undefined' != typeof Windows &&
								Windows &&
								Windows.Xbox &&
								Windows.Xbox.Graphics &&
								Windows.Xbox.Graphics.Display &&
								Windows.Xbox.Graphics.Display.DisplayConfiguration &&
								Windows.Xbox.Graphics.Display.DisplayConfiguration.getForCurrentView &&
								Windows.Xbox.Graphics.Display.DisplayConfiguration.getForCurrentView() &&
								Windows.Xbox.Graphics.Display.DisplayConfiguration.getForCurrentView()
									.currentDisplayMode &&
								Windows.Xbox.Graphics.Display.DisplayConfiguration.getForCurrentView()
									.currentDisplayMode.rawWidth &&
								Windows.Xbox.Graphics.Display.DisplayConfiguration.getForCurrentView()
									.currentDisplayMode.rawHeight
							) {
								var n =
									Windows.Xbox.Graphics.Display.DisplayConfiguration.getForCurrentView()
										.currentDisplayMode;
								t = n.rawWidth + 'x' + n.rawHeight;
							}
							return t;
						},
						Z: function () {
							var t = _;
							return (
								'undefined' != typeof window &&
									window.innerWidth &&
									window.innerHeight &&
									(t = window.innerWidth + 'x' + window.innerHeight),
								t
							);
						},
						nn: function () {
							var t = d;
							return (
								'undefined' != typeof Windows &&
									Windows &&
									Windows.System &&
									Windows.System.UserProfile &&
									Windows.System.UserProfile.GlobalizationPreferences &&
									Windows.System.UserProfile.GlobalizationPreferences.languages &&
									(t = Windows.System.UserProfile.GlobalizationPreferences.languages.getAt(0)),
								t
							);
						},
						tn: function () {
							var t = d;
							return (
								'undefined' != typeof Windows &&
									Windows &&
									Windows.ApplicationModel &&
									Windows.ApplicationModel.Package &&
									Windows.ApplicationModel.Package.current &&
									Windows.ApplicationModel.Package.current.id &&
									Windows.ApplicationModel.Package.current.id.name &&
									(t = Windows.ApplicationModel.Package.current.id.name),
								t
							);
						},
						setPlatformAPI: function () {},
						en: function () {
							var t = 'unknown';
							if (
								'undefined' != typeof Windows &&
								Windows &&
								Windows.ApplicationModel &&
								Windows.ApplicationModel.Package &&
								Windows.ApplicationModel.Package.current &&
								Windows.ApplicationModel.Package.current.id &&
								Windows.ApplicationModel.Package.current.id.architecture
							)
								switch (Windows.ApplicationModel.Package.current.id.architecture) {
									case 5:
										t = 'arm';
										break;
									case 11:
										t = 'neutral';
										break;
									case 9:
										t = 'x64';
										break;
									case 0:
										t = 'x86';
								}
							return t;
						},
						in: function () {
							var t = l.UNKNOWN;
							if (
								'undefined' != typeof Windows &&
								Windows &&
								Windows.Networking &&
								Windows.Networking.Connectivity &&
								Windows.Networking.Connectivity.NetworkInformation &&
								Windows.Networking.Connectivity.NetworkInformation.getInternetConnectionProfile &&
								Windows.Networking.Connectivity.NetworkInformation.getInternetConnectionProfile() &&
								Windows.Networking.Connectivity.NetworkInformation.getInternetConnectionProfile()
									.networkAdapter &&
								Windows.Networking.Connectivity.NetworkInformation.getInternetConnectionProfile()
									.networkAdapter.ianaInterfaceType
							)
								switch (
									Windows.Networking.Connectivity.NetworkInformation.getInternetConnectionProfile()
										.networkAdapter.ianaInterfaceType
								) {
									case 6:
										t = l.ETHERNET;
										break;
									case 71:
										t = l.WIFI;
								}
							return t;
						},
					});
					t.exports = L;
				},
				function (t, n, e) {
					var r = e(0),
						i = e(5),
						o = e(4),
						s = e(18),
						a = e(3),
						u = e(13),
						c = e(6).Wn,
						f = e(8),
						l = e(12),
						d = e(10),
						_ = e(36),
						p = a.wn,
						h = a.bn,
						v = r.extend({}, i, {
							D: 'chromecast',
							T: u,
							N: c,
							Storage: f,
							R: d,
							k: l,
							O: !0,
							M: 6e4,
							F: function () {
								return void 0 === ns_.crm ? p : ns_.crm.getApplicationData().name;
							},
							V: function () {
								return +new Date() + ~~(1e3 * Math.random());
							},
							G: function () {
								return '72';
							},
							j: function () {
								return 'chromecast';
							},
							H: function () {
								return cast.receiver.VERSION + '-' + o.Cn(s.Re() + ' ' + s.ke(), p);
							},
							J: function () {
								return 'html';
							},
							Y: function () {
								return '5';
							},
							X: function () {
								var t =
										'undefined' != typeof window &&
										o.Ln(window.screen) &&
										o.Ln(window.screen.availWidth)
											? window.screen.availWidth
											: 0,
									n =
										'undefined' != typeof window &&
										o.Ln(window.screen) &&
										o.Ln(window.screen.availHeight)
											? window.screen.availHeight
											: 0;
								return t > 0 && n > 0 ? t + 'x' + n : h;
							},
							nn: function () {
								return (
									('undefined' != typeof window &&
										o.Ln(window.navigator) &&
										o.Cn(window.navigator.language, '')) ||
									p
								);
							},
							vn: function (t) {
								_.cr(t);
							},
							_n: function (t) {
								_.lr(t);
							},
						});
					t.exports = v;
				},
				function (t, n, e) {
					var r = e(0),
						i = e(5),
						o = e(4),
						s = e(3),
						a = e(6).xn,
						u = e(6).Wn,
						c = e(8),
						f = e(12),
						l = e(10),
						d = s.bn,
						_ = null,
						p = null,
						h = null;
					function v() {
						null == _ &&
							(o.An(Device.vendorIdentifier)
								? ((_ = +new Date() + ~~(1e3 * Math.random())), (p = '72'))
								: ((_ = Device.vendorIdentifier), (p = '62')),
							(h = _));
					}
					var g = r.extend({}, i, {
						D: 'tvos',
						T: a,
						N: u,
						Storage: c,
						O: !0,
						R: l,
						k: f,
						M: 6e4,
						W: function () {
							return v(), h;
						},
						F: function () {
							return Device.appIdentifier;
						},
						B: function () {
							return Device.appVersion;
						},
						V: function () {
							return v(), _;
						},
						G: function () {
							return p;
						},
						j: function () {
							return Device.productType;
						},
						H: function () {
							return Device.systemVersion;
						},
						J: function () {
							return 'tvos';
						},
						Y: function () {
							return Device.systemVersion;
						},
						X: function () {
							return d;
						},
						nn: function () {
							return Settings.language;
						},
						tn: function () {
							return Device.appIdentifier;
						},
					});
					t.exports = g;
				},
				function (t, n, e) {
					var r = e(0),
						i = e(5),
						o = e(4),
						s = e(3),
						a = e(6).xn,
						u = e(6).Wn,
						c = e(8),
						f = e(10),
						l = e(12),
						d = e(9),
						_ = s.wn,
						p = s.bn,
						h = null,
						v = null,
						g = null;
					function E() {
						if (null == h)
							if ('undefined' != typeof jsmaf && o.Ln(jsmaf.hardwareId)) {
								var t = jsmaf.hardwareId;
								(h = t), (v = '31'), (g = t);
							} else (h = +new Date() + ~~(1e3 * Math.random())), (v = '72'), (g = null);
					}
					var m = r.extend({}, i, {
						D: 'jsmaf',
						T: a,
						N: u,
						Storage: c,
						O: !0,
						R: f,
						k: l,
						M: 6e4,
						W: function () {
							return E(), g;
						},
						V: function () {
							return E(), h;
						},
						G: function () {
							return E(), v;
						},
						j: function () {
							return 'undefined' != typeof jsmaf && o.Ln(jsmaf.platform) ? jsmaf.platform : _;
						},
						J: function () {
							return 'jsmaf';
						},
						Y: function () {
							return 'undefined' != typeof jsmaf && o.Ln(jsmaf.version) ? jsmaf.version : _;
						},
						X: function () {
							return 'undefined' != typeof jsmaf &&
								o.Ln(jsmaf.screenWidth) &&
								o.Ln(jsmaf.screenHeight)
								? jsmaf.screenWidth + 'x' + jsmaf.screenHeight
								: p;
						},
						nn: function () {
							return 'undefined' != typeof jsmaf && o.Ln(jsmaf.locale) ? jsmaf.locale : _;
						},
						setTimeout: function (t, n) {
							return jsmaf.setTimeout(t, n);
						},
						setInterval: function (t, n) {
							return jsmaf.setInterval(t, n);
						},
						clearTimeout: function (t) {
							return jsmaf.clearTimeout(t);
						},
						clearInterval: function (t) {
							return jsmaf.clearInterval(t);
						},
						en: function () {
							if ('undefined' == typeof jsmaf || !o.Ln(jsmaf.platform)) var t = _;
							return (
								'ps3' == jsmaf.platform
									? (t = 'cell')
									: 'ps4' == jsmaf.platform
									? (t = 'ps4')
									: 'vita' == jsmaf.platform && (t = 'vita'),
								t
							);
						},
						in: function () {
							return 'undefined' != typeof jsmaf &&
								'connected' == jsmaf.networkStatus &&
								o.Ln(jsmaf.connectionType)
								? 'wired' == jsmaf.connectionType
									? d.ETHERNET
									: 'wireless' == jsmaf.connectionType
									? d.WIFI
									: 'phone' == jsmaf.connectionType
									? d.WWAN
									: d.UNKNOWN
								: d.UNKNOWN;
						},
					});
					t.exports = m;
				},
				function (t, n, e) {
					var r = e(0),
						i = e(5),
						o = e(4),
						s = e(3).wn,
						a = null,
						u = null,
						c = null;
					function f() {
						null == a && ((a = +new Date() + ~~(1e3 * Math.random())), (u = '72'), (c = null));
					}
					function l(t, n) {
						var r = this,
							i = 0;
						r.send = function (o) {
							var s = o.match(/^https/) ? e(77) : e(78),
								a = (function (t) {
									var n = e(22);
									return {
										headers: {
											'User-Agent':
												(t.F() || 'unknown') +
												'/' +
												(t.B() || 'unknown') +
												' (' +
												(n.platform() || 'unknown') +
												'; ' +
												(n.arch() || 'unknown') +
												'; ' +
												(n.type() || 'unknown') +
												'/' +
												(n.release || 'unknown') +
												') node.js/' +
												process.version,
										},
									};
								})(n);
							s.get(o, a, function (n) {
								var e = n.statusCode;
								if ((302 == e || 301 == e) && i < 20 && n.headers && n.headers.location)
									return i++, void r.send(n.headers.location);
								t(e);
							}).on('error', function () {
								t();
							});
						};
					}
					var d = r.extend({}, i, {
						D: 'nodejs',
						T: function (t, n, e) {
							new l(n, e).send(t);
						},
						N: null,
						Storage: null,
						O: !1,
						W: function () {
							return f(), c;
						},
						V: function () {
							return f(), a;
						},
						G: function () {
							return u;
						},
						H: function () {
							var t = e(22);
							return [t.type(), t.platform(), t.release()].join(';');
						},
						en: function () {
							return e(22).arch() || s;
						},
						J: function () {
							return 'nodejs';
						},
						Y: function () {
							return 'undefined' == typeof process || o.An(process.version) ? s : process.version;
						},
					});
					t.exports = d;
				},
				function (t, n) {
					t.exports = s;
				},
				function (t, n) {
					t.exports = a;
				},
				function (t, n, e) {
					var r = e(0),
						i = e(37),
						o = r.extend({}, i, {
							D: 'webbrowser',
							C: function () {
								return e(17).ye().ne()
									? 'http://b.scorecardresearch.com/p'
									: 'http://b.scorecardresearch.com/p2';
							},
							L: function () {
								return e(17).ye().ne()
									? 'https://sb.scorecardresearch.com/p'
									: 'https://sb.scorecardresearch.com/p2';
							},
							Storage: null,
							R: null,
							k: null,
							O: !1,
							J: function () {
								return 'webbrowser';
							},
							un: function (t) {
								if ('undefined' != typeof document) {
									var n = document;
									(t.c7 = n.URL),
										(t.c8 = n.title),
										(t.c9 = n.referrer),
										(t.ns_c = n.characterSet || n.defaultCharset || '');
								}
							},
							pn: !0,
						});
					t.exports = o;
				},
				function (t, n, e) {
					var r = e(0),
						i = e(2).yn;
					t.exports = function (t, n, e, o) {
						function s(t) {
							200 == t || (o.isHttpRedirectCachingEnabled() && (302 == t || 301 == t))
								? e.onSuccess()
								: e.onFailure();
						}
						r.extend(this, {
							nr: function () {
								i.N ? i.N(t, n, s) : s();
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0);
					t.exports = function () {
						r.extend(this, {
							storeProperties: function () {},
							getProperties: function () {
								return null;
							},
							storeCache: function () {},
							getCache: function () {
								return null;
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(39),
						o = e(42),
						s = e(25),
						a = e(24),
						u = e(27);
					t.exports = function (t) {
						r.extend(this, {
							setLiveTransmissionMode: t.setLiveTransmissionMode,
							setOfflineCacheMode: t.setOfflineCacheMode,
							enableImplementationValidationMode: t.enableImplementationValidationMode,
							getPartnerConfigurations: t.getPartnerConfigurations,
							getPublisherConfigurations: t.getPublisherConfigurations,
							setLabelOrder: t.setLabelOrder,
							getLabelOrder: t.getLabelOrder,
							setApplicationName: t.setApplicationName,
							setApplicationVersion: t.setApplicationVersion,
							addStartLabels: t.addStartLabels,
							setStartLabel: t.setStartLabel,
							removeStartLabel: t.removeStartLabel,
							removeAllStartLabels: t.removeAllStartLabels,
							isEnabled: t.isEnabled,
							addPersistentLabels: t.addPersistentLabels,
							setPersistentLabel: t.setPersistentLabel,
							removePersistentLabel: t.removePersistentLabel,
							removeAllPersistentLabels: t.removeAllPersistentLabels,
							getPartnerConfiguration: t.getPartnerConfiguration,
							getPublisherConfiguration: t.getPublisherConfiguration,
							disable: t.disable,
							disableTcfIntegration: t.disableTcfIntegration,
							addListener: t.addListener,
							removeListener: t.removeListener,
							addClient: t.addClient,
							setDebugEnabled: t.setDebugEnabled,
							setSystemClockJumpDetectionEnabled: t.setSystemClockJumpDetectionEnabled,
							setSystemClockJumpDetectionInterval: t.setSystemClockJumpDetectionInterval,
							setSystemClockJumpDetectionAlternativeContextInterval:
								t.setSystemClockJumpDetectionAlternativeContextInterval,
							setSystemClockJumpDetectionPrecision: t.setSystemClockJumpDetectionPrecision,
							setLiveEndpointUrl: t.setLiveEndpointUrl,
							setOfflineFlushEndpointUrl: t.setOfflineFlushEndpointUrl,
							setCacheMaxMeasurements: t.setCacheMaxMeasurements,
							setCacheMaxFlushesInARow: t.setCacheMaxFlushesInARow,
							setCacheMinutesToRetry: t.setCacheMinutesToRetry,
							setCacheMeasurementExpiry: t.setCacheMeasurementExpiry,
							setUsagePropertiesAutoUpdateMode: t.setUsagePropertiesAutoUpdateMode,
							setUsagePropertiesAutoUpdateInterval: t.setUsagePropertiesAutoUpdateInterval,
							setStorageWriteInterval: t.setStorageWriteInterval,
							addIncludedPublisher: t.addIncludedPublisher,
							addCrossPublisherUniqueDeviceIdChangeListener:
								t.addCrossPublisherUniqueDeviceIdChangeListener,
							removeCrossPublisherUniqueDeviceIdChangeListener:
								t.removeCrossPublisherUniqueDeviceIdChangeListener,
							enableChildDirectedApplicationMode: t.enableChildDirectedApplicationMode,
							PartnerConfiguration: i,
							PublisherConfiguration: o,
							LiveTransmissionMode: s,
							CacheMode: a,
							UsagePropertiesAutoUpdateMode: u,
						});
					};
				},
				function (t, n, e) {
					var r = e(3),
						i = {};
					(i.encrypt = (function () {
						var t = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/',
							n = function (n, e) {
								var r = t.indexOf(n.charAt(e));
								if (-1 === r) throw new Error();
								return r;
							},
							e = function (t) {
								var e,
									r,
									i,
									o = (t = '' + t).length;
								if (0 === o) return t;
								if (o % 4 != 0) throw new Error();
								(e = 0),
									'=' === t.charAt(o - 1) &&
										((e = 1), '=' === t.charAt(o - 2) && (e = 2), (o -= 4));
								var s = [];
								for (r = 0; r < o; r += 4)
									(i = (n(t, r) << 18) | (n(t, r + 1) << 12) | (n(t, r + 2) << 6) | n(t, r + 3)),
										s.push(String.fromCharCode(i >> 16, (i >> 8) & 255, 255 & i));
								switch (e) {
									case 1:
										(i = (n(t, r) << 18) | (n(t, r + 1) << 12) | (n(t, r + 2) << 6)),
											s.push(String.fromCharCode(i >> 16, (i >> 8) & 255));
										break;
									case 2:
										(i = (n(t, r) << 18) | (n(t, r + 1) << 12)),
											s.push(String.fromCharCode(i >> 16));
								}
								return s.join('');
							};
						function i(t, n, e) {
							null != t &&
								('number' == typeof t
									? this.Ns(t, n, e)
									: null == n && 'string' != typeof t
									? this.Os(t, 256)
									: this.Os(t, n));
						}
						function o() {
							return new i(null);
						}
						(i.prototype.Rs = function (t, n, e, r, i, o) {
							for (var s = 16383 & n, a = n >> 14; --o >= 0; ) {
								var u = 16383 & this[t],
									c = this[t++] >> 14,
									f = a * u + c * s;
								(i = ((u = s * u + ((16383 & f) << 14) + e[r] + i) >> 28) + (f >> 14) + a * c),
									(e[r++] = 268435455 & u);
							}
							return i;
						}),
							(i.prototype.ks = 28),
							(i.prototype.Ms = 268435455),
							(i.prototype.Us = 1 << 28),
							(i.prototype.xs = Math.pow(2, 52)),
							(i.prototype.Ws = 24),
							(i.prototype.Fs = 4);
						var s,
							a,
							u = [];
						for (s = '0'.charCodeAt(0), a = 0; a <= 9; ++a) u[s++] = a;
						for (s = 'a'.charCodeAt(0), a = 10; a < 36; ++a) u[s++] = a;
						for (s = 'A'.charCodeAt(0), a = 10; a < 36; ++a) u[s++] = a;
						function c(t) {
							return '0123456789abcdefghijklmnopqrstuvwxyz'.charAt(t);
						}
						function f(t, n) {
							var e = u[t.charCodeAt(n)];
							return null == e ? -1 : e;
						}
						function l(t) {
							var n = o();
							return n.Bs(t), n;
						}
						function d(t) {
							var n,
								e = 1;
							return (
								0 != (n = t >>> 16) && ((t = n), (e += 16)),
								0 != (n = t >> 8) && ((t = n), (e += 8)),
								0 != (n = t >> 4) && ((t = n), (e += 4)),
								0 != (n = t >> 2) && ((t = n), (e += 2)),
								0 != (n = t >> 1) && ((t = n), (e += 1)),
								e
							);
						}
						function _(t) {
							this.o = t;
						}
						function p(t) {
							(this.o = t),
								(this.Vs = t.qs()),
								(this.Gs = 32767 & this.Vs),
								(this.js = this.Vs >> 15),
								(this.Hs = (1 << (t.ks - 15)) - 1),
								(this.Ks = 2 * t._);
						}
						function h(t, n) {
							return t & n;
						}
						function v(t, n) {
							return t | n;
						}
						function g(t, n) {
							return t ^ n;
						}
						function E(t, n) {
							return t & ~n;
						}
						function m(t) {
							if (0 == t) return -1;
							var n = 0;
							return (
								0 == (65535 & t) && ((t >>= 16), (n += 16)),
								0 == (255 & t) && ((t >>= 8), (n += 8)),
								0 == (15 & t) && ((t >>= 4), (n += 4)),
								0 == (3 & t) && ((t >>= 2), (n += 2)),
								0 == (1 & t) && ++n,
								n
							);
						}
						function A(t) {
							for (var n = 0; 0 != t; ) (t &= t - 1), ++n;
							return n;
						}
						function L() {}
						function y(t) {
							return t;
						}
						function I(t) {
							(this.r2 = o()),
								(this.Js = o()),
								i.ONE.Ys(2 * t._, this.r2),
								(this.Xs = this.r2.zs(t)),
								(this.o = t);
						}
						(_.prototype.Qs = function (t) {
							return t.s < 0 || t.$s(this.o) >= 0 ? t.Zs(this.o) : t;
						}),
							(_.prototype.na = function (t) {
								return t;
							}),
							(_.prototype.reduce = function (t) {
								t.ta(this.o, null, t);
							}),
							(_.prototype.ea = function (t, n, e) {
								t.ia(n, e), this.reduce(e);
							}),
							(_.prototype.ra = function (t, n) {
								t.oa(n), this.reduce(n);
							}),
							(p.prototype.Qs = function (t) {
								var n = o();
								return (
									t.abs().Ys(this.o._, n),
									n.ta(this.o, null, n),
									t.s < 0 && n.$s(i.ZERO) > 0 && this.o.ua(n, n),
									n
								);
							}),
							(p.prototype.na = function (t) {
								var n = o();
								return t.sa(n), this.reduce(n), n;
							}),
							(p.prototype.reduce = function (t) {
								for (; t._ <= this.Ks; ) t[t._++] = 0;
								for (var n = 0; n < this.o._; ++n) {
									var e = 32767 & t[n],
										r =
											(e * this.Gs + (((e * this.js + (t[n] >> 15) * this.Gs) & this.Hs) << 15)) &
											t.Ms;
									for (t[(e = n + this.o._)] += this.o.Rs(0, r, t, n, 0, this.o._); t[e] >= t.Us; )
										(t[e] -= t.Us), t[++e]++;
								}
								t.aa(), t.fa(this.o._, t), t.$s(this.o) >= 0 && t.ua(this.o, t);
							}),
							(p.prototype.ea = function (t, n, e) {
								t.ia(n, e), this.reduce(e);
							}),
							(p.prototype.ra = function (t, n) {
								t.oa(n), this.reduce(n);
							}),
							(i.prototype.sa = function (t) {
								for (var n = this._ - 1; n >= 0; --n) t[n] = this[n];
								(t._ = this._), (t.s = this.s);
							}),
							(i.prototype.Bs = function (t) {
								(this._ = 1),
									(this.s = t < 0 ? -1 : 0),
									t > 0 ? (this[0] = t) : t < -1 ? (this[0] = t + DV) : (this._ = 0);
							}),
							(i.prototype.Os = function (t, n) {
								var e;
								if (16 == n) e = 4;
								else if (8 == n) e = 3;
								else if (256 == n) e = 8;
								else if (2 == n) e = 1;
								else if (32 == n) e = 5;
								else {
									if (4 != n) return void this.ca(t, n);
									e = 2;
								}
								(this._ = 0), (this.s = 0);
								for (var r = t.length, o = !1, s = 0; --r >= 0; ) {
									var a = 8 == e ? 255 & t[r] : f(t, r);
									a < 0
										? '-' == t.charAt(r) && (o = !0)
										: ((o = !1),
										  0 == s
												? (this[this._++] = a)
												: s + e > this.ks
												? ((this[this._ - 1] |= (a & ((1 << (this.ks - s)) - 1)) << s),
												  (this[this._++] = a >> (this.ks - s)))
												: (this[this._ - 1] |= a << s),
										  (s += e) >= this.ks && (s -= this.ks));
								}
								8 == e &&
									0 != (128 & t[0]) &&
									((this.s = -1), s > 0 && (this[this._ - 1] |= ((1 << (this.ks - s)) - 1) << s)),
									this.aa(),
									o && i.ZERO.ua(this, this);
							}),
							(i.prototype.aa = function () {
								for (var t = this.s & this.Ms; this._ > 0 && this[this._ - 1] == t; ) --this._;
							}),
							(i.prototype.Ys = function (t, n) {
								var e;
								for (e = this._ - 1; e >= 0; --e) n[e + t] = this[e];
								for (e = t - 1; e >= 0; --e) n[e] = 0;
								(n._ = this._ + t), (n.s = this.s);
							}),
							(i.prototype.fa = function (t, n) {
								for (var e = t; e < this._; ++e) n[e - t] = this[e];
								(n._ = Math.max(this._ - t, 0)), (n.s = this.s);
							}),
							(i.prototype.la = function (t, n) {
								var e,
									r = t % this.ks,
									i = this.ks - r,
									o = (1 << i) - 1,
									s = Math.floor(t / this.ks),
									a = (this.s << r) & this.Ms;
								for (e = this._ - 1; e >= 0; --e)
									(n[e + s + 1] = (this[e] >> i) | a), (a = (this[e] & o) << r);
								for (e = s - 1; e >= 0; --e) n[e] = 0;
								(n[s] = a), (n._ = this._ + s + 1), (n.s = this.s), n.aa();
							}),
							(i.prototype.da = function (t, n) {
								n.s = this.s;
								var e = Math.floor(t / this.ks);
								if (e >= this._) n._ = 0;
								else {
									var r = t % this.ks,
										i = this.ks - r,
										o = (1 << r) - 1;
									n[0] = this[e] >> r;
									for (var s = e + 1; s < this._; ++s)
										(n[s - e - 1] |= (this[s] & o) << i), (n[s - e] = this[s] >> r);
									r > 0 && (n[this._ - e - 1] |= (this.s & o) << i), (n._ = this._ - e), n.aa();
								}
							}),
							(i.prototype.ua = function (t, n) {
								for (var e = 0, r = 0, i = Math.min(t._, this._); e < i; )
									(r += this[e] - t[e]), (n[e++] = r & this.Ms), (r >>= this.ks);
								if (t._ < this._) {
									for (r -= t.s; e < this._; )
										(r += this[e]), (n[e++] = r & this.Ms), (r >>= this.ks);
									r += this.s;
								} else {
									for (r += this.s; e < t._; ) (r -= t[e]), (n[e++] = r & this.Ms), (r >>= this.ks);
									r -= t.s;
								}
								(n.s = r < 0 ? -1 : 0),
									r < -1 ? (n[e++] = this.Us + r) : r > 0 && (n[e++] = r),
									(n._ = e),
									n.aa();
							}),
							(i.prototype.ia = function (t, n) {
								var e = this.abs(),
									r = t.abs(),
									o = e._;
								for (n._ = o + r._; --o >= 0; ) n[o] = 0;
								for (o = 0; o < r._; ++o) n[o + e._] = e.Rs(0, r[o], n, o, 0, e._);
								(n.s = 0), n.aa(), this.s != t.s && i.ZERO.ua(n, n);
							}),
							(i.prototype.oa = function (t) {
								for (var n = this.abs(), e = (t._ = 2 * n._); --e >= 0; ) t[e] = 0;
								for (e = 0; e < n._ - 1; ++e) {
									var r = n.Rs(e, n[e], t, 2 * e, 0, 1);
									(t[e + n._] += n.Rs(e + 1, 2 * n[e], t, 2 * e + 1, r, n._ - e - 1)) >= n.Us &&
										((t[e + n._] -= n.Us), (t[e + n._ + 1] = 1));
								}
								t._ > 0 && (t[t._ - 1] += n.Rs(e, n[e], t, 2 * e, 0, 1)), (t.s = 0), t.aa();
							}),
							(i.prototype.ta = function (t, n, e) {
								var r = t.abs();
								if (!(r._ <= 0)) {
									var s = this.abs();
									if (s._ < r._) return null != n && n.Bs(0), void (null != e && this.sa(e));
									null == e && (e = o());
									var a = o(),
										u = this.s,
										c = t.s,
										f = this.ks - d(r[r._ - 1]);
									f > 0 ? (r.la(f, a), s.la(f, e)) : (r.sa(a), s.sa(e));
									var l = a._,
										_ = a[l - 1];
									if (0 != _) {
										var p = _ * (1 << this.Ws) + (l > 1 ? a[l - 2] >> this.Fs : 0),
											h = this.xs / p,
											v = (1 << this.Ws) / p,
											g = 1 << this.Fs,
											E = e._,
											m = E - l,
											A = null == n ? o() : n;
										for (
											a.Ys(m, A),
												e.$s(A) >= 0 && ((e[e._++] = 1), e.ua(A, e)),
												i.ONE.Ys(l, A),
												A.ua(a, a);
											a._ < l;

										)
											a[a._++] = 0;
										for (; --m >= 0; ) {
											var L = e[--E] == _ ? this.Ms : Math.floor(e[E] * h + (e[E - 1] + g) * v);
											if ((e[E] += a.Rs(0, L, e, m, 0, l)) < L)
												for (a.Ys(m, A), e.ua(A, e); e[E] < --L; ) e.ua(A, e);
										}
										null != n && (e.fa(l, n), u != c && i.ZERO.ua(n, n)),
											(e._ = l),
											e.aa(),
											f > 0 && e.da(f, e),
											u < 0 && i.ZERO.ua(e, e);
									}
								}
							}),
							(i.prototype.qs = function () {
								if (this._ < 1) return 0;
								var t = this[0];
								if (0 == (1 & t)) return 0;
								var n = 3 & t;
								return (n =
									((n =
										((n = ((n = (n * (2 - (15 & t) * n)) & 15) * (2 - (255 & t) * n)) & 255) *
											(2 - (((65535 & t) * n) & 65535))) &
										65535) *
										(2 - ((t * n) % this.Us))) %
									this.Us) > 0
									? this.Us - n
									: -n;
							}),
							(i.prototype.va = function () {
								return 0 == (this._ > 0 ? 1 & this[0] : this.s);
							}),
							(i.prototype.exp = function (t, n) {
								if (t > 4294967295 || t < 1) return i.ONE;
								var e = o(),
									r = o(),
									s = n.Qs(this),
									a = d(t) - 1;
								for (s.sa(e); --a >= 0; )
									if ((n.ra(e, r), (t & (1 << a)) > 0)) n.ea(r, s, e);
									else {
										var u = e;
										(e = r), (r = u);
									}
								return n.na(e);
							}),
							(i.prototype.toString = function (t) {
								if (this.s < 0) return '-' + this._a().toString(t);
								var n;
								if (16 == t) n = 4;
								else if (8 == t) n = 3;
								else if (2 == t) n = 1;
								else if (32 == t) n = 5;
								else {
									if (4 != t) return this.pa(t);
									n = 2;
								}
								var e,
									r = (1 << n) - 1,
									i = !1,
									o = '',
									s = this._,
									a = this.ks - ((s * this.ks) % n);
								if (s-- > 0)
									for (a < this.ks && (e = this[s] >> a) > 0 && ((i = !0), (o = c(e))); s >= 0; )
										a < n
											? ((e = (this[s] & ((1 << a) - 1)) << (n - a)),
											  (e |= this[--s] >> (a += this.ks - n)))
											: ((e = (this[s] >> (a -= n)) & r), a <= 0 && ((a += this.ks), --s)),
											e > 0 && (i = !0),
											i && (o += c(e));
								return i ? o : '0';
							}),
							(i.prototype._a = function () {
								var t = o();
								return i.ZERO.ua(this, t), t;
							}),
							(i.prototype.abs = function () {
								return this.s < 0 ? this._a() : this;
							}),
							(i.prototype.$s = function (t) {
								var n = this.s - t.s;
								if (0 != n) return n;
								var e = this._;
								if (0 != (n = e - t._)) return this.s < 0 ? -n : n;
								for (; --e >= 0; ) if (0 != (n = this[e] - t[e])) return n;
								return 0;
							}),
							(i.prototype.ha = function () {
								return this._ <= 0
									? 0
									: this.ks * (this._ - 1) + d(this[this._ - 1] ^ (this.s & this.Ms));
							}),
							(i.prototype.Zs = function (t) {
								var n = o();
								return this.abs().ta(t, null, n), this.s < 0 && n.$s(i.ZERO) > 0 && t.ua(n, n), n;
							}),
							(i.prototype.ga = function (t, n) {
								var e;
								return (e = t < 256 || n.va() ? new _(n) : new p(n)), this.exp(t, e);
							}),
							(i.ZERO = l(0)),
							(i.ONE = l(1)),
							(L.prototype.Qs = y),
							(L.prototype.na = y),
							(L.prototype.ea = function (t, n, e) {
								t.ia(n, e);
							}),
							(L.prototype.ra = function (t, n) {
								t.oa(n);
							}),
							(I.prototype.Qs = function (t) {
								if (t.s < 0 || t._ > 2 * this.o._) return t.Zs(this.o);
								if (t.$s(this.o) < 0) return t;
								var n = o();
								return t.sa(n), this.reduce(n), n;
							}),
							(I.prototype.na = function (t) {
								return t;
							}),
							(I.prototype.reduce = function (t) {
								for (
									t.fa(this.o._ - 1, this.r2),
										t._ > this.o._ + 1 && ((t._ = this.o._ + 1), t.aa()),
										this.Xs.ma(this.r2, this.o._ + 1, this.Js),
										this.o.ya(this.Js, this.o._ + 1, this.r2);
									t.$s(this.r2) < 0;

								)
									t.Sa(1, this.o._ + 1);
								for (t.ua(this.r2, t); t.$s(this.o) >= 0; ) t.ua(this.o, t);
							}),
							(I.prototype.ea = function (t, n, e) {
								t.ia(n, e), this.reduce(e);
							}),
							(I.prototype.ra = function (t, n) {
								t.oa(n), this.reduce(n);
							});
						var b,
							P,
							N,
							S = [
								2, 3, 5, 7, 11, 13, 17, 19, 23, 29, 31, 37, 41, 43, 47, 53, 59, 61, 67, 71, 73, 79,
								83, 89, 97, 101, 103, 107, 109, 113, 127, 131, 137, 139, 149, 151, 157, 163, 167,
								173, 179, 181, 191, 193, 197, 199, 211, 223, 227, 229, 233, 239, 241, 251, 257, 263,
								269, 271, 277, 281, 283, 293, 307, 311, 313, 317, 331, 337, 347, 349, 353, 359, 367,
								373, 379, 383, 389, 397, 401, 409, 419, 421, 431, 433, 439, 443, 449, 457, 461, 463,
								467, 479, 487, 491, 499, 503, 509, 521, 523, 541, 547, 557, 563, 569, 571, 577, 587,
								593, 599, 601, 607, 613, 617, 619, 631, 641, 643, 647, 653, 659, 661, 673, 677, 683,
								691, 701, 709, 719, 727, 733, 739, 743, 751, 757, 761, 769, 773, 787, 797, 809, 811,
								821, 823, 827, 829, 839, 853, 857, 859, 863, 877, 881, 883, 887, 907, 911, 919, 929,
								937, 941, 947, 953, 967, 971, 977, 983, 991, 997,
							],
							w = (1 << 26) / S[S.length - 1];
						function D() {
							(this.t = 0), (this.wa = 0), (this.ba = []);
						}
						function C() {
							var t;
							(t = new Date().getTime()),
								(P[N++] ^= 255 & t),
								(P[N++] ^= (t >> 8) & 255),
								(P[N++] ^= (t >> 16) & 255),
								(P[N++] ^= (t >> 24) & 255),
								N >= 256 && (N -= 256);
						}
						if (
							((i.prototype.Ia = function (t) {
								return Math.floor((Math.LN2 * this.ks) / Math.log(t));
							}),
							(i.prototype.pa = function (t) {
								if ((null == t && (t = 10), 0 == this.Ea() || t < 2 || t > 36)) return '0';
								var n = this.Ia(t),
									e = Math.pow(t, n),
									r = l(e),
									i = o(),
									s = o(),
									a = '';
								for (this.ta(r, i, s); i.Ea() > 0; )
									(a = (e + s.Pa()).toString(t).substr(1) + a), i.ta(r, i, s);
								return s.Pa().toString(t) + a;
							}),
							(i.prototype.ca = function (t, n) {
								this.Bs(0), null == n && (n = 10);
								for (
									var e = this.Ia(n), r = Math.pow(n, e), o = !1, s = 0, a = 0, u = 0;
									u < t.length;
									++u
								) {
									var c = f(t, u);
									c < 0
										? '-' == t.charAt(u) && 0 == this.Ea() && (o = !0)
										: ((a = n * a + c), ++s >= e && (this.Aa(r), this.Sa(a, 0), (s = 0), (a = 0)));
								}
								s > 0 && (this.Aa(Math.pow(n, s)), this.Sa(a, 0)), o && i.ZERO.ua(this, this);
							}),
							(i.prototype.Ns = function (t, n, e) {
								if ('number' == typeof n)
									if (t < 2) this.Bs(1);
									else
										for (
											this.Ns(t, e),
												this.Da(t - 1) || this.Ca(i.ONE.shiftLeft(t - 1), v, this),
												this.va() && this.Sa(1, 0);
											!this.La(n);

										)
											this.Sa(2, 0), this.ha() > t && this.ua(i.ONE.shiftLeft(t - 1), this);
								else {
									var r = [],
										o = 7 & t;
									(r.length = 1 + (t >> 3)),
										n.Ta(r),
										o > 0 ? (r[0] &= (1 << o) - 1) : (r[0] = 0),
										this.Os(r, 256);
								}
							}),
							(i.prototype.Ca = function (t, n, e) {
								var r,
									i,
									o = Math.min(t._, this._);
								for (r = 0; r < o; ++r) e[r] = n(this[r], t[r]);
								if (t._ < this._) {
									for (i = t.s & this.Ms, r = o; r < this._; ++r) e[r] = n(this[r], i);
									e._ = this._;
								} else {
									for (i = this.s & this.Ms, r = o; r < t._; ++r) e[r] = n(i, t[r]);
									e._ = t._;
								}
								(e.s = n(this.s, t.s)), e.aa();
							}),
							(i.prototype.Na = function (t, n) {
								var e = i.ONE.shiftLeft(t);
								return this.Ca(e, n, e), e;
							}),
							(i.prototype.Oa = function (t, n) {
								for (var e = 0, r = 0, i = Math.min(t._, this._); e < i; )
									(r += this[e] + t[e]), (n[e++] = r & this.Ms), (r >>= this.ks);
								if (t._ < this._) {
									for (r += t.s; e < this._; )
										(r += this[e]), (n[e++] = r & this.Ms), (r >>= this.ks);
									r += this.s;
								} else {
									for (r += this.s; e < t._; ) (r += t[e]), (n[e++] = r & this.Ms), (r >>= this.ks);
									r += t.s;
								}
								(n.s = r < 0 ? -1 : 0),
									r > 0 ? (n[e++] = r) : r < -1 && (n[e++] = this.Us + r),
									(n._ = e),
									n.aa();
							}),
							(i.prototype.Aa = function (t) {
								(this[this._] = this.Rs(0, t - 1, this, 0, 0, this._)), ++this._, this.aa();
							}),
							(i.prototype.Sa = function (t, n) {
								if (0 != t) {
									for (; this._ <= n; ) this[this._++] = 0;
									for (this[n] += t; this[n] >= this.Us; )
										(this[n] -= this.Us), ++n >= this._ && (this[this._++] = 0), ++this[n];
								}
							}),
							(i.prototype.ya = function (t, n, e) {
								var r,
									i = Math.min(this._ + t._, n);
								for (e.s = 0, e._ = i; i > 0; ) e[--i] = 0;
								for (r = e._ - this._; i < r; ++i)
									e[i + this._] = this.Rs(0, t[i], e, i, 0, this._);
								for (r = Math.min(t._, n); i < r; ++i) this.Rs(0, t[i], e, i, 0, n - i);
								e.aa();
							}),
							(i.prototype.ma = function (t, n, e) {
								--n;
								var r = (e._ = this._ + t._ - n);
								for (e.s = 0; --r >= 0; ) e[r] = 0;
								for (r = Math.max(n - this._, 0); r < t._; ++r)
									e[this._ + r - n] = this.Rs(n - r, t[r], e, 0, 0, this._ + r - n);
								e.aa(), e.fa(1, e);
							}),
							(i.prototype.Ra = function (t) {
								if (t <= 0) return 0;
								var n = this.Us % t,
									e = this.s < 0 ? t - 1 : 0;
								if (this._ > 0)
									if (0 == n) e = this[0] % t;
									else for (var r = this._ - 1; r >= 0; --r) e = (n * e + this[r]) % t;
								return e;
							}),
							(i.prototype.ka = function (t) {
								var n = this.Ma(i.ONE),
									e = n.Ua();
								if (e <= 0) return !1;
								var r = n.xa(e);
								(t = (t + 1) >> 1) > S.length && (t = S.length);
								for (var s = o(), a = 0; a < t; ++a) {
									s.Bs(S[Math.floor(Math.random() * S.length)]);
									var u = s.Wa(r, this);
									if (0 != u.$s(i.ONE) && 0 != u.$s(n)) {
										for (var c = 1; c++ < e && 0 != u.$s(n); )
											if (0 == (u = u.ga(2, this)).$s(i.ONE)) return !1;
										if (0 != u.$s(n)) return !1;
									}
								}
								return !0;
							}),
							(i.prototype.clone = function () {
								var t = o();
								return this.sa(t), t;
							}),
							(i.prototype.Pa = function () {
								if (this.s < 0) {
									if (1 == this._) return this[0] - this.Us;
									if (0 == this._) return -1;
								} else {
									if (1 == this._) return this[0];
									if (0 == this._) return 0;
								}
								return ((this[1] & ((1 << (32 - this.ks)) - 1)) << this.ks) | this[0];
							}),
							(i.prototype.Fa = function () {
								return 0 == this._ ? this.s : (this[0] << 24) >> 24;
							}),
							(i.prototype.Ba = function () {
								return 0 == this._ ? this.s : (this[0] << 16) >> 16;
							}),
							(i.prototype.Ea = function () {
								return this.s < 0 ? -1 : this._ <= 0 || (1 == this._ && this[0] <= 0) ? 0 : 1;
							}),
							(i.prototype.Va = function () {
								var t = this._,
									n = [];
								n[0] = this.s;
								var e,
									r = this.ks - ((t * this.ks) % 8),
									i = 0;
								if (t-- > 0)
									for (
										r < this.ks &&
										(e = this[t] >> r) != (this.s & this.Ms) >> r &&
										(n[i++] = e | (this.s << (this.ks - r)));
										t >= 0;

									)
										r < 8
											? ((e = (this[t] & ((1 << r) - 1)) << (8 - r)),
											  (e |= this[--t] >> (r += this.ks - 8)))
											: ((e = (this[t] >> (r -= 8)) & 255), r <= 0 && ((r += this.ks), --t)),
											0 != (128 & e) && (e |= -256),
											0 == i && (128 & this.s) != (128 & e) && ++i,
											(i > 0 || e != this.s) && (n[i++] = e);
								return n;
							}),
							(i.prototype.qa = function (t) {
								return 0 == this.$s(t);
							}),
							(i.prototype.min = function (t) {
								return this.$s(t) < 0 ? this : t;
							}),
							(i.prototype.max = function (t) {
								return this.$s(t) > 0 ? this : t;
							}),
							(i.prototype.Ga = function (t) {
								var n = o();
								return this.Ca(t, h, n), n;
							}),
							(i.prototype.ja = function (t) {
								var n = o();
								return this.Ca(t, v, n), n;
							}),
							(i.prototype.Ha = function (t) {
								var n = o();
								return this.Ca(t, g, n), n;
							}),
							(i.prototype.Ka = function (t) {
								var n = o();
								return this.Ca(t, E, n), n;
							}),
							(i.prototype.Ja = function () {
								for (var t = o(), n = 0; n < this._; ++n) t[n] = this.Ms & ~this[n];
								return (t._ = this._), (t.s = ~this.s), t;
							}),
							(i.prototype.shiftLeft = function (t) {
								var n = o();
								return t < 0 ? this.da(-t, n) : this.la(t, n), n;
							}),
							(i.prototype.xa = function (t) {
								var n = o();
								return t < 0 ? this.la(-t, n) : this.da(t, n), n;
							}),
							(i.prototype.Ua = function () {
								for (var t = 0; t < this._; ++t) if (0 != this[t]) return t * this.ks + m(this[t]);
								return this.s < 0 ? this._ * this.ks : -1;
							}),
							(i.prototype.Ya = function () {
								for (var t = 0, n = this.s & this.Ms, e = 0; e < this._; ++e) t += A(this[e] ^ n);
								return t;
							}),
							(i.prototype.Da = function (t) {
								var n = Math.floor(t / this.ks);
								return n >= this._ ? 0 != this.s : 0 != (this[n] & (1 << t % this.ks));
							}),
							(i.prototype.Xa = function (t) {
								return this.Na(t, v);
							}),
							(i.prototype.za = function (t) {
								return this.Na(t, E);
							}),
							(i.prototype.Qa = function (t) {
								return this.Na(t, g);
							}),
							(i.prototype.add = function (t) {
								var n = o();
								return this.Oa(t, n), n;
							}),
							(i.prototype.Ma = function (t) {
								var n = o();
								return this.ua(t, n), n;
							}),
							(i.prototype.multiply = function (t) {
								var n = o();
								return this.ia(t, n), n;
							}),
							(i.prototype.zs = function (t) {
								var n = o();
								return this.ta(t, n, null), n;
							}),
							(i.prototype.$a = function (t) {
								var n = o();
								return this.ta(t, null, n), n;
							}),
							(i.prototype.Za = function (t) {
								var n = o(),
									e = o();
								return this.ta(t, n, e), [n, e];
							}),
							(i.prototype.Wa = function (t, n) {
								var e,
									r,
									i = t.ha(),
									s = l(1);
								if (i <= 0) return s;
								(e = i < 18 ? 1 : i < 48 ? 3 : i < 144 ? 4 : i < 768 ? 5 : 6),
									(r = i < 8 ? new _(n) : n.va() ? new I(n) : new p(n));
								var a = [],
									u = 3,
									c = e - 1,
									f = (1 << e) - 1;
								if (((a[1] = r.Qs(this)), e > 1)) {
									var h = o();
									for (r.ra(a[1], h); u <= f; ) (a[u] = o()), r.ea(h, a[u - 2], a[u]), (u += 2);
								}
								var v,
									g,
									E = t._ - 1,
									m = !0,
									A = o();
								for (i = d(t[E]) - 1; E >= 0; ) {
									for (
										i >= c
											? (v = (t[E] >> (i - c)) & f)
											: ((v = (t[E] & ((1 << (i + 1)) - 1)) << (c - i)),
											  E > 0 && (v |= t[E - 1] >> (this.ks + i - c))),
											u = e;
										0 == (1 & v);

									)
										(v >>= 1), --u;
									if (((i -= u) < 0 && ((i += this.ks), --E), m)) a[v].sa(s), (m = !1);
									else {
										for (; u > 1; ) r.ra(s, A), r.ra(A, s), (u -= 2);
										u > 0 ? r.ra(s, A) : ((g = s), (s = A), (A = g)), r.ea(A, a[v], s);
									}
									for (; E >= 0 && 0 == (t[E] & (1 << i)); )
										r.ra(s, A), (g = s), (s = A), (A = g), --i < 0 && ((i = this.ks - 1), --E);
								}
								return r.na(s);
							}),
							(i.prototype.nf = function (t) {
								var n = t.va();
								if ((this.va() && n) || 0 == t.Ea()) return i.ZERO;
								for (
									var e = t.clone(), r = this.clone(), o = l(1), s = l(0), a = l(0), u = l(1);
									0 != e.Ea();

								) {
									for (; e.va(); )
										e.da(1, e),
											n
												? ((o.va() && s.va()) || (o.Oa(this, o), s.ua(t, s)), o.da(1, o))
												: s.va() || s.ua(t, s),
											s.da(1, s);
									for (; r.va(); )
										r.da(1, r),
											n
												? ((a.va() && u.va()) || (a.Oa(this, a), u.ua(t, u)), a.da(1, a))
												: u.va() || u.ua(t, u),
											u.da(1, u);
									e.$s(r) >= 0
										? (e.ua(r, e), n && o.ua(a, o), s.ua(u, s))
										: (r.ua(e, r), n && a.ua(o, a), u.ua(s, u));
								}
								return 0 != r.$s(i.ONE)
									? i.ZERO
									: u.$s(t) >= 0
									? u.Ma(t)
									: u.Ea() < 0
									? (u.Oa(t, u), u.Ea() < 0 ? u.add(t) : u)
									: u;
							}),
							(i.prototype.pow = function (t) {
								return this.exp(t, new L());
							}),
							(i.prototype.tf = function (t) {
								var n = this.s < 0 ? this._a() : this.clone(),
									e = t.s < 0 ? t._a() : t.clone();
								if (n.$s(e) < 0) {
									var r = n;
									(n = e), (e = r);
								}
								var i = n.Ua(),
									o = e.Ua();
								if (o < 0) return n;
								for (i < o && (o = i), o > 0 && (n.da(o, n), e.da(o, e)); n.Ea() > 0; )
									(i = n.Ua()) > 0 && n.da(i, n),
										(i = e.Ua()) > 0 && e.da(i, e),
										n.$s(e) >= 0 ? (n.ua(e, n), n.da(1, n)) : (e.ua(n, e), e.da(1, e));
								return o > 0 && e.la(o, e), e;
							}),
							(i.prototype.La = function (t) {
								var n,
									e = this.abs();
								if (1 == e._ && e[0] <= S[S.length - 1]) {
									for (n = 0; n < S.length; ++n) if (e[0] == S[n]) return !0;
									return !1;
								}
								if (e.va()) return !1;
								for (n = 1; n < S.length; ) {
									for (var r = S[n], i = n + 1; i < S.length && r < w; ) r *= S[i++];
									for (r = e.Ra(r); n < i; ) if (r % S[n++] == 0) return !1;
								}
								return e.ka(t);
							}),
							(i.prototype.ef = function () {
								var t = o();
								return this.oa(t), t;
							}),
							(D.prototype.init = function (t) {
								var n, e, r;
								for (n = 0; n < 256; ++n) this.ba[n] = n;
								for (e = 0, n = 0; n < 256; ++n)
									(e = (e + this.ba[n] + t[n % t.length]) & 255),
										(r = this.ba[n]),
										(this.ba[n] = this.ba[e]),
										(this.ba[e] = r);
								(this.t = 0), (this.wa = 0);
							}),
							(D.prototype.next = function () {
								var t;
								return (
									(this.t = (this.t + 1) & 255),
									(this.wa = (this.wa + this.ba[this.t]) & 255),
									(t = this.ba[this.t]),
									(this.ba[this.t] = this.ba[this.wa]),
									(this.ba[this.wa] = t),
									this.ba[(t + this.ba[this.t]) & 255]
								);
							}),
							null == P)
						) {
							var O;
							for (P = [], N = 0; N < 256; )
								(O = Math.floor(65536 * Math.random())), (P[N++] = O >>> 8), (P[N++] = 255 & O);
							(N = 0), C();
						}
						function R() {
							if (null == b) {
								for (C(), (b = new D()).init(P), N = 0; N < P.length; ++N) P[N] = 0;
								N = 0;
							}
							return b.next();
						}
						function x() {}
						function T(t, n) {
							return new i(t, n);
						}
						function U() {
							(this.n = null),
								(this.e = 0),
								(this.d = null),
								(this.p = null),
								(this.q = null),
								(this.dmp1 = null),
								(this.dmq1 = null),
								(this.coeff = null);
						}
						(x.prototype.Ta = function (t) {
							var n;
							for (n = 0; n < t.length; ++n) t[n] = R();
						}),
							(U.prototype.if = function (t) {
								return t.ga(this.e, this.n);
							}),
							(U.prototype.rf = function (t, n) {
								null != t && null != n && t.length > 0 && n.length > 0
									? ((this.n = T(t, 16)), (this.e = parseInt(n, 16)))
									: alert('Invalid RSA public key');
							}),
							(U.prototype.encrypt = function (t) {
								var n = (function (t, n) {
									if (n < t.length + 11) return alert('Message too long for RSA'), null;
									for (var e = [], r = t.length - 1; r >= 0 && n > 0; ) {
										var o = t.charCodeAt(r--);
										o < 128
											? (e[--n] = o)
											: o > 127 && o < 2048
											? ((e[--n] = (63 & o) | 128), (e[--n] = (o >> 6) | 192))
											: ((e[--n] = (63 & o) | 128),
											  (e[--n] = ((o >> 6) & 63) | 128),
											  (e[--n] = (o >> 12) | 224));
									}
									e[--n] = 0;
									for (var s = new x(), a = []; n > 2; ) {
										for (a[0] = 0; 0 == a[0]; ) s.Ta(a);
										e[--n] = a[0];
									}
									return (e[--n] = 2), (e[--n] = 0), new i(e);
								})(t, (this.n.ha() + 7) >> 3);
								if (null == n) return null;
								var e = this.if(n);
								if (null == e) return null;
								var r = e.toString(16);
								return 0 == (1 & r.length) ? r : '0' + r;
							}),
							(U.prototype.uf = function (t) {
								if (null == this.p || null == this.q) return t.Wa(this.d, this.n);
								for (
									var n = t.Zs(this.p).Wa(this.dmp1, this.p),
										e = t.Zs(this.q).Wa(this.dmq1, this.q);
									n.$s(e) < 0;

								)
									n = n.add(this.p);
								return n.Ma(e).multiply(this.coeff).Zs(this.p).multiply(this.q).add(e);
							}),
							(U.prototype.af = function (t, n, e) {
								null != t && null != n && t.length > 0 && n.length > 0
									? ((this.n = T(t, 16)), (this.e = parseInt(n, 16)), (this.d = T(e, 16)))
									: alert('Invalid RSA private key');
							}),
							(U.prototype.ff = function (t, n, e, r, i, o, s, a) {
								null != t && null != n && t.length > 0 && n.length > 0
									? ((this.n = T(t, 16)),
									  (this.e = parseInt(n, 16)),
									  (this.d = T(e, 16)),
									  (this.p = T(r, 16)),
									  (this.q = T(i, 16)),
									  (this.dmp1 = T(o, 16)),
									  (this.dmq1 = T(s, 16)),
									  (this.coeff = T(a, 16)))
									: alert('Invalid RSA private key');
							}),
							(U.prototype.cf = function (t, n) {
								var e = new x(),
									r = t >> 1;
								this.e = parseInt(n, 16);
								for (var o = new i(n, 16); ; ) {
									for (
										;
										(this.p = new i(t - r, 1, e)),
											0 != this.p.Ma(i.ONE).tf(o).$s(i.ONE) || !this.p.La(10);

									);
									for (
										;
										(this.q = new i(r, 1, e)),
											0 != this.q.Ma(i.ONE).tf(o).$s(i.ONE) || !this.q.La(10);

									);
									if (this.p.$s(this.q) <= 0) {
										var s = this.p;
										(this.p = this.q), (this.q = s);
									}
									var a = this.p.Ma(i.ONE),
										u = this.q.Ma(i.ONE),
										c = a.multiply(u);
									if (0 == c.tf(o).$s(i.ONE)) {
										(this.n = this.p.multiply(this.q)),
											(this.d = o.nf(c)),
											(this.dmp1 = this.d.Zs(a)),
											(this.dmq1 = this.d.Zs(u)),
											(this.coeff = this.q.nf(this.p));
										break;
									}
								}
							}),
							(U.prototype.decrypt = function (t) {
								var n = T(t, 16),
									e = this.uf(n);
								return null == e
									? null
									: (function (t, n) {
											for (var e = t.Va(), r = 0; r < e.length && 0 == e[r]; ) ++r;
											if (e.length - r != n - 1 || 2 != e[r]) return null;
											for (++r; 0 != e[r]; ) if (++r >= e.length) return null;
											for (var i = ''; ++r < e.length; ) {
												var o = 255 & e[r];
												o < 128
													? (i += String.fromCharCode(o))
													: o > 191 && o < 224
													? ((i += String.fromCharCode(((31 & o) << 6) | (63 & e[r + 1]))), ++r)
													: ((i += String.fromCharCode(
															((15 & o) << 12) | ((63 & e[r + 1]) << 6) | (63 & e[r + 2]),
													  )),
													  (r += 2));
											}
											return i;
									  })(e, (this.n.ha() + 7) >> 3);
							});
						var M = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/';
						function F(t) {
							var n,
								e,
								r = '';
							for (n = 0; n + 3 <= t.length; n += 3)
								(e = parseInt(t.substring(n, n + 3), 16)),
									(r += M.charAt(e >> 6) + M.charAt(63 & e));
							for (
								n + 1 == t.length
									? ((e = parseInt(t.substring(n, n + 1), 16)), (r += M.charAt(e << 2)))
									: n + 2 == t.length &&
									  ((e = parseInt(t.substring(n, n + 2), 16)),
									  (r += M.charAt(e >> 2) + M.charAt((3 & e) << 4)));
								(3 & r.length) > 0;

							)
								r += '=';
							return r;
						}
						function B(t) {
							var n,
								e,
								r = '',
								i = 0;
							for (n = 0; n < t.length && '=' != t.charAt(n); ++n) {
								var o = M.indexOf(t.charAt(n));
								o < 0 ||
									(0 == i
										? ((r += c(o >> 2)), (e = 3 & o), (i = 1))
										: 1 == i
										? ((r += c((e << 2) | (o >> 4))), (e = 15 & o), (i = 2))
										: 2 == i
										? ((r += c(e)), (r += c(o >> 2)), (e = 3 & o), (i = 3))
										: ((r += c((e << 2) | (o >> 4))), (r += c(15 & o)), (i = 0)));
							}
							return 1 == i && (r += c(e << 2)), r;
						}
						(U.prototype.lf = function (t) {
							t = this.df(t);
							var n = this.vf(),
								e = 0,
								r = null,
								i = null,
								o = 0;
							for (var s in n)
								n.hasOwnProperty(s) &&
									((r = n[s]).hasOwnProperty('offset') && (e += 2 * r.offset),
									(o = 'string' == typeof r.length ? this[r.length] : r.length),
									(o *= 2),
									(i = t.substr(e, o)),
									r.hasOwnProperty('type') &&
										('int' == r.type
											? (i = parseInt(i, 16))
											: 'bigint' == r.type && (i = T(i, 16))),
									(e += o),
									(this[s] = i));
						}),
							(U.prototype._f = function (t) {
								var n = '';
								t = e(t);
								for (var r = 0; r < t.length; ++r) {
									var i = t.charCodeAt(r).toString(16);
									1 === i.length && (i = '0' + i), (n += i);
								}
								return n;
							}),
							(U.prototype.df = function (t) {
								var n = (t = t.replace(/^\s+|\s+$/g, '')).split(/\r?\n/);
								return (
									'-----BEGIN' == n[0].substring(0, 10) && (n = n.slice(1, n.length - 1)),
									(t = n.join('')),
									this._f(t)
								);
							}),
							(U.prototype.pf = function () {
								var t = '',
									n = this.vf(),
									e = null,
									r = null,
									i = 0;
								for (var o in n)
									n.hasOwnProperty(o) &&
										(e = n[o]).hf &&
										((r = this[o].toString(16)).length % 2 && (r = '0' + r),
										e.hasOwnProperty('padded') && e.gf && (r = '00' + r),
										(i = (i = r.length / 2).toString(16)).length % 2 && (i = '0' + i),
										e.hasOwnProperty('extraspace') && (t += i),
										(t += i),
										(t += r),
										(t += '02'));
								return t.slice(0, -2);
							}),
							(U.prototype.mf = function (t, n) {
								if (!t) return t;
								var e = '(.{1,' + (n = n || 64) + '})( +|$\n?)|(.{1,' + n + '})';
								return t.match(new RegExp(e, 'g')).join('\n');
							}),
							(U.prototype.yf = function () {
								var t = '-----BEGIN RSA PRIVATE KEY-----\n',
									n = '3082025e02010002';
								return (
									(n += this.pf()), (t += this.mf(F(n)) + '\n') + '-----END RSA PRIVATE KEY-----'
								);
							}),
							(U.prototype.Sf = function () {
								var t = '-----BEGIN PUBLIC KEY-----\n',
									n = '30819f300d06092a864886f70d010101050003818d0030818902';
								return (n += this.pf()), (t += this.mf(F(n)) + '\n') + '-----END PUBLIC KEY-----';
							});
						var G = function (t) {
							U.call(this), t && this.lf(t);
						};
						((G.prototype = new U()).constructor = G),
							(G.prototype.vf = function () {
								return {
									header: { length: 4 },
									versionlength: { length: 1, offset: 1, type: 'int' },
									version: { length: 'versionlength', type: 'int' },
									n_length: { length: 1, offset: 2, type: 'int' },
									n: { length: 'n_length', type: 'bigint', hf: !0, gf: !0, wf: !0 },
									e_length: { length: 1, offset: 1, type: 'int' },
									e: { length: 'e_length', type: 'int', hf: !0 },
									d_length: { length: 1, offset: 2, type: 'int' },
									d: { length: 'd_length', type: 'bigint', hf: !0, gf: !0, wf: !0 },
									p_length: { length: 1, offset: 1, type: 'int' },
									p: { length: 'p_length', type: 'bigint', hf: !0, gf: !0 },
									q_length: { length: 1, offset: 1, type: 'int' },
									q: { length: 'q_length', type: 'bigint', hf: !0, gf: !0 },
									dmp1_length: { length: 1, offset: 1, type: 'int' },
									dmp1: { length: 'dmp1_length', type: 'bigint', hf: !0 },
									dmq1_length: { length: 1, offset: 1, type: 'int' },
									dmq1: { length: 'dmq1_length', type: 'bigint', hf: !0, gf: !0 },
									coeff_length: { length: 1, offset: 1, type: 'int' },
									coeff: { length: 'coeff_length', type: 'bigint', hf: !0, gf: !0 },
								};
							});
						var k = function (t) {
							U.call(this),
								t &&
									('string' == typeof t
										? this.lf(t)
										: t.hasOwnProperty('n') &&
										  t.hasOwnProperty('e') &&
										  ((this.n = t.n), (this.e = t.e)));
						};
						((k.prototype = new U()).constructor = k),
							(k.prototype.vf = function () {
								return {
									header: { length: 25 },
									n_length: { length: 1, offset: 2, type: 'int' },
									n: { length: 'n_length', type: 'bigint', hf: !0, gf: !0, wf: !0 },
									e_length: { length: 1, offset: 1, type: 'int' },
									e: { length: 'e_length', type: 'int', hf: !0 },
								};
							});
						var V = function () {
							(this.bf = null), (this.If = null);
						};
						return (
							(V.prototype.Ef = function (t) {
								(this.bf = new G(t)), (this.If = new k(this.bf));
							}),
							(V.prototype.Pf = function (t) {
								this.If = new k(t);
							}),
							(V.prototype.decrypt = function (t) {
								return !!this.bf && this.bf.decrypt(B(t));
							}),
							(V.prototype.encrypt = function (t) {
								var n = this.If || this.bf;
								return !!n && F(n.encrypt(t));
							}),
							(V.prototype.yf = function () {
								return (
									this.bf ||
										((this.bf = new G()), this.bf.cf(1024, '010001'), (this.If = new k(this.bf))),
									this.bf.yf()
								);
							}),
							(V.prototype.Sf = function () {
								return this.If || ((this.If = new k()), this.If.cf(1024, '010001')), this.If.Sf();
							}),
							function (t) {
								var n = new V();
								return n.Pf(r.In), n.encrypt(t);
							}
						);
					})()),
						(t.exports = i.encrypt);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(20),
						o = e(44),
						s = e(2).yn,
						a = e(85),
						u = e(86),
						c = e(26),
						f = e(15),
						l = e(9),
						d = e(25),
						_ = e(11),
						p = e(19);
					t.exports = function (t, n, e, h, v, g, E) {
						var m, A, L, y, I, b, P, N, S, w;
						function D() {
							for (var t = 0; t < w.length; t++) {
								var n = w[t];
								C(n.event, n.timestamp, n.Af, n.configuration);
							}
							w = [];
						}
						function C(t, r, o, u) {
							!(function (t) {
								t.setLabel('ns_ap_pn', s.K()),
									t.setLabel('ns_ap_pv', s.Y()),
									t.setLabel('ns_ap_pfm', s.J()),
									t.setLabel('ns_ap_pfv', s.H()),
									t.setLabel('ns_ap_device', s.j()),
									t.setLabel('ns_ap_lang', s.nn()),
									t.setLabel('ns_ap_ar', s.en()),
									t.setLabel('cs_fpdm', '*null'),
									t.setLabel('cs_fpdt', '*null'),
									t.setLabel('cs_fpit', 'c'),
									t.setLabel('cs_fpid', function() {
										var a = '*null';
										if (window.NXSTdata && window.NXSTdata.identity && window.NXSTdata.identity.nexstarId) {
											a = window.NXSTdata.identity.nexstarId;
										}
										return a;
									}()),
									t.setLabel(
										'ns_radio',
										(function (t) {
											switch (t) {
												case l.EMULATOR:
													return 'emu';
												case l.WIFI:
													return 'wlan';
												case l.WWAN:
													return 'wwan';
												case l.ETHERNET:
													return 'eth';
												case l.BLUETOOTH:
													return 'bth';
												default:
													return 'unknown';
											}
										})(s.in()),
									),
									t.setLabel('ns_ap_env', s.gn()),
									t.pr() && t.setLabel('ns_ap_jb', i.Ge(s.rn()));
							})(t),
								(function (t, n) {
									t.setLabel('ns_ap_an', n.F()),
										t.setLabel('ns_ap_res', s.Z()),
										t.setLabel('ns_ap_po', '0x0'),
										t.setLabel('ns_ap_sd', s.X()),
										t.setLabel('ns_ap_ver', n.B()),
										t.setLabel('ns_ap_bi', n.Oi());
								})(t, u),
								(function (t) {
									if (E.isEnabled()) {
										var n = E.Df();
										if (n)
											if (n.Cf) t.addLabels({ cs_cmp_nc: '1' });
											else {
												var e,
													r = {
														gdpr: '0',
														gdpr_p1t: '',
														gdpr_li: '',
														gdpr_purps: '',
														gdpr_pcc: '',
														cs_cmp_id: n.cmpId,
														cs_cmp_sv: n.cmpVersion,
														cs_cmp_nc: n.Lf ? '1' : '0',
													};
												if (n.Tf)
													if (((r.gdpr = '1'), n.Nf)) {
														if (
															((r.gdpr_li = n.Of ? '1' : '0'),
															(r.gdpr_pcc = n.Rf),
															n.kf && n.purposeOneTreatment
																? ((r.gdpr_p1t = '1'), (e = !0))
																: ((r.gdpr_p1t = '0'), (e = n.consents[1])),
															n.Mf)
														) {
															var i = [];
															e && i.push(1),
																n.consents[7] && i.push(7),
																n.consents[8] && i.push(8),
																n.consents[9] && i.push(9),
																n.consents[10] && i.push(10),
																(r.gdpr_purps = i.join(','));
														}
														t.addLabels(r);
													} else t.addLabels(r);
												else t.addLabels(r);
											}
									}
								})(t),
								(function (t) {
									var n = g.Ut();
									n.Ft && t.setLabel('ns_ak', n.Ft), n.Mt && t.setLabel('ns_ap_ni', '1');
								})(t),
								s.un(t.getLabels()),
								A.push(t),
								(function (t) {
									!(function (t) {
										var n = s.in(),
											r = t.ki(),
											i = !1;
										(n != l.DISCONNECTED &&
											r != d.CACHE &&
											(r != d.LAN || (n != l.WWAN && n != l.BLUETOOTH))) ||
											(i = !0);
										for (var o = 0; o < A.length; ++o) {
											var u = A[o];
											if (i) e.er(u, t);
											else {
												var c = new a(u, x, T, t);
												t.Mi() && s.sn('Comscore: ' + c.Uf()), c.nr();
											}
										}
										e.tr(), (A = []);
									})(t);
								})(u),
								n.$t();
						}
						function O(t, e) {
							return (
								(function (t, n) {
									var e = t.getIncludedPublishers();
									if (0 == e.length) return !0;
									for (var r = 0; r < e.length; ++r) if (n.Di(e[r])) return !0;
									return !1;
								})(t, e) &&
								(function () {
									var t = +new Date();
									return (
										t < I
											? ((b = 0), (I = t), (P = 0), (N = t), n.put(_.dt, P), n.put(_.vt, N))
											: (t - I > 1e3 && ((b = 0), (I = t)),
											  t - N > 1e3 && ((P = 0), (N = t), n.put(_.dt, P), n.put(_.vt, N))),
										b < 20 && P < 6e3 && (b++, P++, n.put(_.dt, P), !0)
									);
								})()
							);
						}
						function R(e, r, i, o) {
							O(i, o) &&
								(n.put(_.jn, +new Date()),
								t.ze(r, !0),
								(function (e, r, i, o) {
									0 == m &&
										e.pr() &&
										((L = +new Date()),
										n.put(_.lt, L),
										y++,
										n.put(_._t, y),
										e.setLabel('ns_ap_csf', '1'),
										e.setLabel('ns_ap_cfg', o.Ai())),
										m++,
										e.setLabel('ns_ts', r + ''),
										e.setLabel('ns_ap_ec', m + ''),
										e.setLabel('ns_ap_cs', y + ''),
										e.setLabel('ns_ap_id', L + ''),
										t.Qe() == c.Ee.Ye
											? e.setLabel('name', 'foreground')
											: t.Qe() == c.Ee.Xe || t.Qe() == c.Ee.Ie
											? e.setLabel('name', 'background')
											: e.setLabel('name', 'Application'),
										(i instanceof p && !i.Fe()) ||
											(function (n, e, r) {
												n.setLabel('ns_ap_fg', t.oi() + ''),
													n.setLabel('ns_ap_ft', t.ri(n.pr()) + ''),
													n.setLabel('ns_ap_dft', t.ii() + ''),
													n.setLabel('ns_ap_bt', t.ui(n.pr()) + ''),
													n.setLabel('ns_ap_dbt', t.ei() + ''),
													n.setLabel('ns_ap_it', t.ti(n.pr()) + ''),
													n.setLabel('ns_ap_dit', t.ni() + ''),
													n.setLabel('ns_ap_as', t.$e() + ''),
													n.setLabel('ns_ap_das', t.Ze() + ''),
													n.setLabel(
														'ns_ap_ut',
														1e3 * e.getUsagePropertiesAutoUpdateInterval() + '',
													),
													n.setLabel('ns_ap_usage', r - t.si() + '');
											})(e, o, r),
										(function (t) {
											t.setLabel('c1', '19'),
												t.setLabel('ns_ap_smv', '6.4'),
												t.setLabel('ns_ap_bv', f.VERSION),
												t.setLabel('ns_ap_sv', f.VERSION);
										})(e),
										(function (t, n) {
											var e = n.getPartnerConfigurations();
											if (0 != n.getPartnerConfigurations().length) {
												for (var r = '', i = '', o = 0; o < e.length; ++o) {
													var s = e[o];
													(r += s.getPartnerId() + ','), (i += s.getExternalClientId() + ',');
												}
												(r = r.substring(0, r.length - 1)),
													(i = i.substring(0, i.length - 1)),
													t.setLabel('cs_partner', r),
													t.setLabel('cs_xcid', i);
											}
										})(e, o),
										e.addLabels(S),
										e.addLabels(i.getLabels()),
										u.xf(e, i, e.getLabels(), o);
								})(e, r, i, o),
								(S = {}),
								w.push({ event: e, timestamp: r, Af: i, configuration: o }),
								h.Wf());
						}
						function x(t, r) {
							e.flush(r), e.me(), n.$t();
						}
						function T(t, r) {
							e.er(t, r), e.me(), e.tr(), n.$t();
						}
						r.extend(this, {
							ve: function () {
								return m;
							},
							we: function (t, n, r) {
								var i;
								R(
									((i = new o()).setLabel('ns_type', 'hidden'),
									i.setLabel('ns_ap_ev', 'keep-alive'),
									i.setLabel('ns_ap_oc', e.rr() + ''),
									i),
									t,
									n,
									r,
								);
							},
							Bt: function (n, e, r) {
								R(
									(function () {
										var n = new o();
										n.setLabel('ns_type', 'view'),
											n.setLabel('ns_ap_ev', 'start'),
											n.setLabel('ns_ap_gs', v.Ff() + ''),
											n.setLabel('ns_ap_install', v.Bf() + '');
										var e = t.ai();
										return (
											e > 0 && n.setLabel('ns_ap_lastrun', e + ''),
											v.Vf() && n.setLabel('ns_ap_updated', v.qf() + ''),
											n
										);
									})(),
									n,
									e,
									r,
								);
							},
							pe: function (t, n, e) {
								R(
									(function () {
										var t = new o();
										return t.setLabel('ns_type', 'hidden'), t.setLabel('ns_ap_ev', 'hidden'), t;
									})(),
									t,
									n,
									e,
								);
							},
							ge: function (t, n, e) {
								R(
									(function () {
										var t = new o();
										return t.setLabel('ns_type', 'view'), t.setLabel('ns_ap_ev', 'view'), t;
									})(),
									t,
									n,
									e,
								);
							},
							ie: function (t, e) {
								(S[t] = e + ''), n.put(_.pt, S);
							},
						}),
							(m = 0),
							(A = []),
							(y = n.get(_._t, 0)),
							(I = -1),
							(b = 0),
							(N = -1),
							(P = 0),
							(S = n.get(_.pt, {})),
							(w = []),
							h.addListener(D);
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(2).yn,
						o = e(20);
					t.exports = function (t, n, e, s) {
						var a;
						function u(r) {
							200 == r || (s.isHttpRedirectCachingEnabled() && (302 == r || 301 == r))
								? n(t, s)
								: e(t, s);
						}
						!(function () {
							var n = 4096;
							if (
								(a = o.Be(s.Ti(), t.getLabels(), s.getLabelOrder())).length > n &&
								a.indexOf('&') > 0
							) {
								var e = a.substring(0, n - 8).lastIndexOf('&'),
									r = encodeURIComponent(a.substring(e + 1));
								a = a.substring(0, e) + '&ns_cut=' + r;
							}
							a.length > n && (a = a.substring(0, n));
						})(),
							r.extend(this, {
								nr: function () {
									i.T ? i.T(a, u, s) : u();
								},
								Uf: function () {
									return a;
								},
							});
					};
				},
				function (t, n, e) {
					var r = e(32),
						i = e(0),
						o = e(19),
						s = e(2).yn,
						a = 'a'.charCodeAt(0);
					function u(t, n) {
						if (0 == n) return t;
						var e = String.fromCharCode(a + n - 1),
							r = (function (t) {
								var n = t.match(/^[cC](\d|[12]\d)$/);
								return n && n[1] ? n[1] : '';
							})(t);
						return String(r ? 'c' + e + r : 'c' + e + '_' + t);
					}
					function c(t, n, e) {
						var r = [],
							i = t.getPersistentLabels();
						for (var o in i) r.push(o);
						if (e) {
							var s = t.getStartLabels();
							for (o in s) r.push(o);
						}
						var a = n.getPublisherLabels(t.getPublisherId()),
							u = n.getLabels();
						for (o in a) o in u || r.push(o);
						return r;
					}
					var f = {
						xf: function (t, n, e, a) {
							for (
								var f = {},
									l = t.pr(),
									d = [],
									_ = [],
									p = n.getIncludedPublishers(),
									h = a.getPublisherConfigurations(),
									v = 0;
								v < h.length;
								++v
							) {
								var g = h[v],
									E = g.getPublisherId();
								E != r.re && ((0 != p.length && -1 == p.indexOf(E)) || (d.push(g), _.push(E)));
							}
							if (
								(0 == p.length || -1 != p.indexOf(r.re)) &&
								a.getPartnerConfigurations().length > 0
							) {
								var m = (function (t) {
									for (
										var n = {}, e = {}, r = t.getPartnerConfigurations(), o = 0;
										o < r.length;
										++o
									) {
										var s = r[o];
										i.extend(n, s.getPersistentLabels()), i.extend(e, s.getStartLabels());
									}
									return t.qi().copy({ persistentLabels: n, startLabels: e });
								})(a);
								d.push(m), _.push(m.getPublisherId());
							}
							for (var A = [], L = 0; L < d.length; ++L) {
								var y = d[L];
								if (L >= 26) break;
								var I = {};
								if (
									((I.c2 = y.getPublisherId()),
									(I.c12 = y.getPublisherUniqueDeviceId()),
									s.pn && (I.c12 = s.hn),
									i.extend(I, e),
									i.extend(I, a.getPersistentLabels()),
									i.extend(I, y.getPersistentLabels()),
									i.extend(I, n.getLabels()),
									l && i.extend(I, a.getStartLabels()),
									i.extend(I, n.getPublisherLabels(y.getPublisherId())),
									l && i.extend(I, y.getStartLabels()),
									0 == L)
								)
									(A = c(y, n, l)), i.extend(f, I);
								else {
									for (v = 0; v < A.length; ++v) {
										var b = A[v];
										b in I || (I[b] = '*null');
									}
									for (var P in I) (P in f && f[P] == I[P]) || (f[u(P, L)] = I[P]);
								}
							}
							if (n instanceof o) {
								var N = n.We();
								for (v = 0; v < N.length; ++v) {
									var S = N[v];
									if (L >= 26) break;
									if (-1 == _.indexOf(S)) {
										var w = n.getPublisherLabels(S);
										for (P in ((f[u('c2', L)] = S), w))
											(P in f && f[P] == w[P]) || (f[u(P, L)] = w[P]);
										L++;
									}
								}
							}
							t.addLabels(f);
						},
					};
					t.exports = f;
				},
				function (t, n, e) {
					var r = e(0);
					t.exports = function (t) {
						var n, e;
						!(function () {
							var e = t.getProperties();
							try {
								((n = JSON.parse(e)) && 'object' == typeof n) || (n = {});
							} catch (t) {
								n = {};
							}
						})(),
							(e = !1),
							r.extend(this, {
								put: function (t, r) {
									(n[t] = r), (e = !0);
								},
								get: function (t, e) {
									return t in n ? n[t] : e;
								},
								remove: function (t) {
									delete n[t], (e = !0);
								},
								contains: function (t) {
									return t in n;
								},
								clear: function () {
									n = {};
								},
								$t: function () {
									var r;
									e && ((r = JSON.stringify(n)), t.storeProperties(r)), (e = !1);
								},
							});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(2).yn;
					t.exports = function (t) {
						var n, e;
						function o() {
							e &&
								((e = !1),
								(function () {
									t();
									for (var e = 0; e < n.length; e++) n[e]();
								})());
						}
						function s() {
							e && (e = !1);
						}
						(n = []),
							r.extend(this, {
								addListener: function (t) {
									n.push(t);
								},
								Wf: function () {
									e || ((e = !0), i.U(o, s));
								},
							});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(11);
					t.exports = function (t, n) {
						var e,
							o,
							s,
							a,
							u = -1;
						!(function () {
							(s = !1),
								(a = !1),
								(u = t.get(i.Kn, -1)),
								(e = t.get(i.Jn, -1)),
								(o = t.get(i.Hn, null)),
								t.put(i.Hn, n.B());
							var r = +new Date();
							-1 == u
								? ((u = r), (e = r), t.put(i.Kn, u), t.put(i.Jn, e), (s = !0))
								: o && o != n.B() && ((e = r), t.put(i.Jn, e), t.put(i._t, 0), (a = !0));
						})(),
							r.extend(this, {
								qf: function () {
									return o;
								},
								Ff: function () {
									return u;
								},
								Bf: function () {
									return e;
								},
								Vf: function () {
									return a;
								},
								Gf: function () {
									return s;
								},
							});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(11),
						o = e(2).yn,
						s = [
							'previousVersion',
							'AppName',
							'AppVersion',
							'vid',
							'exitCode',
							'q_dcc',
							'q_dcf',
							'runs',
							'accumulatedActiveUserSessionTime',
							'accumulatedUserSessionTime',
							'activeUserSessionCount',
							'userSessionCount',
							'userInteractionCount',
							'lastActiveUserSessionTimestamp',
							'lastUserInteractionTimestamp',
							'lastUserSessionTimestamp',
						],
						a = {
							CACHE_DROPPED_MEASUREMENTS: i.Gn,
							lastApplicationAccumulationTimestamp: i.Qn,
							lastSessionAccumulationTimestamp: i.$n,
							lastApplicationSessionTimestamp: i.Zn,
							foregroundTransitionsCount: i.nt,
							accumulatedForegroundTime: i.tt,
							accumulatedBackgroundTime: i.et,
							accumulatedInactiveTime: i.it,
							totalForegroundTime: i.rt,
							totalBackgroundTime: i.ot,
							totalInactiveTime: i.ut,
							accumulatedApplicationSessionTime: i.st,
							applicationSessionCountKey: i.ft,
							genesis: i.ct,
							previousGenesis: i.lt,
							installId: i.Jn,
							firstInstallId: i.Kn,
							currentVersion: i.Hn,
							coldStartCount: i._t,
							crossPublisherIdHashed: i.Yn,
							crossPublisherId: i.Xn,
						},
						u = { pg: i.lt };
					t.exports = function () {
						var t = 'function' == typeof o.R ? new o.R() : null;
						r.extend(this, {
							ue: function (n) {
								if (t)
									for (var e in a) {
										var r = t.get(e);
										null != r && n.put(a[e], r);
									}
								for (var i in u) {
									var o = n.get(i);
									null != o && n.put(u[i], o);
								}
							},
							se: function (n) {
								if (t) {
									for (var e in a) t.remove(e);
									for (var r = 0; r < s.length; ++r) t.remove(s[r]);
									t.clear();
								}
								for (var i in u) n.remove(i);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(44),
						o = e(2).yn,
						s = 'cache_dir',
						a = 'undefined' != typeof encodeURIComponent ? decodeURIComponent : unescape;
					t.exports = function (t, n, e) {
						var u = 'function' == typeof o.R ? new o.R() : null,
							c = 'function' == typeof o.k ? new o.k() : null,
							f = function () {
								return (c && c.dir(s)) || [];
							},
							l = function (t) {
								c && (c.deleteFile(s, t), u && u.remove(t));
							},
							d = function (t) {
								if (!c) return [];
								var n = c.read(s, t);
								return n ? n.split('\n') : [];
							};
						function _(t) {
							for (var n = t.split('&'), e = new i(), r = 0; r < n.length; ++r) {
								var o = n[r].split('='),
									s = a(o[0]),
									u = a(o[1]);
								e.setLabel(s, u);
							}
							return e;
						}
						r.extend(this, {
							ae: function () {
								var t = (function (t) {
									for (var n = [], e = 0; e < t.length; ++e) {
										var r = _(t[e]);
										n.push(r);
									}
									return n;
								})(
									(function () {
										for (var t = f(), n = [], e = 0; e < t.length; ++e) {
											var r = d(t[e]);
											n = n.concat(r);
										}
										return n;
									})(),
								);
								e.ir(t);
							},
							fe: function () {
								for (var t = f(), n = 0; n < t.length; ++n) l(t[n]);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(93),
						o = e(11);
					t.exports = function () {
						var t,
							n,
							e = this;
						r.extend(e, {
							zt: function (t) {
								n = t;
							},
							isEnabled: function () {
								return n;
							},
							ce: function (n) {
								t = n;
							},
							ne: function () {
								if (!n) return !0;
								var t = e.Df();
								return (
									!t ||
									!!t.Cf ||
									!t.Tf ||
									(!!t.Nf && !!t.Mf && ((t.kf && t.purposeOneTreatment) || t.consents[1]))
								);
							},
							Df: function () {
								return i.jf();
							},
							te: function () {
								t.remove(o.zn), t.remove(o.Yn), t.remove(o.Xn);
							},
						}),
							(n = !0);
					};
				},
				function (t, n, e) {
					Object.defineProperty(n, 'v', { value: !0 }), (n.jf = void 0);
					var r = [1, 7, 8, 9, 10],
						i = !1;
					function o(t) {
						var n = {};
						if (!t) return n;
						for (var e = 0, i = r; e < i.length; e++) {
							var o = i[e];
							n[o] = t.purpose.consents[o];
						}
						return n;
					}
					n.jf = function () {
						try {
							var t = (function () {
								try {
									var t;
									if ('function' == typeof __tcfapi)
										return (
											__tcfapi('getTCData', 2, function (n) {
												t = n;
											}),
											t || (i = !0),
											t
										);
								} catch (t) {}
							})();
							if (!t && !i) return !1;
							if (t)
								return t.gdprApplies
									? {
											Cf: !1,
											cmpId: t.cmpId,
											cmpVersion: t.cmpVersion,
											Lf: i,
											Tf: !0,
											Nf: !0,
											kf: t.isServiceSpecific,
											purposeOneTreatment: t.purposeOneTreatment,
											Of: t.vendor.legitimateInterests[77],
											Mf: t.vendor.consents[77],
											consents: o(t),
											Rf: t.publisherCC || '',
									  }
									: { Cf: !1, cmpId: t.cmpId, cmpVersion: t.cmpVersion, Lf: i, Tf: !1 };
						} catch (t) {}
						return { Cf: !0, Lf: !0 };
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(14),
						o = e(95),
						s = e(1).I,
						a = e(1).P,
						u = e(16),
						c = e(119),
						f = e(31),
						l = e(120),
						d = e(50),
						_ = e(121),
						p = e(122);
					function h(t) {
						var n,
							e = this;
						r.extend(e, {
							configuration: null,
							extendedAnalytics: null,
							createPlaybackSession: function () {
								n.Hf().jo().Go('createPlaybackSession');
								var t = n.Hf().Qo().zo();
								t != a.IDLE &&
									(n.Hf().jo().Ho('Ending the current Clip. It was in state:', i.wt(t)),
									e.notifyEnd()),
									n.Hf().Lr().Qr() && n.ou();
							},
							addListener: function (t) {
								n.addListener(t);
							},
							removeListener: function (t) {
								n.removeListener(t);
							},
							addMeasurementListener: function (t) {
								n.addMeasurementListener(t);
							},
							removeMeasurementListener: function (t) {
								n.removeMeasurementListener(t);
							},
							setDvrWindowLength: function (t) {
								n.Hf().Lr().Cr().setDvrWindowLength(t), n.Hf().Lr().Cr().ws(!0);
							},
							startFromDvrWindowOffset: function (t) {
								n.Hf().Lr().startFromDvrWindowOffset(t), n.Hf().Lr().Cr().ws(!0);
							},
							setMediaPlayerName: function (t) {
								n.Hf().Lr().setMediaPlayerName(t);
							},
							setMediaPlayerVersion: function (t) {
								n.Hf().Lr().setMediaPlayerVersion(t);
							},
							setImplementationId: function (t) {
								n.Hf().Lr().setImplementationId(t);
							},
							setProjectId: function (t) {
								n.setProjectId(t + '');
							},
							startFromSegment: function (t) {
								n.Hf().jo().Go('startFromSegment', t), n.Hf().Lr().startFromSegment(t);
							},
							startFromPosition: function (t) {
								n.Hf().jo().Go('startFromPosition', t), n.Hf().Lr().startFromPosition(t);
							},
							loopPlaybackSession: function () {
								n.Hf().jo().Go('loopPlaybackSession'), n.Hf().Lr().loopPlaybackSession();
							},
							setMetadata: function (t) {
								n.Hf().Lr().qo(t);
							},
							getPlaybackSessionId: function () {
								n.Hf().Lr().Mo();
							},
							notifyPlay: function () {
								n.Hf().jo().Go('notifyPlay');
								var t = new u(s.PLAY);
								n.$o(t);
							},
							notifyPause: function () {
								n.Hf().jo().Go('notifyPause');
								var t = new u(s.PAUSE);
								n.$o(t);
							},
							notifyEnd: function () {
								n.Hf().jo().Go('notifyEnd');
								var t = new u(s.END);
								n.$o(t);
							},
							notifyBufferStart: function () {
								n.Hf().jo().Go('notifyBufferStart');
								var t = new u(s.BUFFER);
								n.$o(t);
							},
							notifyBufferStop: function () {
								n.Hf().jo().Go('notifyBufferStop');
								var t = new u(s.BUFFER_STOP);
								n.$o(t);
							},
							notifySeekStart: function () {
								n.Hf().jo().Go('notifySeekStart');
								var t = new u(s.SEEK_START);
								n.$o(t);
							},
							notifyChangePlaybackRate: function (t) {
								n.Hf().jo().Go('notifyChangePlaybackRate');
								var e = Math.floor(100 * t),
									r = new u(s.PLAYBACK_RATE);
								(r.Nt.ns_st_rt = e + ''), n.hr(r);
							},
						}),
							(n = new o(t)),
							(e.configuration = n.Kf),
							(e.extendedAnalytics = new c(n.Hf())),
							n.Hf().jo().log('New StreamingAnalytics instance with configuration', t);
					}
					(h.PlayerEvents = s),
						(h.InternalStates = a),
						(h.WindowState = p),
						(h.ContentMetadata = f),
						(h.StackedContentMetadata = l),
						(h.AdvertisementMetadata = d),
						(h.StackedAdvertisementMetadata = _),
						(t.exports = h);
				},
				function (t, n, e) {
					var r = e(96),
						i = e(97),
						o = e(98),
						s = e(99),
						a = e(100),
						u = e(101),
						c = e(102),
						f = e(103),
						l = e(104),
						d = e(105),
						_ = e(106),
						p = e(107),
						h = e(108),
						v = e(14),
						g = e(109),
						E = e(0),
						m = e(21),
						A = e(29),
						L = e(4),
						y = e(110),
						I = e(111),
						b = e(16),
						P = e(47),
						N = e(1).P,
						S = e(1).I,
						w = e(1).A,
						D = e(45),
						C = e(46),
						O = e(115),
						R = e(116),
						x = e(117),
						T = e(3),
						U = e(17),
						M = e(2).yn,
						F = e(19),
						B = e(118);
					function G(t) {
						var n,
							e,
							G,
							k,
							V,
							K,
							Y,
							W,
							j,
							H,
							X,
							q,
							J,
							$,
							z,
							Z,
							Q,
							tt,
							nt,
							et,
							rt,
							it,
							ot,
							st,
							at,
							ut,
							ct,
							ft,
							lt,
							dt,
							_t = this;
						function pt(t) {
							var e = n.Qo().zo();
							if (
								e == N.IDLE ||
								e == N.PLAYBACK_NOT_STARTED ||
								e == N.BUFFERING_BEFORE_PLAYBACK ||
								e == N.SEEKING_BEFORE_PLAYBACK
							) {
								if (t == S.PLAY) return !0;
							} else if (e == N.PLAYING) {
								if (t == S.END || t == S.AD_SKIP || t == S.SEEK_START || t == S.PAUSE) return !0;
							} else if (
								e == N.PAUSED ||
								e == N.BUFFERING_DURING_PAUSE ||
								e == N.SEEKING_DURING_PLAYBACK ||
								e == N.SEEKING_DURING_BUFFERING ||
								e == N.SEEKING_DURING_PAUSE
							) {
								if (t == S.END || t == S.AD_SKIP || t == S.PLAY) return !0;
							} else if (e == N.BUFFERING_DURING_PLAYBACK) {
								if (
									t == S.PAUSE_ON_BUFFERING ||
									t == S.END ||
									t == S.AD_SKIP ||
									t == S.SEEK_START ||
									t == S.PAUSE ||
									t == S.PLAY
								)
									return !0;
							} else if (e == N.BUFFERING_DURING_SEEKING) {
								if (t == S.END || t == S.AD_SKIP || t == S.PAUSE || t == S.PLAY) return !0;
							} else if (
								e == N.PAUSED_DURING_BUFFERING &&
								(t == S.END || t == S.AD_SKIP || t == S.BUFFER_STOP || t == S.PLAY)
							)
								return !0;
							return !1;
						}
						function ht(t) {
							(dt = t) == A.Ii.vi
								? (_t.Jf('1'), n.jo().Ho('System clock jump detected', 'to the far past'))
								: dt == A.Ii._i
								? (_t.Jf('3'), n.jo().Ho('System clock jump detected', 'to the future'))
								: (_t.Jf('2'), n.jo().Ho('System clock jump detected', 'to the near past'));
						}
						function vt() {
							_t.$o(new b(S.END));
						}
						function gt() {
							G = U.configuration.Li();
						}
						function Et() {
							G = U.configuration.Li();
						}
						E.extend(_t, {
							Kf: null,
							Jf: function (t) {
								-1 == ft.indexOf(t) && ft.push(t);
							},
							Ir: function () {
								var t = new b(S.HEARTBEAT);
								t.setLabel('ns_st_hc', n.Yf().Er());
								var e = n.Lr(),
									r = e.Cr(),
									i = isNaN(et) ? nt : et;
								(et = t.Ot), A.qt().wi(t.Ot);
								var o = !1;
								dt && ((dt = null), (o = !0), (t.Ot = i));
								var s = r.uo(),
									a = r.Uu(),
									u = r.no(),
									c = r.eo(),
									f = r.lo(),
									l = r.Ss();
								r.bs() ? e.co(N.PLAYING, null, t.Ot) : e.$r(N.PLAYING, null, t.Ot);
								var d = r.no();
								r.Tu(t);
								var _ = _t.Bn(S.HEARTBEAT, t);
								n.Lr().Cr().hu(_.Xf),
									n.zf().dispatchEvent(_),
									r.Zr(s),
									r.to(a),
									r.ao(u),
									r.fo(c),
									r.yo(f),
									r.So(l),
									o &&
										(r.Zr(d),
										r.to(et),
										e.Yr(et - parseInt(_.Xf.ns_st_dpt)),
										r.Yr(et - parseInt(_.Xf.ns_st_dpt)),
										r.Mu(et - parseInt(_.Xf.ns_st_det)));
							},
							Bn: function (t, r) {
								var i = r.Ot,
									o = n.Lr(),
									s = {};
								(s.ns_ts = i + ''),
									(s.ns_st_ev = S.toString(t)),
									(s.ns_st_mp = 'js_api'),
									(s.ns_st_mv = '7.7.0+211006'),
									(s.ns_st_ub = '0'),
									(s.ns_st_br = '0'),
									(s.ns_st_pn = '1'),
									(s.ns_st_tp = '0'),
									(s.ns_st_it = w.toString(w.g)),
									(s.ns_st_sv = '7.7.0+211006'),
									(s.ns_st_smv = '6.4'),
									(s.ns_type = 'hidden'),
									(s.ns_st_ec = n.zf().ve() + ''),
									(s.ns_st_cfg = e.Qf()),
									(s.ns_st_hd = n.Yf().Nr(i)),
									(s.ns_st_po = o.Cr().no() + ''),
									ft.length > 0 && (s.ns_ap_ie = ft.join(';')),
									o.Bn(s, i),
									o.Cr().Bn(s, i, t == S.HEARTBEAT),
									E.extend(s, r.Nt),
									E.extend(s, ct),
									(function () {
										for (var t = E.h(ut), n = 0; n < t.length; ++n) t[n](s);
									})();
								var a = {};
								E.extend(a, s), E.extend(a, e.getLabels());
								var u = new F();
								u.he(!1);
								var c = e.$f(),
									f = e.Zf,
									l = o.Cr(),
									d = l.getStacks();
								if (f.length > 0)
									for (var _ = 0; _ < f.length; ++_) {
										var p = f[_];
										-1 != G.indexOf(p) && u.addIncludedPublisher(p);
									}
								else
									for (_ = 0; _ < G.length; ++_) {
										var h = G[_];
										u.addIncludedPublisher(h);
									}
								var v = [];
								for (var g in c) {
									var m = c[g];
									if (!(f.length > 0 && -1 == f.indexOf(g))) {
										var A = {};
										E.extend(A, a),
											E.extend(A, m.getLabels()),
											E.extend(A, l.getLabels()),
											d[g] && E.extend(A, d[g]),
											E.extend(A, r.Tt),
											u.addPublisherLabels(g, A),
											v.push(g);
									}
								}
								var L = [];
								for (var y in d) {
									var I = d[y];
									if (-1 == v.indexOf(y)) {
										var b = {};
										E.extend(b, I), E.extend(b, r.Tt), u.addPublisherLabels(y, b), L.push(y);
									}
								}
								return (
									u.xe(L),
									E.extend(a, l.getLabels()),
									E.extend(a, r.Tt),
									u.addLabels(a),
									new B(t, u, s)
								);
							},
							$o: function (t) {
								var r = n.Qo().zo();
								if (e.eu) {
									if (st && r == N.BUFFERING_BEFORE_PLAYBACK && t.Lt == S.BUFFER_STOP) {
										n.jo().Ho('Resume to PLAY from state:', v.wt(r));
										var i = new b(S.PLAY);
										return (i.Ot = t.Ot), (i.Nt.ns_st_ae = '1'), _t.$o(i), void (st = !1);
									}
									st && (st = !1);
								}
								var o = n.Qo().nc(t.Lt);
								if (null != o && o != r) {
									lt && v.bt(r) && !v.bt(o) ? M.vn(vt) : lt && !v.bt(r) && v.bt(o) && M._n(vt);
									var s = n.Lr(),
										a = s.Cr(),
										u = isNaN(et) ? nt : et;
									(et = t.Ot), A.qt().wi(t.Ot);
									var c = !1;
									dt && ((dt = null), (c = !0), (t.Ot = u)),
										r == N.IDLE &&
											a.vr('ns_st_pn') &&
											s.startFromSegment(parseInt(a._r('ns_st_pn'))),
										a.bs() ? s.co(r, o, t.Ot) : s.$r(r, o, t.Ot),
										r == N.IDLE && s.wo(),
										(function (t) {
											var e = n.Qo().zo();
											t.Lt == S.AD_SKIP && !t.Nt.hasOwnProperty('ns_st_ui') && pt(t.Lt)
												? (t.Nt.ns_st_ui = 'skip')
												: t.Lt == S.SEEK_START &&
												  !t.Nt.hasOwnProperty('ns_st_ui') &&
												  pt(t.Lt) &&
												  (t.Nt.ns_st_ui = 'seek');
											var r = t.Lt;
											e == N.IDLE
												? r == S.BUFFER
													? k.tc(t)
													: r == S.SEEK_START
													? k.ec(t)
													: r == S.PLAY && k.ic(t)
												: e == N.PLAYBACK_NOT_STARTED
												? r == S.END || r == S.AD_SKIP
													? K.rc(t)
													: r == S.SEEK_START
													? K.ec(t)
													: r == S.PLAY
													? K.ic(t)
													: r == S.BUFFER && K.tc(t)
												: e == N.PLAYING
												? r == S.END || r == S.AD_SKIP
													? Y.rc(t)
													: r == S.BUFFER
													? Y.tc(t)
													: r == S.SEEK_START
													? Y.ec(t)
													: r == S.PAUSE && Y.oc(t)
												: e == N.PAUSED
												? r == S.END || r == S.AD_SKIP
													? V.rc(t)
													: r == S.PLAY
													? V.ic(t)
													: r == S.BUFFER
													? Z.uc(t)
													: r == S.SEEK_START && Z.sc(t)
												: e == N.BUFFERING_BEFORE_PLAYBACK
												? r == S.END || r == S.AD_SKIP
													? W.rc(t)
													: r == S.BUFFER_STOP
													? W.ac(t)
													: r == S.SEEK_START
													? W.ec(t)
													: r == S.PAUSE
													? W.oc(t)
													: r == S.PLAY && W.ic(t)
												: e == N.BUFFERING_DURING_PLAYBACK
												? r == S.PAUSE_ON_BUFFERING
													? j.fc(t)
													: r == S.BUFFER_STOP
													? Z.cc(t)
													: r == S.END || r == S.AD_SKIP
													? j.rc(t)
													: r == S.SEEK_START
													? j.ec(t)
													: r == S.PAUSE
													? j.oc(t)
													: r == S.PLAY && Z.cc(t)
												: e == N.BUFFERING_DURING_SEEKING
												? r == S.END || r == S.AD_SKIP
													? H.rc(t)
													: r == S.PAUSE
													? H.oc(t)
													: r == S.PLAY
													? H.ic(t)
													: r == S.BUFFER_STOP && Z.lc(t)
												: e == N.BUFFERING_DURING_PAUSE
												? r == S.END || r == S.AD_SKIP
													? X.dc(t)
													: r == S.PAUSE
													? X.oc(t)
													: r == S.PLAY
													? X.ic(t)
													: r == S.SEEK_START
													? Z.sc(t)
													: r == S.BUFFER_STOP && Z.lc(t)
												: e == N.SEEKING_BEFORE_PLAYBACK
												? r == S.END || r == S.AD_SKIP
													? J.rc(t)
													: r == S.PAUSE
													? J.oc(t)
													: r == S.PLAY
													? J.ic(t)
													: r == S.BUFFER && Z.uc(t)
												: e == N.SEEKING_DURING_PLAYBACK
												? r == S.END || r == S.AD_SKIP
													? z.rc(t)
													: r == S.PLAY
													? z.ic(t)
													: r == S.BUFFER
													? Z.uc(t)
													: r == S.PAUSE && Z.vc(t)
												: e == N.SEEKING_DURING_BUFFERING
												? r == S.PAUSE
													? $.oc(t)
													: r == S.BUFFER
													? Z.uc(t)
													: r == S.PLAY
													? Z._c(t)
													: r == S.END || r == S.AD_SKIP
													? Z.pc(t)
													: r == S.BUFFER_STOP && Z.hc(t)
												: e == N.PAUSED_DURING_BUFFERING
												? r == S.END || r == S.AD_SKIP
													? q.rc(t)
													: r == S.BUFFER_STOP
													? q.gc(t)
													: r == S.SEEK_START
													? q.ec(t)
													: r == S.PAUSE
													? q.oc(t)
													: r == S.PLAY && q.gc(t)
												: e == N.SEEKING_DURING_PAUSE &&
												  (r == S.BUFFER
														? Z.uc(t)
														: r == S.PLAY
														? Z._c(t)
														: r == S.PAUSE
														? Z.vc(t)
														: r == S.END || r == S.AD_SKIP
														? Z.pc(t)
														: r == S.BUFFER_STOP && Z.hc(t)),
												pt(r) && n.Lr().Ro(!0);
										})(t),
										n.Qo().$o(t.Lt, t.Ot),
										o == N.IDLE ? A.qt().Si(ht) : r == N.IDLE && A.qt().yi(ht),
										c &&
											(a.Zr(a.no()),
											a.to(et),
											o != N.IDLE &&
												o != N.PLAYBACK_NOT_STARTED &&
												o != N.SEEKING_BEFORE_PLAYBACK &&
												o != N.BUFFERING_BEFORE_PLAYBACK &&
												a.Mu(et),
											(o != N.BUFFERING_BEFORE_PLAYBACK &&
												o != N.BUFFERING_DURING_PAUSE &&
												o != N.BUFFERING_DURING_PLAYBACK &&
												o != N.BUFFERING_DURING_SEEKING &&
												o != N.PAUSED_DURING_BUFFERING) ||
												(s.Kr(et), a.Kr(et)),
											o == N.PLAYING && (s.Yr(et), a.Yr(et)),
											(o != N.SEEKING_BEFORE_PLAYBACK &&
												o != N.SEEKING_DURING_BUFFERING &&
												o != N.SEEKING_DURING_PAUSE &&
												o != N.SEEKING_DURING_PLAYBACK &&
												o != N.BUFFERING_DURING_SEEKING) ||
												a.Hu(et)),
										n
											.jo()
											.log(
												'Transition from',
												v.wt(r),
												'to',
												v.wt(o),
												'due to event:',
												S.toString(t.Lt),
											);
									for (var f = 0, l = at.length; f < l; f++) at[f](r, o, t.Nt);
								} else n.jo().Ho('Ignored event:', S.toString(t.Lt), 'during state', v.wt(r), t.Nt);
							},
							hr: function (t) {
								var e = t.Lt,
									r = t.Nt,
									i = n.Qo().zo();
								if ((e != S.LOAD && e != S.ENGAGE) || i == N.IDLE) {
									var o,
										s,
										a,
										u,
										c = !0,
										f = !1,
										l = !0;
									if (
										(e == S.ERROR && null == r.ns_st_er && (r.ns_st_er = T.wn),
										e == S.TRANSFER && null == r.ns_st_rp && (r.ns_st_rp = T.wn),
										e == S.PLAYBACK_RATE)
									) {
										var d = parseInt(r.ns_st_rt);
										(0 == d || isNaN(d)) &&
											((l = !1), _t.Jf('6'), (r.ns_st_rt = n.Lr().Cr().gs() + ''));
									}
									if (e == S.VOLUME) {
										var _ = parseInt(r.ns_st_vo);
										(_ < 0 || isNaN(_)) && ((l = !1), _t.Jf('7'), (r.ns_st_vo = ct.ns_st_vo));
									}
									switch (e) {
										case S.BIT_RATE:
											(o = 'ns_st_br'), (s = 'ns_st_pbr');
											break;
										case S.PLAYBACK_RATE:
											(o = 'ns_st_rt'), (s = 'ns_st_prt');
											break;
										case S.VOLUME:
											(o = 'ns_st_vo'), (s = 'ns_st_pvo');
											break;
										case S.WINDOW_STATE:
											(o = 'ns_st_ws'), (s = 'ns_st_pws');
											break;
										case S.AUDIO:
											(o = 'ns_st_at'), (s = 'ns_st_pat');
											break;
										case S.VIDEO:
											(o = 'ns_st_vt'), (s = 'ns_st_pvt');
											break;
										case S.SUBS:
											(o = 'ns_st_tt'), (s = 'ns_st_ptt');
											break;
										case S.CDN:
											(o = 'ns_st_cdn'), (s = 'ns_st_pcdn');
											break;
										default:
											c = !1;
									}
									var p = n.Lr(),
										h = p.Cr();
									if (c && o in r)
										switch (((u = r[o]), e)) {
											case S.BIT_RATE:
											case S.VOLUME:
											case S.WINDOW_STATE:
												o in ct && ((a = ct[o]), (r[s] = a), (f = u == a + '')), (ct[o] = r[o]);
												break;
											case S.AUDIO:
											case S.VIDEO:
											case S.SUBS:
											case S.CDN:
												h._u(o) && ((a = h.vu(o)), (r[s] = a), (f = u == a + '')), h.tu(o, r[o]);
												break;
											case S.PLAYBACK_RATE:
												(a = h.gs()), (r[s] = a + '');
										}
									if ((c && i != N.PLAYING && i != N.BUFFERING_DURING_PLAYBACK) || (c && f && l))
										return (
											e == S.PLAYBACK_RATE && h.ms(parseInt(r.ns_st_rt)),
											void n
												.jo()
												.Ho(
													'No measurement send for the pseudo-event:',
													S.toString(e),
													'during state',
													v.wt(i),
													r,
												)
										);
									var g = isNaN(et) ? nt : et;
									(et = t.Ot), A.qt().wi(t.Ot);
									var E = !1;
									dt && ((dt = null), (E = !0), (t.Ot = g)),
										h.bs() ? p.co(i, null, t.Ot) : p.$r(i, null, t.Ot),
										i == N.IDLE && p.wo();
									var m = h.no();
									p.Po(),
										i != N.IDLE &&
											i != N.PLAYBACK_NOT_STARTED &&
											i != N.SEEKING_BEFORE_PLAYBACK &&
											i != N.BUFFERING_BEFORE_PLAYBACK &&
											(h.Ru(t.Ot), h.Mu(t.Ot)),
										i == N.PLAYING &&
											(p.Vr(t.Ot),
											p.Yr(t.Ot),
											h.Vr(t.Ot),
											h.Yr(t.Ot),
											h.ys(m),
											h.Lu(m),
											h.Tu(),
											h.Du(m),
											n.Yf().pause(),
											n.Yf().resume()),
										(i != N.BUFFERING_BEFORE_PLAYBACK &&
											i != N.BUFFERING_DURING_PAUSE &&
											i != N.BUFFERING_DURING_PLAYBACK &&
											i != N.BUFFERING_DURING_SEEKING) ||
											(p.Gr(t.Ot), p.Kr(t.Ot), h.Gr(t.Ot), h.Kr(t.Ot));
									var L = _t.Bn(e, t);
									h.Vo(L.Xf),
										h.hu(L.Xf),
										p.Vo(L.Xf),
										n.zf().dispatchEvent(L),
										e == S.PLAYBACK_RATE && h.ms(parseInt(r.ns_st_rt)),
										E &&
											(h.Zr(m),
											h.to(et),
											i == N.PLAYING && (p.Yr(et), h.Yr(et)),
											i != N.IDLE &&
												i != N.PLAYBACK_NOT_STARTED &&
												i != N.SEEKING_BEFORE_PLAYBACK &&
												i != N.BUFFERING_BEFORE_PLAYBACK &&
												h.Mu(et),
											(i != N.BUFFERING_BEFORE_PLAYBACK &&
												i != N.BUFFERING_DURING_PAUSE &&
												i != N.BUFFERING_DURING_PLAYBACK &&
												i != N.BUFFERING_DURING_SEEKING &&
												i != N.PAUSED_DURING_BUFFERING) ||
												(p.Kr(et), h.Kr(et)),
											(i != N.SEEKING_BEFORE_PLAYBACK &&
												i != N.SEEKING_DURING_BUFFERING &&
												i != N.SEEKING_DURING_PAUSE &&
												i != N.SEEKING_DURING_PLAYBACK &&
												i != N.BUFFERING_DURING_SEEKING) ||
												h.Hu(et));
								} else
									n.jo().Ho('Ignored pseudo-event:', S.toString(e), 'during state', v.wt(i), r);
							},
							addListener: function (t) {
								at.push(t);
							},
							removeListener: function (t) {
								at.splice(m.indexOf(t, at), 1);
							},
							addMeasurementListener: function (t) {
								L.On(t) && ut.push(t);
							},
							removeMeasurementListener: function (t) {
								var n = ut.indexOf(t);
								-1 != n && ut.splice(n, 1);
							},
							mr: function () {
								return M;
							},
							mc: function () {
								return Q;
							},
							yc: function (t) {
								Q = t;
							},
							Sc: function () {
								return tt;
							},
							setLoadTimeOffset: function (t) {
								tt = t;
							},
							wc: function () {
								return nt;
							},
							bc: function () {
								return it;
							},
							Ic: function () {
								return lt;
							},
							Ec: function (t) {
								_t.Pc(),
									(ot = _t.mr().setTimeout(function () {
										var t = new b(S.PAUSE_ON_BUFFERING);
										_t.$o(t);
									}, rt));
							},
							Pc: function () {
								null != ot && (_t.mr().clearTimeout(ot), (ot = null));
							},
							iu: function () {
								st = !0;
							},
							Hf: function () {
								return n;
							},
							ou: function (t) {
								var e = n.Lr();
								n.Ac(new P(n)), n.Lr().To(gt), P.ou(n, e, t), (ft = []);
							},
							Dc: function () {
								n.Yf().reset();
							},
							setProjectId: function (t) {
								ct.cs_proid = t;
							},
						}),
							(n = new g(_t)),
							(e = new I(t || {})),
							(_t.Kf = new y(e)),
							n.Cc(e),
							n.Lc(U),
							n.Tc(new D(n)),
							n.Nc(new C(n)),
							n.Oc(new O(e, U)),
							n.zf().Rc(Et),
							n.kc(new x()),
							n.Mc(new R('STA', n.Uc().configuration.Gi())),
							n.Ac(new P(n)),
							n.Lr().To(gt),
							(G = []),
							(k = new r(n)),
							(V = new i(n)),
							(K = new o(n)),
							(Y = new s(n)),
							(W = new a(n)),
							(j = new u(n)),
							(H = new c(n)),
							(X = new f(n)),
							(q = new l(n)),
							(J = new d(n)),
							($ = new _(n)),
							(z = new p(n)),
							(Z = new h(n)),
							(Q = !1),
							(tt = 0),
							(nt = +new Date()),
							(it = !0),
							(ft = []),
							(at = []),
							(ut = []),
							((ct = {}).ns_st_vo = '100'),
							(dt = null),
							(function (t) {
								(it = t.xc), (rt = t.Wc), (lt = t.Fc);
							})(e);
					}
					(G.Bc = 500), (G.Vc = !0), (G.qc = !0), (t.exports = G);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I;
					t.exports = function (t) {
						r.extend(this, {
							tc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = n.Ot;
								e.Po(), e.Lo(), r.Vu() && r.Xu(r.Qu()), e.Kr(i), r.Kr(i), r.Mu(i);
							},
							ec: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = r.no(),
									o = n.Ot;
								e.Po(),
									e.Lo(),
									r.Vu() && r.Xu(r.Qu()),
									r.Pu(),
									r.Bu(!0),
									r.qu(!0),
									r.$u(i),
									r.Hu(o),
									r.Mu(o);
							},
							ic: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								e.Po(),
									e.Lo(),
									(r.hs() || 0 == e.No()) && (e.Oo(), r.Bo(!1)),
									r.Vu() && (r.Xu(r.Qu()), r.es(o), r.Bu(!1)),
									e.ko(),
									r.ju(!0),
									r.Fo(!0),
									r.us(),
									(0 == r.ls() || r.Io() <= r.ls()) && (r.Wo(r.Io()), r.ss(), r.Co(0), r.as()),
									r.ko(),
									r.Fr(),
									e.Yr(s),
									r.Yr(s),
									r.Mu(s),
									r.Du(o),
									t.gr().mc() ||
										((n.Nt.ns_st_lt = t.gr().Sc() + s - t.gr().wc() + ''), t.gr().yc(!0)),
									t.Yf().resume(),
									t.Gc().start();
								var a = t.gr().Bn(i.PLAY, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I;
					t.exports = function (t) {
						r.extend(this, {
							rc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = n.Ot;
								t.gr().Dc(), t.Gc().stop(), r.Ru(o);
								var s = t.gr().Bn(i.END, n);
								r.Vo(s.Xf),
									r.hu(s.Xf),
									e.Vo(s.Xf),
									t.zf().dispatchEvent(s),
									r.Vu() && r.Gu() && (r.zu(o - r.Ku()), r.Bu(!1)),
									r.os(),
									r.ju(!1);
							},
							ic: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								e.ko(),
									r.Vu() && (r.Gu() && (r.Ju(s), r.qu(!1)), r.es(o), r.Bu(!1)),
									r.Fr(),
									r.ko(),
									e.Yr(s),
									r.Yr(s),
									r.Ru(s),
									r.Mu(s),
									r.Du(o),
									t.Yf().resume(),
									t.Gc().start();
								var a = t.gr().Bn(i.PLAY, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I;
					t.exports = function (t) {
						r.extend(this, {
							rc: function (n) {
								var e = t.Lr().Cr(),
									r = n.Ot;
								e.Vu() && e.Gu() && (e.zu(r - e.Ku()), e.qu(!1)), e.Ru(r), e.os(), e.ju(!1);
							},
							ec: function (n) {
								var e = t.Lr().Cr(),
									r = e.no(),
									i = n.Ot;
								e.Vu() ? e.Hu(i) : e.Pu(), e.Vu() || (e.Bu(!0), e.qu(!0), e.$u(r), e.Hu(i));
							},
							ic: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								r.Vu() && (r.es(o), r.Bu(!1)),
									(r.hs() || 0 == e.No()) && (e.Oo(), r.Bo(!1)),
									e.ko(),
									r.ju(!0),
									r.Fo(!0),
									r.us(),
									(0 == r.ls() || r.Io() <= r.ls()) && (r.Wo(r.Io()), r.ss(), r.Co(0), r.as()),
									r.ko(),
									r.Fr(),
									e.Yr(s),
									r.Yr(s),
									r.Ru(s),
									r.Mu(s),
									r.Du(o),
									t.gr().mc() ||
										((n.Nt.ns_st_lt = t.gr().Sc() + s - t.gr().wc() + ''), t.gr().yc(!0)),
									t.Yf().resume(),
									t.Gc().start();
								var a = t.gr().Bn(i.PLAY, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
							tc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = n.Ot;
								e.Kr(i), r.Kr(i);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I;
					t.exports = function (t) {
						r.extend(this, {
							rc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								t.gr().Dc(), t.Gc().stop(), e.Vr(s), r.Vr(s), r.ys(o), r.Ru(s), r.Lu(o), r.Tu();
								var a = t.gr().Bn(i.END, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a), r.os(), r.ju(!1);
							},
							tc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = r.no(),
									o = n.Ot;
								t.Yf().pause(),
									t.Gc().stop(),
									e.Vr(o),
									r.Vr(o),
									r.ys(i),
									r.Lu(i),
									r.Tu(),
									t.gr().bc() && t.gr().Ec(n),
									r.vs(),
									e.Kr(o),
									r.Kr(o),
									r.Ru(o),
									r.Mu(o);
							},
							ec: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								t.Yf().pause(),
									t.Gc().stop(),
									e.Vr(s),
									r.Vr(s),
									r.ys(o),
									r.Lu(o),
									r.Tu(),
									r.Pu(),
									r.Bu(!0),
									r.qu(!0),
									r.$u(o),
									r.Hu(s),
									r.Ru(s),
									r.Mu(s),
									e.Br(),
									r.Br();
								var a = t.gr().Bn(i.PAUSE, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
							oc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								t.Yf().pause(),
									t.Gc().stop(),
									e.Vr(s),
									r.Vr(s),
									r.ys(o),
									r.Lu(o),
									r.Tu(),
									r.Ru(s),
									r.Mu(s),
									e.Br(),
									r.Br();
								var a = t.gr().Bn(i.PAUSE, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I;
					t.exports = function (t) {
						r.extend(this, {
							rc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = n.Ot;
								e.Gr(i), r.Gr(i), r.Vu() && r.Gu() && r.zu(i - r.Ku()), r.Ru(i), r.os(), r.ju(!1);
							},
							ac: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = n.Ot;
								e.Gr(i), r.Gr(i), r.Vu() && r.Gu() && (r.Ju(i), r.qu(!1));
							},
							ec: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = r.no(),
									o = n.Ot;
								e.Gr(o),
									r.Gr(o),
									r.Vu() ? r.Gu() || (r.Hu(o), r.qu(!0)) : r.Pu(),
									r.Vu() || (r.Bu(!0), r.qu(!0), r.$u(i), r.Hu(o));
							},
							oc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = n.Ot;
								e.Gr(i), r.Gr(i), r.Vu() && r.Gu() && (r.Ju(i), r.qu(!1));
							},
							ic: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								e.Gr(s),
									r.Gr(s),
									r.Vu() && (r.Gu() && (r.Ju(s), r.qu(!1)), r.es(o), r.Bu(!1)),
									r.ju(!0),
									r.Fo(!0),
									(r.hs() || 0 == e.No()) && (e.Oo(), r.Bo(!1)),
									e.ko(),
									r.ju(!0),
									r.us(),
									r.Fr(),
									e.Yr(s),
									r.Yr(s),
									r.Ru(s),
									r.Mu(s),
									r.Du(o),
									(0 == r.ls() || r.Io() <= r.ls()) && (r.Wo(r.Io()), r.ss(), r.Co(0), r.as()),
									r.ko(),
									t.gr().mc() ||
										((n.Nt.ns_st_lt = t.gr().Sc() + s - t.gr().wc() + ''), t.gr().yc(!0)),
									t.Yf().resume(),
									t.Gc().start();
								var a = t.gr().Bn(i.PLAY, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I;
					t.exports = function (t) {
						r.extend(this, {
							fc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = n.Ot;
								t.gr().Pc(), r.Ru(o), r.Mu(o), e.Gr(o), r.Gr(o), e.Br(), r.Br();
								var s = t.gr().Bn(i.PAUSE, n);
								r.Vo(s.Xf), r.hu(s.Xf), e.Vo(s.Xf), t.zf().dispatchEvent(s), e.Kr(o), r.Kr(o);
							},
							rc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = n.Ot;
								t.gr().Pc(), t.gr().Dc(), t.Gc().stop(), e.Gr(o), r.Gr(o), r.Ru(o);
								var s = t.gr().Bn(i.END, n);
								r.Vo(s.Xf), r.hu(s.Xf), e.Vo(s.Xf), t.zf().dispatchEvent(s), r.os(), r.ju(!1);
							},
							ec: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								t.gr().Pc(),
									t.Yf().pause(),
									t.Gc().stop(),
									r.Ru(s),
									r.Mu(s),
									e.Gr(s),
									r.Gr(s),
									r.Pu(),
									r.Bu(!0),
									r.qu(!0),
									r.$u(o),
									r.Hu(s),
									e.Br(),
									r.Br();
								var a = t.gr().Bn(i.PAUSE, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
							oc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = n.Ot;
								t.gr().Pc(), r.Ru(o), r.Mu(o), e.Gr(o), r.Gr(o), e.Br(), r.Br();
								var s = t.gr().Bn(i.PAUSE, n);
								r.Vo(s.Xf), r.hu(s.Xf), e.Vo(s.Xf), t.zf().dispatchEvent(s);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I;
					t.exports = function (t) {
						r.extend(this, {
							rc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = n.Ot;
								t.gr().Dc(), t.Gc().stop(), t.gr().Pc(), e.Gr(o), r.Gr(o), r.Ru(o);
								var s = t.gr().Bn(i.END, n);
								r.Vo(s.Xf),
									r.hu(s.Xf),
									e.Vo(s.Xf),
									t.zf().dispatchEvent(s),
									r.Vu() && r.Gu() && (r.zu(o - r.Ku()), r.qu(!1)),
									r.os(),
									r.ju(!1);
							},
							oc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = n.Ot;
								e.Gr(i),
									r.Gr(i),
									e.Br(),
									r.Br(),
									r.Vu() && r.Gu() && (r.Ju(i), r.qu(!1)),
									r.Ru(i),
									r.Mu(i);
							},
							ic: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								e.ko(),
									r.ko(),
									r.Fr(),
									e.Gr(s),
									r.Gr(s),
									r.Vu() && (r.Gu() && (r.Ju(s), r.qu(!1)), r.es(o), r.Bu(!1)),
									r.Ru(s),
									r.Mu(s),
									e.Yr(s),
									r.Yr(s),
									r.Du(o),
									t.Yf().resume(),
									t.Gc().start();
								var a = t.gr().Bn(i.PLAY, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I;
					t.exports = function (t) {
						r.extend(this, {
							dc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = n.Ot;
								t.gr().Dc(), t.Gc().stop(), e.Gr(o), r.Gr(o), r.Ru(o);
								var s = t.gr().Bn(i.END, n);
								r.Vo(s.Xf),
									r.hu(s.Xf),
									e.Vo(s.Xf),
									t.zf().dispatchEvent(s),
									r.Vu() && r.Gu() && (r.zu(o - r.Ku()), r.qu(!1)),
									r.os(),
									r.ju(!1);
							},
							oc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = n.Ot;
								e.Gr(i), r.Gr(i), r.Ru(i), r.Mu(i);
							},
							ic: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								e.ko(),
									r.ko(),
									r.Fr(),
									e.Gr(s),
									r.Gr(s),
									r.Vu() && (r.Gu() && (r.Ju(s), r.qu(!1)), r.es(o), r.Bu(!1)),
									r.Ru(s),
									r.Mu(s),
									e.Yr(s),
									r.Yr(s),
									r.Du(o),
									t.Yf().resume(),
									t.Gc().start();
								var a = t.gr().Bn(i.PLAY, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I;
					t.exports = function (t) {
						r.extend(this, {
							rc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = n.Ot;
								t.gr().Dc(),
									t.Gc().stop(),
									e.Gr(o),
									r.Gr(o),
									r.Ru(o),
									r.Vu() && r.Gu() && (r.zu(o - r.Ku()), r.qu(!1));
								var s = t.gr().Bn(i.END, n);
								r.Vo(s.Xf), r.hu(s.Xf), e.Vo(s.Xf), t.zf().dispatchEvent(s), r.os(), r.ju(!1);
							},
							ec: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = r.no(),
									o = n.Ot;
								e.Gr(o),
									r.Gr(o),
									r.Vu() ? r.Gu() || (r.Hu(o), r.qu(!0)) : r.Pu(),
									r.Vu() || (r.Bu(!0), r.qu(!0), r.$u(i), r.Hu(o)),
									r.Ru(o),
									r.Mu(o);
							},
							oc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = n.Ot;
								e.Gr(i), r.Gr(i), r.Ru(i), r.Mu(i);
							},
							gc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								e.ko(),
									r.ko(),
									e.Gr(s),
									r.Gr(s),
									r.Fr(),
									e.Yr(s),
									r.Yr(s),
									r.Ru(s),
									r.Mu(s),
									r.Du(o),
									t.Yf().resume(),
									t.Gc().start();
								var a = t.gr().Bn(i.PLAY, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I;
					t.exports = function (t) {
						r.extend(this, {
							rc: function (n) {
								var e = t.Lr().Cr(),
									r = n.Ot;
								e.Vu() && e.Gu() && (e.zu(r - e.Ku()), e.qu(!1)), e.Ru(r), e.os(), e.ju(!1);
							},
							oc: function (n) {
								var e = t.Lr().Cr(),
									r = n.Ot;
								e.Vu() && e.Gu() && (e.Ju(r), e.qu(!1));
							},
							ic: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								r.Vu() && (r.Gu() && (r.Ju(s), r.qu(!1)), r.es(o), r.Bu(!1)),
									(r.hs() || 0 == e.No()) && (e.Oo(), r.Bo(!1)),
									e.ko(),
									r.ju(!0),
									r.Fo(!0),
									r.us(),
									(0 == r.ls() || r.Io() <= r.ls()) && (r.Wo(r.Io()), r.ss(), r.Co(0), r.as()),
									r.ko(),
									r.Fr(),
									e.Yr(s),
									r.Yr(s),
									r.Ru(s),
									r.Mu(s),
									r.Du(o),
									t.gr().mc() ||
										((n.Nt.ns_st_lt = t.gr().Sc() + s - t.gr().wc() + ''), t.gr().yc(!0)),
									t.Yf().resume(),
									t.Gc().start();
								var a = t.gr().Bn(i.PLAY, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0);
					t.exports = function (t) {
						r.extend(this, {
							oc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = n.Ot;
								e.Br(), r.Br(), r.Vu() && r.Gu() && (r.Ju(i), r.qu(!1)), r.Ru(i), r.Mu(i);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I;
					t.exports = function (t) {
						r.extend(this, {
							rc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = n.Ot;
								t.gr().Dc(), t.Gc().stop(), r.Ru(o);
								var s = t.gr().Bn(i.END, n);
								r.Vo(s.Xf),
									r.hu(s.Xf),
									e.Vo(s.Xf),
									t.zf().dispatchEvent(s),
									r.Vu() && r.Gu() && (r.zu(o - r.Ku()), r.qu(!1)),
									r.os(),
									r.ju(!1);
							},
							ic: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								e.ko(),
									r.ko(),
									r.Fr(),
									r.Vu() && (r.Gu() && (r.Ju(s), r.qu(!1)), r.es(o), r.Bu(!1)),
									r.Ru(s),
									r.Mu(s),
									e.Yr(s),
									r.Yr(s),
									r.Du(o),
									t.gr().mc() ||
										((n.Nt.ns_st_lt = t.gr().Sc() + s - t.gr().wc() + ''), t.gr().yc(!0)),
									t.Yf().resume(),
									t.Gc().start();
								var a = t.gr().Bn(i.PLAY, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I;
					t.exports = function (t) {
						r.extend(this, {
							sc: function (n) {
								var e = t.Lr().Cr(),
									r = e.no(),
									i = n.Ot;
								e.Vu() ? e.Gu() || (e.Hu(i), e.qu(!0)) : e.Pu(),
									e.Vu() || (e.Bu(!0), e.qu(!0), e.$u(r), e.Hu(i)),
									e.Ru(i),
									e.Mu(i);
							},
							uc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = n.Ot;
								e.Kr(i), r.Kr(i), r.Ru(i), r.Mu(i);
							},
							_c: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = r.no(),
									s = n.Ot;
								e.ko(),
									r.ko(),
									r.Vu() && (r.Gu() && (r.Ju(s), r.qu(!1)), r.es(o), r.Bu(!1)),
									r.Fr(),
									e.Yr(s),
									r.Yr(s),
									r.Ru(s),
									r.Mu(s),
									r.Du(o),
									t.Yf().resume(),
									t.Gc().start();
								var a = t.gr().Bn(i.PLAY, n);
								r.Vo(a.Xf), r.hu(a.Xf), e.Vo(a.Xf), t.zf().dispatchEvent(a);
							},
							lc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = n.Ot;
								e.Gr(i), r.Gr(i), r.Ru(i), r.Mu(i);
							},
							vc: function (n) {
								var e = t.Lr().Cr(),
									r = n.Ot;
								e.Vu() && e.Gu() && (e.Ju(r), e.qu(!1)), e.Ru(r), e.Mu(r);
							},
							pc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									o = n.Ot;
								t.gr().Dc(), t.Gc().stop(), r.Ru(o);
								var s = t.gr().Bn(i.END, n);
								r.Vo(s.Xf),
									r.hu(s.Xf),
									e.Vo(s.Xf),
									t.zf().dispatchEvent(s),
									r.Vu() && r.Gu() && (r.zu(o - r.Ku()), r.qu(!1)),
									r.os(),
									r.ju(!1);
							},
							hc: function (n) {
								var e = t.Lr().Cr(),
									r = n.Ot;
								e.Vu() && e.Gu() && (e.Ju(r), e.qu(!1)), e.Ru(r), e.Mu(r);
							},
							cc: function (n) {
								var e = t.Lr(),
									r = e.Cr(),
									i = r.no(),
									o = n.Ot;
								t.gr().Pc(),
									e.Gr(o),
									r.Gr(o),
									e.Yr(o),
									r.Yr(o),
									r.Du(i),
									r.Ru(o),
									r.Mu(o),
									t.Yf().resume(),
									t.Gc().start();
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0);
					t.exports = function (t) {
						var n, e, i, o, s, a, u, c;
						r.extend(this, {
							Uc: function () {
								return e;
							},
							gr: function () {
								return t;
							},
							getConfiguration: function () {
								return n;
							},
							zf: function () {
								return i;
							},
							Qo: function () {
								return o;
							},
							Yf: function () {
								return s;
							},
							Gc: function () {
								return a;
							},
							Lr: function () {
								return u;
							},
							jo: function () {
								return c;
							},
							Cc: function (t) {
								n = t;
							},
							Lc: function (t) {
								e = t;
							},
							Tc: function (t) {
								a = t;
							},
							Nc: function (t) {
								s = t;
							},
							Oc: function (t) {
								i = t;
							},
							kc: function (t) {
								o = t;
							},
							Ac: function (t) {
								u = t;
							},
							Mc: function (t) {
								c = t;
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0);
					t.exports = function (t) {
						r.extend(this, {
							addLabels: t.addLabels,
							setLabel: t.setLabel,
							removeLabel: t.removeLabel,
							removeAllLabels: t.removeAllLabels,
							getStreamingPublisherConfiguration: t.getStreamingPublisherConfiguration,
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(7),
						o = e(45),
						s = e(46),
						a = e(47),
						u = e(53),
						c = e(114),
						f = {
							xc: 'pauseOnBuffering',
							Wc: 'pauseOnBufferingInterval',
							Fc: 'exitEndEvent',
							yr: 'keepAliveMeasurement',
							Sr: 'keepAliveInterval',
							Or: 'heartbeatMeasurement',
							Rr: 'heartbeatIntervals',
							Zf: 'includedPublishers',
							eu: 'autoResumeStateOnAssetChange',
							Is: 'playbackIntervalMergeTolerance',
							labels: 'labels',
							Es: 'customStartMinimumPlayback',
						};
					t.exports = function (t) {
						var n = this,
							e = '',
							l = {};
						for (var d in ((n.xc = !0),
						(n.Wc = 500),
						(n.Fc = !0),
						(n.yr = o.ENABLED),
						(n.Sr = o.wr),
						(n.Or = s.ENABLED),
						(n.Rr = s.kr),
						(n.Zf = []),
						(n.eu = a.uu),
						(n.Is = u.Ps),
						(n.labels = {}),
						(n.Es = u.Ds),
						f)) {
							var _ = t[f[d]];
							null != _ && (n[d] = _);
						}
						(n.Sr = n.Sr < o.br ? o.br : n.Sr),
							(n.Wc = n.Wc < 300 ? 300 : n.Wc),
							(n.Is = n.Is < u.As ? u.As : n.Is),
							(n.Sr = 1e3 * Math.floor(n.Sr / 1e3)),
							(n.Wc = 100 * Math.floor(n.Wc / 100)),
							(n.Is = 100 * Math.floor(n.Is / 100)),
							(n.Es = 1e3 * Math.floor(n.Es / 1e3)),
							(e += n.xc ? '1' : '0'),
							(e += n.yr ? '1' : '0'),
							(e += n.Or ? '1' : '0'),
							(e += n.eu ? '1' : '0'),
							(e += n.Fc ? '1' : '0'),
							(e += s.Mr(n.Rr, s.kr) ? '0' : '1'),
							(e += n.Zf.length > 0 ? '1' : '0'),
							(e += n.Is != u.Ps ? '1' : '0'),
							(e += n.Es != u.jc ? '1' : '0'),
							(e += '-' + (n.Wc / 100).toString(16)),
							(e += '-' + (n.Sr / 1e3).toString(16)),
							(e += '-' + (n.Es / 1e3).toString(16)),
							(e += '-' + (n.Is / 100).toString(16)),
							(n.Qf = function () {
								return e;
							}),
							r.extend(n, {
								getLabels: function () {
									return n.labels;
								},
								addLabels: function (t) {
									i.Bn(n.labels, t);
								},
								setLabel: function (t, e) {
									i.Fn(n.labels, t, e);
								},
								removeLabel: function (t) {
									delete n.labels[t];
								},
								removeAllLabels: function () {
									n.labels = {};
								},
								getStreamingPublisherConfiguration: function (t) {
									return (l[t] = l[t] || new c()), l[t];
								},
								$f: function () {
									return l;
								},
							});
					};
				},
				function (t, n) {
					var e = e || {};
					(e.Hc = function (t) {
						return t.replace(/&#x([0-9A-Fa-f]{2})/g, function () {
							return String.fromCharCode(parseInt(arguments[1], 16));
						});
					}),
						(e.cu = function (t, n) {
							var e = n + '';
							return (
								Array(t)
									.join('0')
									.substring(0, Math.max(t - e.length, 0)) + e
							);
						}),
						(t.exports = e);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(48);
					t.exports = function () {
						var t = null,
							n = {};
						r.extend(this, {
							Ao: function (e) {
								var r = e.oo();
								(n[r] = e), i.ro(e._r('ns_st_ad')) || (t = e);
							},
							Ko: function (t) {
								return !!n[t];
							},
							Cr: function (t) {
								return n[t];
							},
							io: function () {
								return t;
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(7);
					t.exports = function () {
						var t;
						r.extend(this, {
							setLabel: function (n, e) {
								i.Fn(t, n, e);
							},
							removeLabel: function (n) {
								delete t[n];
							},
							addLabels: function (n) {
								i.Bn(t, n);
							},
							removeAllLabels: function () {
								t = {};
							},
							getLabels: function () {
								return t;
							},
						}),
							(t = {});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(1).I,
						o = e(28);
					t.exports = function (t, n) {
						var e,
							s,
							a,
							u,
							c = this;
						function f() {
							for (var t = 0; t < s.length; ++t) {
								var e = s[t];
								n.pe(e);
							}
							s = [];
						}
						function l(t) {
							switch (t) {
								case o.TIME_WINDOW_ELAPSED:
									(u = !0), _() && d();
									break;
								case o.PUBLISHER:
									u && _() && d();
							}
						}
						function d() {
							for (var e = 0; e < a.length; ++e) a[e]();
							n.configuration.removeListener(l);
							var r = n.configuration.Li();
							for (e = 0; e < s.length; ++e)
								for (var i = s[e], o = 0; o < r.length; o++) {
									var u = r[o];
									(t.Zf.length > 0 && -1 == t.Zf.indexOf(u)) || i.addIncludedPublisher(u);
								}
							f();
						}
						function _() {
							var e = n.configuration.Li();
							if (0 == t.Zf.length) return !0;
							for (var r = 0; r < e.length; ++r) if (-1 != t.Zf.indexOf(e[r])) return !0;
							return !1;
						}
						r.extend(this, {
							dispatchEvent: function (t) {
								t.Kc != i.HEARTBEAT && c.Jc(), s.push(t.Af), u && _() && f();
							},
							Rc: function (t) {
								a.push(t);
							},
							ve: function () {
								return e;
							},
							Jc: function () {
								e++;
							},
						}),
							(e = 1),
							(s = []),
							(a = []),
							((u = n.configuration.Vi()) && _()) || n.configuration.addListener(l);
					};
				},
				function (t, n) {
					t.exports = function (t, n) {
						function e(n) {
							n = n || [];
							var e = ['comScore', +new Date()];
							return t && e.push(t), (n = Array.prototype.slice.call(n)), e.concat(n);
						}
						function r(t) {
							var e, r, i;
							if ('boolean' == typeof n || !n) return !!n;
							if (((i = t.join(' ')), n instanceof Array && n.length > 0)) {
								for (e = 0; e < n.length; ++e)
									if ((r = n[e]) instanceof RegExp && r.test(i)) return !0;
								return !1;
							}
							if ('object' == typeof n) {
								var o = !1;
								if (n.hide instanceof Array)
									for (e = 0; e < n.hide.length; ++e)
										if ((r = n.hide[e]) instanceof RegExp && r.test(i)) {
											o = !0;
											break;
										}
								if (n.show instanceof Array)
									for (e = 0; e < n.show.length; ++e)
										if ((r = n.show[e]) instanceof RegExp && r.test(i)) return !0;
								return !o && !n.show;
							}
							return !0;
						}
						(this.log = function () {
							var t = e(arguments);
							'undefined' != typeof console &&
								'function' == typeof console.log &&
								r(t) &&
								console.log.apply(console, t);
						}),
							(this.warn = function () {
								var t = e(arguments);
								'undefined' != typeof console &&
									'function' == typeof console.warn &&
									r(t) &&
									console.warn.apply(console, t);
							}),
							(this.error = function () {
								var t = e(arguments);
								'undefined' != typeof console &&
									'function' == typeof console.error &&
									r(t) &&
									console.error.apply(console, t);
							}),
							(this.Go = function (t) {
								for (var n = ['API call to:', t], e = 1; e < arguments.length; ++e)
									n.push('arg' + e + ':', arguments[e]);
								this.log.apply(this, n);
							}),
							(this.Ho = function () {
								var t = ['Trace log:'];
								t.push.apply(t, Array.prototype.slice.call(arguments)), this.log.apply(this, t);
							}),
							(this.Yc = function (t, n) {
								var e = ['Deprecated API:', t, 'is deprecated and will be eventually removed.'];
								n && e.push('Use', n, 'instead.'), this.warn.apply(this, e);
							});
					};
				},
				function (t, n, e) {
					var r = e(1).P,
						i = e(0),
						o = e(1).I;
					t.exports = function (t) {
						var n,
							e,
							s,
							a = this;
						i.extend(a, {
							nc: function (t) {
								if (s == r.IDLE) {
									if (t == o.PLAY) return r.PLAYING;
									if (t == o.SEEK_START) return r.SEEKING_BEFORE_PLAYBACK;
									if (t == o.BUFFER) return r.BUFFERING_BEFORE_PLAYBACK;
								} else if (s == r.PLAYBACK_NOT_STARTED) {
									if (t == o.PLAY) return r.PLAYING;
									if (t == o.SEEK_START) return r.SEEKING_BEFORE_PLAYBACK;
									if (t == o.BUFFER) return r.BUFFERING_BEFORE_PLAYBACK;
									if (t == o.END || t == o.AD_SKIP) return r.IDLE;
								} else if (s == r.PLAYING) {
									if (t == o.END || t == o.AD_SKIP) return r.IDLE;
									if (t == o.BUFFER) return r.BUFFERING_DURING_PLAYBACK;
									if (t == o.PAUSE) return r.PAUSED;
									if (t == o.SEEK_START) return r.SEEKING_DURING_PLAYBACK;
								} else if (s == r.PAUSED) {
									if (t == o.END || t == o.AD_SKIP) return r.IDLE;
									if (t == o.BUFFER) return r.BUFFERING_DURING_PAUSE;
									if (t == o.PLAY) return r.PLAYING;
									if (t == o.SEEK_START) return r.SEEKING_DURING_PAUSE;
								} else if (s == r.BUFFERING_BEFORE_PLAYBACK) {
									if (t == o.END || t == o.AD_SKIP) return r.IDLE;
									if (t == o.PAUSE || t == o.BUFFER_STOP) return r.PLAYBACK_NOT_STARTED;
									if (t == o.PLAY) return r.PLAYING;
									if (t == o.SEEK_START) return r.SEEKING_BEFORE_PLAYBACK;
								} else if (s == r.BUFFERING_DURING_PLAYBACK) {
									if (t == o.END || t == o.AD_SKIP) return r.IDLE;
									if (t == o.PLAY || t == o.BUFFER_STOP) return r.PLAYING;
									if (t == o.PAUSE_ON_BUFFERING) return r.PAUSED_DURING_BUFFERING;
									if (t == o.SEEK_START) return r.SEEKING_DURING_BUFFERING;
									if (t == o.PAUSE) return r.PAUSED;
								} else if (s == r.BUFFERING_DURING_SEEKING) {
									if (t == o.END || t == o.AD_SKIP) return r.IDLE;
									if (t == o.PLAY) return r.PLAYING;
									if (t == o.BUFFER_STOP) return r.SEEKING_DURING_PLAYBACK;
									if (t == o.PAUSE) return r.PAUSED;
								} else if (s == r.BUFFERING_DURING_PAUSE) {
									if (t == o.END || t == o.AD_SKIP) return r.IDLE;
									if (t == o.PLAY) return r.PLAYING;
									if (t == o.SEEK_START) return r.SEEKING_DURING_PAUSE;
									if (t == o.BUFFER_STOP || t == o.PAUSE) return r.PAUSED;
								} else if (s == r.SEEKING_BEFORE_PLAYBACK) {
									if (t == o.END || t == o.AD_SKIP) return r.IDLE;
									if (t == o.PAUSE) return r.PLAYBACK_NOT_STARTED;
									if (t == o.PLAY) return r.PLAYING;
									if (t == o.BUFFER) return r.BUFFERING_BEFORE_PLAYBACK;
								} else if (s == r.SEEKING_DURING_PLAYBACK) {
									if (t == o.END || t == o.AD_SKIP) return r.IDLE;
									if (t == o.PLAY) return r.PLAYING;
									if (t == o.PAUSE) return r.PAUSED;
									if (t == o.BUFFER) return r.BUFFERING_DURING_SEEKING;
								} else if (s == r.SEEKING_DURING_BUFFERING) {
									if (t == o.END || t == o.AD_SKIP) return r.IDLE;
									if (t == o.PLAY) return r.PLAYING;
									if (t == o.PAUSE || t == o.BUFFER_STOP) return r.PAUSED;
									if (t == o.BUFFER) return r.BUFFERING_DURING_SEEKING;
								} else if (s == r.SEEKING_DURING_PAUSE) {
									if (t == o.END || t == o.AD_SKIP) return r.IDLE;
									if (t == o.PLAY) return r.PLAYING;
									if (t == o.PAUSE || t == o.BUFFER_STOP) return r.PAUSED;
									if (t == o.BUFFER) return r.BUFFERING_DURING_PAUSE;
								} else if (s == r.PAUSED_DURING_BUFFERING) {
									if (t == o.END || t == o.AD_SKIP) return r.IDLE;
									if (t == o.SEEK_START) return r.SEEKING_DURING_BUFFERING;
									if (t == o.PAUSE) return r.PAUSED;
									if (t == o.PLAY || t == o.BUFFER_STOP) return r.PLAYING;
								}
								return null;
							},
							zo: function () {
								return s;
							},
							$o: function (t, r) {
								var i = a.nc(t);
								s != i && ((e = s), (s = i), (n = r));
							},
							Xc: function () {
								return e;
							},
							zc: function () {
								return n;
							},
						}),
							(s = r.IDLE),
							(e = null),
							(n = NaN);
					};
				},
				function (t, n) {
					t.exports = function (t, n, e) {
						(this.Kc = t), (this.Af = n), (this.Xf = e);
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(7),
						o = e(1).I,
						s = e(16);
					t.exports = function (t) {
						var n = t.gr();
						function e(t, n) {
							var e = new s(t);
							return i.Bn(e.Tt, n || {}), e;
						}
						function a(t, n, r, i) {
							var o = e(t, i);
							return null != r && o.setLabel(n, r + ''), o;
						}
						r.extend(this, {
							setLoadTimeOffset: function (e) {
								t.jo().Go('setLoadTimeOffset', e), n.setLoadTimeOffset(e);
							},
							setPlaybackSessionExpectedLength: function (e) {
								t.jo().Go('setPlaybackSessionExpectedLength', e), n.Hf().Lr().Uo(e);
							},
							setPlaybackSessionExpectedNumberOfItems: function (e) {
								t.jo().Go('setPlaybackSessionExpectedNumberOfItems', e), n.Hf().Lr().xo(e);
							},
							notifySkipAd: function (r) {
								t.jo().Go('notifySkipAd', r);
								var i = e(o.AD_SKIP, r);
								n.$o(i);
							},
							notifyLoad: function (r) {
								t.jo().Go('notifyLoad', r);
								var i = e(o.LOAD, r);
								n.hr(i);
							},
							notifyEngage: function (r) {
								t.jo().Go('notifyEngage', r);
								var i = e(o.ENGAGE, r);
								n.hr(i);
							},
							notifyCallToAction: function (r) {
								t.jo().Go('notifyCallToAction', r);
								var i = e(o.CTA, r);
								n.hr(i);
							},
							notifyDrmFail: function (r) {
								t.jo().Go('notifyDrmFail', r);
								var i = e(o.DRM_FAILED, r);
								n.hr(i);
							},
							notifyDrmApprove: function (r) {
								t.jo().Go('notifyDrmApprove', r);
								var i = e(o.DRM_APPROVED, r);
								n.hr(i);
							},
							notifyDrmDeny: function (r) {
								t.jo().Go('notifyDrmDeny', r);
								var i = e(o.DRM_DENIED, r);
								n.hr(i);
							},
							notifyCustomEvent: function (r, i) {
								t.jo().Go('notifyCustomEvent', r, i);
								var s = e(o.CUSTOM, i);
								s.setLabel('ns_st_cev', r + ''), n.hr(s);
							},
							notifyChangeBitrate: function (e, r) {
								t.jo().Go('notifyChangeBitrate', e, r);
								var i = a(o.BIT_RATE, 'ns_st_br', e, r);
								n.hr(i);
							},
							notifyChangeVolume: function (e, r) {
								t.jo().Go('notifyChangeVolume', e, r);
								var i = Math.floor(100 * e),
									s = a(o.VOLUME, 'ns_st_vo', i, r);
								n.hr(s);
							},
							notifyChangeWindowState: function (e, r) {
								t.jo().Go('notifyChangeWindowState', e, r);
								var i = a(o.WINDOW_STATE, 'ns_st_ws', e, r);
								n.hr(i);
							},
							notifyChangeAudioTrack: function (e, r) {
								t.jo().Go('notifyChangeAudioTrack', e, r);
								var i = a(o.AUDIO, 'ns_st_at', e, r);
								n.hr(i);
							},
							notifyChangeVideoTrack: function (e, r) {
								t.jo().Go('notifyChangeVideoTrack', e, r);
								var i = a(o.VIDEO, 'ns_st_vt', e, r);
								n.hr(i);
							},
							notifyChangeSubtitleTrack: function (e, r) {
								t.jo().Go('notifyChangeSubtitleTrack', e, r);
								var i = a(o.SUBS, 'ns_st_tt', e, r);
								n.hr(i);
							},
							notifyChangeCdn: function (e, r) {
								t.jo().Go('notifyChangeCdn', e, r);
								var i = a(o.CDN, 'ns_st_cdn', e, r);
								n.hr(i);
							},
							notifyError: function (t, e) {
								n.Hf().jo().Go('notifyError', t, e);
								var r = a(o.ERROR, 'ns_st_er', t, e);
								n.hr(r);
							},
							notifyTransferPlayback: function (t, e) {
								n.Hf().jo().Go('notifyTransferPlayback', t, e);
								var r = a(o.TRANSFER, 'ns_st_rp', t, e);
								n.hr(r);
							},
						});
					};
				},
				function (t, n, e) {
					var r = e(0),
						i = e(52);
					function o() {
						var t = new i();
						r.extend(this, t);
					}
					r.extend(o, i), (t.exports = o);
				},
				function (t, n, e) {
					var r = e(0),
						i = e(51);
					function o() {
						var t = this,
							n = new i();
						r.extend(t, n),
							r.extend(t, {
								getMetadataLabels: function (n) {
									var e = {};
									return (
										n && r.extend(e, n.getStandardLabels()),
										r.extend(e, t.getStandardLabels()),
										n && r.extend(e, n.getCustomLabels()),
										r.extend(e, t.getCustomLabels()),
										e
									);
								},
							});
					}
					r.extend(o, i), (t.exports = o);
				},
				function (t, n) {
					t.exports = { NORMAL: 'norm', FULL_SCREEN: 'full', MINIMIZED: 'min', MAXIMIZED: 'max' };
				},
			]));
	})({
		get exports() {
			return i;
		},
		set exports(t) {
			i = t;
		},
	});
	var u = i,
		c = '2.0.0+2303300717',
		f = {},
		l = {};
	Object.defineProperty(l, '__esModule', { value: !0 }),
		(l.initializeAnalytics = void 0),
		(l.initializeAnalytics = function (t, n) {
			t.PlatformApi.setPlatformApi(t.PlatformApi.PlatformApis.WebBrowser);
			var e = new t.configuration.PublisherConfiguration({ publisherId: n.publisherId });
			t.configuration.addClient(e), t.start();
			var r = n.persistentLabels || n.persistentlabels;
			if (r) {
				var i = (function (t) {
					var n = {};
					if ('' != t)
						for (var e = 0, r = t.split(','); e < r.length; e++) {
							var i = r[e].split('=');
							i[0] && i[1] && (n[i[0].trim()] = i[1].trim());
						}
					return n;
				})(r);
				t.configuration.addPersistentLabels(i);
			}
		});
	var d = {},
		_ =
			(r && r.__assign) ||
			function () {
				return (
					(_ =
						Object.assign ||
						function (t) {
							for (var n, e = 1, r = arguments.length; e < r; e++)
								for (var i in (n = arguments[e]))
									Object.prototype.hasOwnProperty.call(n, i) && (t[i] = n[i]);
							return t;
						}),
					_.apply(this, arguments)
				);
			};
	Object.defineProperty(d, '__esModule', { value: !0 }), (d.processCustomMetadataLabels = void 0);
	var p = /^([Cc][A-Da-d]_)?ns_st_.+/,
		h = /^[Cc][A-Da-d]?([1-9]|1\d|20)$/;
	d.processCustomMetadataLabels = function (t, n) {
		var e = (function (t, n) {
				var e = [],
					r = [];
				for (var i in n) e.push(i);
				var o = t.exclude,
					s = t.excludePrefixes || t.exclude_prefixes,
					a = t.include,
					u = t.includePrefixes || t.include_prefixes;
				if ('_all_' == o || '_all_' == s || (!a && !u)) return {};
				if (a)
					if ('_all_' == a) r = e;
					else
						for (var c = 0, f = a.split(/[ ,]+/); c < f.length; c++) {
							(p = f[c]) in n && -1 == r.indexOf(p) && r.push(p);
						}
				if (u)
					if ('_all_' == u) r = e;
					else
						for (var l = u.split(/[ ,]+/), d = 0, _ = e; d < _.length; d++)
							for (var p = _[d], h = 0, v = l; h < v.length; h++) {
								var g = v[h];
								0 == p.indexOf(g) && -1 == r.indexOf(p) && r.push(p);
							}
				if (o)
					for (var E = 0, m = o.split(/[ ,]+/); E < m.length; E++) {
						p = m[E];
						-1 != (P = r.indexOf(p)) && r.splice(P, 1);
					}
				if (s)
					for (var A = s.split(/[ ,]+/), L = 0, y = r; L < y.length; L++) {
						p = y[L];
						for (var I = 0, b = A; I < b.length; I++) {
							g = b[I];
							var P = r.indexOf(g);
							0 == p.indexOf(g) && -1 != P && r.splice(P, 1);
						}
					}
				for (var N = {}, S = 0, w = r; S < w.length; S++) {
					null != n[(p = w[S])] && (N[p] = n[p] + '');
				}
				return N;
			})(t, n.current),
			r = (function (t) {
				var n = {};
				for (var e in t) (e.match(p) || e.match(h)) && null != t[e] && (n[e] = t[e] + '');
				return n;
			})(n.current),
			i = (function (t, n) {
				var e = /([^, ]+) *= *("[^"]+"|'[^']+'|[^, ]+)/gi,
					r = {},
					i = t.labelMapping || t.labelmapping;
				if (i) {
					var o = i.match(e);
					if (o)
						for (var s = 0, a = o; s < a.length; s++) {
							var u = a[s].split('='),
								c = u[0],
								f = u[1];
							if (c && f) {
								c = c.trim();
								var l = void 0;
								if ((l = (f = f.trim()).match(/^"(.+)"$/))) {
									var d = l[1];
									r[c] = d + '';
								} else if ((l = f.match(/^'(.+)'$/))) {
									d = l[1];
									r[c] = d + '';
								} else if (-1 != f.indexOf('.')) {
									var _ = f.split('.');
									if (_[0]) {
										var p = _[0].trim(),
											h = _.slice(1).join('.');
										h = h.trim();
										var v = n[p];
										v && null != v[h] && (r[c] = v[h] + '');
									}
								} else {
									p = f.trim();
									var g = n.current[p];
									g && null != g && (r[c] = n.current[p] + '');
								}
							}
						}
				}
				return r;
			})(t, n);
		return _(_(_({}, e), r), i);
	};
	var v = {};
	Object.defineProperty(v, '__esModule', { value: !0 }),
		(v.setStreamingPluginInfo = void 0),
		(v.setStreamingPluginInfo = function (t, n, e, r, i) {
			t.configuration.addPersistentLabels({ ns_st_mp: r, ns_st_mv: e, ns_st_pv: i }),
				n.setMediaPlayerName(r),
				n.setMediaPlayerVersion(e);
		}),
		(function (t) {
			var n =
					(r && r.__createBinding) ||
					(Object.create
						? function (t, n, e, r) {
								void 0 === r && (r = e);
								var i = Object.getOwnPropertyDescriptor(n, e);
								(i && !('get' in i ? !n.__esModule : i.writable || i.configurable)) ||
									(i = {
										enumerable: !0,
										get: function () {
											return n[e];
										},
									}),
									Object.defineProperty(t, r, i);
						  }
						: function (t, n, e, r) {
								void 0 === r && (r = e), (t[r] = n[e]);
						  }),
				e =
					(r && r.__exportStar) ||
					function (t, e) {
						for (var r in t)
							'default' === r || Object.prototype.hasOwnProperty.call(e, r) || n(e, t, r);
					};
			Object.defineProperty(t, '__esModule', { value: !0 }),
				(t.VERSION = void 0),
				(t.VERSION = '0.0.1+2205242515'),
				e(l, t),
				e(d, t),
				e(v, t);
		})(f);
	var g = (function () {
			function n(n, e) {
				var r = this;
				(this.player = n),
					(this.settings = e),
					f.initializeAnalytics(u, this.settings),
					(this.streamingAnalytics = new u.StreamingAnalytics()),
					f.setStreamingPluginInfo(u, this.streamingAnalytics, t.VERSION, 'videojs', c),
					this.player.on('playing', this.onPlayingEventListener.bind(this)),
					this.player.on('pause', this.onPauseEventListener.bind(this)),
					this.player.on('ended', this.onEndedEventListener.bind(this)),
					this.player.on('seeking', this.onSeekingEventListener.bind(this)),
					this.player.on('loadedmetadata', this.onLoadedMetadataEventListener.bind(this)),
					this.player.on('adend', this.onAdEndEventListener.bind(this)),
					this.player.on('dispose', this.onDisposeEventListener.bind(this)),
					this.player.on('ads-manager', function () {
						r.logEvent('ads-manager'),
							r.player.ima &&
								(r.player.ima.addEventListener(
									google.ima.AdEvent.Type.STARTED,
									r.onImaAdStartedEventListener.bind(r),
								),
								r.player.ima.addEventListener(
									google.ima.AdEvent.Type.PAUSED,
									r.onImaPausedEventListener.bind(r),
								),
								r.player.ima.addEventListener(
									google.ima.AdEvent.Type.RESUMED,
									r.onImaResumedEventListener.bind(r),
								),
								r.player.ima.addEventListener(
									google.ima.AdEvent.Type.COMPLETE,
									r.onImaAdCompleteEventListener.bind(r),
								),
								r.player.ima.addEventListener(
									google.ima.AdEvent.Type.SKIPPED,
									r.onImaAdSkippedEventListener.bind(r),
								));
					});
			}
			return (
				(n.prototype.log = function (t) {
					for (var n = [], r = 1; r < arguments.length; r++) n[r - 1] = arguments[r];
					this.settings.debug &&
						console.log.apply(console, e(['Comscore - ComscoreVideoJSPlugin', t], n, !1));
				}),
				(n.prototype.logEvent = function (t) {
					for (var n = [], r = 1; r < arguments.length; r++) n[r - 1] = arguments[r];
					this.log.apply(this, e(["Player event: '".concat(t, "'")], n, !1));
				}),
				(n.prototype.onPlayingEventListener = function () {
					this.logEvent('playing'), this.streamingAnalytics.notifyPlay();
				}),
				(n.prototype.onDisposeEventListener = function () {
					this.logEvent('dispose'), this.streamingAnalytics.notifyEnd();
				}),
				(n.prototype.onPauseEventListener = function () {
					this.logEvent('pause'), this.streamingAnalytics.notifyPause();
				}),
				(n.prototype.onEndedEventListener = function () {
					this.logEvent('ended'), this.streamingAnalytics.notifyEnd();
				}),
				(n.prototype.onSeekingEventListener = function () {
					this.logEvent('seeking'),
						this.streamingAnalytics.notifySeekStart(),
						this.streamingAnalytics.startFromPosition(Math.floor(1e3 * this.player.currentTime()));
				}),
				(n.prototype.onLoadedMetadataEventListener = function () {
					this.logEvent('loadedmetadata'), this.streamingAnalytics.createPlaybackSession();
					var t = new u.StreamingAnalytics.ContentMetadata(),
						n = this.player.el(),
						e = { content: {}, current: {} };
					n instanceof HTMLElement &&
						(t.setProgramTitle(n.title),
						t.setEpisodeTitle(n.title),
						t.setPlaylistTitle(n.title),
						(e = { content: n.dataset, current: n.dataset }));
					var r = f.processCustomMetadataLabels(this.settings, e);
					t.setMediaType(u.StreamingAnalytics.ContentMetadata.ContentType.OTHER),
						t.setLength(Math.floor(1e3 * this.player.duration())),
						t.setVideoDimensions(this.player.videoWidth(), this.player.videoHeight()),
						t.setClipUrl(this.player.currentSrc()),
						t.addCustomLabels(r),
						(this.currentContentMetadata = t),
						this.streamingAnalytics.setMetadata(this.currentContentMetadata);
				}),
				(n.prototype.onImaAdStartedEventListener = function () {
					var t = this;
					if (
						(this.logEvent('ima-started'),
						(this.adMaximumPlaybackTimeout = setTimeout(function () {
							t.streamingAnalytics.notifyEnd(),
								(t.currentAdMetadata = null),
								(t.adMaximumPlaybackTimeout = void 0);
						}, 18e4)),
						this.player.ima && this.player.ima.getAdsManager)
					) {
						var n = this.player.ima.getAdsManager().getCurrentAd(),
							e = n.getAdPodInfo().getPodIndex(),
							r = '',
							i = this.player.el(),
							o = { content: i instanceof HTMLElement ? i.dataset : {}, current: n, ad: n };
						r =
							0 == e
								? u.StreamingAnalytics.AdvertisementMetadata.AdvertisementType.ON_DEMAND_PRE_ROLL
								: -1 == e
								? u.StreamingAnalytics.AdvertisementMetadata.AdvertisementType.ON_DEMAND_POST_ROLL
								: u.StreamingAnalytics.AdvertisementMetadata.AdvertisementType.ON_DEMAND_MID_ROLL;
						var s = new u.StreamingAnalytics.AdvertisementMetadata();
						s.setRelatedContentMetadata(this.currentContentMetadata);
						var a = f.processCustomMetadataLabels(this.settings, o);
						s.setServer('ima'),
							s.setUniqueId(n.getAdId()),
							s.setMediaType(r),
							s.setVideoDimensions(n.getWidth(), n.getHeight()),
							s.setLength(Math.floor(1e3 * n.getDuration())),
							s.setTitle(n.getTitle()),
							s.addCustomLabels(a),
							(this.currentAdMetadata = s),
							this.log('Advertisement Metadata Labels:', s.getMetadataLabels()),
							this.streamingAnalytics.setMetadata(this.currentAdMetadata),
							this.streamingAnalytics.notifyPlay();
					}
				}),
				(n.prototype.onImaResumedEventListener = function () {
					this.logEvent('ima-resumed'), this.streamingAnalytics.notifyPlay();
				}),
				(n.prototype.onImaPausedEventListener = function () {
					this.logEvent('ima-paused'), this.streamingAnalytics.notifyPause();
				}),
				(n.prototype.onImaAdCompleteEventListener = function () {
					this.logEvent('ima-ad-completed'),
						clearTimeout(this.adMaximumPlaybackTimeout),
						this.streamingAnalytics.notifyEnd(),
						(this.currentAdMetadata = null),
						(this.adMaximumPlaybackTimeout = void 0);
				}),
				(n.prototype.onAdEndEventListener = function () {
					this.logEvent('adend'), this.streamingAnalytics.setMetadata(this.currentContentMetadata);
				}),
				(n.prototype.onImaAdSkippedEventListener = function () {
					this.logEvent('ima-ad-skipped'),
						clearTimeout(this.adMaximumPlaybackTimeout),
						this.streamingAnalytics.notifyEnd(),
						(this.currentAdMetadata = null),
						(this.adMaximumPlaybackTimeout = void 0);
				}),
				n
			);
		})(),
		E = (function (t) {
			function e(n, e) {
				var r = t.call(this, n, e) || this;
				return (r._streamingPlugin = new g(n, e)), r;
			}
			return (
				(function (t, e) {
					if ('function' != typeof e && null !== e)
						throw new TypeError(
							'Class extends value ' + String(e) + ' is not a constructor or null',
						);
					function r() {
						this.constructor = t;
					}
					n(t, e),
						(t.prototype = null === e ? Object.create(e) : ((r.prototype = e.prototype), new r()));
				})(e, t),
				(e.VERSION = c),
				e
			);
      // The below two lines do not work without the changes made.
		})(globalThis.videojs.getPlugin('plugin')); // Manually changed from "t" to globalThis.videojs.
    globalThis.videojs.registerPlugin('comscore', E); // Manually changed from "t" to globalThis.videojs.
});
