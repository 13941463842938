import videojs from 'video.js';
import Swiper, { Navigation } from 'swiper';

Swiper.use([Navigation]);

const Component = videojs.getComponent('Component');

class EndScreen extends Component {
	// eslint-disable-next-line class-methods-use-this
	createEl() {
		const el = videojs.dom.createEl('div', {
			className: 'vjs-endscreen',
		});

		return el;
	}

	// eslint-disable-next-line class-methods-use-this
	addNewElementToDOM(elementType, className, parentElement, index = '') {
		const newElement = document.createElement(elementType);
		newElement.classList.add(className);
		newElement.setAttribute('id', `${this.player_.id_}-${className}${index}`);
		parentElement.appendChild(newElement);
		return newElement;
	}

	createRecommendations(options) {
		const endscreenEl = this.el_;
		/* Adds current title. */
		const titleSection = this.addNewElementToDOM('div', 'replay-current', endscreenEl);
		const titleEl = this.addNewElementToDOM('span', 'current-video-title', titleSection);
		titleEl.innerHTML = this.player_.options_.title;

		/* Adds necessary swiper elements to endscreen so that
        recommendation plugin can initialize the swiper. */
		const swiperEl = this.addNewElementToDOM('div', 'swiper-container', endscreenEl);
		swiperEl.prepend(titleSection);
		const wrapperEl = this.addNewElementToDOM('div', 'swiper-wrapper', swiperEl);
		options.recommendations.forEach((element, index) => {
			const recEl = this.addNewElementToDOM('div', 'swiper-slide', wrapperEl, index);
			const imageWrapper = this.addNewElementToDOM('div', 'recommendation-wrapper', recEl, index);
			const recPoster = this.addNewElementToDOM('img', 'recommendation-image', imageWrapper, index);
			recPoster.src = element.image;
			recPoster.onerror = () => {
				console.log('error loading src for recommendation-image with video id:', element.mcpid);
				document.getElementById(`${this.player_.id_}-swiper-slide${index}`)?.remove();
				element.toBeRemoved = true;
			};
			const recTitle = this.addNewElementToDOM('div', 'recommendation-title', recEl, index);
			recTitle.innerHTML = element.title;
		});
		/* Adds navigation button elements to swiper. */
		this.addNewElementToDOM('div', 'swiper-button-prev', endscreenEl);
		this.addNewElementToDOM('div', 'swiper-button-next', endscreenEl);
	}
}

videojs.registerComponent('endscreen', EndScreen);
