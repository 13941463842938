import videojs from 'video.js';

import { is_dev } from '../util/env.js';

import { dispatchCustomEvent, analyticsPayloadDict } from '../util/helper.js';
import { videoInfoApiCaller } from '../util/anvato';

const Plugin = videojs.getPlugin('plugin');

const default_state = {
	next_item_ready: false,
	next_item: null,
	index: -1,
};

/* Rounds given number to given decimal point. */
function roundNumber(number, decimals = 1) {
	decimals = decimals >= 0 ? decimals : 0;
	return Math.round((number + Number.EPSILON) * 10 ** decimals) / 10 ** decimals;
}

class Playlist extends Plugin {
	constructor(player, playlist, _udl = {}, show_title_overlay, overrided_title) {
		super(player, playlist);

		this.setState({
			...default_state,
			playlist,
			_udl,
			show_title_overlay,
			overrided_title,
		});

		this.ad_tag_url = '';

		this.on('statechanged', (e) => {
			if (e.changes && e.changes.next_item && this.is_first_video() && this.state.next_item) {
				this._next().then(() => {
					const analyticsPayload = analyticsPayloadDict[player.id()];
					if (analyticsPayload) {
						analyticsPayload.playlistIndex = 1;
						analyticsPayload.playlistSize = playlist?.length;
					}
					dispatchCustomEvent(this.player, 'playlistStart');
				});
			}
		});

		this.player.on('timeupdate', () => {
			if (this.player.currentTime() === 0) return;
			if (this.player.scrubbing()) return;

			const currentTime = roundNumber(this.player.currentTime(), 0);
			const duration = this.player.duration();
			const milestone = 0.75;

			const currentRatio = roundNumber(currentTime / duration, 2);
			if (currentRatio >= milestone && !this.is_next_ready()) {
				this._prepare_next_item(player);
			}
		});

		let is_changing = false;
		this.player.on('ended', () => {
			setTimeout(() => {
				if (player?.ads?.isInAdMode && player.ads.isInAdMode()) {
				} else {
					/* Do not try to change the video as it's already getting changed. */
					if (is_changing) {
						return;
					}
					is_changing = true;
					this._next().then((success) => {
						if (success === true) {
							dispatchCustomEvent(this.player, 'playlistNewItem');
						}
						is_changing = false;
					});
				}
			}, 500);
		});

		// Loads the first item into the state
		this._prepare_next_item(player);
	}

	dispose() {
		super.dispose();
	}

	current_index() {
		return this.state.index;
	}

	last_index() {
		return this.state.playlist?.length - 1;
	}

	is_next_ready() {
		return this.state.next_item_ready;
	}

	is_first_video() {
		return this.state.index === -1;
	}

	set_new_ad_tag_url(ad_tag_url) {
		this.ad_tag_url = ad_tag_url;
	}

	update(playlist, _udl = null, ad_tag_url) {
		this.setState({
			...default_state,
			playlist,
			_udl,
			ad_tag_url,
		});

		this._prepare_next_item(this.player, 0);
		this._set_playlist_item(this.player, this.state);
	}

	static async get_refreshed_playlist_item(item, player) {
		if (!item?.id || !player?.id()) return;
		const refreshedData = await videoInfoApiCaller(item.id, null, null, player.id(), '');
		item.sources[0].src = refreshedData.url;
		item.isRefreshed = true;
		return item;
	}

	async _prepare_next_item() {
		const { playlist, index, _udl } = this.state;
		const next_index = index + 1;
		if (!(playlist?.length > next_index && next_index >= 0)) {
			return false;
		}
		let next_item = playlist[next_index];

		if (!this.is_first_video() && !next_item?.isRefreshed) {
			next_item = await this.constructor.get_refreshed_playlist_item(next_item, this.player);
		}

		return this.setState({
			...this.state,
			next_item_ready: true,
			next_item,
			_udl,
		});
	}

	async _next(newIndex) {
		const { playlist, index: originalIndex, next_item: prevNextItem } = this.state;
		const index = newIndex ?? originalIndex; // index === 0 breaks this that's why ?? is used instead of ||
		if (index >= playlist?.length || !this.is_next_ready()) {
			dispatchCustomEvent(this.player, 'playlistEnd');
			return false;
		}
		const next_item = playlist?.[index]
			? await this.constructor.get_refreshed_playlist_item(playlist?.[index], this.player)
			: prevNextItem;

		const set_success = await this._set_playlist_item(this.player, this.state);
		if (!set_success) {
			return false;
		}

		// Overlay configuration for each video of playlist
		if (this.state.show_title_overlay)
			this.player.overlay({
				content: this.state.overrided_title || this.state.next_item.title,
				align: 'top-left',
				overlays: [
					{
						start: 'overlayActive',
						end: 'overlayInactive',
					},
				],
			});

		// To honor autoplay props passed intially
		if (index >= 0) {
			this.player.play();
		}

		this.setState({
			...this.state,
			next_item,
			next_item_ready: false,
			index: newIndex || index + 1,
		});

		return true;
	}

	async _set_playlist_item(player) {
		const { next_item } = this.state;
		if (!next_item) {
			return false;
		}

		if (!this.is_first_video() && player?.ima?.setContentWithAdTag) {
			player.ima.setContentWithAdTag(
				next_item.sources,
				this.ad_tag_url || next_item.ads?.ad_tag_url,
			);

			try {
				player.ima.requestAds();
			} catch {}
		} else {
			player.src(next_item.sources);
		}
		player.poster(next_item.poster);
		const analyticsPayload = analyticsPayloadDict[player.id()];
		if (analyticsPayload) {
			analyticsPayload.playlistIndex++;
		}
		return true;
	}

	async set_current_index(index) {
		const { playlist } = this.state;
		const next_item = playlist?.[index];
		this.setState({
			...this.state,
			next_item,
			next_item_ready: true,
		});
		await this._next(index).then((success) => {
			if (success) {
				const analyticsPayload = analyticsPayloadDict[this.player.id()];
				analyticsPayload.isManuallyTriggered = true;
				analyticsPayload.recommendationIndex = 0;
				analyticsPayload.loadMethod = 'playlist';
				dispatchCustomEvent(this.player, 'playlistNewItem');
			}
		});
	}
}

if (!videojs.getPlugin('playlist')) {
	videojs.registerPlugin('playlist', Playlist);
}
