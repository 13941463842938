import React, { useEffect} from 'react';
import PropTypes from 'prop-types';

/**
 * Swipeable area on the right side of the minimized player.
 */
export default function TouchArea(props) {
  const {onExpand, title} = props;

  // This targets the video title on the right side under "now playing" or "up next".
  // It truncates it to fit within three lines of space and adds ellipses.
  useEffect(() => {
    const minTitle = document.querySelector(".min-title");
    if (minTitle?.textContent && minTitle.clientHeight >=50) {
      let text1 = minTitle.textContent.trim();
      let index1 = text1.lastIndexOf(' ');
      while (minTitle.clientHeight >= 50) {
        text1 = text1.substring(0, text1.length - 4);
        text1 = text1.substring(0, index1);
        index1 = text1.lastIndexOf(' ');
        text1 += ' ...';
        minTitle.textContent = text1;
      }
    }
  }, [TouchArea, title]);

  /**
   * This handles a swipe on the right half of the minimized player, with the black background.
   * It can end up also scrolling the page underneath the player at the same time which we
   * may want to address in a future update.
   */
  useEffect(() => {
    const touchArea = document.getElementById('touch-area');

    let mouseY = 0;
    let initialY = 0;
    let isSwiped;

    const events = {
      mouse: {
        down: 'mousedown',
        move: 'mousemove',
        up: 'mouseup',
      },
      touch: {
        down: 'touchstart',
        move: 'touchmove',
        up: 'touchend',
      },
    };
    let deviceType = '';

    const isTouchDevice = () => {
      try {
        document.createEvent('TouchEvent');
        deviceType = 'touch';
        return true;
      } catch (e) {
        deviceType = 'mouse';
        return false;
      }
    };

    const rectTop = touchArea.getBoundingClientRect().top;

    const getY = (e) => {
      mouseY = (! isTouchDevice() ? e.pageY : e.touches[0].pageY) - rectTop;
    };
    isTouchDevice();
    // Start Swipe
    touchArea.addEventListener(events[deviceType].down, (event) => {
      isSwiped = true;
      getY(event);
      initialY = mouseY;
    });
    // Mousemove / touchmove
    touchArea.addEventListener(events[deviceType].move, (event) => {
      if (! isTouchDevice()) {
        event.preventDefault();
      }
      if (isSwiped) {
        getY(event);
        const diffY = mouseY - initialY;
        if (diffY > 9) {
          onExpand();
        }
      }
    });
    // Stop Drawing
    touchArea.addEventListener(events[deviceType].up, () => {
      isSwiped = false;
    });
    touchArea.addEventListener('mouseleave', () => {
      isSwiped = false;
    });
    window.onload = () => {
      isSwiped = false;
    };
  }, []);

  return (
    <div id='touch-area'>
      <div className='video-status'>NOW PLAYING</div>
      <div className='min-title'>{title}</div>
      <button className='mobile-expand-button' aria-label='Expand Player' onClick={onExpand} type='button'/>
    </div>
  );
};

/* Set component property types. */
TouchArea.propTypes = {
	onExpand: PropTypes.func,
	title: PropTypes.string,
};

/* Set component default property values. */
TouchArea.defaultProps = {
	onExpand: () => {},
	title: '',
};
