import { getQueryParamsFromURL, getCookie, getItemFromSessionStorage } from '../util/helper';

export function generate_bob_url(
	base_bob_url,
	player,
	video_info,
	autoplay,
	_udl = {},
	progress = 0,
	isPreroll = false,
) {
	const BOB_PARAMS = [
		'd_code',
		'video_id',
		'page_url',
		'vid_asset_url',
		'vid_cr',
		'vid_desc',
		'vid_dim',
		'vid_duration',
		'vid_keywords',
		'vid_pageurl',
		'vid_playinit',
		'vid_title',
		'vid_type',
		'bob_cb',
		'bob_session_id',
		'video_play_sess_id',
		'repeat_visitor',
	];
	const query_params = new URLSearchParams(
		Array.from(BOB_PARAMS)
			.map((param) => {
				const key = param;
				let value = '';
				switch (param) {
					case 'vid_asset_url':
						value = player?.src();
						break;
					case 'vid_cr':
						/* Content progress */
						value = String(progress);
						break;
					case 'vid_desc':
						value = video_info?.description;
						break;
					case 'vid_dim':
						const width = (player?.currentWidth && player?.currentWidth()) || 0;
						const height = (player?.currentHeight && player?.currentHeight()) || 0;
						if (!width && !height) {
							break;
						}
						value = `${width}x${height}`;
						break;
					case 'vid_duration':
						/* Total duration */
						const total_seconds = player?.duration() || 0;
						const seconds = total_seconds % 60;
						const minutes = Math.floor(total_seconds / 60);
						if (minutes || seconds) {
							value = `${minutes}:${seconds}`;
						}
						break;
					case 'vid_keywords':
						value = (video_info?.categories || []).join(',');
						break;
					case 'vid_pageurl':
						value = _udl?.page?.fullUrl;
						break;
					case 'vid_playinit':
						value = autoplay ? 'Auto' : 'Manual';
						break;
					case 'vid_title':
						value = video_info?.title;
						break;
					case 'vid_type':
						value = isPreroll ? 'pre-roll' : 'video';
						break;
					case 'd_code':
						try {
							const custParams = getQueryParamsFromURL(new URL(video_info?.ads?.ad_tag_url)).get(
								'cust_params',
							);
							const dCodeValue = new URLSearchParams(custParams).get('d_code');
							value = dCodeValue
								? Array.isArray(dCodeValue) > 0
									? dCodeValue.join(',')
									: dCodeValue
								: '0';
						} catch (e) {
							value = '0';
						}
						break;
					case 'video_id':
						value = video_info?.id;
						break;
					case 'page_url':
						value = _udl?.page?.fullUrl;
						break;
					case 'bob_cb':
						// Cache Buster - unix timestamp to prevent browser caching
						value = +new Date();
						break;
					case 'bob_session_id':
						value = getCookie('bob_session_id') || 0;
						break;
					case 'repeat_visitor':
						value = getCookie('repeat_visitor') || 0;
						break;
					case 'video_play_sess_id':
						value = getItemFromSessionStorage('video_play_sess_id')?.value;
						break;
					default:
						break;
				}
				// value can be 0
				if (value !== 0 && !value) {
					return [];
				}
				return [key, value];
			})
			.filter((array) => array.length),
	);
	return `https://${base_bob_url}/bob_003.gif?${query_params.toString()}`;
}
