import videojs from 'video.js';

const Button = videojs.getComponent('Button');

/**
 * Playlist UI button.
 */
class PlaylistInterfaceButton extends Button {
  constructor(player, options) {
    super(player, options);

    this.addClass('vjs-playlist-interface-button');
    this.controlText(player.localize('Playlist'));
  }

  handleClick() {
    this.player().getChild('playlistInterface').addClass('visible');
  }
}

videojs.registerComponent('playlistInterfaceButton', PlaylistInterfaceButton);
