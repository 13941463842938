import { useEffect, useState } from 'react';
import { Typography } from '@material-ui/core';

import { useLocation } from 'react-router-dom';
import DemoPage from './components/DemoPage';
import { getQueryParamsFromURL } from './util/helper';

const getVideoIdList = (query) => {
	let videoIdList = [];
	try {
		videoIdList = JSON.parse(query.get('videos') || '["6211966", "6416622"]');
		videoIdList = videoIdList.map((item) => (item.includes(',') ? item.split(',') : String(item)));
	} catch (error) {
		videoIdList = ['6211966', '6416622', '6416421', '7096915'];
	}
	return videoIdList;
};

const getVideoOptions = (query) => {
	let videoOptionList = [];
	const option_string = query.get('options') || 'W10=';
	try {
		const decoded = Buffer.from(option_string, 'base64').toString();
		videoOptionList = JSON.parse(decoded);
	} catch (error) {
		console.error(error);
		videoOptionList = [];
	}
	return videoOptionList;
};

const App = () => {
	const location = useLocation();
	const [videos, setVideos] = useState([]);
	const [options, setOptions] = useState([]);

	useEffect(() => {
		const query = getQueryParamsFromURL(location);
		setOptions(getVideoOptions(query));
		setVideos(getVideoIdList(query));
	}, [location.search]);

	return (
		<>
			<Typography variant="h4" align="center" style={{ margin: '2% auto' }}>
				Video Player Demo
			</Typography>
			<DemoPage videos={videos} options={options} />
		</>
	);
};

export default App;
