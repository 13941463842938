/* Import VideoJS. */
// eslint-disable-next-line no-unused-vars
import videojs from 'video.js';

/* Import WM libraries. */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import debounce from 'debounce';

/* Import local libraries. */
import { generate_bob_url } from '../routines';
import { getItemFromSessionStorage } from '../util/helper';

/* Main video component. */
const BobPixel = ({
	player = {},
	video_info = {},
	autoplay = false,
	bobBaseHost = '',
	_udl = {},
}) => {
	const [bob_source, set_bob_source] = useState();
	const isAdsDisabled = video_info.ads?.disable_ads || false;

	/* Add event listeners for player start/end events. */
	useEffect(() => {
		if (player) {
			const start_handler = (isPreroll) => {
				set_bob_source(
					generate_bob_url(bobBaseHost, player, video_info, autoplay, _udl, 0, isPreroll),
				);
			};
			const ended_handler = (isPreroll) => {
				set_bob_source(
					generate_bob_url(bobBaseHost, player, video_info, autoplay, _udl, 100, isPreroll),
				);
			};

			// used debounce to prevent re-triggering for playlistNewItem
			const preRollStartBobRequest = () => start_handler(true);
			const debouncedPreRollStart = debounce(preRollStartBobRequest, 250);

			const initializeBob = () => {
				start_handler();
				if (window.google && !isAdsDisabled) debouncedPreRollStart();
			};
			const preRollEndBobRequest = () => ended_handler(true);
			const videoEndBobRequest = () => ended_handler();

			if (!autoplay) player.one('play', initializeBob);
			else player.one('playerReady', initializeBob);
			player.on('playlistNewItem', initializeBob);
			player.on('videoStart', preRollEndBobRequest);
			if (window.google && !isAdsDisabled) player.on('videoComplete', videoEndBobRequest);
			else player.on('ended', videoEndBobRequest);

			return () => {
				if (!autoplay) player.off('play', initializeBob);
				else player.off('playerReady', initializeBob);
				player.off('playlistNewItem', initializeBob);
				player.off('videoStart', preRollEndBobRequest);
				if (window.google && !isAdsDisabled) player.off('videoComplete', videoEndBobRequest);
				else player.off('ended', videoEndBobRequest);
			};
		}
	}, [player]);

	const removeVideoPlaySessId = () => {
		sessionStorage.removeItem('video_play_sess_id');
	};

	useEffect(() => {
		const currentVideoPlaySessId = getItemFromSessionStorage('video_play_sess_id');
		if (currentVideoPlaySessId && currentVideoPlaySessId.pathname === window.location.pathname)
			return;
		// below code executes once for the page.
		sessionStorage.setItem(
			'video_play_sess_id',
			JSON.stringify({
				pathname: window.location.pathname,
				value: `${+new Date()}-${Math.floor(Math.random() * 1000000)}`,
			}),
		);
		window.addEventListener('beforeunload', removeVideoPlaySessId);
		return () => {
			window.removeEventListener('beforeunload', removeVideoPlaySessId);
		};
	}, []);
	/* Return generated component. */
	return (
		<>
			{bobBaseHost && video_info.id && (
				<div className="screen-reader-text">
					<img
						id={`bob_ovp_${video_info.id}`}
						alt=""
						src={bob_source}
						style={{ width: '1px', opacity: 0 }}
					/>
				</div>
			)}
		</>
	);
};

/* Set component property types. */
BobPixel.propTypes = {
	video_info: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
	autoplay: PropTypes.bool,
	_udl: PropTypes.object,
	bobBaseHost: PropTypes.string,
	player: PropTypes.object,
};

/* Set component default property values. */
BobPixel.defaultProps = {
	video_info: {},
	autoplay: false,
	bobBaseHost: '',
	_udl: {},
};

/* Export component. */
export default BobPixel;
