import axiosInstance from 'axios';

const axios = ({ method, url, data, responseType }) =>
	axiosInstance({
		method,
		url,
		data,
		responseType,
	});
export default axios;
