import { trackers } from '@rmm/error-tracker';
import { ERROR_TRACKER_RENDER_GROUP, dispatchCustomEvent } from './helper';

const { SimpleErrorTracker } = trackers;

const loggerFn = (player_id, player_name) => {
	const videoStartTracker = new SimpleErrorTracker('videoStart (old)', 100, {
		id: player_id,
		position: player_name,
		renderGroup: ERROR_TRACKER_RENDER_GROUP.videoServe,
	});
	videoStartTracker.sendSuccess();
}

export const oldVideoStart = (player, ads, player_id, player_name) => {
	let isAdsLoaded;
	if (window.google && !ads.disable_ads) {
		let adsErrorHappened = false;
		player.one('readyforpreroll', () => {
			player.one('adend', () => {
				loggerFn(player_id, player_name);
			});
		});

		// Below code runs in case ad blocker is turned on
		// If ads are loaded do not execute below code, if not
		// Check if adserror is triggered for 3 seconds and dispatch
		// videoStart event when adserror occurs(this means ad blocker is blocking ads to load after video is started).
		// When adsready event is triggered set isAdsLoaded flag to true.
		// We are checking isAdsLoaded value for recommendation videos,
		// That is why it is a global variable.
		player.one('play', () => {
			if (isAdsLoaded) return;
			setTimeout(() => {
				if (adsErrorHappened) {
					loggerFn(player_id, player_name);
				}
			}, 3000);
		});

		player.one('adsready', () => {
			if (isAdsLoaded) return;
			isAdsLoaded = true;
		});

		player.one('adstart', () => {
			if (isAdsLoaded) return;
			isAdsLoaded = true;
		});

		player.one('adserror', () => {
			adsErrorHappened = true;
			isAdsLoaded = false;
		});
	} else {
		player.one('play', () => {
			setTimeout(() => loggerFn(player_id, player_name));
		});
	}
}

export const handleVideoIntentLogging = (player, player_id, player_name, adBlockerEnabled) => {
	const intentLogger = new SimpleErrorTracker('videoIntent', 100, {
		id: player_id,
		position: player_name,
		renderGroup: ERROR_TRACKER_RENDER_GROUP.videoServe,
	});

	if (player.videoStartAlreadyTriggered) {
		intentLogger.sendSuccess();
	} else {
		intentLogger.sendError(
			{
				name: 'videoStart was not fired',
				message: {
					adBlockerEnabled,
				},
			},
			10,
		);
		dispatchCustomEvent(player, 'videoStart');
	}
}
