/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
/* eslint-disable import/no-extraneous-dependencies */
import VideoPlayerComponent from '../../player.jsx';

import 'video.js/dist/video-js.css';
import 'videojs-ima/dist/videojs.ima.css';
import 'swiper/swiper-bundle.min.css';

const VideoPlayer = (props) => <VideoPlayerComponent {...props} />;

VideoPlayer.propTypes = VideoPlayerComponent.propTypes;

export default VideoPlayer;
