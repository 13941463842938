/* eslint-disable no-empty */
/* eslint-disable react-hooks/exhaustive-deps */
/* Import VideoJS. */
// eslint-disable-next-line no-unused-vars
import videojs from 'video.js';
import { ErrorBoundary } from 'react-error-boundary';

/* Import WM libraries. */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '@material-ui/icons/Close';
import './assets/videojs-share.scss';
import './assets/vjs-nexstar-skin.scss';

import { trackers } from '@rmm/error-tracker';

/* Import local libraries. */
import BobPixel from './components/BobPixel.jsx';
import TouchArea from './VideojsComponents/TouchArea';
import initialize_comscore from './routines/comscore.js';
import { gamAdtagRawUpdate } from './routines/ads.js';

import { is_test_environment } from './util/env.js';
import { handle_ads_resize, override_minimized_state } from './routines/sticky';
import { update_share } from './routines/ui';

import {
	as_react_style,
	dispatchCustomEvent,
	analyticsPayloadDict,
	resetAnalyticsPayloadDict,
	updateAdRequest,
	dispatchOverlayActiveEvent,
	dispatchOverlayInactiveEvent,
	ANALYTICS_ADPODTYPE,
	getCurrentTime,
	determineCorrectEvent,
	isAnvatoFeed,
	ERROR_TRACKER_RENDER_GROUP,
	videoStartDispatcher,
	detectBrowser,
	canRunAds,
} from './util/helper.js';

import { errorHandler, loadHandler } from './util/load-tracker.js';

import { initialize_player, handle_sticky, pauseAndUnfloatOtherInstance } from './routines';
import { handleVideoIntentLogging } from './util/logger';
import { videoGetFeedData, videoInfoApiCaller } from './util/anvato';

import axios from './util/axios';
import PLAYER_CONSTANTS from './util/constants';

const { ANVATO_KEY, EVENT_API_URL } = PLAYER_CONSTANTS;
let isVideoCompleteDispatched = false; // TODO move this property within the player object
const { SimpleErrorTracker } = trackers;

const eventInfoApiCaller = (video_id, anvack) => {
	const anvatoEventApiURL = `${EVENT_API_URL}/${video_id}?anvack=${anvack}`;
	return new Promise((resolve) =>
		axios({
			url: anvatoEventApiURL,
		}).then(({ data }) => {
			resolve(data);
		}),
	);
};

const initialVideoInfoValue = (video_id) => (Array.isArray(video_id) ? [] : undefined);

const playerInitMoment = performance.now();

/* Main video component. */
const VideoPlayer = ({
	video_id,
	player_id,
	sticky = false,
	share_embed = '',
	min_player_enabled = false,
	autoplay = false,
	ads = {},
	on_start = () => {},
	on_end = () => {},
	on_playlist = () => {},
	bobBaseHost = '',
	_udl = {},
	recommendations = true,
	update_ads = () => {},
	show_title_overlay = false,
	overrided_title,
	is_manually_triggered,
	player_name,
	extra_params,
	on_error = () => {},
	// eslint-disable-next-line react/prop-types
	test_video_info,
	anvack,
	nielsen,
	volume_level,
}) => {
	/* Generate element refs. */
	const placeholder_elem = useRef(null);
	const container_elem = useRef(null);
	const video_elem = useRef(null);
	/* Generate player state variables. */
	const [player, set_player] = useState(null);
	const [video_info, setVideoInfo] = useState(
		test_video_info
			? is_test_environment() // test_video_info should be used if is_test_environment() returns true only
				? { ...test_video_info }
				: initialVideoInfoValue(video_id)
			: initialVideoInfoValue(video_id),
	);
	let playlistEnded = false;
	const livestreamEvents = useRef(null);
	let scheduledEventInterval;
	const safelyUpdateAds = (updatedAds, id) => {
		if (canRunAds() && update_ads)  {
			update_ads(updatedAds, id);
		}
	}

	const populatePlaylist = (array_ids) => {
		Promise.all(
			array_ids.map((id) =>
				videoInfoApiCaller(id, ads, recommendations?.items || [], player_id, player_name, anvack),
			),
		)
			.then((videoInfos) => setVideoInfo(videoInfos))
			.catch((err) => {
				// Call on_error handler when there is any error occured with Akta's API.
				on_error(err);
			});
	};

	const loadNonce = async (video_id, ads) => {
		if (!Number.isNaN(parseInt(video_id, 10)) || ads?.isFreewheel) {
			return null;
		}

		if (!window.goog?.pal) {
			return null;
		}

		const consentSettings = new window.goog.pal.ConsentSettings();
		consentSettings.allowStorage = true;
		const nonceLoader = new window.goog.pal.NonceLoader(consentSettings);

		const request = new window.goog.pal.NonceRequest();
		request.adWillAutoPlay = true;
		request.adWillPlayMuted = true;
		request.continuousPlayback = false;
		request.descriptionUrl = window.location.href;
		request.iconsSupported = true;
		request.playerType = 'OVP Player';
		request.playerVersion = '1.0';
		request.ppid = ads?.ppid || '';
		request.supportedApiFrameworks = '2,7,9';
		request.url = 'https://developers.google.com/ad-manager/pal/html5';
		const nonceManager = await nonceLoader.loadNonceManager(request);
		return nonceManager.getNonce();
	};

	/* Fetch the video metadata according to video_id and register hook to initialize player. */
	useEffect(() => {
		if (!(test_video_info && is_test_environment()) && !video_id) {
			const message = 'video_id can not be null or undefined';
			console.error(message);
			return on_error(new Error(message));
		}
		if (isAnvatoFeed(video_id)) {
			videoGetFeedData(video_id).then((videoList) => {
				populatePlaylist(videoList);
			});
		} else if (Array.isArray(video_id)) {
			populatePlaylist(video_id);
		} else {
			let palNonce;
			loadNonce(video_id)
				.then((nonce) => {
					palNonce = nonce;
				})
				.catch(() => {
					// Failed to load the nonce should not stop the akta call.
				})
				.finally(() => {
					if (palNonce) {
						const parsedParams = new URLSearchParams(extra_params);
						parsedParams.set('ads.dfp_pal_nonce', palNonce);
						extra_params = parsedParams.toString(); // eslint-disable-line no-param-reassign
					}

					videoInfoApiCaller(video_id, ads, extra_params, player_id, player_name, anvack)
						.then((res) => {
							const videoInfo = Array.isArray(recommendations) ? { ...res, recommendations } : res;
							if (res?.videoType !== '2') {
								setVideoInfo(videoInfo);
								return;
							}

							eventInfoApiCaller(video_id, anvack).then((res) => {
								livestreamEvents.current = res?.schedule;
							}).finally(() => {
								setVideoInfo(videoInfo);
							});
						})
						.catch((err) => {
							// Call on_error handler when there is any error occured with Akta's API.
							on_error(err);
						});
				});
		}
	}, []);

	useEffect(() => {
		if (
			player ||
			!video_info ||
			(Array.isArray(video_id) &&
				video_id?.length !== video_info?.length &&
				!isAnvatoFeed(video_id))
		)
			return;
		const initializeTracker = new SimpleErrorTracker('Player initialize', 100, {
			id: player_id,
			position: player_name,
			renderGroup: ERROR_TRACKER_RENDER_GROUP.general,
		});
		const initialized_player = initialize_player(
			video_elem.current,
			video_info,
			player_id,
			autoplay,
			ads,
			_udl,
			recommendations,
			show_title_overlay,
			overrided_title,
			is_manually_triggered,
			player_name,
			on_error,
			initializeTracker,
			nielsen,
			share_embed,
			min_player_enabled,
			volume_level,
		);
		set_player(initialized_player);
	}, [video_info]);

	useEffect(
		() => () => {
			if (player) {
				player.trigger('unmount');
				player.dispose();
				set_player(null);
				clearInterval(scheduledEventInterval);
			}
		},
		[player],
	);

	useEffect(() => {
		if (!player || ads.disable_ads) return;
		const newTag = gamAdtagRawUpdate(player, ads?.ad_tag_url);
		player?.ima?.changeAdTag(newTag);

		if (!ads?.isFreewheel && player) {
			player?.ima?.controller?.setSetting('adWillPlayMuted', player.muted());
		}

		if (Array.isArray(video_info)) {
			player.playlist()?.set_new_ad_tag_url(newTag);
		} else if (typeof player?.ima?.setContentWithAdTag === 'function') {
			// Re-request the ads with the updated ad tag url after player update
			// Use the existing player source by passing 'undefined' as first parameter.
			player?.ima?.setContentWithAdTag(undefined, newTag);
			try {
				player?.ima?.requestAds();
				player?.play();
			} catch {
				console.log('ima.requestAds error!');
			}
		}
	}, [ads]);

	/* Generate video state variables. */
	const [current_info, set_current_info] = useState({});
	/* Register hook to configure current info. */
	useEffect(() => {
		if (Array.isArray(video_info)) {
			const current_index = (player && player.playlist().current_index()) || 0;
			set_current_info(video_info[current_index]);
		} else {
			set_current_info(video_info);
		}
	}, [video_info]);
	/* Register player event for playlist change. */
	useEffect(() => {
		if (player) {
			player.on('playlistNewItem', () => {
				videoStartDispatcher(player, ads);
				const currentPlaylistIndex = player.playlist().current_index();
				const currentPlayerInfo = video_info[currentPlaylistIndex];
				currentPlayerInfo.recommendation_index = currentPlayerInfo.recommendation_index || 0;
				currentPlayerInfo.load_method = currentPlayerInfo.load_method || 'playlist';
				set_current_info(currentPlayerInfo);
				resetAnalyticsPayloadDict(currentPlayerInfo, player.id());
				initialize_comscore(player, currentPlayerInfo);
				update_share(player, currentPlayerInfo);
			});
		}
	}, [player]);
	useEffect(() => {
		if (!player || !share_embed) {
			return;
		}

		fetch(share_embed)
			.then((response) => response.json())
			.then((response) => {
				const shareOverlay = player.getChild('ShareOverlay');
				if (response.embed && shareOverlay) {
					shareOverlay.options.embedCode = response?.embed.replace(/"/g, '&quot;');
				}
			})
		}, [player, share_embed]
	);

	/* Set stickyness state variables. */
	const [placeholder_style, set_placeholder_style] = useState({});
	const [is_sticky, set_is_sticky] = useState(false);
	const [is_minimized, set_is_minimized] = useState(false);
	/* Generate handler function for scroll event. */
	const scrollHandler = () => {
		if (sticky)
			handle_sticky(
				container_elem.current,
				placeholder_elem.current,
				player,
				is_sticky,
				is_minimized,
				min_player_enabled,
				set_is_sticky,
				set_is_minimized,
				set_placeholder_style,
			);
	};
	const minimizedOverrideHandler = (e) => {
		override_minimized_state(
			player,
			set_is_minimized,
			set_is_sticky,
			e.detail
		);
	};

	/* Register relevant hooks if given element is sticky. */
	useEffect(() => {
		/* Skip if not set to be sticky. */
		if (!sticky) {
			/* Corrective action for stickyness change. */
			if (is_sticky) {
				const analyticsPayload = analyticsPayloadDict[player.id()];
				if (analyticsPayload) {
					analyticsPayload.floatStatus = false;
					analyticsPayload.unfloatTrigger = 'automatic';
				}
				const is_playing = !player.paused() || player?.ima?.controller?.sdkImpl?.isAdPlaying();
				if (!is_playing) {
					dispatchCustomEvent(player, 'playerUnfloat');
				}
			}
			return () => {};
		}
		/* Register scroll event and minimized player override */
		window.addEventListener('scroll', scrollHandler);
		window.addEventListener('nxst-ovp-set-minimized-state', minimizedOverrideHandler);

		/** We also need to minimize the player if required when a player plays (autoplay next video) */
		let playerIsPlayingInterval = false;
		if (player) {
			/** There is a change that the play event trigger but the player is not yet playing */
			/** We need to make sure that is happening first before calling scrollHandler */
			player.on('play', () => {
				if (playerIsPlayingInterval) {
					return;
				}

				playerIsPlayingInterval = setInterval(() => {
					const is_playing = !player.paused() || player?.ima?.controller?.sdkImpl?.isAdPlaying();
					if (is_playing) {
						scrollHandler();
						clearInterval(playerIsPlayingInterval);
					}
				}, 100);
			});

			player.on('pause', () => {
				if (playerIsPlayingInterval) clearInterval(playerIsPlayingInterval);
			});

			player.on('ended', () => {
				if (playerIsPlayingInterval) clearInterval(playerIsPlayingInterval);
			});
		}

		/* Deregisteration for scroll event and interval checker */
		return () => {
			if (playerIsPlayingInterval) clearInterval(playerIsPlayingInterval);
			window.removeEventListener('scroll', scrollHandler);
			window.removeEventListener('nxst-ovp-set-minimized-state', minimizedOverrideHandler);
		};
	}, [player, sticky, is_sticky, is_minimized]);

	/* Exposing some events to WM side. */
	useEffect(() => {
		if (!player) {
			return;
		}
		const get_payload = () => {
			if (Array.isArray(video_info)) {
				return { videoInfo: video_info, playerId: player.id_ };
			}
			return { videoInfo: current_info, playerId: player.id_ };
		};
		const event_calls = {
			on_start: () => on_start(get_payload()),
			on_playlist: () => on_playlist(get_payload()),
			on_end: () => on_end(get_payload()),
		};
		player.one('play', event_calls.on_start);
		player.one('playlistNewItem', event_calls.on_playlist);

		player.one('play', () => {
			if (player.isLivestream) {
				const analyticsPayload = analyticsPayloadDict[player.id()];
				const setScheduledEventTitle = () => {
					const determinedEvent = determineCorrectEvent(livestreamEvents.current);
					if (!determinedEvent) {
						// If there is no current event and video title is set previously
						// Set the original title back as videoTitle
						if (analyticsPayload.videoTitle !== analyticsPayload.originalVideoTitle) {
							analyticsPayload.videoTitle = analyticsPayload.originalVideoTitle;
							dispatchCustomEvent(player, 'titleUpdated');
							if (show_title_overlay && !overrided_title) {
								player.overlay({
									content: analyticsPayload?.originalVideoTitle,
									align: 'top-left',
									overlays: [
										{
											start: 'overlayActive',
											end: 'overlayInactive',
										},
									],
								});
								dispatchOverlayActiveEvent(player);
							}
						}
						return;
					}
					const { content: currentRenderedTitle, class: overlayClass } =
						player.overlays_?.[0]?.options_ || {};
					// Adding !overlayClass.includes("ad") to prevent ad overlay message in showMidrollOverlay
					// Function to break this logic.
					if (currentRenderedTitle !== determinedEvent?.title && !overlayClass?.includes('ad')) {
						analyticsPayload.videoTitle =
							determinedEvent?.title || analyticsPayload.originalVideoTitle;
						dispatchCustomEvent(player, 'titleUpdated');
						if (show_title_overlay && !overrided_title) {
							player.overlay({
								content: determinedEvent?.title,
								align: 'top-left',
								overlays: [
									{
										start: 'overlayActive',
										end: 'overlayInactive',
									},
								],
							});
							dispatchOverlayActiveEvent(player);
						}
					}
				};
				// Dont set any interval if current_event, next_event and upcoming_events values are empty
				if (
					Object.values(livestreamEvents.current)?.filter((event) => Object.keys(event)?.length)
						.length
				) {
					setScheduledEventTitle();
					scheduledEventInterval = setInterval(() => setScheduledEventTitle(), 10 * 1000);
				}
			}
		});

		if (Array.isArray(video_info)) {
			player.one('playlistEnd', () => {
				playlistEnded = true;
			});
			player.one('playlistEnd', event_calls.on_end);
		} else {
			player.one('ended', event_calls.on_end);
		}
		// eslint-disable-next-line consistent-return
		return () => {
			player.off('play', event_calls.on_start);
			player.off('playlistNewItem', event_calls.on_playlist);
			player.off('playlistEnd', event_calls.on_end);
			player.off('ended', event_calls.on_end);
		};
	}, [player, current_info]);
	/* Analytics events. */
	useEffect(() => {
		if (player) {
			let videoIntent = true;
			let videoFirstQuartile = true;
			let videoMidPoint = true;
			let videoThirdQuartile = true;
			let previousTime = 0;
			let currentTime = 0;
			let videoFirstQuartileMark = null;
			let videoMidPointMark = null;
			let videoThirdQuartileMark = null;
			let naturallyHitFirstQuartile = false;
			let naturallyHitMidPoint = false;
			let naturallyHitThirdQuartile = false;
			const adHeartbeatInterval = 1;
			const videoHeartbeatInterval = 10;
			let lastHeartbeat = 0;
			let adHeartbeatID;
			const playerContainer = player.el_;
			let videoLocation = null;
			const is_firefox = detectBrowser() === 'Firefox';
			if (Boolean(playerContainer.closest('#daily-news-video'))) {
				videoLocation = 'daily news';
			} else if (Boolean(playerContainer.closest('.article-featured-media'))) {
				videoLocation = 'lead media';
			} else if (Boolean(playerContainer.closest('.video-center-content'))) {
				videoLocation = 'video center';
			} else if (
				Boolean(playerContainer.closest('.post-video-bin')) ||
				Boolean(playerContainer.closest('.widget_ns-video-bin'))
			) {
				videoLocation = 'video bin';
			}

			// Reset these variables to default values
			const resetAnalyticsValues = () => {
				videoIntent = true;
				videoFirstQuartile = true;
				videoMidPoint = true;
				videoThirdQuartile = true;
				videoFirstQuartileMark = null;
				videoMidPointMark = null;
				videoThirdQuartileMark = null;
				naturallyHitFirstQuartile = false;
				naturallyHitMidPoint = false;
				naturallyHitThirdQuartile = false;
				lastHeartbeat = 0;
				isVideoCompleteDispatched = false;
			};

			const analyticsPayload = analyticsPayloadDict[player.id()];
			if (analyticsPayload) {
				analyticsPayload.frequency = videoHeartbeatInterval;
			}
			// Listen to visibilitychange in order to play the player if it is not started already
			// When the tab is active.
			document.addEventListener('visibilitychange', () => {
				if (
					player &&
					player.currentTime &&
					!player?.currentTime() &&
					autoplay &&
					(!player?.ads?.isInAdMode ||
						(typeof player?.ads?.isInAdMode === 'function' && !player?.ads?.isInAdMode())) &&
					document.visibilityState === 'visible'
				) {
					if (is_firefox) {
						player.on(['loadeddata'], () => {
							player.play();
						});
					} else {
						player.ready(() => {
							player.play();
						});
					}
				}
			});
			player.on('playerUpdate', () => {
				// Reset analytics variables to default values when recommendation video is
				// Played in order to be able to dispatch quartile events
				resetAnalyticsValues();
				videoStartDispatcher(player, ads, player_id, player_name);
			});
			player.on('videoReplay', () => {
				// Reset analytics variables to default values when a video is replayed
				// Played in order to be able to dispatch quartile events
				resetAnalyticsValues();
				// We want to see videoStartCount + 1 value in the upcoming videoStart event payload
				analyticsPayload.videoStartCount++;
				analyticsPayload.videoStartMethod = 'Replay';
				player.isPlayedViaReplayButtonClick = true;
				analyticsPayload.videoInitType = 'manual';
				dispatchCustomEvent(player, 'videoStart');
			});
			player.on('playlistNewItem', () => {
				resetAnalyticsValues();
			});

			player.el_.addEventListener(
				'videoStart',
				() =>
					setTimeout(() => {
						// This will only be checked when we're on at least the second video in a playlist.
						// Because of this, if the playlist index is not more than 0, this isn't a playlist.
						// But if the index has increased, then we know we're not on recommendations unless...
						// playlistEnded is also true, since it fires on the playlistEnd event.
						const isPlaylistPresentAndOver = analyticsPayload.playlistIndex > 0 && playlistEnded;
						// We also need to account for if the playlist doesn't exist as part of the check for recommendations.
						// This will always be false on first video. That's acceptable, as the first video will never be from recommendations.
						const noPlaylist = analyticsPayload.playlistSize === 0;
						// Are we currently in a playlist? Note: Index will be 1 for the first video, not 0.
						const inPlaylist = analyticsPayload.playlistIndex > 0 && !playlistEnded;
						// First check is for after first video recommended video starts, which means playlists need to be over.
						// The first video will never be Recommendations, since they play after at least one intentionally embedded video.
						if (player.isPlayedViaReplayButtonClick) {
							player.isPlayedViaReplayButtonClick = false;
						} else if (
							analyticsPayload.videoStartCount > 1 &&
							recommendations &&
							(isPlaylistPresentAndOver || noPlaylist)
						) {
							analyticsPayload.videoStartMethod = 'Recommendations';
						} else if (videoLocation === 'lead media' && inPlaylist) {
							analyticsPayload.videoStartMethod = 'Lead Media Playlist';
						} else if (videoLocation === 'daily news') {
							analyticsPayload.videoStartMethod = 'Daily News with Video hero unit';
						} else if (videoLocation === 'video center') {
							analyticsPayload.videoStartMethod = 'Video Center';
						} else if (videoLocation === 'video bin') {
							// Only for video bins that don't fit other options above
							analyticsPayload.videoStartMethod = 'Video Bin Widget';
						} else {
							analyticsPayload.videoStartMethod = 'Default';
						}

						if (inPlaylist) {
							// If in playlist, set default values for recommendationIndex and loadMethod
							analyticsPayload.recommendationIndex = analyticsPayload.recommendationIndex || 0;
							analyticsPayload.loadMethod = analyticsPayload.loadMethod || 'playlist';
						}
					}),
				250,
			);
			player.one('loadstart', () => {
				analyticsPayload.loadTime = performance.now() - playerInitMoment;
				const currentVideoId = Array.isArray(video_id)
					? player.playlist()?.state?.next_item?.id
					: video_id;
				loadHandler(currentVideoId, player.id(), analyticsPayload, player.el_);
				dispatchCustomEvent(player, 'playerReady');
				const loadStartTracker = new SimpleErrorTracker('Video load started', 100, {
					id: player_id,
					position: player_name,
					renderGroup: ERROR_TRACKER_RENDER_GROUP.videoInit,
				});
				loadStartTracker.sendSuccess();
			});
			player.on('pause', () => {
				dispatchOverlayActiveEvent(player);
			});
			// This allows clicking anywhere on the player (except the control bar) to pause and play
			player.on('touchstart', (event) => {
				if (event.target.tagName === 'VIDEO') {
					if (player.paused()) {
						player.play();
					} else {
						player.pause();
					}
				}
			});
			player.on('error', () => {
				if (analyticsPayload) {
					analyticsPayload.errorMessage = player.error();
				}
				const onErrorTracker = new SimpleErrorTracker('Video onError call', 100, {
					id: player_id,
					position: player_name,
					renderGroup: ERROR_TRACKER_RENDER_GROUP.general,
				});
				onErrorTracker?.sendError(
					{
						name: 'Render video Error',
						message: player.error(),
					},
					1,
				);
				// Keep track of player.isOnErrorCalled since more than 1 error
				// Handler can get triggered for an error scenario.
				if (!player.isOnErrorCalled) {
					player.isOnErrorCalled = true;
					dispatchCustomEvent(player, 'playbackError');
					console.warning("on_error is called in player's on error handler");
					on_error(player.error());
				}
			});
			player.on('loadstart', () => {
				dispatchCustomEvent(player, 'bufferStart');
				dispatchOverlayActiveEvent(player);
			});
			player.on('useractive', () => {
				dispatchOverlayActiveEvent(player);
			});
			player.on('userinactive', () => {
				if (!player.paused()) {
					dispatchOverlayInactiveEvent(player);
				}
			});
			player.on('loadeddata', () => {
				dispatchCustomEvent(player, 'bufferEnd');
				player.textTracks()?.addEventListener('change', () => {
					if (
						player.controlBar.el_?.querySelector(
							'.vjs-subs-caps-button .vjs-menu-item.vjs-selected span',
						)?.outerText !== 'captions off'
					) {
						analyticsPayload.closedCaptions = true;
					} else {
						analyticsPayload.closedCaptions = false;
					}
				});
				const loadedDataTracker = new SimpleErrorTracker('Video load finished', 100, {
					id: player_id,
					position: player_name,
					renderGroup: ERROR_TRACKER_RENDER_GROUP.videoInit,
				});
				loadedDataTracker.sendSuccess();
				setTimeout(() => {
					scrollHandler();
				}, 500);
			});
			player.on('loadedmetadata', () => {
				const loadedMetadataTracker = new SimpleErrorTracker('Video meta data loaded', 100, {
					id: player_id,
					position: player_name,
					renderGroup: ERROR_TRACKER_RENDER_GROUP.videoInit,
				});
				loadedMetadataTracker.sendSuccess();
			});
			player.one('seeking', () => {
				dispatchCustomEvent(player, 'seekStart');
			});
			player.on('seeked', () => {
				dispatchCustomEvent(player, 'seekEnd');
				player.one('seeking', () => {
					dispatchCustomEvent(player, 'seekStart');
				});
				// This becomes false after three seconds of the video has played.
				videoIntent = getCurrentTime(player) < 3;
			});
			player.on('fullscreenchange', () => {
				if (player.isFullscreen()) {
					if (analyticsPayload) {
						analyticsPayload.fullScreen = true;
					}
					dispatchCustomEvent(player, 'fullscreenOn');
				} else {
					if (analyticsPayload) {
						analyticsPayload.fullScreen = false;
					}
					dispatchCustomEvent(player, 'fullscreenOff');
				}
			});
			player.on('readyforpostroll', () => {
				analyticsPayload.adPodType = ANALYTICS_ADPODTYPE.POSTROLL;
				isVideoCompleteDispatched = true;
				dispatchCustomEvent(player, 'videoComplete');
			});

			player.on('ended', () => {
				player.videoStartAlreadyTriggered = false;

				const videoEndedTracker = new SimpleErrorTracker('Video playback ended', 100, {
					id: player_id,
					position: player_name,
					renderGroup: ERROR_TRACKER_RENDER_GROUP.videoServe,
				});
				videoEndedTracker.sendSuccess();
				setTimeout(() => {
					if (!isVideoCompleteDispatched) {
						dispatchCustomEvent(player, 'videoComplete');
					}
					dispatchCustomEvent(player, 'playbackComplete');
					player.one('play', () => {
						dispatchCustomEvent(player, 'playbackStart');
					});
				});
			});
			player.on('timeupdate', () => {
				// Add ability to track the previous and current time every timeupdate event.
				previousTime = currentTime;
				currentTime = getCurrentTime(player);
				// Handy reference to the moment in the current video that each quartile refers to.
				videoFirstQuartileMark = player.duration() / 4;
				videoMidPointMark = player.duration() / 2;
				videoThirdQuartileMark = (3 * player.duration()) / 4;
				// Grab the moment in time we need to hit per quartile, and give a buffer around it.
				// This is because the timeupdate is not consistently the same time between updates.
				// However, there should never be more than a second between current and previous time.
				naturallyHitFirstQuartile =
					currentTime <= videoFirstQuartileMark + 1 && previousTime >= videoFirstQuartileMark - 1;
				naturallyHitMidPoint =
					currentTime <= videoMidPointMark + 1 && previousTime >= videoMidPointMark - 1;
				naturallyHitThirdQuartile =
					currentTime <= videoThirdQuartileMark + 1 && previousTime >= videoThirdQuartileMark - 1;
				if (!player.paused()) {
					if (videoIntent && getCurrentTime(player) >= 3) {
						videoIntent = false;
						dispatchCustomEvent(player, 'videoIntent');
						handleVideoIntentLogging(player, player_id, player_name, window.isAdBlockerActive);
					} else if (videoFirstQuartile && naturallyHitFirstQuartile) {
						videoFirstQuartile = false;
						dispatchCustomEvent(player, 'videoFirstQuartile');
					} else if (videoMidPoint && naturallyHitMidPoint) {
						videoMidPoint = false;
						dispatchCustomEvent(player, 'videoMidPoint');
					} else if (videoThirdQuartile && naturallyHitThirdQuartile) {
						videoThirdQuartile = false;
						dispatchCustomEvent(player, 'videoThirdQuartile');
					}
				}
				const currentSec = Math.floor(getCurrentTime(player));
				if (currentSec % videoHeartbeatInterval === 0 && currentSec > lastHeartbeat) {
					if (!player.clickBeaconObj?.anvInfoType) dispatchCustomEvent(player, 'videoHeartbeat');
					clearInterval(adHeartbeatID);
					lastHeartbeat = currentSec;
				}
			});
			player.on('volumechange', (event) => {
				const isMuted = player.muted();
				if (analyticsPayload) {
					analyticsPayload.volume = player.volume();
					analyticsPayload.muteStatus = isMuted;
				}

				const newTag = gamAdtagRawUpdate(player, ads?.ad_tag_url);
				ads.ad_tag_url = newTag;

				// Trigger Both events if is muted to keep consistency to AKTA player.
				if (isMuted) {
					dispatchCustomEvent(player, 'volumeMute');
				}

				dispatchCustomEvent(player, 'volumeChange');
			});
			const replayEventHandler = () => {
				const playToggle = player.controlBar.playToggle;
				if (playToggle.el_.classList.contains('vjs-ended')) {
					player.one('seeked', () => {
						if (!playToggle.el_.classList.contains('vjs-ended')) {
							playToggle.el_.classList.add('vjs-ended');
						}
					});

					playToggle.one(['click', 'tap'], () => {
						player.currentTime(0);
						// making sure that the class is there.
						if (player.controlBar.playToggle.el_.classList.contains('vjs-ended')) {
							dispatchCustomEvent(player, 'videoReplay');
						}
					});
				}
			};
			player.one('ended', replayEventHandler);
			player.on('videoReplay', () => {
				player.one('ended', replayEventHandler);
			});
			player.on('readyforpreroll', () => {
				analyticsPayload.adPodType = ANALYTICS_ADPODTYPE.PREROLL;
				player.isPrerollEnded = false;
			});
			player.on('videoStart', () => {
				player.isPrerollEnded = true;
				const videoStart = new SimpleErrorTracker('Video playback started', 100, {
					id: player_id,
					position: player_name,
					renderGroup: ERROR_TRACKER_RENDER_GROUP.videoServe,
				});
				if (!player.isPlayedViaReplayButtonClick) analyticsPayload.videoStartCount++;
				if (player.isLivestream) {
					analyticsPayload.adPodType = ANALYTICS_ADPODTYPE.MIDROLL;
				}
				videoStart.sendSuccess();
			});
			player.on('adsready', () => {
				player.ima.addEventListener(window?.google?.ima.AdEvent.Type.STARTED, (adEvent) => {
					if (videojs.browser.IS_IOS && player.isFullscreen()) {
						player.exitFullscreen();
					}
					// Set inner div container height&width to 100% explicitly
					const adContainer = document.getElementById(`${player_id}_ima-ad-container`);
					const innerAdContainer = adContainer?.querySelector('div');
					if (innerAdContainer) {
						innerAdContainer.style.width = '100%';
						innerAdContainer.style.height = '100%';
					}
					dispatchCustomEvent(player, 'adBreakStart');
					adHeartbeatID = setInterval(() => {
						dispatchCustomEvent(player, 'adHeartbeat');
					}, adHeartbeatInterval * 1000);
					const currentAd = adEvent.getAd();
					if (analyticsPayload) {
						analyticsPayload.adTitle = currentAd.getTitle();
						analyticsPayload.adId = currentAd.getAdId();
						analyticsPayload.adDuration = currentAd.getDuration();
						// eslint-disable-next-line no-restricted-globals
						analyticsPayload.adDurationTime = !isNaN(analyticsPayload.adDuration)
							? new Date(analyticsPayload.adDuration * 1000).toISOString()?.substring(11, 19)
							: undefined;
						const adPod = currentAd?.getAdPodInfo().data;
						analyticsPayload.adPodSize = adPod?.totalAds;
						analyticsPayload.adPodPosition = adPod?.adPosition;
					}
					dispatchCustomEvent(player, 'adStart');
					const adStartTracker = new SimpleErrorTracker('adStart', 100, {
						id: player_id,
						position: player_name,
						renderGroup: ERROR_TRACKER_RENDER_GROUP.adServe,
					});
					adStartTracker.sendSuccess();
				});
				player.ima.addEventListener(window?.google?.ima.AdEvent.Type.COMPLETE, () => {
					if (videojs.browser.IS_IOS && player.isFullscreen()) {
						player.exitFullscreen();
					}
					dispatchCustomEvent(player, 'adComplete');
					setTimeout(() => dispatchCustomEvent(player, 'adBreakComplete'));
					delete analyticsPayload.adPodSize;
					delete analyticsPayload.adPodPosition;
					clearInterval(adHeartbeatID);
					const adContainer = document.querySelector('[data-vjs-player=true] .ima-ad-container');
					if (adContainer) adContainer.style.display = 'none';
				});
				player.ima.addEventListener(window?.google?.ima.AdEvent.Type.PAUSED, () => {
					dispatchCustomEvent(player, 'playbackPause');
					clearInterval(adHeartbeatID);
				});
				player.ima.addEventListener(window?.google?.ima.AdEvent.Type.LOADED, () => {
					if (videojs.browser.IS_IOS) {
						const adsFullscreenIcon = player.el_.querySelector('.ima-fullscreen-div');
						if (adsFullscreenIcon) adsFullscreenIcon.style.display = 'none';
					}
					// For the 2nd preroll, ima ad container gets disappeared even if the 2nd preroll is still active
					// Thus, I found this workaround to display the ad container, and this code block works only if player is paused
					// And player is not started yet.
					const adContainer = document.getElementById(`${player_id}_ima-ad-container`);
					if (adContainer && player.paused() && !player.currentTime())
						adContainer.style.display = 'block';
				});
				player.ima.addEventListener(window?.google?.ima.AdEvent.Type.RESUMED, () => {
					dispatchCustomEvent(player, 'playbackResume');
					clearInterval(adHeartbeatID);
					adHeartbeatID = setInterval(() => {
						dispatchCustomEvent(player, 'adHeartbeat');
					}, adHeartbeatInterval * 1000);
				});
				player.ima.addEventListener(window?.google?.ima.AdEvent.Type.CLICK, () => {
					dispatchCustomEvent(player, 'adClick');
					clearInterval(adHeartbeatID);
				});
				player.ima.addEventListener(window?.google?.ima.AdEvent.Type.SKIPPED, () => {
					dispatchCustomEvent(player, 'adSkip');
					clearInterval(adHeartbeatID);
					// SetTimeout needed to avoid the issue of two events being dispatched at the same time.
					setTimeout(() => dispatchCustomEvent(player, 'adBreakComplete'));
					const adContainer = document.querySelector('[data-vjs-player=true] .ima-ad-container');
					if (adContainer) adContainer.style.display = 'none';
				});
				player.ima.addEventListener(window?.google?.ima.AdEvent.Type.VOLUME_CHANGED, () => {
					analyticsPayload.muteStatus = false;
				});
				player.ima.addEventListener(window?.google?.ima.AdEvent.Type.VOLUME_MUTED, () => {
					analyticsPayload.muteStatus = true;
				});
			});
		}
	}, [player]);
	useEffect(() => {
		if (player) {
			// Listen to playerUpdate event for recommendation videos
			// And update video info/analytics payload/ad tag url with the new video's info/id and call
			// Callback function to update caller class ads
			player.on('playerUpdate', (event) => {
				setVideoInfo(event.detail);
				resetAnalyticsPayloadDict(event.detail, player.id());

				// Grabbing the things we want to add to custParams. Default videoDuration to null if not present.
				const { videoDuration = null, id, ad_unit_path } = event.detail || {};
				// Renaming them as needed
				const custParams = { content_len: videoDuration, vid: id };
				const additionalParams = {};
				if (ad_unit_path?.length && !ads?.isFreewheel) {
					additionalParams.iu = ad_unit_path;
				};
				
				const updatedAds = updateAdRequest(ads, custParams, additionalParams, player.muted());
				update_ads(updatedAds, id);
				safelyUpdateAds(updatedAds, id);
			});
		}
	}, [player]);
	/* Ensures there is only one floating and playing OVP instance. */
	useEffect(() => {
		if (player) {
			player.on('play', () => {
				pauseAndUnfloatOtherInstance(player);
			});
			player.on('adsready', () => {
				player.ima.addEventListener(window?.google?.ima.AdEvent.Type.RESUMED, () => {
					pauseAndUnfloatOtherInstance(player);
				});
			});
		}
	}, [player]);
	/* Unfloats the player when another OVP instance starts playing. */
	useEffect(() => {
		if (player) {
			player.on('desticky', () => {
				player.isFloating = false;
				set_is_sticky(false);
				dispatchCustomEvent(player, 'playerUnfloat');
			});
		}
	}, [player]);

	const classes = `${is_sticky ? 'floating' : 'inline-player-container'} ${
		is_minimized ? 'minimized' : 'expanded'
	}`;

	const currentTitle = overrided_title || analyticsPayloadDict[player?.id()]?.videoTitle || current_info?.title;
	const expandedTitle = show_title_overlay ? currentTitle : '';
	const minimizedTitle = currentTitle || ''; // Min player ignores the overlay toggle.

	if (!video_id) return null;
	/* Return generated component. */
	return (
		<ErrorBoundary
			FallbackComponent={() => <div />}
			onError={(error) => {
				errorHandler(video_id, player?.id(), analyticsPayloadDict, error);
			}}>
			{is_sticky && <div ref={placeholder_elem} style={as_react_style(placeholder_style)} />}
			<div className={classes} ref={container_elem}>
				{is_sticky && !is_minimized && (
					<div className="title-bar">
						<span>{expandedTitle}</span>
						<CloseIcon
							onClick={() => {
								const analyticsPayload = analyticsPayloadDict[player.id()];
								if (analyticsPayload) {
									analyticsPayload.floatStatus = false;
									analyticsPayload.unfloatTrigger = 'manual';
								}
								dispatchCustomEvent(player, 'playerUnfloat');
								player.pause();
								try {
									player.ima.pauseAd();
								} catch {}
								player.isFloating = false;
								set_is_sticky(false);
							}}
							className="close-expanded-player"
						/>
					</div>
				)}
				<div data-vjs-player data-internal-video-id={video_id?.id || video_id}>
					<video
						className="vjs-nexstar-skin video-js"
						ref={video_elem}
						style={{ display: !video_info ? 'none' : 'block' }}
					/>
				</div>
				{is_sticky && is_minimized && (
					<div id="min-right-side">
						<TouchArea
							onExpand={() => {
								set_is_minimized(false);
								handle_ads_resize(player_id);
								dispatchCustomEvent(player, 'playerFloat');
							}}
							title={minimizedTitle}
							player={player}
						/>
						<CloseIcon
							onClick={() => {
								const analyticsPayload = analyticsPayloadDict[player.id()];
								if (analyticsPayload) {
									analyticsPayload.floatStatus = false;
									analyticsPayload.unfloatTrigger = 'manual';
								}
								const eventName = is_minimized ? 'playerUnminimize' : 'playerFloat';
								dispatchCustomEvent(player, eventName);
								player.pause();
								try {
									player.ima.pauseAd();
								} catch {}
								player.isFloating = false;
								set_is_sticky(false);
							}}
							className="close-minimized-player"
						/>
					</div>
				)}
			</div>
			<BobPixel
				player={player}
				video_info={current_info}
				autoplay={autoplay}
				bobBaseHost={bobBaseHost}
				_udl={_udl}
			/>
		</ErrorBoundary>
	);
};

/* Set component property types. */
VideoPlayer.propTypes = {
	video_id: PropTypes.string.isRequired,
	player_id: PropTypes.string.isRequired,
	sticky: PropTypes.any,
	share_embed: PropTypes.any,
	min_player_enabled: PropTypes.any,
	autoplay: PropTypes.any,
	on_start: PropTypes.func,
	on_end: PropTypes.func,
	on_playlist: PropTypes.func,
	bobBaseHost: PropTypes.string,
	_udl: PropTypes.object,
	ads: PropTypes.object,
	recommendations: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
	update_ads: PropTypes.func,
	show_title_overlay: PropTypes.bool,
	overrided_title: PropTypes.string,
	is_manually_triggered: PropTypes.bool,
	player_name: PropTypes.string,
	extra_params: PropTypes.string,
	volume_level: PropTypes.number,
	on_error: PropTypes.func,
	anvack: PropTypes.string,
	nielsen: PropTypes.object,
};

/* Set component default property values. */
VideoPlayer.defaultProps = {
	sticky: false,
	share_embed: '',
	min_player_enabled: false,
	autoplay: false,
	on_start: () => {},
	on_end: () => {},
	on_playlist: () => {},
	bobBaseHost: '',
	_udl: {},
	ads: {},
	recommendations: true,
	update_ads: () => {},
	show_title_overlay: false,
	overrided_title: undefined,
	is_manually_triggered: false,
	player_name: 'Lead Media',
	extra_params: '',
	anvack: ANVATO_KEY,
	nielsen: {},
	volume_level: 0,
};

/* Export component. */
export default VideoPlayer;
