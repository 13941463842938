import { nielsenDict } from '../util/helper';

function myTimer(nSdkInstance, player) {
	// send Ad play head to the SDK
	const nielsenData = nielsenDict?.[player.id()];
	nSdkInstance.ggPM('setPlayheadPosition', nielsenData.adPlayhead);
	nielsenData.adPlayhead++;
}

const onAdEvent = (event, player, nSdkInstance) => {
	const adObj = event.getAdData();
	let adIdentifier = '';
	const nielsenData = nielsenDict?.[player.id()];

	if (event && event.type === 'loaded' && adObj) {
		adIdentifier = adObj.adId;
	}
	const currentTime = player.currentTime();
	if (
		player?.isLivestream &&
		player?.isPrerollEnded &&
		currentTime &&
		Math.round(currentTime) !== Math.round(player.duration())
	) {
		nielsenData.isMidroll = true;
		nielsenData.isPostroll = false;
	}
	if (event.type === 'pause' || event.type === 'skip') {
		clearInterval(nielsenData.adTimer);
	} else if (event.type === 'resume' && ! nielsenData.dtvr) {
		nielsenData.adTimer = setInterval(() => myTimer(nSdkInstance, player), 1000);
	} else if (event.type === 'loaded') {
		if (player.isPrerollEnded || nielsenData.isPostroll) {
			nielsenData.postrollAdMetadataObject.assetid = adIdentifier;
			nielsenData.isPostroll = false;
		} // send ad metadata to the SDK
		else if (nielsenData.isMidroll) {
			nielsenData.midrollAdMetadataObject.assetid = adIdentifier;
		} else {
			nielsenData.prerollAdMetadataObject.assetid = adIdentifier;
			nSdkInstance.ggPM('loadMetadata', nielsenData.prerollAdMetadataObject);
		}
	} else if (event.type === 'complete') {
		clearInterval(nielsenData.adTimer);
		nielsenData.isTimerStarted = false;
		// send the ad stop event to the SDK
		nSdkInstance.ggPM('stop', nielsenData.adPlayhead - 1);
		nielsenData.adPlayhead = player?.isLivestream ? new Date().getTime() : 1;
	}

	// we should only start the timer if the start event is triggered
	if (! nielsenData.dtvr && !nielsenData.isTimerStarted && !nielsenData.isEndCalled && event.type === 'start') {
		nielsenData.adTimer = setInterval(() => myTimer(nSdkInstance, player), 1000);
		nielsenData.isTimerStarted = true;
	}
};

export const adsManagerLoadedCallback = (player, nSdkInstance) => {
	if (!nSdkInstance) return;
	const events = [
		window.google.ima.AdEvent.Type.ALL_ADS_COMPLETED,
		window.google.ima.AdEvent.Type.CLICK,
		window.google.ima.AdEvent.Type.COMPLETE,
		window.google.ima.AdEvent.Type.FIRST_QUARTILE,
		window.google.ima.AdEvent.Type.LOADED,
		window.google.ima.AdEvent.Type.MIDPOINT,
		window.google.ima.AdEvent.Type.SKIPPED,
		window.google.ima.AdEvent.Type.PAUSED,
		window.google.ima.AdEvent.Type.RESUMED,
		window.google.ima.AdEvent.Type.STARTED,
		window.google.ima.AdEvent.Type.THIRD_QUARTILE,
	];

	for (let index = 0; index < events.length; index++) {
		player.ima.addEventListener(events[index], (e) => onAdEvent(e, player, nSdkInstance));
	}
};

export const initialize_nielsen = (player, nielsen, video_info) => {
	const currentVideoInfo = Array.isArray(video_info)
		? video_info?.[player.playlist()?.current_index() < 0 ? 0 : player.playlist()?.current_index()]
		: video_info;
	const {
		airdate,
		programName: program,
		title,
		videoDuration: length,
		ads: { disable_ads: isAdsDisabled = false } = {},
		videoType,
		id,
	} = currentVideoInfo || {};

	const customAirdate = airdate
		? new Date(airdate * 1000).toJSON()?.split('T')?.[0]?.replaceAll('-', '')
		: new Date().toJSON()?.split('T')?.[0]?.replaceAll('-', '');

		const enableId3 = id === 'adstXlnjeoByg4YE';
		console.log('player.id3metadata', player?.id3metadata);
	/**
	 * Content Metadata.
	 * See https://engineeringportal.nielsen.com/docs/DCR_Video_Browser_SDK#Create_Metadata_Objects for reference
	 */
	const contentMetadataObject = {
		type: 'content',
		airdate: customAirdate,
		program,
		title,
		length: Number(length) || 0,
		isfullepisode: player?.isLivestream || videoType === '2' ? 'y' : 'n',
		adloadtype:  enableId3 ? '1' : '2', // 1  = Linear  and 2 = Dynamic Ad Insertion (DAI)
		assetid: id,
	};

	if (enableId3) {
		contentMetadataObject.adModel = '1';
	} else {
		contentMetadataObject.hasAds = isAdsDisabled ? '0' : '1';
	}

	const adPlayhead = player?.isLivestream ? new Date().getTime() : 1;

	// Nielsen plugin initial state
	nielsenDict[player.id()] = {
		prerollAdMetadataObject: { assetid: 'Default', type: 'preroll' },
		midrollAdMetadataObject: { assetid: 'Default', type: 'midroll' },
		postrollAdMetadataObject: { assetid: 'Default', type: 'postroll' },
		contentMetadata: true,
		adMetadata: true,
		isEndCalled: false,
		isPostroll: false,
		isMidroll: false,
		adPlayhead,
		isTimerStarted: false,
		adTimer: undefined,
		prevPos: null,
		dtvr: enableId3,
	};

	const nielsenData = nielsenDict?.[player.id()];
	const nSdkInstance = window?.NOLBUNDLE?.nlsQ(nielsen?.apid, 'nlsnInstance', {
		containerId: player.id(),
		optout: 'false', // remove
		sfcode: nielsen.sfcode,
		type: nielsen.type,
		apn: nielsen.apn,
		environment: nielsen.environment,
		useDerivedMetadata: nielsen.useDerivedMetadata,
		mapping: nielsen.mapping,
	});

	// Event handler to the content progress event
	player.on('timeupdate', () => {
		const getcurrentTime = player.currentTime();
		const intPlayedTime = parseInt(getcurrentTime, 10);
		if (nielsenData.contentMetadata && !nielsenData.isEndCalled) {
			// send content metadata to the SDK
			nSdkInstance.ggPM('loadMetadata', contentMetadataObject);
			nielsenData.contentMetadata = false;
		}
		// Takes care of duplicate playheads while sending to SDK
		if (! enableId3 && intPlayedTime > 0 && intPlayedTime !== nielsenData.prevPos) {
			nielsenData.prevPos = intPlayedTime;
			// send content play head to the SDK
			nSdkInstance.ggPM(
				'setPlayheadPosition',
				Math.round(player?.isLivestream ? new Date().getTime() : getcurrentTime),
			);
			if (Math.round(getcurrentTime) === Math.round(player.duration())) {
				// send content end event to the SDK
				nSdkInstance.ggPM('end', Math.round(player.currentTime()));
				nielsenData.isMidroll = false;
				nielsenData.isPostroll = true;
			}
		}
	});
	player.on('ended', () => {
		nielsenData.contentMetadata = true;
		nielsenData.isEndCalled = true;
		nielsenData.isMidroll = false;
		nielsenData.isPostroll = false;
	});

	// Event handler to the content play event
	player.on('play', () => {
		if (nielsenData.contentMetadata) {
			// send content metadata to the SDK
			nSdkInstance.ggPM('loadMetadata', contentMetadataObject);
			if ( player?.id3metadata) {
				nSdkInstance.ggPM('sendID3', player?.id3metadata);
			}
			nielsenData.contentMetadata = false;
		}
	});

	// Event handler to the content pause event
	player.on('pause', () => {
		// send pause event to the SDK
		nSdkInstance.ggPM('pause', Math.round(player.currentTime()));
	});

	// Event handler to the ad progress event
	player.on('adtimeupdate', () => {
		// Check ad whether postroll or midroll or preroll
		if (nielsenData.adMetadata) {
			nielsenData.adMetadata = false;
		}
	});

	// Event handler to the ad postroll event
	player.on('readyforpostroll', () => {
		nSdkInstance.ggPM('loadMetadata', nielsenData.postrollAdMetadataObject);
	});

	// Event handler to the ad end event
	player.on('adend', () => {
		nielsenData.adMetadata = true;
		nielsenData.contentMetadata = true;
	});
	return nSdkInstance;
};
