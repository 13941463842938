import { lightBlue, red, amber } from '@material-ui/core/colors';
import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
	default: {
		palette: {
			type: 'light',
			primary: '#000',
			secondary: {
				light: lightBlue[400],
				main: lightBlue[600],
				dark: lightBlue[700],
			},
			error: red,
			danger: amber[600],
		},
		status: {
			danger: 'orange',
		},
	},
});

export default theme;
