import videojs from 'video.js';
import PLAYER_CONSTANTS from '../util/constants';

const { FAIRPLAY_CERTIFICATE } = PLAYER_CONSTANTS;

function base64ToUint8Array(base64) {
  console.log('base64', base64);
	const raw = window.atob(base64);
	const rawLength = raw.length;
	const array = new Uint8Array(new ArrayBuffer(rawLength));

	for (let i = 0; i < rawLength; i++) {
		array[i] = raw.charCodeAt(i);
	}
	return array;
}

function uint8ArrayToBase64(input) {
	const keyString = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
	let output = '';
	let chr1;
	let chr2;
	let chr3;
	let enc1;
	let enc2;
	let enc3;
	let enc4;
	let i = 0;
	while (i < input.length) {
		chr1 = input[i++];
		chr2 = input[i++];
		chr3 = input[i++];
		enc1 = chr1 >> 2; // eslint-disable-line no-bitwise
		enc2 = ((chr1 & 3) << 4) | (chr2 >> 4); // eslint-disable-line no-bitwise
		enc3 = ((chr2 & 15) << 2) | (chr3 >> 6); // eslint-disable-line no-bitwise
		enc4 = chr3 & 63; // eslint-disable-line no-bitwise
		if (Number.isNaN(chr2)) {
			enc3 = enc4 = 64; // eslint-disable-line no-multi-assign
		} else if (Number.isNaN(chr3)) {
			enc4 = 64;
		}
		output =
			output +
			keyString.charAt(enc1) +
			keyString.charAt(enc2) +
			keyString.charAt(enc3) +
			keyString.charAt(enc4);
	}
	return output;
}

function initWidevine(player, info) {
	player.src([
		{
			src: info.url,
			type: 'application/dash+xml',
			keySystemOptions: [
				{
					name: 'com.widevine.alpha',
					options: {
						serverURL: info.licenseURL,
					},
				},
			],
		},
	]);
}

function initFairplay(player, info) {
	player.eme();
	player.src({
		src: info.url,
		type: 'application/x-mpegURL',
		keySystems: {
			'com.apple.fps.1_0': {
				getCertificate: (emeOptions, callback) => {
					callback(null, base64ToUint8Array(FAIRPLAY_CERTIFICATE))
				},
				getContentId: (emeOptions, contentId) => {
					const decoder = new TextDecoder('utf-16');
					const initDataStr = decoder.decode(contentId);
					contentId = initDataStr.replace(/.*skd:\/\//g, '');
					return contentId;
				},
				getLicense: (emeOptions, contentId, keyMessage, callback) => {
					const params = {
						spc: uint8ArrayToBase64(keyMessage),
						assetid: contentId,
					};

					const headers = {
						'Content-type': 'application/json',
					};
					videojs.xhr(
						{
							uri: info.licenseURL,
							method: 'POST',
							responseType: 'json',
							body: JSON.stringify(params),
							headers,
						},
						(err, data) => {
							if (err) {
								callback(err);
								return;
							}
							callback(null, base64ToUint8Array(data.body.ckc));
						},
					);
				},
			},
		},
	});
}

export default function initialize_drm(player, info) {
	switch (info.DRM) {
		case 'widevine':
			initWidevine(player, info);
			break;
		case 'fairplay':
			initFairplay(player, info);
			break;
		default:
			break;
	}
}
