export function is_dev() {
	return process.env.NODE_ENV === 'development';
}

export function is_prod() {
	return process.env.NODE_ENV === 'production';
}

// This method should return true on local development environment, and on
// stage.player.psg.nexstardigital.net only
export function is_test_environment() {
	return is_dev() || window.location.hostname === 'stage.player.psg.nexstardigital.net';
}
