import { trackers } from '@rmm/error-tracker';
import axios from './axios';
import { extractJSON, ERROR_TRACKER_RENDER_GROUP } from './helper.js';
import PLAYER_CONSTANTS from './constants';

const { ANVATO_API_URL, ANVATO_FEED_API_URL, M3U8_URL, ANVATO_KEY } = PLAYER_CONSTANTS;
const { SimpleErrorTracker } = trackers;

/**
 * Returns a list of videos from the feed API
 * @param {string} video_id - The video id
 */
export const videoGetFeedData = async (video_id) => {
	const reqUrl = `${ANVATO_FEED_API_URL}${video_id.id}?start=0&fmt=json`;
	const { data } = await axios({ url: reqUrl, timeout: 5000 });
	const videoList = [];
	if (!data.docs) {
		throw new Error('Invalid response from Anvato feed API');
	}
	data.docs.forEach((doc) => {
		if ((doc?.play_ids || []).length > 0) {
			videoList.push(doc.obj_id);
		}
	});
	return videoList;
};

const supportsDRM = async (type) => {
	try {
		await navigator.requestMediaKeySystemAccess(type, [
			{
				initDataTypes: ["keyids", "mp4", "cenc", "webm"],
				audioCapabilities: [{ contentType: 'audio/mp4;codecs="mp4a.40.2"' }],
				videoCapabilities: [{ contentType: 'video/mp4;codecs="avc1.64001e"' }],
			},
		]);

		return true;
	} catch (error) {
		return false;
	}
};

const processAnvatoSources = async urls => {

	if (urls.length === 0) {
		return {
			videoUrl: null,
			cert: null,
			licenseURL: null,
			DRM: false,
		};
	}

	const isDRM = urls.some(url => !!url.license_url);

	if (!isDRM) {
		return {
			videoUrl: urls[0].embed_url,
			cert: null,
			licenseURL: null,
			DRM: false,
		};
	}

	const dashSource = urls.find(url => url.format === 'dash');
	const m3u8Source = urls.find(url => url.format === 'm3u8-variant');
	const supportsFairPlay = m3u8Source ? await supportsDRM('com.apple.fps.1_0') : false;
	const supportsWidevine = dashSource ? await supportsDRM('com.widevine.alpha') : false;
	let videoUrl;


	// Doesn't support DRM.
	if (!supportsWidevine && !supportsFairPlay) {

		console.log({ type: 'DRM Unsupported' });

		return {
			videoUrl: null,
			cert: null,
			licenseURL: null,
			DRM: false,
		};
	}

	if (m3u8Source && supportsFairPlay) {
		videoUrl = m3u8Source.embed_url;

		console.log({ type: 'DRM Support Fairplay', licenseURL: m3u8Source.license_url });

		return {
			videoUrl,
			cert: m3u8Source.fp_cert?.payload,
			licenseURL: m3u8Source.license_url,
			DRM: 'fairplay',
		};
	}

	if (dashSource && supportsWidevine) {
		// Initial akta response contains a redirect to the actual video
		videoUrl = dashSource.embed_url;

		try {
			const response = await axios({
				url: videoUrl,
				timeout: 5000,
			});

			const { data } = response;
			const parser = new DOMParser();
			const xmlDoc = parser.parseFromString(data, "application/xml");
			const locationElement = xmlDoc.getElementsByTagName("Location");
			if (locationElement.length > 0) {
				videoUrl = locationElement[0].textContent;
			}

			console.log({ type: 'DRM Support Widevine', licenseURL: dashSource.license_url});

			return {
				videoUrl,
				cert: null,
				licenseURL: dashSource.license_url,
				DRM: 'widevine',
			};

		} catch (error) {
			console.log({ type: 'DRMError', error });
			return {
				videoUrl: null,
				cert: null,
				licenseURL: null,
				DRM: false,
			};
		}
	}
};

/**
 *  Makes the call to the Anvato API
 */
const makeAnvatoApiCall = (video_id, ads, extra_params, player_id, player_name, anvack) => {
	const apiCallerTracker = new SimpleErrorTracker('Fetch Data From Akta API', 100, {
		id: player_id,
		position: player_name,
		renderGroup: ERROR_TRACKER_RENDER_GROUP.videoInit,
	});

	let anvatoApiURL = `${ANVATO_API_URL}/${video_id}?anvack=${anvack}`;

	if (extra_params) {
		anvatoApiURL += `&${extra_params}`;
	}

	return new Promise((resolve, reject) =>
		axios({
			url: anvatoApiURL,
			timeout: 5000,
		})
			.then(({ data }) => {
				const {
					upload_id: id,
					def_title: title,
					def_description: description,
					program_name: programName,
					src_image_url: poster,
					categories: tags = [],
					ts_airdate: airdate,
					frame_rate: framerate,
					episode_no: episodeNo,
					duration: videoDuration,
					published_urls: publishedUrls,
					recommendations,
					video_type: videoType,
				} = extractJSON(data) || {};

				const m3u8Tracker = new SimpleErrorTracker('Set m3u8 URL', 100, {
					id: player_id,
					position: player_name,
					renderGroup: ERROR_TRACKER_RENDER_GROUP.videoInit,
				});

				processAnvatoSources(publishedUrls).then((source) => {
					const { videoUrl, licenseURL, DRM, cert } = source;
					const videoSourceURL =  videoUrl || `${M3U8_URL}/${video_id}/m3u8?anvack=${anvack}`;

					if (videoUrl) {
						m3u8Tracker.sendSuccess();
					} else {
						m3u8Tracker.sendError(
							{
								name: 'No M3U8 URL returned',
								message: {
									requested_url: anvatoApiURL,
									response: extractJSON(data),
								},
							},
							10,
						);
					}

					apiCallerTracker.sendSuccess();
					resolve({
						id,
						title,
						description,
						programName,
						poster,
						tags,
						full_episode: false,
						ads,
						autoplay: false,
						url: videoSourceURL,
						airdate,
						framerate,
						episodeNo,
						videoDuration,
						recommendations,
						videoType,
						DRM,
						licenseURL,
						cert,
					});
				});
			})
			.catch((err) => {
				let exception;
				try {
					exception = Object.assign(extractJSON(err?.response?.data)?.exception, {
						requested_url: anvatoApiURL,
					});
				} catch (exceptionDefinitionError) {
					exception = err;
				}
				apiCallerTracker.sendError(
					{
						name: 'Akta API Call Error',
						message: exception,
					},
					1,
				);
				console.error(exception);
				return reject(exception);
			}),
	);
};

/**
 *  Returns data from the Anvato API. This function wraps the makeAnvatoApiCall function and retries the call if it fails.
 * @param {string} video_id - The video id
 * @param {any} ads - Ads data
 * @param {any} extra_params - Extra params to be passed to the Anvato API
 * @param {string} player_id - The player id
 * @param {string} player_name - The player name
 * @param {number} retries - Number of retries desired
 * @param {boolean} retrying - Whether or not the fn was called in a retry
 *
 */
export const videoInfoApiCaller = async (
	video_id,
	ads,
	extra_params,
	player_id,
	player_name,
	anvack = ANVATO_KEY,
	retries = 2,
	retrying = false,
) => {
	if (retrying) {
		// If in a retry, await 1/4 second before trying again
		await new Promise((r) => setTimeout(r, 250));
	}
	return makeAnvatoApiCall(video_id, ads, extra_params, player_id, player_name, anvack)
		.then((data) => data)
		.catch(() => {
			if (retries > 0) {
				return videoInfoApiCaller(
					video_id,
					ads,
					extra_params,
					player_id,
					player_name,
					anvack,
					retries - 1,
					true,
				);
			}
			throw new Error('Unable to fetch data from Anvato API');
		});
};
