import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(
	() => ({
		video: { margin: '2% 0' },
		text: {
			margin: '2% 0',
		},
	}),
	{ index: 1 },
);

export default useStyles;
