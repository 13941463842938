export class CueManager extends EventTarget {
	constructor() {
		super();
		this.startTime = -1;
		this.video = null;
		this.videoHandler = null;
		this.trackHandler = null;
		/**
		 * @type {Array<{
		 *      id: number,
		 *      type: "master" | "slate" | "ad",
		 *      startTime: number,
		 *      ad?: {
		 *          index: number,
		 *          podPosition: number,
		 *          duration: number,
		 *          podSize: number,
		 *          podDuration: number,
		 *          metaUrl: string
		 *      }
		 * }>}
		 */
		this.cues_ = [];
		this.inserted = new Set();
		/**
		 * @type {{
		 *      id: number,
		 *      type: "master" | "slate" | "ad",
		 *      startTime: number,
		 *      ad?: {
		 *          index: number,
		 *          podPosition: number,
		 *          duration: number,
		 *          podSize: number,
		 *          podDuration: number,
		 *          metaUrl: string
		 *      }
		 * }}
		 */
		this.activeCue_ = null;
	}

	getCues() {
		return this.cues_;
	}

	getActiveCue() {
		return this.activeCue_;
	}

	/**
	 *
	 * @param {HTMLVideoElement} video
	 */
	attachMedia(video) {
		this.video = video;
		if (this.videoHandler) {
			this.video.removeEventListener('timeupdate', this.videoHandler);
		}
		const handler = (e) => {
			const time = e.target.currentTime;
			const nextCue = this.searchCue(time);
			// Hash Equal
			if (nextCue !== this.activeCue_) {
				this.activeCue_ = nextCue;
				this.dispatchEvent(new Event('cuechange'));
			}
		};
		this.videoHandler = handler;
		this.video.addEventListener('timeupdate', handler);
	}

	searchCue(time) {
		for (let i = 0; i < this.cues_?.length; i++) {
			const cue = this.cues_[i];
			if (time > cue.startTime) {
				return cue;
			}
		}
		return null;
	}

	/**
	 *
	 * @param {TextTrackCue} track
	 * @param {function} handleId3
	 */
	attachTrack(track, handleId3) {
		track.mode = 'hidden';
		track.addEventListener('cuechange', (e) => {
			if (!e.target.cues) {
				return;
			}
			for (let i = 0; i < e.target.cues.length; i++) {
				const cue = e.target.cues[i];
				if (cue.value.key === 'PRIV') {
						handleId3(cue.value);
				}
				if (cue.type !== 'com.apple.quicktime.HLS' || cue.value.key !== 'X-LURA-DATA') {
					// eslint-disable-next-line no-continue
					continue;
				}
				const data = atob(cue.value.data);
				const dataArgs = data.split(',').reduce((prev, cur) => {
					const [key, value] = cur.replaceAll('"', '').split('=');
					return { ...prev, [key]: value };
				}, {});
				const id = Number(dataArgs['X-LURA-SEQ-ID']);
				if (!this.inserted.has(id)) {
					const formatted = {
						id,
						startTime: Number(dataArgs['X-RELATIVE-TS']) || 0,
						type: dataArgs['X-LURA-TYPE'].toLowerCase(),
						sessionId: dataArgs['X-LURA-SESSION-ID'],
					};
					if (formatted.type === 'ad') {
						formatted.ad = {
							index: Number(dataArgs['X-LURA-AD-INDEX']),
							podPosition: Number(dataArgs['X-LURA-AD-POD-POSITION']),
							duration: Number(dataArgs['X-LURA-AD-DURATION']),
							podSize: Number(dataArgs['X-LURA-POD-SIZE']),
							podDuration: Number(dataArgs['X-LURA-POD-DURATION']),
							metaUrl: dataArgs['X-LURA-META-URL'],
						};
					}

					this.cues_ = this.cues_.concat(formatted).sort((a, b) => b.startTime - a.startTime);
					this.inserted.add(id);
				}
			}
		});
	}
}
