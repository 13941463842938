import { Container, Typography, Button, Box } from '@material-ui/core';
import { useState } from 'react';
import VideoPlayer from './components/VideoPlayer';

const testPlayerProps = {
	video_id: '6211966',
	sticky: false,
	min_player_enabled: false,
	autoplay: true,
	ads: {
		disable_ads: false,
		ad_tag_url:
			'https://pubads.g.doubleclick.net/gampad/ads?sz=1x1000&iu=%2F5678%2Fdfp-test%2Fwm&impl=s&gdfp_req=1&env=vp&output=vmap&unviewed_position_start=1&ad_rule=1&url=%5BUDL_PAGE_URL%5D&video_url_to_fetch=%5BUDL_PAGE_URL%5D&cust_params=vid%3D6416622%26pid%3D%255BUDL_PAGE_ID%255D%26vidcat%3D%255BUDL_PAGE_CATEGORY%255D%26bob_ck%3D%255Bbob_ck_val%255D%26d_code%3D%255BUDL_DCODE%255D%26nlpcat1%3D%255BUDL_NLP_CAT_1%255D%26nlpcat2%3D%255BUDL_NLP_CAT_2%255D%26nlpcat3%3D%255BUDL_NLP_CAT_3%255D%26nlpsent%3D%255BUDL_NLP_SENT%255D%26playerwidth%3D%255BPLAYER_WIDTH%255D%26playerheight%3D%255BPLAYER_HEIGHT%255D',
	},
	bobBaseHost: 'uat.bob.dmpxs.com',
	_udl: {},
	recommendations: true,
	show_title_overlay: false,
	is_manually_triggered: false,
	player_name: 'Lead Media',
	player_id: 'vjs_1',
};

const test_akta_response = {
	upload_id: '6211966',
	owner_id: '20003',
	program_id: '112',
	video_type: '1',
	image_id: '0',
	src_image_url:
		'https://h104216-pcdn.mp.lura.live/iupl_lin/24B/247/24B24764697D45FEB8E31C627B954CEF.jpg?aktaexp=1677532444&aktasgn=ae1e29f73a18d64282a9bd16abfffb80',
	def_title: 'Biden inauguration: Andy Fox reports live in D.C. on inauguration day',
	def_description: "Preparations are underway before the sun comes up in the nation's capital.",
	url: 'https://tkx.mp.lura.live/rest/v2/mcp/video/6211966?anvack=2DVzl9QRVv8pYnuLGDSau3MXgOQOnqPK',
	categories: ['News\\Local', 'Video', 'Mobile'],
	recommendations: [],
	thumbnail:
		'https://h104216-pcdn.mp.lura.live/iupl_lin/24B/247/24B24764697D45FEB8E31C627B954CEF_160x90.jpg?aktaexp=1677532444&aktasgn=bc0100bde17ed2470772dc0a35b2f34d',
	captions: [],
	program_name: 'Local News',
	program_description:
		'Local news video. This syndicates to both WP and YT. Only use if we shot the video! NO national sports, network video, etc. goes here.',
};

const errorButtons = [
	{
		title: 'Invalid Video ID',
		props: {
			video_id: '621196612312312',
		},
	},
	{
		title: 'Invalid Ad Tag URL',
		props: {
			ads: {
				disable_ads: false,
				ad_tag_url:
					'https://pubads123123123.g.doubleclick.net/gampad/ads?sz=1x1000&iu=%2F5678%2Fdfp-test%2Fwm&impl=s&gdfp_req=1&env=vp&output=vmap&unviewed_position_start=1&ad_rule=1&url=%5BUDL_PAGE_URL%5D&video_url_to_fetch=%5BUDL_PAGE_URL%5D&cust_params=vid%3D6416622%26pid%3D%255BUDL_PAGE_ID%255D%26vidcat%3D%255BUDL_PAGE_CATEGORY%255D%26bob_ck%3D%255Bbob_ck_val%255D%26d_code%3D%255BUDL_DCODE%255D%26nlpcat1%3D%255BUDL_NLP_CAT_1%255D%26nlpcat2%3D%255BUDL_NLP_CAT_2%255D%26nlpcat3%3D%255BUDL_NLP_CAT_3%255D%26nlpsent%3D%255BUDL_NLP_SENT%255D%26playerwidth%3D%255BPLAYER_WIDTH%255D%26playerheight%3D%255BPLAYER_HEIGHT%255D',
			},
		},
	},
	{
		title: 'Undefined video id',
		props: { video_id: undefined },
	},
	{
		title: 'Player playback error w/ads',
		props: {
			test_video_info: { ...test_akta_response, url: 'https://example.org/' },
		},
	},
	{
		title: 'Akta API failure w/ads',
		props: {
			test_video_info: {
				...test_akta_response,
				url: 'https://tkx.mp.lura.live/rest/v2/mcp/video/6211966',
			},
		},
	},
	{
		title: 'Player playback error',
		props: {
			ads: { disable_ads: true },
			test_video_info: { ...test_akta_response, url: 'https://example.org/' },
		},
	},
	{
		title: 'Akta API failure',
		props: {
			ads: { disable_ads: true },
			test_video_info: {
				...test_akta_response,
				url: 'https://tkx.mp.lura.live/rest/v2/mcp/video/6211966',
			},
		},
	},
];

const Test = () => {
	const [renderVideoPlayerState, setRenderVideoPlayerState] = useState({
		render: false,
		props: undefined,
	});
	const [error, setError] = useState();

	const handleErrorButtonClick = (button) => {
		setRenderVideoPlayerState({ render: true, props: button.props });
	};
	return (
		<Container
			style={{ width: 'fit-content', display: 'flex', flexDirection: 'column', gap: '10px' }}>
			<Typography variant="h4" align="center" style={{ margin: '2% auto' }}>
				Video Player Test Page For Error Scenarios
			</Typography>
			<Box style={{ display: 'flex', gap: '10px' }}>
				{errorButtons.map((button) => (
					<Button
						variant="contained"
						onClick={() => handleErrorButtonClick(button)}
						key={button.title}
						disabled={!!error}>
						{button.title}
					</Button>
				))}
			</Box>
			<Button
				variant="contained"
				onClick={() => {
					setRenderVideoPlayerState({
						render: false,
						props: undefined,
					});
					setTimeout(() => setError(false));
				}}
				style={{ width: 'fit-content' }}>
				Reset
			</Button>
			{error ? <span>Check developer console for the error log</span> : null}
			{renderVideoPlayerState.render && (
				<div style={{ margin: 'auto', width: 900, height: 600 }}>
					<VideoPlayer
						{...testPlayerProps}
						{...renderVideoPlayerState.props}
						on_error={(error) => {
							console.log('on_error triggered in caller component');
							setError(error);
						}}
					/>
				</div>
			)}
		</Container>
	);
};

export default Test;
