import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import { ThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import App from './App';
import Test from './Test';
import reportWebVitals from './reportWebVitals';
import theme from './theme';

ReactDOM.render(
	<React.StrictMode>
		<ThemeProvider theme={responsiveFontSizes(theme)}>
			<Router>
				<Switch>
					<Route path="/" component={App} exact />
					<Route path="/test" component={Test} exact />
				</Switch>
			</Router>
			<CssBaseline />
		</ThemeProvider>
	</React.StrictMode>,
	document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
