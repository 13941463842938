import videojs from 'video.js';
import './Endscreen';
import Swiper, { Navigation } from 'swiper';
import { trackers } from '@rmm/error-tracker';
import { update_player } from '../../routines';
import { videoInfoApiCaller } from '../../util/anvato';
import {
	isMobile,
	analyticsPayloadDict,
	dispatchCustomEvent,
	ERROR_TRACKER_RENDER_GROUP,
	truncateMinTitle,
} from '../../util/helper';

const { SimpleErrorTracker } = trackers;
Swiper.use([Navigation]);

const Plugin = videojs.getPlugin('plugin');

/**
 * Initialize the plugin.
 *
 * @function plugin
 * @param    {Object} [options={}]
 */
class Recommendation extends Plugin {
	constructor(player, options) {
		super(player, options);

		this.setState({
			...options,
			recommendations: options.recommendations?.filter((rec) => !rec.toBeRemoved),
		});

		const countdownObj = {};
		this.endScreenTracker = new SimpleErrorTracker('Render End Screen', 100, {
			id: options.player_id,
			position: options.player_name,
			renderGroup: ERROR_TRACKER_RENDER_GROUP.general,
		});
		/* Adds Endscreen component to player. */
		const endscreen = this.player.addChild('endscreen');
		const endscreenEl = endscreen.el_;
		/* Populates the Endscreen component with necessary swiper elements and recommendations. */
		endscreen.createRecommendations(options);
		/* Initializes swiper. */
		const swiper = new Swiper(endscreenEl.querySelector('.swiper-container'), {
			navigation: {
				nextEl: endscreenEl.querySelector('.swiper-button-next'),
				prevEl: endscreenEl.querySelector('.swiper-button-prev'),
			},
			observer: true,
			observeParents: true,
			slidesPerView: 1.7,
			spaceBetween: 10,
		});

		const swiperWrapperEl = endscreenEl.querySelector('.swiper-wrapper');
		const recommendationElements = swiperWrapperEl.children;

		this.addRecommendationEventHandlers(
			this.state.recommendations,
			recommendationElements,
			countdownObj,
			swiperWrapperEl,
			endscreenEl,
			swiper,
		);

		const controlBarEl = this.player.controlBar.el_;
		const playToggleEl = this.player.controlBar.playToggle.el_;

		this.handlePlayToggleChange(endscreenEl, controlBarEl, playToggleEl);

		/* Reset slider state. */
		this.player.on('play', () => {
			swiper.slideTo(0, 0, false);
		});

		this.handlePluginViewability(endscreenEl, options.isPlaylist);

		this.player.on('ended', () => {
			if (this.player.isFullscreen() && isMobile()) {
				this.player.exitFullscreen();
			}
		});

		if (options.isPlaylist) {
			this.player.one('playlistEnd', () => {
				if (this.player.ads._contentHasEnded === undefined || this.player.ads._contentHasEnded) {
					this.handleCountdown(swiperWrapperEl, endscreenEl, countdownObj, swiper);
				}
			});
		} else {
			this.initializeCountdown(
				swiperWrapperEl,
				endscreenEl,
				countdownObj,
				swiper,
				this.player.firstRecommendationID,
			);
		}

		this.player.on('videoReplay', () => {
			this.initializeCountdown(
				swiperWrapperEl,
				endscreenEl,
				countdownObj,
				swiper,
				this.player.firstRecommendationID,
			);
		});
	}

	setRecItemAnalyticsMetadata(index, title) {
		const analyticsPayload = analyticsPayloadDict[this.player?.id()];
		if (!analyticsPayload) return;
		analyticsPayload.recommendationIndex = index;
		analyticsPayload.recommendationClickTitle = title;
		dispatchCustomEvent(this.player, 'videoRecommendationClick');
		delete analyticsPayload.recommendationClickTitle;
	}

	/* Cancels the countdown for the next video. */
	// eslint-disable-next-line class-methods-use-this
	cancelCountdown(countdownObj, swiperWrapperEl) {
		clearInterval(countdownObj.countdown);
		const countdownEl = swiperWrapperEl.querySelector('.next-vid-countdown');
		if (countdownEl) {
			countdownEl.remove();
		}
		const highlightedRecImage = swiperWrapperEl.querySelector('.highlighted-recommendation-image');
		if (highlightedRecImage) {
			highlightedRecImage.classList.remove('highlighted-recommendation-image');
		}
	}

	/* Adds click handlers to recommendations so that the player updates accordingly. */
	addRecommendationEventHandlers(
		recArray,
		recElements,
		countdownObj,
		swiperWrapperEl,
		endscreenEl,
		swiper,
	) {
		for (let i = 0; i < recElements.length; i += 1) {
			recElements[i].onclick = () => {
				this.cancelCountdown(countdownObj, swiperWrapperEl);
				endscreenEl.classList.remove('vjs-ended');
				const videoStatus = document.querySelector('.video-status');
				if (videoStatus) {
					videoStatus.innerText = "NOW PLAYING";
				}
				const upcomingRecVideoId = this.setCurrentRecommendationVideo(i);

				videoInfoApiCaller(upcomingRecVideoId || recArray[i].mcpid, '').then((res) => {
					this.setRecItemAnalyticsMetadata(i + 1, res?.title);

					if (!this.state.isCustomRecommendationsSet) {
						this.setState({ ...this.state, recommendations: res.recommendations });
					}
					update_player(
						this.player,
						{
							...res,
							recommendations: this.state.isCustomRecommendationsSet
								? this.state.recommendations
								: res.recommendations,
							ad_unit_path: this.state.recommendations?.find(
								(rec) => rec.mcpid === upcomingRecVideoId,
							)?.ad_unit_path,
						},
						false,
						this.state.show_title_overlay,
						this.state.overrided_title,
						{
							is_manually_triggered: true,
							recommendation_index: i + 1,
							load_method: 'recommendation',
						},
						this.state.player_id,
						this.state.player_name,
					);
					if (this.state.isCustomRecommendationsSet)
						this.handleCustomRecommendationsUpcomingVideo(upcomingRecVideoId);
					this.updateRecommendations(
						swiperWrapperEl,
						endscreenEl,
						countdownObj,
						swiper,
						this.state.isCustomRecommendationsSet
							? this.state.recommendations
							: res.recommendations,
						res.title,
					);
					this.initializeCountdown(
						swiperWrapperEl,
						endscreenEl,
						countdownObj,
						swiper,
						upcomingRecVideoId,
					);
				});
			};
		}
	}

	/* Manipulates DOM so that parent of the play toggle changes when the video starts or ends. */
	handlePlayToggleChange(endscreenEl, controlBarEl, playToggleEl) {
		this.player.on('play', () => {
			controlBarEl.appendChild(playToggleEl);
		});

		this.player.on('ended', () => {
			const titleSection = endscreenEl.querySelector('.replay-current');
			titleSection.prepend(playToggleEl);
		});
	}

	/* Checks for ads and initializes the countdown when the video ends. */
	initializeCountdown(swiperWrapperEl, endscreenEl, countdownObj, swiper) {
		this.player.one('ended', () => {
			if (this.player.ads._contentHasEnded === undefined || this.player.ads._contentHasEnded) {
				this.handleCountdown(swiperWrapperEl, endscreenEl, countdownObj, swiper);
			} else {
				this.player.one('ended', () => {
					this.handleCountdown(swiperWrapperEl, endscreenEl, countdownObj, swiper);
				});
			}
		});
	}

	/* Sets the upcoming video id and resets the custom recommendations array if
	All videos are played */
	setCurrentRecommendationVideo(videoIndex) {
		// Set firstRecommendationID and return after if videoIndex is set
		if (videoIndex >= 0) {
			this.player.firstRecommendationID = this.state.recommendations?.[videoIndex]?.mcpid;
			return this.player.firstRecommendationID;
		}

		let currentRecommendations = this.state.recommendations;
		const lastPlayedRecVideoIndex = currentRecommendations?.findLastIndex((item) => item.played);
		this.player.firstRecommendationID =
			currentRecommendations?.[
				lastPlayedRecVideoIndex >= 0 ? lastPlayedRecVideoIndex + 1 : 0
			]?.mcpid;

		// Reset custom recommendations if all of them are played already
		if (!this.player.firstRecommendationID) {
			this.player.firstRecommendationID = currentRecommendations?.[0]?.mcpid;
			currentRecommendations = currentRecommendations?.map((recItem) => ({
				...recItem,
				played: undefined,
			}));
			this.setState({ ...this.state, recommendations: currentRecommendations });
		}

		return this.player.firstRecommendationID;
	}

	handleCustomRecommendationsUpcomingVideo(upcomingRecVideoId) {
		const currentRecommendations = this.state.recommendations;
		const upcomingRecVideoIndex = currentRecommendations.findIndex(
			(item) => item.mcpid === upcomingRecVideoId,
		);

		if (currentRecommendations?.[upcomingRecVideoIndex])
			currentRecommendations[upcomingRecVideoIndex].played = true;
	}

	/* Starts countdown for the next video when the current video ends
    and terminates countdown in case of user interaction. */
	handleCountdown(swiperWrapperEl, endscreenEl, countdownObj, swiper) {
		this.endScreenTracker.sendSuccess();
		const countdownEl = document.createElement('span');
		countdownEl.classList.add('next-vid-countdown');
		countdownEl.innerHTML = 'UP NEXT IN 10';
		const minTitle = document.querySelector('.min-title');
		// This could have been handled in state but we're about to move away from React anyway.
		const videoStatus = document.querySelector('.video-status');
		const upNextTitle = swiperWrapperEl.firstChild.querySelector('.recommendation-title');
		const firstRecommendationWrapper = swiperWrapperEl.firstChild.firstChild;
		firstRecommendationWrapper.firstChild.classList.add('highlighted-recommendation-image');
		firstRecommendationWrapper.appendChild(countdownEl);
		let seconds = 10;

		// Set the first video as upcoming video if custom recommendations are not enabled
		// Evalute the custom recommendation video order if custom recommendations are passed by passing undefined as parameter
		const upcomingRecVideoId = this.setCurrentRecommendationVideo(
			this.state.isCustomRecommendationsSet ? undefined : 0,
		);

		const advanceToNextVideo = () => {
			this.cancelCountdown(countdownObj, swiperWrapperEl);
			if (endscreenEl?.clientHeight === 0) return;
			endscreenEl.classList.remove('vjs-ended');
			if (videoStatus) {
				videoStatus.innerText = "NOW PLAYING";
			}
			videoInfoApiCaller(upcomingRecVideoId, '').then((res) => {
				if (!this.state.isCustomRecommendationsSet) {
					this.setState({ ...this.state, recommendations: res.recommendations });
				}
				update_player(
					this.player,
					{
						...res,
						recommendations: this.state.isCustomRecommendationsSet
							? this.state.recommendations
							: res.recommendations,
						ad_unit_path: this.state.recommendations?.find(
							(rec) => rec.mcpid === upcomingRecVideoId,
						)?.ad_unit_path,
					},
					true,
					this.state.show_title_overlay,
					this.state.overrided_title,
					{
						is_manually_triggered: false,
						recommendation_index: 1,
						load_method: 'recommendation',
					},
					this.state.player_id,
					this.state.player_name,
				);
				if (this.state.isCustomRecommendationsSet)
					this.handleCustomRecommendationsUpcomingVideo(upcomingRecVideoId);

				this.updateRecommendations(
					swiperWrapperEl,
					endscreenEl,
					countdownObj,
					swiper,
					this.state.isCustomRecommendationsSet ? this.state.recommendations : res.recommendations,
					res.title,
				);
				this.initializeCountdown(
					swiperWrapperEl,
					endscreenEl,
					countdownObj,
					swiper,
					upcomingRecVideoId,
				);
			});
		};

		if (minTitle?.innerText && upNextTitle?.innerText) {
			minTitle.innerText = upNextTitle.innerText;
			truncateMinTitle();
			if (videoStatus) {
				videoStatus.innerText = "UP NEXT";
			}
		}

		countdownObj.countdown = setInterval(() => {
			if (seconds === 0) {
				advanceToNextVideo();
			} else {
				seconds -= 1;
				countdownEl.innerHTML = `UP NEXT IN ${seconds}`;
			}
		}, 1000);

		this.player.on('videoReplay', () => {
			this.cancelCountdown(countdownObj, swiperWrapperEl);
		});

		swiper.on('sliderFirstMove', () => {
			this.cancelCountdown(countdownObj, swiperWrapperEl);
		});

		swiper.on('slideChange', () => {
			this.cancelCountdown(countdownObj, swiperWrapperEl);
		});
	}

	/* Changes endscreen element class so that it becomes viewable after postroll. */
	handlePluginViewability(endscreenEl, isPlaylist) {
		this.player.one('play', () => {
			endscreenEl.classList.remove('vjs-ended');
		});

		this.player.on('videoReplay', () => {
			endscreenEl.classList.remove('vjs-ended');
		});

		if (isPlaylist) {
			this.player.on('playlistEnd', () => {
				if (this.player.ads._contentHasEnded === undefined || this.player.ads._contentHasEnded) {
					endscreenEl.classList.add('vjs-ended');
				}
			});
		} else {
			this.player.on('ended', () => {
				if (this.player.ads._contentHasEnded === undefined || this.player.ads._contentHasEnded) {
					endscreenEl.classList.add('vjs-ended');
				}
			});
		}
	}

	/* Changes recommendations shown in the endscreen. */
	// eslint-disable-next-line class-methods-use-this
	updateRecommendations(
		swiperWrapperEl,
		endscreenEl,
		countdownObj,
		swiper,
		recommendations,
		title,
	) {
		endscreenEl.querySelector('.current-video-title').innerHTML = title;
		const recElements = endscreenEl.querySelector('.swiper-wrapper').children;
		for (let i = 0; i < recElements.length; i += 1) {
			const recElement = recElements[i];
			recElement.querySelector('.recommendation-image').src = recommendations[i].image;
			recElement.querySelector('.recommendation-title').innerHTML = recommendations[i].title;
			recElement.onclick = () => {
				endscreenEl.classList.remove('vjs-ended');
				const videoStatus = document.querySelector('.video-status');
				if (videoStatus) {
					videoStatus.innerText = "NOW PLAYING";
				}
				this.cancelCountdown(countdownObj, swiperWrapperEl);
				const upcomingRecVideoId = this.setCurrentRecommendationVideo(i);

				videoInfoApiCaller(upcomingRecVideoId || recommendations[i].mcpid, '').then((res) => {
					this.setRecItemAnalyticsMetadata(i + 1, res?.title);

					if (!this.state.isCustomRecommendationsSet) {
						this.setState({ ...this.state, recommendations: res.recommendations });
					}
					update_player(
						this.player,
						{
							...res,
							recommendations: this.state.isCustomRecommendationsSet
								? this.state.recommendations
								: res.recommendations,
							ad_unit_path: this.state.recommendations?.find(
								(rec) => rec.mcpid === upcomingRecVideoId,
							)?.ad_unit_path,
						},
						false,
						this.state.show_title_overlay,
						this.state.overrided_title,
						{
							is_manually_triggered: true,
							recommendation_index: i + 1,
							load_method: 'recommendation',
						},
						this.state.player_id,
						this.state.player_name,
					);
					if (this.state.isCustomRecommendationsSet)
						this.handleCustomRecommendationsUpcomingVideo(upcomingRecVideoId);

					this.updateRecommendations(
						swiperWrapperEl,
						endscreenEl,
						countdownObj,
						swiper,
						this.state.isCustomRecommendationsSet
							? this.state.recommendations
							: res.recommendations,
						res.title,
					);
					this.initializeCountdown(
						swiperWrapperEl,
						endscreenEl,
						countdownObj,
						swiper,
						upcomingRecVideoId,
					);
				});
			};
		}
	}
}

videojs.registerPlugin('recommendation', Recommendation);
